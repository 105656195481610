import { Component, OnInit } from '@angular/core';
import * as moment from "moment";

@Component({
    selector: 'app-edit-cert-date-modal',
    templateUrl: './edit-cert-date-modal.component.html',
    styleUrls: ['./edit-cert-date-modal.component.scss']
})
export class EditCertDateModalComponent implements OnInit {
    public date = moment().format('DD-MM-YYYY');

    constructor() {}

    ngOnInit(): void {}
}
