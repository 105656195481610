<p>test komponentów!</p>
<!-- <app-message [isFixed]="false"></app-message><app-message></app-message>

<app-form-select></app-form-select> -->
<!-- <textarea class="ta" cdkTextareaAutosize>
    </textarea>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <div class="ta">
        <app-large-text-field [limit]="'5000'"></app-large-text-field>
    </div>

    <app-form-select [placeholderText]="'Select option'" [labelText]="'Test przeniesienia selecta'"></app-form-select> -->
<!-- <app-number-input [isDisabled]="true"></app-number-input>
<app-url-field [isDisabled]="true"></app-url-field>
<app-url-field></app-url-field>
<br>
<app-form-select [isMulitSelect]="true" [placeholderText]="'Select option(s)'" [labelText]="'Test przeniesienia selecta'"></app-form-select>
<app-large-text-field [limit]="'5000'"></app-large-text-field>
<app-number-input [model]="''" [isDecimal]="false" style=" width:fit-content; height: fit-content; "></app-number-input>
<app-form-builder-calendar [disabled]="true"></app-form-builder-calendar><br><br>
<app-form-builder-button></app-form-builder-button>

<app-slider-template></app-slider-template> -->
<!-- <br>
    <app-number-input [isDecimal]="false" style=" width:fit-content; height: fit-content; "></app-number-input>

    <app-form-builder-button></app-form-builder-button>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>

    <app-form-builder-button></app-form-builder-button>
    <app-text-field></app-text-field>


    <app-drag-drop></app-drag-drop>

    <app-table *ngIf="!loading" [column]="column" [rows]="users2" (filtersChange)="getFilters($event)" [functions]="functions"></app-table>


    <app-bulk-actions></app-bulk-actions>

    <app-radio-button-group></app-radio-button-group>

    <app-filters-search></app-filters-search>

    <app-filters-number [number]="3"></app-filters-number>

    <app-filters-star [rating]="1"></app-filters-star>

    <app-filters-category></app-filters-category>

c
    <app-filters-comments></app-filters-comments>
    <app-filters-list></app-filters-list>
    <app-form-select [isMulitSelect]="true"></app-form-select>
    <app-text-field></app-text-field>


    <app-support-content-container style="position: fixed;  width: 100%;"></app-support-content-container> -->
<!-- <input type="number" pattern="[0-9]*" style="width: 100%; height: 60px;">
    <app-number-input [isSuffix]="true" [model]="'0.00'" [isDecimal]="true" style=" width:fit-content; height: fit-content;   "></app-number-input>
 
    <app-button-primary [isFullWidth]="false" [text]="'Button'"></app-button-primary>
    <app-button-primary [isActive]="false" [isFullWidth]="true" [text]="'disabled'"></app-button-primary>

    <app-button-secondary [isGreen]="true" [isText]="true" [buttonText]="'Add new product'" [imgUrl]="'../../../assets/svg/plus-icon.svg'"></app-button-secondary>
    <app-button-secondary [isGreen]="false" [isText]="true" [buttonText]="'Close'" [imgUrl]="'../../../assets/svg/minus-icon.svg'"></app-button-secondary>

    <app-heading1 [isTextBlack]="true" [text]="'Test1'"></app-heading1>
    <app-heading2 [isTextBlack]="true" [text]="'Test2'"></app-heading2>
    <app-heading3 [isTextBlack]="true" [text]="'Test3'"></app-heading3>
    <app-heading4 [isTextBlack]="true" [text]="'Test4'"></app-heading4>
    <app-heading5 [isTextBlack]="true" [text]="'Test5'"></app-heading5>

    <app-paragraph1 [isTextBlack]="true" [text]="'Test6'"></app-paragraph1>
    <app-paragraph2 [isTextBlack]="true" [text]="'Test7'"></app-paragraph2>

    <app-form-builder-button></app-form-builder-button>


    <app-filters [isActive]="false"></app-filters>

    <app-notification-overlay></app-notification-overlay>

    <app-conversation-preview [head]="'Head title'"></app-conversation-preview>
    <app-tick-box></app-tick-box>

    <app-tick-box [value]="true"></app-tick-box>
    <app-tick-box [value]="false"></app-tick-box>


    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>

    <app-form-builder-button></app-form-builder-button>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>
    <app-conversation-preview [head]="'conversation'"></app-conversation-preview>

    <app-form-builder-button></app-form-builder-button>
    <app-text-field></app-text-field>


    <app-drag-drop></app-drag-drop>

    <app-form-select [placeholderText]="'Select option'" [labelText]="'Test przeniesienia selecta'"></app-form-select>
    <app-form-select [isMulitSelect]="false" [placeholderText]="'Select option(s)'" [labelText]="'Test przeniesienia selecta'"></app-form-select>

    <app-bulk-actions></app-bulk-actions>

    <app-radio-button-group></app-radio-button-group>

    <app-filters-search></app-filters-search>

    <app-filters-number [number]="3"></app-filters-number>

    <app-filters-star [rating]="1"></app-filters-star>

    <app-filters-category></app-filters-category>

    <app-filters-calendar></app-filters-calendar>

    <app-filters-comments></app-filters-comments>
    <app-filters-list></app-filters-list>
    <app-form-select [isMulitSelect]="true"></app-form-select>
    <app-text-field></app-text-field>

    <div class="test-table">
        <app-filters-list></app-filters-list>
    </div>
    <app-number-field></app-number-field>


    <app-text-field></app-text-field>


    <app-number-field></app-number-field>
 
    <app-text-field [limit]="200"></app-text-field>

    <app-large-text-field [limit]="'5000'"></app-large-text-field>

    <app-drag-drop></app-drag-drop>

    <app-filters-list></app-filters-list><br><br>
    <app-form-builder-calendar></app-form-builder-calendar><br><br>
    <app-table *ngIf="!loading" [column]="column" [rows]="users2" (filtersChange)="getFilters($event)" [functions]="functions"></app-table>

    <app-notification-overlay [isToogle]="true"></app-notification-overlay> -->
<!-- <app-application-result></app-application-result>
<app-form-select [placeholderText]="'Select option'" [labelText]="'Test przeniesienia selecta'"></app-form-select>
<app-form-select [isMulitSelect]="false" [placeholderText]="'Select option(s)'" [labelText]="'Test przeniesienia selecta'"></app-form-select>
<app-input-field></app-input-field>
<app-large-text-field [limit]="'5000'"></app-large-text-field>
<app-number-input [isDisabled]="true"></app-number-input>
<app-text-field></app-text-field>
<app-url-field></app-url-field> -->
<!-- <app-slider-template></app-slider-template> -->
<!-- <app-number-input [step]="0.001" [isDisabled]="false" [isDecimal]="true" [isNegative]="false"></app-number-input>
<app-form-select [isMulitSelect]="true"></app-form-select> -->
<!-- <app-table *ngIf="!loading" [column]="column" [rows]="users2" (filtersChange)="getFilters($event)" [bulkActionsModel]="bam"></app-table>
<app-url-field></app-url-field> -->
<!-- <button (click)="active = !active">enable</button>
<app-product-card [display]="active"></app-product-card> -->
<!-- <app-avatar [model]="{name: 'Amanda test', imgUrl:'''}"></app-avatar>
<app-avatar [model]="{name: 'Zbigniew test'}"></app-avatar> -->
<app-confetti></app-confetti>
<app-number-input [isDisabled]="false"></app-number-input>
<app-pagination [pages]="5"></app-pagination>
<app-number-field [sufix]="'%'"></app-number-field>
<app-filters-calendar></app-filters-calendar>
<app-filters-list></app-filters-list>
<app-form-select></app-form-select>
<app-filters-list></app-filters-list>