<div class="container">
    <div class="option" *ngFor="let option of options; let i = index">
        <mat-radio-button disabled></mat-radio-button>
        <div>
            <input [(ngModel)]="option.label" (ngModelChange)="updateData()" placeholder="Option label" />
            <div class="value">
                <label>Value:</label>
                <input placeholder="#" [(ngModel)]="option.value" (ngModelChange)="updateData()" />
            </div>
        </div>
        <button class="rm-btn" (click)="removeOption(i)">-</button>
    </div>
    <button class="add-btn" (click)="insertOption()">+</button>
</div>