import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { Theme } from 'src/app/model/responsible/Theme';
import { PartialService } from 'src/app/services/partial.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { ThemeAPI } from 'src/app/services/responsible/theme.service';

import { LoaderComponent } from '../../../partials/loader/loader.component';
@Component({
    selector: 'app-edit-themes',
    templateUrl: './edit-themes.component.html',
    styleUrls: ['../admins.component.scss', './edit-themes.component.scss']
})
export class EditThemesComponent implements OnInit {
    public themes:Theme[] = [];

    constructor(
        private themeApi:ThemeAPI,
        private attachmentApi:AttachmentAPI,
        private partials:PartialService,
    ){
        this.themeApi.index().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.themes = [];
            for(let t of response)
                this.themes.push(Theme.create(t));
                LoaderComponent.show.emit(false);
        })
    }

    ngOnInit():void{}

    public uploadIcon(e: any, target:Theme) {
        let flist = e.target.files;
        if (flist.item(0)) {
            if (!['image/png', 'image/jpeg', 'image/svg+xml'].includes(flist.item(0)!.type)) {
                this.partials.notificator.emit({ type:'error', message:'Icon must be of PNG, JPEG or SVG file type.', timeout: 5000 });
                return;
            }
            let added = false;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                switch (res.type) {
                    // case HttpEventType.Sent:
                    // case HttpEventType.UploadProgress:
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        target.icon = att;
                        target.iconId = att.id;
                        break;
                }
            }, err => {
                if (added) {
                    target.icon = undefined;
                    target.iconId = undefined;
                }
                this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
            });
        }
    }
    public uploadImage(e: any, target:Theme) {
        let flist = e.target.files;
        if (flist.item(0)) {
            if (!['image/png', 'image/jpeg'].includes(flist.item(0)!.type)) {
                this.partials.notificator.emit({ type:'error', message:'Icon must be of PNG or JPEG file type.', timeout: 5000 });
                return;
            }
            let added = false;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                switch (res.type) {
                    // case HttpEventType.Sent:
                    // case HttpEventType.UploadProgress:
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        target.image = att;
                        target.imageId = att.id;
                        break;
                }
            }, err => {
                if (added) {
                    target.image = undefined;
                    target.imageId = undefined;
                }
                this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
            });
        }
    }

    public send(){
        this.themeApi.update(this.themes).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Themes have been updated', timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error',message:err.error.message, timeout: 5000 });
        });
    }

    public addNew(){
        this.themes.push(new Theme());
    }

    public del(e:Theme){
        this.themes.splice(this.themes.indexOf(e), 1);
    }
}
