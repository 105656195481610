import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-field-maker',
    templateUrl: './field-maker.component.html',
    styleUrls: ['./field-maker.component.scss']
})
export class FieldMakerComponent implements OnInit {
    @Input() public canPickOrder:boolean = true;
    @Output() public selected = new EventEmitter<string>();

    public expanded:boolean = false
    constructor(){}

    ngOnInit():void{}

    public select(type:string){
        this.selected.emit(type);
    }
    public close(){
        this.selected.emit();
    }
}
