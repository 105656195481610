import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, DoCheck } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
@Component({
  selector: 'app-large-text-field',
  templateUrl: './large-text-field.component.html',
  styleUrls: ['./large-text-field.component.scss']
})
export class LargeTextFieldComponent implements OnInit {
  @Input() public model: string = ''
  @Input() public isError: boolean = false
  @Input() public errorText: string = 'This is example error message'
  @Input() public placeholderText: string = 'Input text here'
  @Input() public multiline: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() isTextLimit: boolean = true
  @Input() limit: string = '500'
  @Output() public modelChange = new EventEmitter<string>();

  // @ViewChild('autosize') autosize?: CdkTextareaAutosize; 

  public isFocused: boolean = false
  public isAccepted: boolean = false
  public isEmpty: boolean = true
  constructor() { }


  ngOnInit(): void {
  }

  ngOnChanges(changes: any){ 
    if(changes.model.currentValue) this.model = changes.model.currentValue
    else if(changes.model == undefined || changes.model.currentValue == undefined) this.model = ''
}

  onFocus(event: any) {
    if (event) {
      this.isFocused = true;
      this.isAccepted = false
    }
  }
 
  ngAfterContentChecked(): void {
    this.istextAreaEmpty(this.model)
  }
  onBlur() {
    if (this.isEmpty) {
      this.isFocused = false;
    } else {
      this.isAccepted = true
    }
  }

  istextAreaEmpty(text: string) {
    if (!text || text.length == 0) {
      this.isEmpty = true
    } else {
      this.isEmpty = false
      // this.isFocused = true
    }
  }

  public placeholderVal() {
    if (this.model || this.model !== undefined) {
      if (this.isFocused) {
        if (this.model == null) return true
        else if (this.model.length > 0) return true
        else return true
      }
      else {
        if (this.model == null) return false
        else if (this.model.length > 0) return true
        else return false
      }
    } else return false
  }
public onEnterPress(event : Event){
  event.preventDefault()
}
}
