import { User, user_response } from "../User";
import { Application, application_response } from "./Application";
import { Attachment, attachment_response } from "./Attachment";
import { DefaultAnswer, default_answer_response } from "./DefaultAnswer";

export interface provider_response{
    id:number;
    name:string;
    email:string;
    phone?:string;
    website?:string;
    about?:string;
    show:boolean;

    owner_id:number;
    banner_id?:number|null;
    logo_id?:number|null;

    owner?:user_response;
    banner?:attachment_response;
    logo?:attachment_response;
    applications?:application_response[];
    defaults?:default_answer_response[];
}
export class Provider{
    public id:number = 0;
    public name:string = '';
    public email:string = '';
    public phone:string = '';
    public website:string = '';
    public about:string = '';
    public show:boolean = true;

    public ownerId:number = 0;
    public bannerId?:number|null;
    public logoId?:number|null;

    public owner?:User;
    public banner?:Attachment;
    public logo?:Attachment;

    public applications:Application[] = [];
    public defaults:DefaultAnswer[] = [];

    public map(data:provider_response){
        this.id = data.id;
        this.name = data.name;
        this.email = data.email;
        this.phone = data.phone||'';
        this.website = data.website||'';
        this.about = data.about||'';
        this.show = data.show;

        this.ownerId = data.owner_id;
        this.bannerId = data.banner_id;
        this.logoId = data.logo_id;

        this.owner = data.owner?User.create(data.owner):undefined;
        this.banner = data.banner?Attachment.create(data.banner):undefined;
        this.logo = data.logo?Attachment.create(data.logo):undefined;

        this.applications = [];
        for(let a of data.applications??[])
            this.applications.push(Application.create(a));

        this.defaults = [];
        for(let d of data.defaults??[])
            this.defaults.push(DefaultAnswer.create(d));

        return this;
    }
    public responsify():provider_response{
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            website: this.website,
            about: this.about,
            show: this.show,

            owner_id: this.ownerId,
            banner_id: this.bannerId,
            logo_id: this.logoId,

            defaults:(() => {
                let arr:default_answer_response[] = [];
                for(let d of this.defaults) arr.push(d.responsify());
                return arr;
            })()
        }
    }
    public static create(data:provider_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public get applicationCount(){
        return this.applications.length;
    }
    public get ownerName(){
        return this.owner?.fullName;
    }
}