import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { SupportCheck } from 'src/app/model/responsible/SupportCheck';
import { SupportVideo } from 'src/app/model/responsible/SupportVideo';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { FormBuilderComponent } from '../form-builder/form-builder.component';

@Component({
    selector: 'app-support-content',
    templateUrl: './support-content.component.html',
    styleUrls: ['./support-content.component.scss']
})
export class SupportContentComponent implements OnInit {
    @Input() public section:FormSection = new FormSection();
    constructor(
        private attachmentApi:AttachmentAPI,
        private dialog:MatDialog
    ) { }

    ngOnInit():void{}

    //? Videos
    public addNewVideo(){
        this.section.supportVideos.push(new SupportVideo());
    }
    public deleteVideo(index:number){
        this.section.supportVideos.splice(index, 1);
        FormBuilderComponent.changed = true;
    }
    public moveVideoUp(index:number){
        let item = this.section.supportVideos.splice(index, 1);
        this.section.supportVideos.splice(index-1, 0, item[0]);
        FormBuilderComponent.changed = true;
    }
    public moveVideoDown(index:number){
        let item = this.section.supportVideos.splice(index, 1);
        this.section.supportVideos.splice(index+1, 0, item[0]);
        FormBuilderComponent.changed = true;
    }
    
    public changeMade(){
        FormBuilderComponent.changed = true;
    }
}
