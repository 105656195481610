import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../../model/User";
import { MailingGroup } from "../../model/MailingGroup";
import { UsersAPIService } from "../../services/users.service";
import { CodedResponseModel } from "../../model/CodedResponseModel";
import { MailingGroupService } from '../../services/mailing-group.service';

///notyfications state message
import { NotyficationMessageStateModel } from 'src/app/model/NotyficationMessageStateModel';

@Component({
  selector: 'app-mailing-group-edit',
  templateUrl: './mailing-group-edit.component.html',
  styleUrls: ['./mailing-group-edit.component.scss']
})
export class MailingGroupEditComponent implements OnInit {
  //loader
  public showLoader: boolean = false;
  public showNotyfication: boolean = false;
  public notyficationMessage: string = '';
  public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
  //loader END



  public new: boolean = false;
  public mailingGroup: MailingGroup = new MailingGroup();
  public usersOrginal: Array<User> = [];
  public users: Array<User> = [];

  public filter:string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usersAPIService: UsersAPIService,
    private mailingGroupService: MailingGroupService
  ) {
    if (this.route.snapshot.data.new) {
      this.new = true;
    }
  }

  ngOnInit(): void {
    this.getUsers();
  }

  private getUsers() {
    this.showLoader = true;
    this.usersAPIService.index().subscribe(
      response => {
        let codedResponseModel = new CodedResponseModel();
        codedResponseModel.map(response);
        let orginalResponse = codedResponseModel.decodeData();
        if (orginalResponse) {
          orginalResponse.forEach((item: MailingGroup) => {
            let user = new User();
            //user.map(item);
            this.usersOrginal.push(user);
          });
        }
        if (this.new) {
          this.showLoader = false;
        }else{
          this.getMailingGroup();
        }
        this.setUser();
      }, err => {
        this.showLoader = false;
        this.showNotyfication = true;
        this.notyficationMessage = err.error.message;
        this.notyficationMessageStateModel.errors_save = true;
      }
    );
  }
  public setUser() {
    if(this.filter=='') this.users = this.usersOrginal;
    else {
      let tmp = [],
          lcf = this.filter.toLowerCase();
      for(let i of this.usersOrginal){
        if(i.name.toLowerCase().includes(lcf)||i.lname.toLowerCase().includes(lcf)||i.email.toLowerCase().includes(lcf)/*||i.user_role.toLowerCase().includes(lcf)*/)
          tmp.push(i);
      }
      this.users = tmp;
    }
  }

  private getMailingGroup(){
    let id: any;
    this.route.paramMap
        .subscribe(params => {
          id = params.get('id');
        });
    this.mailingGroupService.get(parseInt(id)).subscribe(
      response => {
        let codedResponseModel = new CodedResponseModel();
        codedResponseModel.map(response);
        let orginalResponse = codedResponseModel.decodeData();
        this.mailingGroup.map(orginalResponse);
        this.mailingGroup.users.forEach( item => {
          let user = this.users.find( x=> x.id == item.id);
          // if(user != undefined){
          //   user.checked = true;
          // }
        });
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        this.showNotyfication = true;
        this.notyficationMessage = err.error.message;
        this.notyficationMessageStateModel.errors_save = true;
      }
    );
  }
  public save() {
    if (this.mailingGroup.name.length > 0) {
      this.showLoader = true;
      this.mailingGroup.users = [];
      // this.users.forEach( item => {
      //   if(item.checked){
      //     this.mailingGroup.users.push(item);
      //   }
      // });
      if(this.new){
        this.mailingGroupService.save(this.mailingGroup).subscribe(
          response => {
            this.router.navigate(['mailing-group']);
  
          }, err => {
            this.showLoader = false;
            this.showNotyfication = true;
            this.notyficationMessage = err.error.message;
            this.notyficationMessageStateModel.errors_save = true;
          }
        );
      }else{
        this.mailingGroupService.update(this.mailingGroup).subscribe(
          response => {
            this.router.navigate(['mailing-group']);
  
          }, err => {
            this.showLoader = false;
            this.showNotyfication = true;
            this.notyficationMessage = err.error.message;
            this.notyficationMessageStateModel.errors_save = true;
          }
        );
      }
    }
  }

}
