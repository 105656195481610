import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { UploadModalComponent } from '../upload-modal/upload-modal.component';

export interface file_interface {
  file?: Attachment;
  filename: string;
  extension: string;
  status: string;
  progress: number;
  error?: string;
}

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.scss']
})
export class DragDropComponent implements OnInit {

  // @Input() element: ElementModel = new ElementModel();

  //@Input() value: string = `{ type: "image", url: "" }`;
  //@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();


  // public actualValue: AppMediaConfig = { type: "image", url: "" };
  public uploading: boolean = false;
  public isUploaded: boolean = false;
  public uploadingProgress: number = 0;
  public isError: boolean = false;
  public errorMsg: string = "unknown"
  public name: string = "testowa nazwa lorem inpsum"

  @Input() textLabel: string = 'Drag and Drop';
  @Input() fileList: file_interface[] = [];
  @Input() types: string = '';
  @Input() isHardcodedTypes: boolean = false
  @Input() modal:boolean = true;
  @Input() isFileLimit: boolean = true
  @Output() fileEvent = new EventEmitter<FileList>();
  @Output() fileRemoved = new EventEmitter<file_interface>();
  @Output() openModal = new EventEmitter<void>();


  constructor(
    private deviceService: DeviceDetectorService,
  ) { this.checkDevice() }

  ngOnInit(): void {
    if(this.isHardcodedTypes == false){
      if(this.types===''){
        this.types = "PDF, Word and Pages documents";
      } else {
        let t = this.types.split('|'),
            f = '';
        for(let [i, v] of t.entries()){
          if(v=='documents') f+= 'PDF, Word';
          if(v=='images') f+= 'Images';
          if(['spreadsheets','sheets'].includes(v)) f+= 'Spreadsheets';
          if(i<t.length-1) f += ', ';
        }
        this.types = f;
      }
    }
    
  }
  public sufix: string = "..."

  public isMobile: boolean = false
  getName = (name: string) => {
    if (this.isMobile) {
      if (name.length < 20) return name
      else return name.split("").splice(0, 19).join("") + this.sufix
    } else {
      if (name.length < 30) return name
      else return name.split("").splice(0, 29).join("") + this.sufix
    }
  }

  public fileUrl(file:file_interface){
    if(file.progress == 100 && !file.error && file.file)
      return file.file?.getUrl();
    else return 'javascript://';
  }

  checkDevice = () => {
    this.isMobile = this.deviceService.isMobile();

  }
  public fileUploaded(event: any) {
    this.fileEvent.emit(event.target.files);
  }
  // public valueEvent() {
  //   this.valueChange.emit(JSON.stringify(this.actualValue));
  // }

  // public dropFile(e: FileList) {
  //   try {
  //     this.uploading = true;
  //     let file = e.item(0)!;
  //     if (!this.verifyTypes().includes(file.type)) throw "Unsupported file type";
  //     if (file.size / 1024 / 1024 > this.element.params.maxSize) throw `Image cannot be larger than ${this.element.params.maxSize} MB`;

  //     if (this.isUploaded) this.deleteCurrentFile();
  //     this.fileApi.upload(file).subscribe(res => {
  //       let response = new CodedResponseModel(res).decodeData();
  //       this.actualValue.url = `media/${response.filename}`;
  //       this.actualValue.fileId = response.id;

  //       this.uploading = false;
  //       this.isUploaded = true;
  //       this.valueEvent();
  //     }, err => {
  //       this.uploading = false;
  //       this.partials.notificator.emit({ type: "error", message: "An error occured while trying to upload a file", details: err.error.message });
  //     });
  //   } catch (er) {
  //     this.uploading = false;
  //     this.partials.notificator.emit({ type: "error", message: "An error occured while trying to upload a file", details: er });
  //   }
  // }
  // public uploadFile(e: Event) {
  //   this.dropFile((e.target as any).files);
  // }

  // public deleteCurrentFile() {
  //   if (this.actualValue.fileId) {
  //     this.fileApi.delete(this.actualValue.fileId).subscribe(res => {
  //       this.actualValue.url = undefined;
  //       this.actualValue.fileId = undefined;
  //       this.valueEvent();
  //     }, err => {
  //       this.partials.notificator.emit({ type: "error", message: "Could not delete the current file", details: err.error.message });
  //     })
  //   }
  // }

  // private verifyTypes() {
  //   let types = [];
  //   if (this.element.params.acceptedTypes.includes('Images'))
  //     types.push("image/png", "image/jpeg");                                      //? PNG, JPEG
  //   //if(this.element.params.acceptedTypes.includes('Video'))
  //   //    types.push("video/webm", "video/mp4", "video/mpeg", "video/x-msvideo");   //? WEBM, MP4, MPEG, AVI
  //   if (this.element.params.acceptedTypes.includes('Audio'))
  //     types.push("audio/webm", "audio/wav", "audio/mpeg");                        //? WEBM, WAV, MPEG
  //   if (this.element.params.acceptedTypes.includes('PDF'))
  //     types.push("application/pdf");

  //   return types;
  // }
}
