<div class="container">
    <div class="main">
        <h5>let’s get started</h5>
        <h1>Start your application</h1>
        <app-text-field [placeholderText]="'Product name'"></app-text-field>
        <app-form-select [placeholderText]="'Select provider'"></app-form-select>
        <app-form-select [placeholderText]="'Select collaborator(s)'"></app-form-select>
        <app-tick-box [text]="'Pre-upload files for application'"></app-tick-box>
        <app-button-primary [text]="'Start application'"></app-button-primary>
    </div>
</div>