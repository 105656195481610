import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-login-featured-content',
    templateUrl: './login-featured-content.component.html',
    styleUrls: ['./login-featured-content.component.scss']
})
export class LoginFeaturedContentComponent implements OnInit {
    public isFeatureBrand: boolean = true;
    public rightSideImgSrc: string = 'assets/jpg/login_image.jpg';
    public isFeaturedResearchVisible: boolean = true;
    public featuredResearchTitle: string = '30 characters lipsum dolor sit';
    public featuredResearchDesc: string = '80 characters lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod';
    public featuredResearchLinkUrl: string = 'http://www.google.com';
    public featuredResearchLinkText: string = 'learn more';

    public isFeaturedBrandVisible: boolean = true;
    public featuredBrandStars: number = 5;
    public featuredBrandName: string = 'Australian Equities Trust';
    public featuredBrandLogo: string = 'assets/svg/u-ethical-logo.svg';
    @Input() public settings = {
        banner:'assets/jpg/login_image.jpg',
        featured:true,
        featuredHead: '',
        featuredStars:0,
        featuredLogo:'',
        research:true,
        researchHead:'',
        researchSubhead:'',
        researchLink:'',
        researchLinkTxt:'',
    }

    constructor(){}

    ngOnInit():void{}

    public counter(i: number) {
        return new Array(i);
    }

    public showFeaturedBrand() {
        this.isFeatureBrand = true;
    }
    public showFeaturedResearch() {
        this.isFeatureBrand = false;
    }
}
