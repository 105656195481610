import { Component, EventEmitter, Input, OnInit, Output, ViewChild,ElementRef } from '@angular/core';
import { mode } from 'crypto-js';
import { trigger, style, animate, transition } from '@angular/animations';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import {OverlayComponent } from '../overlay/overlay.component'
import { LoaderComponent } from 'src/app/partials/loader/loader.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-filters-search',
  templateUrl: './filters-search.component.html',
  styleUrls: ['./filters-search.component.scss'],
  animations: [
    trigger(
      'filtersOutIn',
      [
        transition(
          ':enter',
          [
            style({ transform: 'scaley(0) ', opacity: 0 }),
            animate('0.3s ease-out',
              style({ transform: 'scaley(1)' , opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({transform: 'scaley(1)',  opacity: 1 }),
            animate('0.3s ease-in',
              style({ transform: 'scaley(0)', opacity: 0 }))
          ]
        )
      ]
    )
  ],

})

export class FiltersSearchComponent implements OnInit {
  @Input() model: string = '';
  @Input() placeholder: string = 'Search bar';
  @Output() modelChange = new EventEmitter();
  @Output() onEnter = new EventEmitter();
  @Output() clearField = new EventEmitter();
  @Input() cellName: string = ''
  @Input() widnowWidth?: number = 82
  //to detect state of earlier enabled search
  public active: boolean = false

  //canActive search
  public enabled: boolean = false
  public sufix = "..."
  //position in table
  public positionStyle: string = ''
  public left: boolean = false
  public right: boolean = false
  @ViewChild('searchContainer') searchArea?: ElementRef

  public getOverlayState: Subscription = OverlayComponent.clicked.subscribe((res)=>{
    console.log(res)
    this.enabled = false; 
    this.scroll.enableBodyScroll()  
   })

  constructor(public scroll:BodyScrollService) { }
   ngOnInit(): void {
     this.getOverlayState
  }
  ngOnDestroy(){
    this.getOverlayState.unsubscribe()
   }
  public change(newValue: string) {
    if (newValue.length <= 30) {
      this.model = newValue.trim();
      if (newValue.substring(0, 1) == " ") return
      else this.modelChange.emit(newValue.toLowerCase());

    }
  }
 
  public getModel() {
    if (this.model.length <= 29) {
      return this.model
    }
    else {
      return this.model + this.sufix
    }
  }

  public clear() {
    OverlayComponent.show.emit(false)
    this.enabled = false
    this.scroll.enableBodyScroll()
    this.change('')
    this.clearField.emit()
  }
  
  public setSelectPostion() {  
     if (this.searchArea && this.widnowWidth && window.innerWidth > 767 && this.enabled) { 
        const selectRect = this.searchArea.nativeElement.getBoundingClientRect();
        const selectR = selectRect.right;
        let windowWidth = window.innerWidth;
        let menuWidth = 82
         if(this.widnowWidth !== undefined) {
          windowWidth = this.widnowWidth; 
          menuWidth = window.innerWidth - this.widnowWidth
         }
        const spaceToLeft = windowWidth - (windowWidth - selectR + 350) - menuWidth
        const spaceToRight = windowWidth - selectR + menuWidth
        // console.log('lewo',spaceToLeft)    
        // console.log('prawo',spaceToRight)
          if (spaceToLeft < 10) {
            if(this.left == false){
              this.left = true
              this.right = false    
            }
              this.positionStyle = 'transform: translateX(' + (Math.abs(spaceToLeft) + 20 ) + 'px)'
          }
          else if (spaceToRight < 10){
            if(this.right == false){
              this.right = true
              this.left = false 
             }
             this.positionStyle = 'transform: translateX( -' + (Math.abs(spaceToRight) + 20 ) + 'px)'
             }
            else if((spaceToLeft >= 10 && !this.left ) && (spaceToRight >= 10 && !this.right)){
            this.right = false
            this.left = false
            this.positionStyle = ''
        }
      }
    }
    public animFinish(event: any){
      if(!this.enabled) this.positionStyle = ''
    }
    activate(){
      this.enabled=true;
       this.setSelectPostion(); 
       this.scroll.disableBodyScroll()
      OverlayComponent.show.emit(true)
    }
}
