import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Application } from 'src/app/model/responsible/Application';


@Component({
    selector: 'app-application-progress',
    templateUrl: './application-progress.component.html',
    styleUrls: ['./application-progress.component.scss']
})
export class ApplicationProgressComponent implements OnInit {
    @Input() public application: Application = new Application();
    @Output() public editApp = new EventEmitter<void>();

    public currentStage: number = 0;

    constructor() { }

    ngOnInit(): void {
        console.log(this.application)
        switch (this.application.status) {
            case 'New Application':
            case 'In Progress':
            case 'RIAA Assessment':
                this.currentStage = 0;
                break;
            case 'IVP Assessment':
                this.currentStage = 1;
                break;
            case 'Approved':
                this.currentStage = 2;
                break;
            case 'Conditional':
                this.currentStage = 3;
                break;
            case 'Denied':
                this.currentStage = 4;
                break;
        }
    }
}
