import { Component, OnInit } from '@angular/core';
import { PartialService } from '../services/partial.service';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-ups.component.html',
  styleUrls: ['./pop-ups.component.scss']
})
export class PopUpsComponent implements OnInit {
  public exists: boolean = false;
  public display: boolean = false;
  public popUp: number = 0;

  constructor(private comm: PartialService,) { 
    this.comm.popUps.subscribe(res => {

      if (res.isActive) {
        this.exists = true;
        setTimeout(() => { this.display = true }, 20);
      }
      if (!res.isActive) {
        this.closePoUp();
      }
      if(res.id) {
        this.popUp = res.id;
      }
    })
  }

  ngOnInit(): void {
  }

  public closePoUp() {
    this.display = false;
    setTimeout(() => { this.exists = false; }, 400);
  }

}

export interface PopUpsCall {
  isActive: boolean;
  id: number;
}

/**
 * Display a notification
 * @param {number} popUp 1 - File upload modal
 */