import { ViewportScroller } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { MenuComponent } from 'src/app/menu/menu.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Application, application_response } from 'src/app/model/responsible/Application';
import { ApplicationAnswer } from 'src/app/model/responsible/ApplicationAnswer';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { Provider, provider_response } from 'src/app/model/responsible/Provider';
import { User, UserTypes, user_response } from 'src/app/model/User';
import { PartialService } from 'src/app/services/partial.service';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { FormAPI } from 'src/app/services/responsible/forms.service';
import { ProviderAPI } from 'src/app/services/responsible/provider.service';
import { UsersAPIService } from 'src/app/services/users.service';

import { LoaderComponent } from '../../partials/loader/loader.component';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { SectionBundle } from 'src/app/model/responsible/FormSectionBundle';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss']
})
export class ApplicationEditorComponent implements OnInit, OnDestroy {
    public application: Application = new Application();
    public providers: Provider[] = [];
    public members: User[] = [];
    public collaborators: User[] = [];
    public bannerFile: file_interface[] = [];
    public form:SectionBundle = new SectionBundle();
    public preuploads: file_interface[] = [];

    public isAdmin:boolean = false;

    public new: boolean = true;

    public currSection: number = -1;

    public pCategories: ProductCategory[] = [];
    public pTargets: ProductTarget[] = [];
    public markets: Market[] = [];
    public strategies: RIStrategy[] = [];

    public applications: Application[] = [];

    public cloneApp: boolean = false;
    public appToClone?: Application
    public preuploadFiles: boolean = false;

    public lastAutosave?: Date;
    public autosaver: any;

    public static changed: boolean = false;

    public isContentReady: boolean = false
    public isViewReady: boolean = true
    public isBottom: boolean = false

     //width of suppoort content

    // @ViewChild('confetti') confetti?: ElementRef
    // @ViewChild('scontent') scontent?: ElementRef

    public selType:string = '';
    public pdfTypes = [
        { label:'Download full application', type:'answers' },
        // { label:'Download star rating questions', type:'starred' },
        { label:'Download front-end questions', type:'front' },
        { label:'Download exclusion list', type:'exclusions' }
    ];
    public downloadPdf:any;

    public membersLoading:boolean = false;
    public providersLoading:boolean = false;
    public collabsLoading:boolean = false;
    public appsLoading:boolean = false;

    public membersCooldown:any;
    public providersCooldown:any;
    public collabsCooldown:any;
    public appsCooldown:any;

    // public membersSearch:string = '';
    // public providersSearch:string = '';
    // public collabsSearch:string = '';

    constructor(
        private applicationApi: ApplicationAPI,
        private formApi: FormAPI,
        private providerApi: ProviderAPI,
        private userApi: UsersAPIService,
        private attachmentApi: AttachmentAPI,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private scroll: ViewportScroller,
        private partial: PartialService,
        private jwt: JwtHelperService,
    ) {
        if([UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type)) this.isAdmin = true;
        LoaderComponent.menuHidden.emit(true)
        MenuComponent.show.emit(true);
        ApplicationEditorComponent.changed = false;
        this.route.paramMap.subscribe(p => {
            let id = p.get('id');
            let from = p.get('from');
            if (from) {
                this.new = true;
                this.applicationApi.get(Number(from)).subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.application = Application.create(response);
                    this.application.id = 0;
                    this.bannerFile[0] = {
                        file: this.application.banner,
                        filename: this.application.banner?.filename ?? '',
                        extension: this.application.banner?.getExtension() ?? '',
                        status: 'ok',
                        progress: 100,
                    }
                    for(let a of this.application.answers) a.id = 0;
                    this.formApi.getLatest().subscribe(res => {
                        let response = CodedResponseModel.decode(res);
                        this.form.sections = [];
                        for(let s of response.form)
                            this.form.sections.push(FormSection.create(s));

                        this.application.formId = Number(response.extra.currForm);
                        this.application.form = this.form;
                        this.preuploadFiles = this.application.pFiles.length!=0;
                        if(this.preuploadFiles)
                            this.preuploads = this.application.pFilesList.map(f => ({
                                file: f,
                                filename: f.label,
                                extension: f.filename.split('.').pop()??'',
                                progress: 100,
                                status: 'ok'
                            }));
                        this.form.confirmAllCondis();
                        this.form.mapAnswers(this.application.answers);
                        this.application.conversations = [];

                        ApplicationEditorComponent.changed = false;
                        this.isContentReady = true;
                        LoaderComponent.show.emit(false);
                    })
                })
            } else if (id !== 'new') {
                this.new = false;
                this.applicationApi.get(Number(id)).subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.application = Application.create(response);
                    console.log(this.application);
                    this.bannerFile[0] = {
                        file: this.application.banner,
                        filename: this.application.banner?.filename ?? '',
                        extension: this.application.banner?.getExtension() ?? '',
                        status: 'ok',
                        progress: 100,
                    }
                    this.form = this.application.form!;
                    this.preuploadFiles = this.application.pFiles.length!=0;
                    if(this.preuploadFiles)
                        this.preuploads = this.application.pFilesList.map(f => ({
                            file: f,
                            filename: f.label,
                            extension: f.filename.split('.').pop()??'',
                            progress: 100,
                            status: 'ok'
                        }));
                    this.form.mapAnswers(this.application.answers);
                    this.form.confirmAllCondis();

                    this.isContentReady = true;
                    LoaderComponent.show.emit(false);
                    this.partial.conversations.emit({ applicationId: this.application.id, formId: this.application.formId, name:this.application.productName });
                    this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
                }, err => {
                    console.error(err);
                })
            } else this.fetchFormData();

        });
        this.route.queryParamMap.subscribe(q => {
            let qk = q.get('q'),
                view = q.get('view'),
                cert = q.get('cert');
            if (qk) {
                let f = () => {
                    if (this.form.sections.length) {
                        base: for (let [i, s] of this.form.sections.entries()) {
                            for (let q of s.questions) {
                                if (q.key == qk) {
                                    this.currSection = i;
                                    break base;
                                }
                                for (let u of q.subquestions) {
                                    if (u.key == qk) {
                                        this.currSection = i;
                                        break base;
                                    }
                                }
                            }
                        }
                        setTimeout(() => { this.scroll.scrollToAnchor(qk!) }, 500);
                    } else setTimeout(() => { f() }, 100);
                }
                f();
            }
            else if (view !== undefined) {
                let f = () => {
                    if (this.form.sections.length) {
                        this.currSection = -4;
                    } else setTimeout(() => { f() }, 100);
                }
            }
            else if (cert !== undefined) {
                let f = () => {
                    if (this.form.sections.length) {
                        this.currSection = -5;
                    } else setTimeout(() => { f() }, 100);
                }
            }
        })
        this.fetchRelatedData();
    }


    ngOnInit(): void {
        // this.setupConfetti()
         console.log(this.form.sections);
        //  LoaderComponent.menuHidden.emit(true)
        //  LoaderComponent.show.emit(true)

    }
    ngOnDestroy():void{
        clearTimeout(this.autosaver);
    }


    private fetchFormData() {
        LoaderComponent.show.emit(true);
        if(this.new){
            this.formApi.getLatest().subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.form.sections = [];
                for(let s of response.form)
                    this.form.sections.push(FormSection.create(s));

                this.application.formId = Number(response.extra.currForm);
                this.application.form = this.form;
                this.form.confirmAllCondis();

                ApplicationEditorComponent.changed = false;
                this.isContentReady = true;
                LoaderComponent.show.emit(false);
            })
        }

    }
    private fetchRelatedData() {
        LoaderComponent.show.emit(true);
        this.applicationApi.essentials().subscribe(res => {
            let response = CodedResponseModel.decode(res);
            for(let p of response.providers)
                this.providers.push(Provider.create(p));
            // for(let c of response.collaborators)
            //     this.collaborators.push(User.create(c));
            for(let a of response.apps)
                this.applications.push(Application.create(a));

            if(this.isAdmin){
                this.collaborators = (response.collaborators as user_response[]).map(c => User.create(c));
                this.members = (response.members as user_response[]).map(c => User.create(c));
            } else
                this.collaborators = (response.collaborators as user_response[]).map(c => User.create(c));

            LoaderComponent.show.emit(false);
            this.isContentReady = true;
        });

        console.log(this.collaborators)

    }
    public send(status?: string) {
        if (status) this.application.status = status;
        this.submitApplication(true);
    }
    private submitApplication(redirect?: boolean) {
        if(this.autosaver) clearTimeout(this.autosaver);
        if (this.new) {
            this.applicationApi.create(this.application).subscribe(res => {
                if (redirect) this.currSection = -4;
                let response = CodedResponseModel.decode(res);
                this.new = false;
                this.application.id = response.id;
                this.application.userId = response.user_id;
                LoaderComponent.show.emit(false);
                this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
            });
        } else {
            this.applicationApi.update(this.application, false).subscribe(res => {
                if (redirect) this.currSection = -3;
                this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
            })
        }
    }
    public editApp(){
        if(this.autosaver) clearTimeout(this.autosaver);
        this.applicationApi.update(this.application, false).subscribe(res => {
            this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
            this.partial.notificator.emit({ type:'success', message:'Application updated', timeout: 5000 });
        }, err => {
            this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
            this.partial.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
    public submitApp(){
        if(this.autosaver) clearTimeout(this.autosaver);
        this.partial.loader.emit(true);
        this.application.status = 'New Application';
        this.applicationApi.update(this.application, false).subscribe(res => {
            this.currSection = -4;
            this.partial.loader.emit(false);
            this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
        }, err => {
            this.partial.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
            this.partial.loader.emit(false);
            this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
        })
    }
    private autosave(repeat?: boolean) {
        if (ApplicationEditorComponent.changed) {
            this.applicationApi.update(this.application, true).subscribe(res => {
                this.lastAutosave = new Date();
            }, err => {
                //
            })
            ApplicationEditorComponent.changed = false;
        }
        if (repeat) this.autosaver = setTimeout(() => { this.autosave(true); }, 5000);
    }
    public uploadBanner(flist: FileList) {
        if (flist.item(0)) {
            if (!['image/png', 'image/jpeg'].includes(flist.item(0)!.type)) {
                console.error('Banner image must be of PNG or JPEG file type');
                return;
            }
            if (this.bannerFile[0]) this.bannerFile.pop();
            let added = false,
                handle: file_interface;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        this.bannerFile[0] = handle;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        console.log(Math.round(res.loaded / res.total! * 100));
                        this.bannerFile[0].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.application.banner = att;
                        this.application.bannerId = att.id;
                        this.bannerFile[0].file = att;
                        this.bannerFile[0].progress = 100;
                        ApplicationEditorComponent.changed = true;
                        break;
                }
            }, err => {
                if (added) {
                    this.application.banner = undefined;
                    this.bannerFile.pop();
                }
                console.warn(err);
            });
        }
    }
    public preuploadFile(flist: FileList) {
        if (flist.item(0) && this.preuploads.length<5 ) {
            let added = false,
                index: number,
                handle: file_interface;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        index = this.preuploads.push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        //console.log(Math.round(res.loaded/res.total!*100));
                        this.preuploads[index].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.application.pFiles.push(att.id);
                        this.preuploads[index].file = att;
                        this.preuploads[index].progress = 100;
                        break;
                }
            }, err => {
                if (added) {
                    this.preuploads.splice(index, 1);
                }
                console.warn(err);
            });
        }
    }
    public deleteBanner() {
        this.application.banner = undefined;
        this.bannerFile.pop();
        ApplicationEditorComponent.changed = true;
    }
    public deletePreupload(file: file_interface) {
        let index = this.preuploads.indexOf(file);
        this.preuploads.splice(index, 1);
        this.application.pFiles.splice(this.application.pFiles.indexOf(file.file!.id), 1);
    }

    public startApplication() {
        if(this.application.description && this.application.productName && this.bannerFile !== undefined && this.application.provider !== undefined) {
            LoaderComponent.show.emit(true)
            if (this.new ) {
                if (this.cloneApp && this.appToClone) {
                    LoaderComponent.show.emit(true)
                    this.applicationApi.get(this.appToClone.id).subscribe(res => {
                        let response = CodedResponseModel.decode(res);
                        let answers = (() => {
                            let arr = [];
                            for (let a of response.answers){
                                a.id = 0;
                                arr.push(ApplicationAnswer.create(a));
                            }
                            return arr;
                        })();
                        this.application.answers = answers;
                        this.form.mapAnswers(answers);

                        this.application.status = 'In Progress';
                        this.submitApplication();
                        this.currSection = 0;
                        LoaderComponent.show.emit(false)
                    });
                } else {
                    this.application.status = 'In Progress';
                    for(let d of this.application.provider.defaults){
                        let a = new ApplicationAnswer();
                        a.questionId = d.questionId;
                        a.value = d.value;
                        a.files = d.files;
                        this.application.answers.push(a);
                        let q = this.form.getQuestionById(d.questionId);
                        if(q) q.answer = a;
                    }
                    this.submitApplication();
                    this.currSection = 0;
                }
            } else {
                this.currSection = 0;
                LoaderComponent.show.emit(false)
            }
        }
    }

    public openUploader(f: 'banner' | 'preupload') {
        let d = this.dialog.open(UploadModalComponent, {
            panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) {
                if (f === 'banner') this.uploadBanner(res.file);
                else if (f === 'preupload') this.preuploadFile(res.file);
            }
        });
    }
    public nextSection() {
        if (this.currSection == this.form.sections.length - 1) this.currSection = (this.application.status=='In Progress' ? -2 : -3);
        else this.currSection++;
    }
    public changesMade() {
        ApplicationEditorComponent.changed = true;
    }
    public generatePdf(){
        LoaderComponent.show.emit(true);
        if(!this.selType) return;
        if(this.selType==='exclusions'){
            this.applicationApi.getExclusionsPdf(this.application.id).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.downloadPdf = {
                    size: this.fSize(res.size),
                    url: `${environment.pdfUrl}${res.fname}`,
                    name: 'EXCLUSIONS'
                }
                LoaderComponent.show.emit(false);
            })
        } else {
            this.applicationApi.getPdf(this.application.id, this.selType).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.downloadPdf = {
                    size: this.fSize(res.size),
                    url: `${environment.pdfUrl}${res.fname}`,
                    name: this.selType==='answers'?'APPLICATION':this.selType==='starred'?'STARRED':'FRONT-END'
                }
                LoaderComponent.show.emit(false);
            });
        }
    }

    public memberSearch(query:string){
        if(this.membersCooldown) clearTimeout(this.membersCooldown);
        this.membersCooldown = setTimeout(() => {
            this.membersLoading = true;
            this.userApi.search({ query: query, types: [UserTypes.Member], limit:15 }).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.members = res.data.map((m:user_response) => User.create(m));
                this.membersLoading = false;
            });
        }, 500);
    }
    public providerSearch(query:string){
        if(this.providersCooldown) clearTimeout(this.providersCooldown);
        this.providersCooldown = setTimeout(() => {
            this.providersLoading = true;
            this.providerApi.search({ query: query, limit:15 }).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.providers = res.data.map((p:provider_response) => Provider.create(p));
                this.providersLoading = false;
            })
        }, 500);
    }
    public collabsSearch(query:string){
        if(this.collabsCooldown) clearTimeout(this.collabsCooldown);
        this.collabsCooldown = setTimeout(() => {
            this.collabsLoading = true;
            this.userApi.search({ query: query, types: [UserTypes.Collaborator], limit:15 }).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.collaborators = res.data.map((m:user_response) => User.create(m));
                this.collabsLoading = false;
            });
        }, 500);
    }
    public appsSearch(query:string){
        if(this.appsCooldown) clearTimeout(this.appsCooldown);
        this.appsCooldown = setTimeout(() => {
            this.appsLoading = true;
            this.applicationApi.search({ query: query, limit:15 }).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.applications = res.data.map((a:application_response) => Application.create(a));
                this.appsLoading = false;
            })
        }, 500);
    }

    public fSize(size:number){
        if(size<1024) return `${size}B`;
        size /= 1024;
        if(size<1024) return `${Math.round(size*10)/10}KB`;
        size /= 1024;
        return `${Math.round(size*10)/10}MB`;
    }

    public scrollToTop(){
        window.scrollTo(0, 0);
    }

    public debug() {
        console.log(this);
    }

    // //confetti
    // setupConfetti() {
    //     const colors = ['#4159D7', '#0098BA', '#993AC6', '#D68000', '#4BBD48', '#CB4546'];
    //     const animations = ['s', 'm', 'f'];
    //     this.renderConfetti(colors, animations)
    // }
    // renderConfetti(colors: Array<any>, animations: Array<any>) {
    //     const time = setInterval(() => {
    //         const elem: any = document.createElement('div')
    //         const elemS = (Math.floor(Math.random() * 3) + 7) + 'px';
    //         const colorsC = colors[Math.floor(Math.random() * colors.length)];
    //         let spaceLeft
    //         if (this.confetti) spaceLeft = (Math.floor(Math.random() * this.confetti.nativeElement.offsetWidth)) + 'px';
    //         const confettiAnimation = animations[Math.floor(Math.random() * animations.length)];
    //         elem.classList.add('confetti', 'c-a-' + confettiAnimation);
    //         elem.style.left = spaceLeft;
    //         elem.style.width = elemS;
    //         elem.style.height = elemS;
    //         elem.style.backgroundColor = colorsC;
    //         this.confetti?.nativeElement.appendChild(elem)
    //         setTimeout(() => {
    //             if (this.confetti) this.confetti.nativeElement.removeChild(elem)
    //         }, 3000)
    //     }, 20)
    //     setTimeout(() => {
    //         clearInterval(time)
    //     }, 3000)
    // }
}
