<div class="notifications" *ngIf="exists" [class.active]="display">
    <div class="top">
        <app-heading2 [text]="'Notifications'" [isTextBlack]="false"></app-heading2>
        <div class="filters">
            <div class="all active" (click)="filterNotifs('all')" [class.active]="activeFilter == 'all'">
                All
                <div class="divider divider1"></div>
            </div>

            <div class="new_products" (click)="filterNotifs('new')" [class.active]="activeFilter == 'new'">
                New&nbsp;<span class="hidden-xs">Products</span>
                <div class="divider divider2"></div>
            </div>
            <div class="ongoing_products" (click)="filterNotifs('ongoing')" [class.active]="activeFilter == 'ongoing'">Ongoing&nbsp;<span class="hidden-xs">products</span></div>
        </div>
    </div>
    <div class="notifications_content">
        <ng-container *ngFor="let i of notifications">
            <app-notification-overlay [clearable]="true" [head]="i.title" [name]="i.desc" [newMessage]="timestamp<i.createdAt" [avatarUrl]="i.imgUrl||''" 
                [time]="i.createdAt" (onClear)="clearNotif(i)" (click)="clickNotif(i)"></app-notification-overlay>
        </ng-container>
    </div>
</div>