import { Keyed } from "./Form";
import { FormQuestion, form_question_response } from "./FormQuestion";

export interface form_question_front_response{
    id:number;
    page:string;
    section:string;
    position:number;
    label?:string;
    question_id:number;

    question?:form_question_response;

    key?:string;
}
export class FormQuestionFront extends Keyed{
    public id:number = 0;
    public page:string = '';
    public section:string = '';
    public position:number = 0;
    public label:string = '';

    public questionId:number = 0;
    
    public question?:FormQuestion;

    public map(data:form_question_front_response){
        this.id = data.id;
        this.page = data.page;
        this.section = data.section;
        this.position = data.position;
        this.label = data.label??'';
        this.questionId = data.question_id;

        if(data.question) this.question = FormQuestion.create(data.question);

        return this;
    }
    public responsify():form_question_front_response{
        return {
            id: this.id,
            page: this.page,
            section: this.section,
            position: this.position,
            label: this.label,
            question_id: this.questionId,
            question: this.question?.responsify(),

            key:this.key,
        };
    }
    public static create(data:form_question_front_response){
        let r = new this;
        r.map(data);
        return r;
    }
}