import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { PartialService } from "src/app/services/partial.service";
import { ConvertVideoTimeService } from "src/app/services/convert-video-time.service"


@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['../video.component.scss', './youtube.component.scss']
})
@HostListener('window:resize', ['$event'])

export class YoutubeComponent implements OnInit {
  public youtubeId: any;
  public closePlaceholder: boolean = false;
  @Input() element: any;
  @Input() videoUrl: string = '';
  @Input() placeholder: string = '';
  @Input() openModal: boolean = false;
  @Input() isMetadataVisible: boolean = true;
  public player: any;
  @ViewChild('youtube') youtube?: YouTubePlayer;
  public duration: string = '';
  public title: string = '';
  public fetchDone = false;
  public innerWidth: any;

  constructor(
    private comm: PartialService,
    private videoTime: ConvertVideoTimeService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    if (this.placeholder === null || this.placeholder === '') {
      this.placeholder = '';
    }
    this.youtubeId = this.getYoutubeId();
    // This code loads the IFrame Player API code asynchronously, according to the instructions at
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  public getYoutubeId() {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = this.videoUrl.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return;
    }
  }

  public playVideo() {
    if (this.openModal && this.innerWidth > 767) {
      this.comm.videoModal.emit({ isActive: true, videoUrl: this.videoUrl });
    } else {
      this.youtube?.playVideo();
      this.closePlaceholder = !this.closePlaceholder;
    }
  }

  public getYoutubeThumbnail(): string {
    let imgSize: string = window.innerWidth > 767 ? 'maxresdefault' : '0';

    if (this.placeholder === null || this.placeholder === '') {
      this.placeholder = `https://img.youtube.com/vi/${this.youtubeId}/${imgSize}.jpg`;
    }

    return this.placeholder;
  }

  public getDuration() {
    return this.duration = this.videoTime.convertTime(this.youtube?.getDuration());
  }

  public getYoutubeTitle() {

    if (this.title == '' && !this.fetchDone) {
      fetch(`https://noembed.com/embed?dataType=json&url=${this.videoUrl}`)
        .then(res => res.json())
        .then(data => {
          this.title = data.title;
          this.fetchDone = true;
        });
      return this.title;
    }
    return this.title;
  }

  onResize() {
    this.innerWidth = window.innerWidth;
  }

}
