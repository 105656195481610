<div [class.container]='!sub'>
    <div class='question' [ngClass]="{'first': ntl(question.order)=='A' && sub,
        'sub':ntl(question.order)!='A' && sub ,'custom': question.custom}">
        <div class='question-container' [class.subquestion]="sub" [ngClass]="{'first': ntl(question.order)=='A'}">
            <div class='active field_btn' *ngIf="!sub"></div>
            <h4 class='subheading' *ngIf="question.category!=='riaa'">
                Question {{sub?sub:question.order+1}}{{sub?ntl(question.order):''}} <span *ngIf="question.custom">Custom</span>
            </h4>
            <textarea autosize class='subheading' *ngIf="question.category==='riaa'" [(ngModel)]="question.subheading" (input)="changesMade()" placeholder="Subheading" rows="1"></textarea>
            <textarea autosize [minRows]="1" class='ta_question' [(ngModel)]="question.question" (input)="changesMade()" placeholder="Question label"></textarea>
            <ng-container *ngIf="!collapsed">
                <ng-container *ngIf="question.custom<3">
                    <app-field [field]="field" [custom]="question.custom" *ngFor="let field of question.fields; let i= index" [section]="section" [parentId]="(sub?sub:question.order+1)+(sub?ntl(question.order):'')" [cantMoveUp]="i==0" [cantMoveDown]="i==question.fields.length-1"
                        (addAnother)="addAnotherField(field)" (moveUp)="fieldMoveUp(i)" (moveDown)="fieldMoveDown(i)" (delete)="deleteField(i)"></app-field>
                    <app-form-builder-button class="padd_bottom" buttonText="add new Field" *ngIf="!insertingField" (click)="question.type==='order'?insertField('order'):insertingField=true"></app-form-builder-button>
                    <!-- <app-form-builder-button buttonText="Field" *ngIf="question.type" (click)="insertField(question.type)"></app-form-builder-button> -->
                    <app-field-maker *ngIf="insertingField" [canPickOrder]="!question.type" (selected)="insertField($event)"></app-field-maker>
                </ng-container>
                <ng-container *ngIf="question.custom>2">
                    <app-field *ngFor="let option of options; let i= index" [field]="option.fieldified" [custom]="question.custom" [section]="section" (labelChange)="option.name=$event" (delete)="deleteOption(i)" (moveUp)="optionMoveUp(i)" (moveDown)="optionMoveDown(i)"
                        [cantMoveUp]="i==0" [cantMoveDown]="i==options.length-1"></app-field>
                    <app-form-builder-button buttonText="add new Field" (click)="addOption()"></app-form-builder-button>
                </ng-container>
                <textarea autosize class='hint' *ngIf="question.category==='riaa'" [(ngModel)]="question.hint" (input)="changesMade()" placeholder="Hint" rows="1"></textarea>
            </ng-container>
        </div>
        <div class='question-controls'>
            <div class='btn-group'>
                <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/collapse.svg'" *ngIf="!collapsed" (click)="collapsed=true"></app-button-secondary>
                <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/expand.svg'" *ngIf="collapsed" (click)="collapsed=false"></app-button-secondary>
            </div>
            <div class='btn-group'>
                <app-button-secondary [class.disabled]="cantMoveUp" [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="cantMoveUp" style='transform:rotate(180deg)' (click)="moveUp.emit()"></app-button-secondary>
                <app-button-secondary [class.disabled]="cantMoveUp" [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="cantMoveDown" (click)="moveDown.emit()"></app-button-secondary>
            </div>
            <div class='btn-group' [matMenuTriggerFor]="qMenu" (menuClosed)="menuClose($event)" (menuOpened)="menuOpen($event)">
                <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/menu.svg'"></app-button-secondary>
            </div>
            <mat-menu #qMenu="matMenu">
                <button *ngIf="question.category==='application'" disableRipple="true" mat-menu-item (click)="openConditions()">Create Condition</button>
                <button *ngIf="question.category==='application'" disableRipple="true" mat-menu-item (click)="openFront()">Add to RR Frontend</button>
                <button *ngIf="question.category==='application'" disableRipple="true" mat-menu-item (click)="question.viewableData=!question.viewableData; changesMade()">
                    {{question.viewableData?'Remove from':'Add to'}} Viewable Data
                </button>
                <button *ngIf="question.category!=='ivp'" disableRipple="true" mat-menu-item (click)="question.ivp=!question.ivp; changesMade()">
                    {{question.ivp?'Remove from':'Add to'}} IVP Review
                </button>
                <button *ngIf="question.category==='application'" disableRipple="true" mat-menu-item (click)="question.defaultable=!question.defaultable; changesMade()">
                    {{question.defaultable?'Remove from':'Add to'}} provider
                </button>
                <button *ngIf="question.category==='application'" disableRipple="true" mat-menu-item (click)="question.starred=!question.starred; changesMade()">
                    {{question.starred?'Remove from':'Add to'}} star rating
                </button>
                <button *ngIf="question.category==='application'" disableRipple="true" mat-menu-item (click)="openTimeframe()">Add Update Timeframe</button>
                <button disableRipple="true" mat-menu-item *ngIf="!question.custom&&!sub" (click)="moveCategory()">Move
                    Category</button>
                <button disableRipple="true" mat-menu-item *ngIf="!question.custom" (click)="deleteQuestion()">Delete</button>
            </mat-menu>
        </div>
    </div>
    <app-fb-question *ngFor="let subquestion of question.subquestions; let j=
        index" [question]="subquestion" [sub]="question.order+1" [section]="section" [cantMoveUp]="j==0" [cantMoveDown]="j==question.subquestions.length-1" (moveUp)="subquestionMoveUp(j)" (moveDown)="subquestionMoveDown(j)" (deleted)="question.subquestions.splice(j, 1);
        question.fixSubquestionOrdinals()"></app-fb-question>
    <div class='footer' *ngIf="!sub">
        <app-form-builder-button buttonText="add new Question" *ngIf="!adding" (click)="adding= true"></app-form-builder-button>
        <app-field-maker *ngIf="adding" (selected)="insertSubquestion($event)"></app-field-maker>
    </div>
</div>