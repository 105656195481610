import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { DefaultAnswer } from 'src/app/model/responsible/DefaultAnswer';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { Provider } from 'src/app/model/responsible/Provider';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';

interface FileLists{
    [key:number]:file_interface[]
}

@Component({
    selector: 'app-default-question',
    templateUrl: './default-question.component.html',
    styleUrls: ['./default-question.component.scss']
})
export class DefaultQuestionComponent implements OnInit {
    @Input() public question:FormQuestion = new FormQuestion();
    @Input() public provider:Provider = new Provider();

    public files:FileLists = {};
    public isExpanded: boolean = false
    constructor(
        private attachmentApi:AttachmentAPI,
        private dialog:MatDialog,
    ){}

    ngOnInit():void{
     }
    private confirmAnswer(){
        if(!this.question.default){
            let answer = new DefaultAnswer();
            answer.questionId = this.question.id;
            this.question.default = answer;
            this.provider.defaults.push(answer);
        }
    }

    public getAnswer(id:number){
        return this.question.default?.value[id];
    }
    public getRadio(){
        for(let f of this.question.fields)
            if(f.id == this.question.default?.value.radio) return f;
        return undefined;
    }
    public getDateAnswer(id:number){
        if(this.question.default&&this.question.default.value[id]){
            let answer = this.question.default.value[id];
            return answer;
        }
        return [moment().unix(),moment().unix()].join(';');
    }

    public setAnswer(id:number, value:string|boolean|number){
        this.confirmAnswer();
        this.question.default!.value[id] = value;
    }
    public setRadio(id:number){
        this.confirmAnswer();
        this.question.default!.value.radio = id;
    }
    public setDateAnswer(id:number, range:{startDate:string, endDate:string}){
        this.confirmAnswer();
        this.question.default!.value[id] = `${range.startDate};${range.endDate}`;
    }
    public uploadFile(flist:FileList, id:number){
        this.confirmAnswer();
        if(!this.files[id]) this.files[id] = [];
        if(!this.question.default!.value[id]) this.question.default!.value[id] = [];
        if(flist.item(0)){
            let acceptedTypes = [],
                types = this.question.getFieldById(id)?.filetypes?.split('|');
            if(types?.includes('documents')) acceptedTypes.push('application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            if(types?.includes('images')) acceptedTypes.push('image/png','image/jpeg');
            if(types?.includes('spreadsheets')) acceptedTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv');
            if(!acceptedTypes.includes(flist.item(0)!.type)){
                console.error(`Only following file types are accepted: ${types?.join(', ')}`);
                return;
            }
            let added = false,
                handle:file_interface,
                index = 0;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = { 
                    filename: file.name, 
                    extension: file.name.split('.').pop()??'',
                    status: 'new',
                    progress: 0
                };
                switch(res.type){
                    case HttpEventType.Sent:
                        index = this.files[id].push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        console.log(Math.round(res.loaded/res.total!*100));
                        this.files[id][index].progress = Math.round(res.loaded/res.total!*99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.question.default!.files.push(att);
                        this.files[id][index].file = att;
                        this.files[id][index].progress = 100;
                        this.question.default!.value[id].push(att.id);
                        break;
                }
            }, err => {
                if(added){
                    this.files[id].splice(index, 1);
                }
                console.warn(err);
            });
        }
    }
    public deleteFile(file:file_interface, id:number){
        this.files[id].splice(this.files[id].indexOf(file), 1);
        if(file.file) this.question.default!.files.splice(this.question.default!.files.indexOf(file.file), 1);
    }
    public moveOrderUp(index:number){
        let item = this.question.default!.value.order!.splice(index, 1);
        this.question.default!.value.order?.splice(index - 1, 0, item[0]);
    }
    public moveOrderDown(index:number){
        let item = this.question.default!.value.order!.splice(index, 1);
        this.question.default!.value.order?.splice(index + 1, 0, item[0]);
    }

    public openUploader(id:number){
        let d = this.dialog.open(UploadModalComponent, {
            panelClass:'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if(res){
                if(res.new)
                    this.uploadFile(res.file, id);
                else{
                    this.confirmAnswer();
                    if(!this.files[id]) this.files[id] = [];
                    if(!this.question.default!.value[id]) this.question.default!.value[id] = [];
                    this.files[id].push(res.file);
                    this.question.default!.files.push(res.file.file);
                    this.question.default!.value[id].push(res.file.file.id);
                }
            }
        });
    }
}
