<div class="conversations" *ngIf="exists" [class.active]="display">
    <div class="top">
        <app-heading5 [text]="name" [isTextBlack]="false" class="hidden-xs"></app-heading5>
        <div class="heading">
            <div class="back visible-xs" (click)="dismiss()"></div>
            <app-heading2 [text]="'Conversations'" [isTextBlack]="false"></app-heading2>
            <div class="new_chat" (click)="openNewCoversation()">
                <div class="circle"></div>
                <app-heading5 [text]="'New chat'" [isTextBlack]="false"></app-heading5>
            </div>
        </div>

    </div>
    <div class="conversations_content" [class.loading]="loading">
        <ng-container *ngFor="let i of conversations">
            <app-notification-overlay [name]="i.lastMessage?.message||''" [head]="i.questionId?'Question '+i.question?.tag + ', ' + sectionName(i.section!):'General Enquiry'" 
                [time]="i.lastMessage?.createdAt!" (click)="openChat(i.id)" [newMessage]="!historyArray[i.id]||historyArray[i.id]<i.lastMessage?.createdAt!">
                </app-notification-overlay>
        </ng-container>
    </div>
    <div class='spinner' *ngIf="loading">
        <mat-spinner [strokeWidth]="3"></mat-spinner>
    </div>
</div>

<app-new-conversation [applicationId]="id" [formId]="formId" [exists]="isNewConversationViewOpen" (closeNewConversation)="closeNewConversation($event)"></app-new-conversation>
<app-chat [exists]="isChatViewOpen" [loadChat]="loadChat" (closeChat)="closeChat()"></app-chat>