<div class="container" [ngClass]="{'hide': pages < 1 || pages == 1}">
    <button class="prev_btn" [ngClass]="{'disable': currentPage == 1 }" (click)="goToPage(currentPage - 1)"><svg-icon src="../../../assets/svg/pagination_arrow.svg"></svg-icon><span>prev</span></button>
    <div class="page_list">
        <!-- first -->
        <span *ngIf="currentPage > 2  && pages > 5" class="last_item desktop" (click)="goToPage(1)">1</span>
        <span class="dots desktop_dots" *ngIf="currentPage > 2  && pages > 5">...</span>

        <span *ngIf="currentPage > 1  && pages > 2" class="last_item mobile" (click)="goToPage(1)">1</span>
        <span class="dots mobile_dots" *ngIf="(currentPage > 1  && pages > 2) && currentPage > 2">...</span>
        <!-- first -->
        <span class="page" [ngClass]="{'active': currentPage == item, 'dis_mar': (pages > 5 && item == currentPage + 1 && currentPage < pages - 2) || (pages > 5 && currentPage < 3 && item == 3)}" *ngFor='let item of counter(currentPage); let i = index' (click)="goToPage(item)">{{item}}</span>
        <span class="dots desktop_dots" *ngIf="currentPage < pages - 1  && pages > 5">...</span>

        <span class="dots mobile_dots" *ngIf="(currentPage < pages  && pages > 2) && currentPage < pages - 1">...</span>
        <span *ngIf="currentPage < pages - 1  && pages > 5" class="last_item desktop" (click)="goToPage(pages)">{{pages}}</span>
        <span *ngIf="currentPage < pages  && pages > 1" class="last_item mobile" (click)="goToPage(pages)">{{pages}}</span>

    </div>
    <button class="next_btn" [ngClass]="{'disable': currentPage == pages}" (click)="goToPage(currentPage + 1)"><span>next</span><svg-icon src="../../../assets/svg/pagination_arrow.svg"></svg-icon></button>
</div>