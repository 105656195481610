import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filters-star',
  templateUrl: './filters-star.component.html',
  styleUrls: ['./filters-star.component.scss']
})
export class FiltersStarComponent implements OnInit {
  @Input() rating: number = 0;

  @Output() ratingChange = new EventEmitter<number>()

  public stars = [{
    id: 1,
    class: ''
  },
  {
    id: 2,
    class: ''
  },
  {
    id: 3,
    class: ''
  },
  {
    id: 4,
    class: ''
  },
  {
    id: 5,
    class: ''
  }]
  constructor() { }

  ngOnInit(): void {
    this.starSelected(this.rating)
  }
  starSelected(value: number) {
    this.stars.filter((star) => {
      if (star.id <= value) {
        star.class = "star-hover"

      } else {
        star.class = ""
      }
    })
    if (value > 0) this.change(value)
    this.rating = value
    return this.stars
  }

  public change(value: number) {

    this.ratingChange.emit(value)
  }

}
