import { file_interface } from "src/app/core/drag-drop/drag-drop.component";
import { Keyed } from "./Form";
import { FormQuestion, form_question_response } from "./FormQuestion";
import { SectionBundle } from "./FormSectionBundle";
import { SectionConditionGroup, section_condition_group_response } from "./SectionConditionGroup";
import { SupportCheck, support_check_response } from "./SupportCheck";
import { SupportFAQ, support_faq_response } from "./SupportFAQ";
import { SupportFile, support_file_response } from "./SupportFile";
import { SupportVideo, support_video_response } from "./SupportVideo";

export interface form_section_response{
    id:number;
    title:string;
    description:string;
    time:string;
    order:number;
    hid:boolean;

    questions?:form_question_response[];
    riaa_questions?:form_question_response[];
    ivp_questions?:form_question_response[];
    conditions?:section_condition_group_response[];

    support_faqs?:support_faq_response[];
    support_files?:support_file_response[];
    support_videos?:support_video_response[];
    support_checks?:support_check_response[];

    key?:string;
}
export class FormSection extends Keyed{
    public id:number = 0;
    public title:string = '';
    public description:string = '';
    public time:string = '';
    public order:number = 0;
    public hidden:boolean = false;

    public questions:FormQuestion[] = [];
    public riaaQuestions:FormQuestion[] = [];
    public ivpQuestions:FormQuestion[] = [];
    public conditions:SectionConditionGroup[] = [];

    public supportFAQs:SupportFAQ[] = [];
    public supportFiles:SupportFile[] = [];
    public supportVideos:SupportVideo[] = [];
    public supportChecks:SupportCheck[] = [];

    public supportFileList:file_interface[] = [];

    public appearing:boolean = true;

    public map(data:form_section_response){
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.time = data.time;
        this.order = data.order;
        this.hidden = data.hid;

        this.questions = [];
        for(let q of data.questions??[])
            this.questions.push(FormQuestion.create(q));

        this.riaaQuestions = [];
        for(let q of data.riaa_questions??[])
            this.riaaQuestions.push(FormQuestion.create(q));
        
        this.ivpQuestions = [];
        for(let q of data.ivp_questions??[])
            this.ivpQuestions.push(FormQuestion.create(q));

        this.conditions = [];
        for(let c of data.conditions??[])
            this.conditions.push(SectionConditionGroup.create(c));

        this.supportFAQs = [];
        for(let s of data.support_faqs??[])
            this.supportFAQs.push(SupportFAQ.create(s));

        this.supportFiles = [];
        for(let s of data.support_files??[])
            this.supportFiles.push(SupportFile.create(s));

        this.supportVideos = [];
        for(let s of data.support_videos??[])
            this.supportVideos.push(SupportVideo.create(s));

        this.supportChecks = [];
        for(let s of data.support_checks??[])
            this.supportChecks.push(SupportCheck.create(s));

        this.questions.sort((a,b) => { return a.order>b.order?1:-1 });
        this.riaaQuestions.sort((a,b) => { return a.order>b.order?1:-1 });
        this.ivpQuestions.sort((a,b) => { return a.order>b.order?1:-1 });
        this.supportFAQs.sort((a,b) => { return a.order>b.order?1:-1 });
        this.supportVideos.sort((a,b) => { return a.order>b.order?1:-1 });
        this.supportChecks.sort((a,b) => { return a.order>b.order?1:-1 });
        
        this.initSupportFileList();
        return this;
    }
    public responsify():form_section_response{
        return {
            id: this.id,
            title: this.title,
            description: this.description??'',
            time: this.time??'',
            order: this.order,
            hid: this.hidden,
            questions: (() => {
                let arr:form_question_response[] = [];
                for(let q of this.questions) arr.push(q.responsify());
                return arr;
            })(),
            riaa_questions: (() => {
                let arr:form_question_response[] = [];
                for(let q of this.riaaQuestions) arr.push(q.responsify());
                return arr;
            })(),
            ivp_questions: (() => {
                let arr:form_question_response[] = [];
                for(let q of this.ivpQuestions) arr.push(q.responsify());
                return arr;
            })(),
            conditions: (() => {
                let arr:section_condition_group_response[] = [];
                for(let c of this.conditions) arr.push(c.responsify());
                return arr;
            })(),
            support_faqs: (() => {
                let arr:support_faq_response[] = [];
                for(let s of this.supportFAQs) arr.push(s.responsify());
                return arr;
            })(),
            support_files: (() => {
                let arr:support_file_response[] = [];
                for(let s of this.supportFiles) arr.push(s.responsify());
                return arr;
            })(),
            support_checks: (() => {
                let arr:support_check_response[] = [];
                for(let s of this.supportChecks) arr.push(s.responsify());
                return arr;
            })(),
            support_videos: (() => {
                let arr:support_video_response[] = [];
                for(let s of this.supportVideos) arr.push(s.responsify());
                return arr;
            })(),

            key:this.key,
        }
    }
    public static create(data:form_section_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public fixQuestionOrdinals(){
        for(let [i, q] of this.questions.entries())
            q.order = i;
    }
    public fixRIAAOrdinals(){
        for(let [i, q] of this.riaaQuestions.entries())
            q.order = i;
    }
    public fixIVPOrdinals(){
        for(let [i, q] of this.ivpQuestions.entries())
            q.order = i;
    }
    public deepOrdinalFix(){
        for(let [i, q] of this.questions.entries()){
            q.order = i;
            q.fixFieldOrdinals();
            for(let [j, s] of q.subquestions.entries()){
                s.order = j;
                s.fixFieldOrdinals();
            }
        }
        for(let [i, q] of this.riaaQuestions.entries()){
            q.order = i;
            q.fixFieldOrdinals();
            for(let [j, s] of q.subquestions.entries()){
                s.order = j;
                s.fixFieldOrdinals();
            }
        }
        for(let [i, q] of this.ivpQuestions.entries()){
            q.order = i;
            q.fixFieldOrdinals();
            for(let [j, s] of q.subquestions.entries()){
                s.order = j;
                s.fixFieldOrdinals();
            }
        }
        this.fixSupportOrdinals();
    }
    public fixSupportOrdinals(){
        for(let [i, s] of this.supportFAQs.entries())
            s.order = i;
        for(let [i, s] of this.supportFiles.entries())
            s.order = i;
        for(let [i, s] of this.supportVideos.entries())
            s.order = i;
        for(let [i, s] of this.supportChecks.entries())
            s.order = i;
    }

    public initSupportFileList(){
        this.supportFileList = [];
        for(let f of this.supportFiles)
            this.supportFileList.push({ 
                file: f.attachment!, progress:100, status:'ok', filename:f.attachment!.filename, extension:f.attachment!.getExtension()! 
            });
    }

    public getQuestionById(id:number){
        for(let q of this.questions){
            if(q.id==id) return q;
            for(let s of q.subquestions)
                if(s.id==id) return s;
        }
        return null;
    }
    public getQuestionByKey(key:string){
        for(let q of this.questions){
            if(q.key==key) return q;
            for(let s of q.subquestions)
                if(s.key==key) return s;
        }
        return null;
    }
    public getFieldById(id:number){
        for(let q of this.questions){
            for(let f of q.fields)
                if(f.id==id) return f;
            for(let s of q.subquestions)
                for(let f of s.fields)
                    if(f.id==id) return f;
        }
        return null;
    }
    public confirmConditions(form:SectionBundle){
        if(this.hidden===null) this.hidden = true;
        if(!this.conditions.length){ 
            this.appearing = true;
            return;
        }
        let hasValidCondi = false, 
            confirmed = false;

        for(let c of this.conditions){
            if(confirmed) break;
            if(!c.conditions.length) continue;

            // Get target question
            if(c.targetId)
                c.target = form.getQuestionById(c.targetId)??undefined;
            else continue;

            if(c.target){
                let final = true,
                    radioFinal = undefined,
                    answer = c.target.answer;
                for(let r of c.conditions){
                    if(!final) break;

                    if(c.target.custom<3){
                        // Get target field
                        if(r.fieldId)
                            r.field = c.target.getFieldById(r.fieldId)??undefined;
                        else continue;

                        if(r.field){
                            switch(r.field.type){
                                case 'radio':
                                    hasValidCondi = true;
                                    if(radioFinal) continue;        // Radio conditions work on OR. If one is confirmed, no need to verify the rest.
                                    if(radioFinal===undefined) radioFinal = false;
                                    if(!answer||!c.target.appearing||!form.getSectionById(c.target.sectionId!)?.appearing) radioFinal = r.condition==='false';
                                    else if(answer.value.radio===r.fieldId) radioFinal = r.condition==='true';
                                    break;
                                case 'check':
                                    hasValidCondi = true;
                                    if(!answer||!c.target.appearing||!form.getSectionById(c.target.sectionId!)?.appearing) final = r.condition==='false';
                                    else{
                                        if(answer.value[r.fieldId]) final = r.condition==='true';
                                        else final = r.condition==='false';
                                    }
                                    break;
                                case 'slider-numeric':
                                case 'slider-percent':
                                    hasValidCondi = true;
                                    if(!answer||!c.target.appearing||!form.getSectionById(c.target.sectionId!)?.appearing) final = r.rangeStart<=r.field.rangeStart;
                                    else {
                                        if(answer.value[r.fieldId]) final = (answer.value[r.fieldId]>=r.rangeStart&&answer.value[r.fieldId]<=r.rangeEnd);
                                        else final = r.rangeStart<=r.field.rangeStart;
                                    }
                                    break;
                                default:
                                    continue;
                            }
                        } else continue;
                    } else {
                        if(r.customId){
                            hasValidCondi = true;
                            if(!answer||!c.target.appearing||!form.getSectionById(c.target.sectionId!)?.appearing) final = r.condition==='false';
                            else {
                                if(answer.value[r.customId]) final = r.condition==='true';
                                else final = r.condition==='false';
                            }
                        } else continue;
                    }
                }
                if(final&&(radioFinal===undefined||radioFinal===true)) confirmed = true;
            } else continue;
        }

        if(hasValidCondi){ 
            if(confirmed) this.appearing = this.hidden;
            else this.appearing = !this.hidden;
        } else this.appearing = true;
    }
}