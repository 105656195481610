<div class='card'>
    <h2>{{title}}</h2>
    <div class="desc" *ngIf="desc.length > 0">{{desc}}</div>
    <!-- <button *ngIf="!section.supportFAQs.length" (click)="addNewFAQ()">Add</button> -->
    <app-button-secondary [isGreen]="true" [imgUrl]="'assets/svg/plus-icon.svg'" *ngIf="type==='support'?!section.supportFAQs.length:!faq.length" (click)="addNewFAQ()"></app-button-secondary>

    <div *ngFor="let f of (type==='support'?section.supportFAQs:faq); let i = index" class='field faq'>
        <div class='content'>
            <app-text-field [isTextLimit]="false" [isAutoSize]="true" placeholderText="Question" [(model)]="f.question" (modelChange)="changeMade()"></app-text-field>
            <app-text-field [isTextLimit]="false" [isAutoSize]="true" placeholderText="Answer" [(model)]="f.answer" (modelChange)="changeMade()"></app-text-field>
        </div>
        <div class='controls'>
            <div class='btn-group'>
                <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNewFAQ()"></app-button-secondary>
                <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="deleteFAQ(i)"></app-button-secondary>
            </div>
            <div class='btn-group'>
                <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/arrow.svg'" (click)="moveFAQUp(i)" [disabled]="i==0" style="transform:rotate(180deg)"></app-button-secondary>
                <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/arrow.svg'" (click)="moveFAQDown(i)" [disabled]="i==section.supportFAQs.length-1"></app-button-secondary>
            </div>
        </div>
    </div>
</div>