<app-auth></app-auth>
<app-menu></app-menu>
<!-- <appLoader [showLoader]="showLoader" [showNotyfication]="showNotyfication"
  [notyficationMessage]="notyficationMessage" [notyficationMessageStateModel]="notyficationMessageStateModel">
</appLoader> -->
<div class='container'>
    <h2>Mail events</h2>
    <app-input-field [(model)]="filter" placeholderText="Filter" (modelChange)="setEvents()"></app-input-field>
    <div class='btn'>
        <app-button-primary text="New" (click)="add()"></app-button-primary>
    </div>
    <div class='list'>
        <div class='event' *ngFor="let event of mailEvents; let i = index">
            <h3 class='title'>{{event.subject}}</h3>
            <div class='slug'>{{event.event_slug}}</div>
            <div class='params'>{{event.main_values.join(', ')}}</div>
            <div class='buttons'>
                <app-button-secondary [isText]="true" buttonText="Edit" (click)="edit(event.id)"></app-button-secondary>
                <app-button-secondary [isText]="true" buttonText="Delete" (click)="del(event)"></app-button-secondary>
            </div>
            <hr *ngIf="i!=mailEvents.length-1">
        </div>
    </div>
    <div class='btn'>
        <app-button-primary text="New" (click)="add()"></app-button-primary>
    </div>
</div>
<div *ngIf="showDeleteModal">
    <p>Are you sure you want to delete the selected item?</p>
    <button (click)="confirmDeletion()">Yes</button>
    <button (click)="showDeleteModal=false">No</button>
</div>