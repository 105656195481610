import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormField } from 'src/app/model/responsible/FormField';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { FormBuilderComponent } from '../../form-builder/form-builder.component';
import { CharLimitComponent } from '../char-limit/char-limit.component';
import { ConditionsComponent } from '../conditions/conditions.component';
import { FileTypesComponent } from '../file-types/file-types.component';
import { ThemeExclusionComponent } from '../theme-exclusion/theme-exclusion.component';
import { FieldThemesComponent } from '../themes/themes.component';

@Component({
    selector: 'app-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {
    @Input() field: FormField = new FormField();
    @Input() cantMoveDown: boolean = false;
    @Input() cantMoveUp: boolean = false;
    @Input() parentId: string = '';
    @Input() section: FormSection = new FormSection();
    @Input() custom: number = 0;
    @Output() moveUp = new EventEmitter<void>();
    @Output() moveDown = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() addAnother = new EventEmitter<void>();
    @Output() labelChange = new EventEmitter<string>();

    public conditionable = () => { return ['radio', 'check', 'slider-numeric', 'slider-percent'].includes(this.field.type); };
    public hasMenu = () => { return ['radio', 'check', 'slider-numeric', 'slider-percent', 'file', 'single-line', 'multi-line', 'radio-group'].includes(this.field.type)||[1,2].includes(this.custom); }

    constructor(
        private dialog: MatDialog
    ) { }

    ngOnInit(): void { }

    public setCharLimit() {
        let d = this.dialog.open(CharLimitComponent, { data: { field: this.field, qIndex: this.parentId, index: this.field.order }, panelClass: 'modal-white' });
        d.afterClosed().subscribe(res => {
            console.log(res);
            if (res === false) return;
            else {
                if (!res) this.field.charlimit = undefined;
                else this.field.charlimit = res;
                this.changesMade();
            }
        })
    }
    public setFileTypes() {
        let d = this.dialog.open(FileTypesComponent, { data: { field: this.field, qIndex: this.parentId, index: this.field.order }, panelClass: 'modal-white' });
        d.afterClosed().subscribe(res => {
            if (res === false) return;
            else {
                console.log(res);
                let r = [];
                if (res.documents) r.push('documents');
                if (res.images) r.push('images');
                if (res.spreadsheets) r.push('sheets');
                this.field.filetypes = r.join('|');
                this.changesMade();
            }
        })
    }

    public getAllConditions() {
        let arr = [];
        for (let q of this.section.questions) {
            for (let g of q.conditions) {
                let found = false;
                for (let c of g.conditions) {
                    if (c.fieldId == this.field.id) {
                        arr.push(q);
                        break;
                    }
                }
                if (found) break;
            }
            for (let s of q.subquestions) {
                for (let g of s.conditions) {
                    let found = false;
                    for (let c of g.conditions) {
                        if (c.fieldId == this.field.id) {
                            arr.push(s);
                            break;
                        }
                    }
                    if (found) break;
                }
            }
        }
        let d = this.dialog.open(ConditionsComponent, { data: { list: arr, tag: this.parentId, field: this.field }, panelClass: 'modal-white' });
    }
    public openExclusions() {
        let d = this.dialog.open(ThemeExclusionComponent, {
            data: {
                field: this.field.responsify(),
            }, panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res !== false) {
                this.field.customs = res;
                this.changesMade();
            }
        })
    }
    public openThemes() {
        let d = this.dialog.open(FieldThemesComponent, {
            data: {
                field: this.field.responsify(),
            }, panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res !== false) {
                this.field.customs = res;
                this.changesMade();
            }
        })
    }
    public changesMade(){
        FormBuilderComponent.changed = true;
    }
}
