import { FormSelectOption } from "../FormSelectOption";
import { User, user_response } from "../User";
import { ApplicationAnswer, application_answer_response } from "./ApplicationAnswer";
import { Assessment, assessment_response } from "./Assessment";
import { Attachment, attachment_response } from "./Attachment";
import { Conversation, conversation_response } from "./Conversation";
import { ProductCategory, ProductTarget } from "./CustomIntegration";
import { form_section_response } from "./FormSection";
import { SectionBundle } from "./FormSectionBundle";
import { Provider, provider_response } from "./Provider";

export interface application_response{
    id:number;
    form_id:number;
    user_id:number;
    product_name:string;
    status:string;
    banner_id?:number;
    sustainability:string;
    description:string;
    invoice:string;
    provider_id:number;
    update_status?:string;
    cert_start?:string;
    published:boolean;

    form?:form_section_response[];
    user?:user_response;
    provider?:provider_response;
    collaborators?:user_response[];
    banner?:attachment_response;
    answers?:application_answer_response[];
    riaa_assessment?:assessment_response;
    ivp_assessment?:assessment_response;

    conversations?:conversation_response[];
    assigned?:user_response[];

    product_target?:ProductTarget[];
    product_category?:ProductCategory[];
    files?:attachment_response[];
    p_files?:attachment_response[];
    pFiles?:number[];
    needs_ivp?:boolean;

    created_at?:string;
    updated_at?:string;
}
export class Application{
    public static readonly newStatuses = ['In Progress', 'New Application', 'RIAA Assessment', 'IVP Assessment', 'Updated', 'More Info', 'Did not proceed'];
    public static readonly sustainabilityClasses = [
        new FormSelectOption('Not Applicable'),
        new FormSelectOption('Responsible'),
        new FormSelectOption('Sustainable'),
        new FormSelectOption('Sustainable Plus'),
    ];

    public id:number = 0;
    public formId:number = 0;
    public userId:number = 0;
    public productName:string = '';
    public status:string = '';
    public bannerId?:number;
    public sustainability:FormSelectOption = new FormSelectOption('Not Applicable');
    public description:string = '';
    public invoice:string = 'Not Required';
    public providerId:number = 0;
    public updateStatus?:string;
    public certStart?:Date;
    public published:boolean = true;

    public form?:SectionBundle;
    public user?:User;
    public provider?:Provider;
    public banner?:Attachment;
    public answers:ApplicationAnswer[] = [];
    public collaborators:User[] = [];

    public riaaAssessment?:Assessment;
    public ivpAssessment?:Assessment;

    public conversations:Conversation[] = [];
    public assigned:User[] = [];

    public productTargets:ProductTarget[] = [];
    public productCategories:ProductCategory[] = [];
    public files:Attachment[] = [];
    public pFilesList:Attachment[] = [];
    public pFiles:number[] = [];
    public needsIVP?:boolean;

    public createdAt?:Date;
    public updatedAt?:Date;

    public cachedVisibleQuestions:number = 0;
    public cachedFilledQuestions:number = 0;

    public map(data:application_response){
        this.id = data.id;
        this.formId = data.form_id;
        this.userId = data.user_id;
        this.productName = data.product_name;
        this.status = data.status;
        this.bannerId = data.banner_id;
        this.description = data.description;
        this.sustainability = new FormSelectOption(data.sustainability || 'Not Applicable');
        this.invoice = data.invoice;
        this.providerId = data.provider_id;
        this.updateStatus = data.update_status;
        this.certStart = data.cert_start?new Date(data.cert_start):undefined;
        this.published = data.published;
        this.needsIVP = data.needs_ivp;

        this.form = data.form?SectionBundle.create(data.form):undefined;
        if(data.provider) this.provider = Provider.create(data.provider);
        if(data.banner) this.banner = Attachment.create(data.banner);
        this.user = data.user?User.create(data.user):undefined;

        if(data.riaa_assessment) this.riaaAssessment = Assessment.create(data.riaa_assessment);
        if(data.ivp_assessment) this.ivpAssessment = Assessment.create(data.ivp_assessment);

        this.conversations = [];
        for(let c of data.conversations??[])
            this.conversations.push(Conversation.create(c));

        this.answers = [];
        for(let a of data.answers??[])
            this.answers.push(ApplicationAnswer.create(a));

        this.collaborators = [];
        for(let c of data.collaborators??[])
            this.collaborators.push(User.create(c));

        this.assigned = [];
        for(let a of data.assigned??[])
            this.assigned.push(User.create(a));

        this.productCategories = [];
        for(let c of data.product_category??[])
            this.productCategories.push(ProductCategory.create(c));

        this.productTargets = [];
        for(let t of data.product_target??[])
            this.productTargets.push(ProductTarget.create(t));

        // this.files = [];
        this.files = data.files?.map(f => Attachment.create(f))??[];
        this.pFilesList = data.p_files?.map(f => Attachment.create(f))??[];
        this.pFiles = data.p_files?.map(f => f.id)??[];

        if(data.created_at) this.createdAt = new Date(data.created_at);
        if(data.updated_at) this.updatedAt = new Date(data.updated_at);

        return this;
    }
    public responsify():application_response{
        const applicationResponse = {
            id:this.id,
            form_id:this.formId,
            user_id:this.user?.id??this.userId,
            product_name:this.productName,
            status:this.status,
            banner_id:this.bannerId,
            description:this.description,
            sustainability:this.sustainability.value,
            invoice:this.invoice,
            provider_id:this.provider?.id??this.providerId,
            update_status:this.updateStatus,
            published:this.published,

            provider:this.provider?.responsify(),
            banner:this.banner,

            answers:(()=>{
                let arr = [];
                for(let a of this.answers) arr.push(a.responsify());
                return arr;
            })(),
            collaborators:(() => {
                let arr = [];
                for(let c of this.collaborators) arr.push(c.responsify());
                return arr;
            })(),
            pFiles:this.pFiles
        };
        return applicationResponse;
    }
    public static create(data:application_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public recalcCache(){
        this.cachedVisibleQuestions = (() => {
            let result = 0;
            return result;
        })()
    }

    public get providerName(){
        return this.provider?.name;
    }
    public get categories(){
        let arr = [];
        for(let i of this.productCategories)
            arr.push(i.name);
        return arr.join(', ');
    }
    public get targets(){
        let arr = [];
        for(let i of this.productTargets)
            arr.push(i.name);
        return arr.join(', ');
    }
    public get hasUnreads(){
        let history = localStorage.getItem('convHistory');
        if(history) history = JSON.parse(history);
        else return false;
        for(let c of this.conversations){
            if(c.lastMessage){
                if(!history![c.id]) return true;
                if(history![c.id]){
                    let t = new Date(history![c.id]);
                    if(!c.lastMessage.createdAt) continue;
                    if(t<c.lastMessage.createdAt) return true;
                    else continue;
                }
            } else continue;
        }
        return false;
    }
    public get certExpiry(){
        const twoyears = 63072000000;
        if(this.certStart){
            return new Date(this.certStart.getTime() + twoyears);
        }
        return undefined;
    }
    public get completion(){
        //return Math.floor((this.cachedFilledQuestions/this.cachedVisibleQuestions)*100);
        let filled = 0, total = 0;
        for(let s of this.form?.sections??[]){
            for(let q of s.questions){
                total++;
                if(q.answer?.value) filled++;
                for(let u of q.subquestions){
                    total++;
                    if(u.answer?.value) filled++;
                }
            }
        }
        if(!total) return 0;
        return Math.floor((filled/total)*100);
    }
    public get unfilled(){
        let unfilled = 0;
        for(let s of this.form?.sections??[]){
            for(let q of s.questions){
                if(!q.answer?.value) unfilled++;
                for(let u of q.subquestions)
                    if(!u.answer?.value) unfilled++;
            }
        }
        return unfilled;
    }

    public get pubStatus(){
        if(!this.published)
            return 'Unpublished';
        else return this.status;
    }
}
