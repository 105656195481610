import { Attachment, attachment_response } from "./Attachment";

export interface exclusion_response{
    id:number;
    name:string;
    link?:string;
    order:number;
    description?:string;
    active:boolean;
    icon_id?:number;
    image_id?:number;

    icon?:attachment_response;
    image?:attachment_response;
}
export class Exclusion{
    public id:number = 0;
    public name:string = '';
    public link:string = '';
    public order:number = 0;
    public description:string = '';
    public active:boolean = true;
    public iconId?:number;
    public imageId?:number;

    public icon?:Attachment;
    public image?:Attachment;

    public map(data:exclusion_response){
        this.id = data.id;
        this.name = data.name;
        this.link = data.link??'';
        this.order = data.order;
        this.description = data.description??'';
        this.active = data.active;
        this.iconId = data.icon_id;
        this.imageId = data.image_id;

        if(data.icon) this.icon = Attachment.create(data.icon);
        if(data.image) this.image = Attachment.create(data.image);

        return this;
    }
    public responsify():exclusion_response{
        return {
            id: this.id,
            name: this.name,
            link: this.link,
            order: this.order,
            description: this.description,
            active: this.active,
            icon_id: this.iconId,
            image_id: this.imageId,
        }
    }
    public static create(data:exclusion_response){
        let r = new this;
        r.map(data);
        return r;
    }
}