import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BodyScrollService {

  constructor() { }

  ///enable body scroll
  public enableBodyScroll() {
    let bodyItem: any = document.getElementsByTagName("body")[0];
    let pageOffsetTop: any;

    if (bodyItem.classList.contains('disableScroll')) {
      pageOffsetTop = (bodyItem.getAttribute('data-offsettop')) ? bodyItem.getAttribute('data-offsettop') : 0;
      bodyItem.removeAttribute('data-offsettop');
      bodyItem.removeAttribute('style');
      bodyItem.classList.remove('disableScroll');
      window.scrollTo(0, pageOffsetTop);
    }
  }

  ///disable body scroll
	public disableBodyScroll() {
		let bodyItem: any = document.getElementsByTagName("body")[0];
		let pageOffsetTop;
		if (!bodyItem.classList.contains('disableScroll')) {
			pageOffsetTop = window.pageYOffset;
			bodyItem.setAttribute('data-offsettop', pageOffsetTop);
			bodyItem.classList.add('disableScroll');
      bodyItem.style.overflow = 'hidden';
      bodyItem.style.position = 'relative';
      bodyItem.style.height = '100%';
		}
	}

  ///toggle body scroll
  public toggleBodyScroll(itemCheck: boolean) {
    ///toggle body scroll///
    let bodyItem: any = document.getElementsByTagName("body")[0];
    let pageOffsetTop: any;

    if (itemCheck) {
      if (!bodyItem.classList.contains('disableScroll')) {
        pageOffsetTop = window.pageYOffset;
        bodyItem.setAttribute('data-offsettop', pageOffsetTop);
        bodyItem.classList.add('disableScroll');
        bodyItem.style.overflow = 'hidden';
        bodyItem.style.position = 'relative';
        bodyItem.style.height = '100%';
      }
    } else {
      if (bodyItem.classList.contains('disableScroll')) {
        pageOffsetTop = (bodyItem.getAttribute('data-offsettop')) ? bodyItem.getAttribute('data-offsettop') : 0;
        bodyItem.removeAttribute('data-offsettop');
        bodyItem.removeAttribute('style');
        bodyItem.classList.remove('disableScroll');
        window.scrollTo(0, pageOffsetTop);
      }
    }
    ///end togglebodyscroll///
  }

}
