import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter,AfterViewInit } from '@angular/core';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { trigger, style, animate, transition } from '@angular/animations';
import { transformAll } from '@angular/compiler/src/render3/r3_ast';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { ResourceFAQ } from 'src/app/model/responsible/ResourceFAQ';
import { ResourceCheck } from 'src/app/model/responsible/ResourceCheck';
import { ResourceFile } from 'src/app/model/responsible/ResourceFile';
import { Application } from 'src/app/model/responsible/Application';

@Component({
    selector: 'app-support-content-container',
    templateUrl: './support-content-container.component.html',
    styleUrls: ['./support-content-container.component.scss'],
    //  animations: [
    //     trigger(
    //         'faqsOutIn',
    //         [
    //             transition(
    //                 ':enter',
    //                 [
    //                     style({
    //                         opacity: 0, transform: 'translateY(-100%)'
    //                     }),
    //                     animate('0.3s ease-out',
    //                         style({ opacity: 1, transform: 'translateY(0)' }))
    //                 ]
    //             ),
    //             transition(
    //                 ':leave',
    //                 [
    //                     style({ opacity: 1, transform: 'translateY(0)' }),
    //                     animate('0.3s ease-in',
    //                         style({ opacity: 0, transform: 'translateY(-100%)' }))
    //                 ]
    //             ),
    //         ]
    //     )
    // ],
})
export class SupportContentContainerComponent implements OnInit {

    @Input() public section: FormSection = new FormSection();
    @Input() public app!:Application;
    @Input() public resFaq:ResourceFAQ[] = [];
    @Input() public resChecks:ResourceCheck[] = [];
    @Input() public resFiles:ResourceFile[] = [];
    @ViewChild('puller') puller!: ElementRef;
    @ViewChild('container') container: any
    @Input() public isResources: boolean = false
    public showFAQ: boolean = false;
    public showVideos: boolean = false;
    public showFiles: boolean = false;
    public showChecklist: boolean = false;
    
    //to mobile expand
    public mobileExpand: boolean = false
 
    constructor(public scroll:BodyScrollService) { }
    public dragDrop() {
    }
    ngOnChanges(changes: any){
        console.log(changes)
    }
    ngOnInit(): void {
        console.log(this.section)
    }
    ngAfterViewInit():void{
     }
    public closeAll(current: string) {
        switch (current) {
            case 'showFAQ': {
                this.showFAQ = !this.showFAQ;
                this.showVideos = false;
                this.showFiles = false;
                this.showChecklist = false;
                break;
            }
            case 'showVideos': {
                this.showFAQ = false;
                this.showVideos = !this.showVideos;
                this.showFiles = false;
                this.showChecklist = false;
                break;
            }
            case 'showFiles': {
                this.showFAQ = false;
                this.showVideos = false;
                this.showFiles = !this.showFiles;
                this.showChecklist = false;
                break;
            }
            case 'showChecklist': {
                this.showFAQ = false;
                this.showVideos = false;
                this.showFiles = false;
                this.showChecklist = !this.showChecklist;
                break;
            }
        }
    }
    public swipeDown(event: any) {
        if (event.targetTouches[0].clientY > 100) this.mobileExpand = false; this.scroll.enableBodyScroll()
    }
 

}
