import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PartialService } from "src/app/services/partial.service";
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { Conversation } from 'src/app/model/responsible/Conversation';
import { ConversationAPI } from 'src/app/services/responsible/conversation.service';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { Application } from 'src/app/model/responsible/Application';
import { Subscription } from 'rxjs';
import { NewConversationComponent } from './new-conversation/new-conversation.component';

@Component({
    selector: 'app-conversations',
    templateUrl: './conversations.component.html',
    styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit, OnDestroy {
    public id: number = 0;
    public exists: boolean = false;
    public display: boolean = false;
    public conversations: Conversation[] = [];
    public loading:boolean = false;
    public formId:number = 0;
    public name:string = '';
    //public application: Application = new Application();
    @Input() isNewConversationViewOpen: boolean = false;
    @Input() isChatViewOpen: boolean = false;
    @Output() closeOverlay = new EventEmitter();
    @Output() newConversationViewIsOpen = new EventEmitter();
    @Output() chatViewIsOpen = new EventEmitter();

    public refresher = new EventEmitter();
    public loadChat = new EventEmitter<{ cId?: number, aId?: number, qId?: number }>();
    public historyArray:any = {};

    private subs: Subscription[] = [];

    public static refresh = new EventEmitter<void>();

    //public static updateList = new EventEmitter();

    constructor(
        private comm: PartialService,
        private bodyScroll: BodyScrollService,
        private conversationApi: ConversationAPI,
    ) {
        let h = localStorage.getItem('convHistory');
        if(h) this.historyArray = JSON.parse(h);
        this.subs.push(this.comm.conversations.subscribe(res => {
            if (res.isActive) {
                this.bodyScroll.enableBodyScroll();
                this.exists = true;
                setTimeout(() => { this.display = true }, 20);
            }
            if (res.isActive === false) {
                this.dismiss();
            }
            if (res.applicationId)
                this.id = res.applicationId;
            if(res.formId)
                this.formId = res.formId;
            if(res.name)
                this.name = res.name;
            if(res.applicationId&&res.questionId){
                this.isChatViewOpen = true;
                this.chatViewIsOpen.emit();
                this.loadChat.emit({ aId: res.applicationId, qId: res.questionId });
            }
            if(res.cId)
                this.openChat(res.cId);
        }));
        this.subs.push(ConversationsComponent.refresh.subscribe(res => {
            this.getChats();
        }));
    }

    ngOnInit(): void { }

    ngOnDestroy() {
        for (let s of this.subs) s.unsubscribe();
    }

    private getChats(){
        this.loading = true;
        this.conversationApi.index(this.id).subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.conversations = [];
            for(let c of response)
                this.conversations.push(Conversation.create(c));

            this.loading = false;
        }, err => {
            console.error(err);
            this.loading = false;
        })
    }

    public dismiss() {
        this.bodyScroll.enableBodyScroll();
        this.display = false;
        setTimeout(() => { this.exists = false; }, 400);
        this.closeOverlay.emit();
    }

    public openNewCoversation() {
        this.isNewConversationViewOpen = true;
        this.newConversationViewIsOpen.emit();
        NewConversationComponent.refresh.emit();
    }

    public openChat(id: number) {
        this.isChatViewOpen = true;
        this.chatViewIsOpen.emit();
        this.loadChat.emit({ cId: id });
    }

    public closeNewConversation(e: { aId: number, qId?: number } | null) {
        this.isNewConversationViewOpen = false;
        if (e) {
            this.isChatViewOpen = true;
            this.chatViewIsOpen.emit();
            this.loadChat.emit({ aId: e.aId, qId: e.qId });
        }
    }

    public closeChat() {
        this.isChatViewOpen = false;
    }

    public counter(i: number) {
        return new Array(i);
    }

    public sectionName(s:FormSection){
        if(s.title.length>10) return `${s.title.slice(0, 10)}...`;
        else return s.title;
    }

}

export interface CoversationsCall {
    isActive?: boolean;
    // updateList?:Conversation[]|string;
    // application?:Application;
    applicationId?: number;
    questionId?: number;
    formId?:number;
    name?:string;
    cId?:number;
}
