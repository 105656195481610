import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormField } from 'src/app/model/responsible/FormField';

@Component({
    selector: 'app-file-types',
    templateUrl: './file-types.component.html',
    styleUrls: ['./file-types.component.scss']
})
export class FileTypesComponent implements OnInit {
    public documents:boolean = false;
    public images:boolean = false;
    public spreadsheets:boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:{ field:FormField, index:string, qIndex:string }
    ){}

    ngOnInit():void{
        this.documents = this.data.field.filetypes?.includes('documents')??false;
        this.images = this.data.field.filetypes?.includes('images')??false;
        this.spreadsheets = this.data.field.filetypes?.includes('sheets')??false;
    }

}
