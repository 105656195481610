<div class="text_wrap center">
    <h4 *ngIf="editing">Edit admin</h4>
    <h1>{{editing?user.fullName:'Create a new admin'}}</h1>
</div>
<div class="form_block">
    <app-input-field placeholderText="First name" [(model)]="user.name"></app-input-field>
    <app-input-field placeholderText="Last name" [(model)]="user.lname"></app-input-field>
    <app-input-field placeholderText="Email" [(model)]="user.email"></app-input-field>
    <app-input-field placeholderText="Phone number" [(model)]="user.phone"></app-input-field>
    <app-form-select [placeholderText]="'Type of admin'" [list]="adminTypes" [model]="user.id?{ name: user.userType, type: user.type }:undefined" (modelChange)="user.type=$event.type" [isMulitSelect]="false" *ngIf="!isIVP"></app-form-select>
    <app-button-primary [text]="editing?'Save':'Add Admin'" (click)="send()"></app-button-primary>
</div>

<div class="form_block delete_admin" *ngIf="editing">
    <h3>Delete your admin</h3>
    <app-input-field #delPass type="password" placeholderText="Enter password"></app-input-field>
    <app-button-primary text="Delete admin" (click)="del(delPass.model)" [isActive]="delPass.model.length>0"></app-button-primary>
</div>