import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { User, UserTypes } from 'src/app/model/User';
import { PartialService } from 'src/app/services/partial.service';
import { UsersAPIService } from 'src/app/services/users.service';

import { LoaderComponent } from '../../../partials/loader/loader.component';
@Component({
    selector: 'app-add-member',
    templateUrl: './add-member.component.html',
    styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit, OnDestroy {
    @Input() isMember: boolean = true
    @Input() isEdit: boolean = false
    @Input() target:number = 0;

    public isLoaded: boolean = false
    public isAdmin:boolean = false;
    public user:User = new User();
    public members:User[] = [];
    public jobFunctions = [
        { name:'Portfolio Management' },
        { name:'Portfolio Analyst' },
        { name:'Research' },
        { name:'Marketing' },
        { name:'Business Development' },
        { name:'ESG Specialist' },
        { name:'Impact Specialist' },
        { name:'C-Suite' },
    ];
    public deleteInputs = {
        password:'',
        moveProviders:true,
        moveProducts:true,
        moveCollaborators:true,
        moveTo:undefined
    }

    private subs:Subscription[] = [];

    public static refresher = new EventEmitter<void>();

    constructor(
        private userApi:UsersAPIService,
        private partials:PartialService,
        private jwt:JwtHelperService,
        private router:Router,
    ){  
        LoaderComponent.show.emit(true)
        this.isAdmin = [UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type);
        this.subs.push(AddMemberComponent.refresher.subscribe(r => {            
            if(this.isEdit&&this.target){
                this.userApi.show(this.target).subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.user = User.create(response);
                })
            } else {
                this.user = new User();
                if(this.isMember) this.user.type = UserTypes.Member;
                else this.user.type = UserTypes.Collaborator;
            }
            
            if(!this.isMember||this.isEdit){
                this.userApi.index({filters:{type:`${UserTypes.Member}`}}).subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.members = [];
                    for(let m of response.data)
                        this.members.push(User.create(m));
                        this.isLoaded = true
                        LoaderComponent.show.emit(false);
                 })
             }
         }));
     }

    ngOnInit():void{
        console.log('isEdit', this.isEdit);
        console.log('target', this.target);
        console.log('isMember', this.isMember);
        LoaderComponent.show.emit(true);
          if(this.isEdit&&this.target){
            this.userApi.show(this.target).subscribe(res => {
                
                let response = CodedResponseModel.decode(res);
                this.user = User.create(response);

                switch(this.user.type){
                    case UserTypes.Member: this.isMember = true; break;
                    case UserTypes.Collaborator: this.isMember = false; break;
                    default: this.router.navigate(['/members']); break;
                }
             })
        } else {
            this.user = new User();
            if(this.isMember) this.user.type = UserTypes.Member;
            else this.user.type = UserTypes.Collaborator;
        }
        if(!this.isMember||this.isEdit){
            this.userApi.index({filters:{type:`${UserTypes.Member}`}}).subscribe(res => {
                LoaderComponent.show.emit(true);
                let response = CodedResponseModel.decode(res);
                this.members = [];
                for(let m of response.data)
                    if(m.id!=this.user.id) this.members.push(User.create(m));
                    this.isLoaded = true
                    LoaderComponent.show.emit(false);
              })
        }
       if(this.isMember && this.isEdit == false){ 
           this.isLoaded = true;
            LoaderComponent.show.emit(false);
        }


    }
    ngOnDestroy():void{
        for(let s of this.subs) s.unsubscribe();
    }

    public send(){
        try{
            if(!this.user.name) throw "First name cannot be empty";
            if(!this.user.lname) throw "Last name cannot be empty";
            if(!this.user.email) throw "Email cannot be empty";
            if(!/^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]+$/g.test(this.user.email)) throw "Invalid email";

            if(this.isEdit){
                this.userApi.edit(this.user).subscribe(res => {
                    this.partials.notificator.emit({ type:'success', message:'Changes have been saved', timeout: 5000 });
                    this.router.navigate(['/members']);
                }, err => {
                    this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
                });
            } else {
                this.userApi.create(this.user).subscribe(res => {
                    this.partials.notificator.emit({ type:'success', message:'User has been created', timeout: 5000 });
                    this.router.navigate(['/members']);
                }, err => {
                    this.partials.notificator.emit({ type:'error', message: err.error.message, timeout:5000 });
                });
            }
        } catch(e:any){
            this.partials.notificator.emit({ type:'error', message: e, timeout: 5000 });
        }
    }
    public del(){
        this.userApi.standardDelete(this.user.id, this.deleteInputs).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'User successfully deleted', timeout: 5000 });
            this.router.navigate(['/members']);
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
}
