import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-paragraph1',
  templateUrl: './paragraph1.component.html',
  styleUrls: ['./paragraph1.component.scss']
})
export class Paragraph1Component implements OnInit {
  @Input() text: string = ''
  @Input() isTextBlack: boolean = true
  constructor() { }

  ngOnInit(): void {
  }

}
