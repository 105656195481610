import { environment } from "../../environments/environment";
import { Provider, provider_response } from "./responsible/Provider";

export enum UserTypes {
    Admin,
    IVP,
    RIAA,
    Member,
    Collaborator
}

export interface user_response {
    id: number;
    name: string;
    lname: string;
    email: string;
    type: UserTypes;
    phone:string;
    avatar?:string;
    parent_id?:number;
    job_title?:string;
    job_function?:string;
    created_at?: string;

    new_count?:number;
    ongoing_count?:number;

    parent?:user_response;
    providers?:provider_response[];
    parents_providers?:provider_response[];
}

export class User {
    public id: number = 0;
    public name: string = "";
    public lname: string = "";
    public email: string = "";

    public changePassword: boolean = false;
    public password: string = '';
    public newPass: string = '';
    public passConfirm: string = '';

    public type: UserTypes = 0;
    public createdAt: Date = new Date();
    public phone:string = '';
    public avatar?:string;
    public parentId?:number;
    public jobTitle?:string;
    public jobFunction?:string;

    public newAssigned:number = 0;
    public ongoingAssigned:number = 0;

    public parent?:User;
    public providers:Provider[] = [];
    public parentsProviders:Provider[] = [];

    public map(data: user_response) {
        this.id = data.id;
        this.name = data.name;
        this.lname = data.lname;
        this.email = data.email;
        this.type = data.type;
        if (data.created_at) this.createdAt = new Date(data.created_at);
        this.phone = data.phone;
        this.avatar = data.avatar;
        this.parentId = data.parent_id;
        this.jobTitle = data.job_title;
        this.jobFunction = data.job_function;

        this.parent = data.parent?User.create(data.parent):undefined;

        this.newAssigned = data.new_count??0;
        this.ongoingAssigned = data.ongoing_count??0;

        this.providers = [];
        for(let p of data.providers??[])
            this.providers.push(Provider.create(p));

        this.parentsProviders = data.parents_providers?.map(p => Provider.create(p))??[];

        return this;
    }
    public responsify(): user_response {
        return {
            id: this.id,
            name: this.name,
            lname: this.lname,
            email: this.email,
            type: this.type,
            phone:this.phone,
            avatar:this.avatar,
            parent_id:this.parentId,
            job_title:this.jobTitle,
            job_function:this.jobFunction,
        }
    }
    public static create(data: user_response) {
        let r = new this;
        r.map(data);
        return r;
    }
    public get fullName() {
        return `${this.name} ${this.lname}`;
    }
    public get userType() {
        switch (this.type) {
            case 0: return 'Admin';
            case 1: return 'IVP Admin';
            case 2: return 'RIAA Admin';
            case 3: return 'Member';
            case 4: return 'Collaborator';
            default: return 'Invalid';
        }
    }
    public get avatarURL(){
        if(this.avatar) return `${environment.imgUrl}avatars/${this.avatar}`;
        else return null;
    }
    public get providerNames(){
        let r = [];
        if(this.parentId)
            for(let p of this.parentsProviders)
                r.push(p.name);
        else
            for(let p of this.providers)
                r.push(p.name);
        return r.join(', ');
    }
}
