import { Component, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tab_interface } from 'src/app/core/top-tabs/top-tabs.component';
import { UserTypes } from 'src/app/model/User';
import { AddMemberComponent } from './add-member/add-member.component';
import { LoaderComponent } from '../../partials/loader/loader.component';
@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
    @Input() public model: any = []
    public total: number = 0
    public isEdit: boolean = true
    public currTab: number = 0; 
    public currPage: number = 1
    public isLoaded: boolean = true;
    public target:number = 0;
    public isMember:boolean = true;
    public expanded: boolean = false
    public tabs: tab_interface[] = [
        { label: 'Members & collabs', onClick: () => { this.router.navigate(['/members']); }, active: true, badge: MembersComponent.memory.members },
        { label: 'Providers', onClick: () => { this.router.navigate(['/providers']); }, active: false, badge: MembersComponent.memory.providers },
        { label: 'Add members', onClick: () => { this.router.navigate(['/members/new/member']) }, active: false, hidden:![UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type) },
        { label: 'Add Collaborators', onClick: () => { this.router.navigate(['/members/new/collaborator']); }, active: false },
        { label: 'Add providers', onClick: () => { this.router.navigate(['/providers/new']); }, active: false },
    ];

    public bulkActions: Array<{isPlus: boolean, btnText: string, imgUrl: string, function: CallableFunction }> = [
        { isPlus: false, btnText: 'Delete all selected', imgUrl: '/assets/svg/minus-icon.svg', function: () => { console.log("-") } }
    ]
    public refresher = new EventEmitter();
    @ViewChild('main') main: any
 
    public static memory = {
        members:0,
        providers:0,
    }

    constructor(
        private route:ActivatedRoute,
        private router:Router,
        private jwt:JwtHelperService,
    ){
        this.route.data.subscribe(d => {
            if(d.directive){
             
                switch(d.directive){
                    case 'members':             // /members
                        this.goToTab(0);
                        this.isEdit = false;
                        break;
                    case 'providers':           // /providers
                        this.goToTab(1);
                        this.isEdit = false;
                        break;
                    case 'new-member':          // /members/new/member
                        if(![UserTypes.Admin,UserTypes.RIAA].includes(this.jwt.decodeToken().user.type)){
                            this.router.navigate(['/members']);
                            break;
                        }
                        this.goToTab(2);
                        this.isEdit = false;
                        this.isMember = true;
                        AddMemberComponent.refresher.emit();
                        break;
                    case 'new-collaborator':    // /members/new/collaborator
                        this.goToTab(3);
                        this.isEdit = false;
                        this.isMember = false;
                        AddMemberComponent.refresher.emit();
                        break;
                    case 'new-provider':        // /providers/new
                        this.goToTab(4);
                        this.isEdit = false;
                        break;
                    case 'edit-member':         // /members/:id
                        for(let t of this.tabs) t.active = false;
                        this.currTab = 2;
                        
                        this.isEdit = true;
                        this.target = Number(this.route.snapshot.paramMap.get('id'));
                        AddMemberComponent.refresher.emit();
                        break;
                    case 'edit-provider':       // /providers/:id
                        for(let t of this.tabs) t.active = false;
                        this.currTab = 4;
                        this.isEdit = true;
                        this.target = Number(this.route.snapshot.paramMap.get('id'));
                        break;
                }
            }
        });
    }


    ngOnInit(): void {
         LoaderComponent.show.emit(true);
    }

    public goToTab(i: number) {
        LoaderComponent.show.emit(true);
          this.currTab = i;
        for (let t of this.tabs) t.active = false;
        this.tabs[i].active = true;
    }
    public getItemsCount(counts: { members: number, providers: number }) {
         this.total = this.currTab==1?counts.providers:counts.members;
        this.tabs[0].badge = counts.members;
        this.tabs[1].badge = counts.providers;
        MembersComponent.memory.members = counts.members;
        MembersComponent.memory.providers = counts.providers;
        LoaderComponent.show.emit(false);
    }
    public getOffset(value: boolean) {
        this.expanded = value
    }
}
