import { from } from "rxjs";
import {User, user_response} from './User';
export class MailingGroup{
    public id: number = 0;
    public name: string = '';

    public users: Array<User> = [];

    public checked: boolean = false;

    public map(_data: any){
		this.id = _data.id;
        this.name = _data.name;

        if(_data.users){
            this.users = [];
            _data.users.forEach( (item: user_response) => {
                let user = new User();
                user.map(item);
                this.users.push(user);
            });
        }
	} 
}