<div class='ctr'>
    <h4>{{application.provider?.name}}</h4>
    <h1>Application Progress</h1>
    <div class='cards'>
        <div class='card inactive'>
            <!-- <div class='img'></div> -->
            <svg-icon class="tick" src="assets/svg/big_tick.svg"></svg-icon>
            <h4>1-2 Weeks</h4>
            <h1>Application Submitted</h1>
            <p>Your application is waiting to be assessed by our team.</p>
        </div>
        <div class='card' [class.active]="currentStage==0"
            [class.inactive]="currentStage>0">
            <!-- <div class='img'></div> -->
            <svg-icon class="tick" src="assets/svg/big_tick.svg"></svg-icon>
            <h4>2-3 Months</h4>
            <h1>RIAA Assessment</h1>
            <p>{{currentStage==0?'Your application is being assessed by our team.':'Your application has been assessed and accepted by our team.'}}</p>
            <div *ngIf="currentStage==0" class="cta_active_mobile">
                <p class="q_left" *ngIf="true"><span>{{application.unfilled}} Questions</span> require
                    more info</p>
                <app-button-primary text="Edit application"
                    (click)="editApp.emit()"></app-button-primary>
            </div>
        </div>
        <div class='card' [class.inactive]="currentStage>1&&currentStage!=4"
            [class.denied]="currentStage==4">
            <!-- <div class='img'></div> -->
            <svg-icon class="tick" src="assets/svg/big_tick.svg"></svg-icon>
            <h4>2 Years</h4>
            <h1>{{currentStage<3? 'Approved':currentStage==3? 'Conditionally Approved':'Denied'}}</h1>
            <p>{{currentStage<3? 'Your product was approved and is now certified.':
                    currentStage==3? 'Your product was approved conditionally and is now certified.': 
                    'Your application has been denied certification.'}}</p>
        </div>
        <div class='card' [class.active]="currentStage>2"
            [class.inactive]="!application.needsIVP">
            <!-- <div class='img'></div> -->
            <svg-icon class="tick" src="assets/svg/big_tick.svg"></svg-icon>
            <h4>2-3 Months</h4>
            <h1>IVP Assessment</h1>
            <p>{{currentStage<4? 'Your application is being assessed by the IVP team.': 'Your application has been assessed and accepted by the IVP team.'}}</p>

            <div *ngIf="currentStage==5" class="cta_active_mobile">
                <p class="q_left" *ngIf="true"><span>{{application.unfilled}} Questions</span> require
                    more info</p>
                <app-button-primary text="Edit application"
                    (click)="editApp.emit()"></app-button-primary>
            </div>
        </div>

    </div>
    <div class="cta_active_desktop">
        <p class="q_left" *ngIf="true"><span>{{application.unfilled}} Questions</span> require more
            info
        </p>
        <app-button-primary text="Edit application" (click)="editApp.emit()"></app-button-primary>
    </div>
</div>