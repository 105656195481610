<div class="icon_header_char">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='body'>
    <!-- <h4>{{data.productName}}</h4> -->
    <h3>Share Application</h3>
    <p>Shares an editable version of this question for 24 hours.</p>
    <p><span>Please note:</span> we cannot ensure security and privacy of this question once shared.</p>
    <app-text-field [(model)]="email" [placeholderText]="'Enter email'"></app-text-field>
    <app-button-primary buttonContent="Share" [mat-dialog-close]="email"></app-button-primary>
    <app-button-secondary [isGreen]="false" [isText]="true" buttonText="Cancel" [mat-dialog-close]="false"></app-button-secondary>
</div>