// import { ApplicationAnswer } from "./ApplicationAnswer";
// import { AssessmentAnswer } from "./AssessmentAnswer";
// import { FormSection, form_section_response } from "./FormSection";

// export interface form_response{
//     id:number;
//     name:string;
//     active:boolean;
//     sections?:form_section_response[];
// }
// export class Form{
//     public id:number = 0;
//     public name:string = '';
//     public active:boolean = true;

//     public sections:FormSection[] = [];

//     public map(data:form_response){
//         this.id = data.id;
//         this.name = data.name;
//         this.active = data.active;

//         this.sections = [];
//         for(let s of data.sections??[])
//             this.sections.push(FormSection.create(s));

//         return this;
//     }
//     public responsify():form_response{
//         return {
//             id: this.id,
//             name: this.name,
//             active: this.active,
//             sections: (() => {
//                 let arr:form_section_response[] = [];
//                 for(let s of this.sections)
//                     arr.push(s.responsify());
//                 return arr;
//             })()
//         };
//     }
//     public static create(data:form_response){
//         let r = new this;
//         r.map(data);
//         return r;
//     }

//     public fixSectionOrdinals(){
//         for(let [i, s] of this.sections.entries())
//             s.order = i;
//     }
//     public deepOrdinalFix(){
//         for(let [i, s] of this.sections.entries()){
//             s.order = i;
//             s.deepOrdinalFix();
//         }
//     }
//     public mapAnswers(answers:ApplicationAnswer[]){
//         for(let a of answers){
//             if(a.questionKey){
//                 secloop: for(let s of this.sections){
//                     for(let q of s.questions){
//                         if(q.key == a.questionKey){ 
//                             q.answer = a;
//                             break secloop;
//                         }
//                         for(let u of q.subquestions){
//                             if(u.key == a.questionKey){
//                                 u.answer = a;
//                                 break secloop;
//                             }
//                         }
//                     } 
//                 }
//             } else { // question ID fallback
//                 secloop: for(let s of this.sections){
//                     for(let q of s.questions){
//                         if(q.id == a.questionId){ 
//                             a.questionKey = q.key;
//                             q.answer = a;
//                             break secloop;
//                         }
//                         for(let u of q.subquestions){
//                             if(u.id == a.questionId){
//                                 a.questionKey = u.key;
//                                 u.answer = a;
//                                 break secloop;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     public mapRIAAAnswers(answers:AssessmentAnswer[]){
//         for(let a of answers){
//             secloop: for(let s of this.sections){
//                 for(let q of s.riaaQuestions){
//                     if(q.key==a.questionKey){
//                         q.answer = a;
//                         break secloop;
//                     }
//                     for(let u of q.subquestions){
//                         if(u.key == a.questionKey){
//                             u.answer = a;
//                             break secloop;
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     public getQuestionById(id:number){
//         for(let s of this.sections){
//             let r = s.getQuestionById(id);
//             if(r) return r;
//         }
//         return null;
//     }
//     public getQuestionByKey(key:string){
//         for(let s of this.sections){
//             let r = s.getQuestionByKey(key);
//             if(r) return r;
//         }
//         return null;
//     }
//     public verifySectionData(){
//         for(let [i, s] of this.sections.entries()){
//             if(!s.title) return i;
//         }
//         return null;
//     }

//     public completion():number{
//         let t = 0, f = 0;
//         for(let s of this.sections){
//             for(let q of s.questions){
//                 t++;
//                 if(q.filled()) f++;
//                 for(let u of q.subquestions){
//                     t++;
//                     if(u.filled()) f++;
//                 }
//             }
//         }
//         return Math.floor(f/t*100);
//     }
//     public syncIds(input:Form){
//         let found = [], missing = [];
//         for(let [k,v] of this.sections.entries()){
//             if(v.id){ 
//                 found.push(v.id);
//                 for(let n of input.sections??[]){
//                     if(v.id==n.id){ 
//                         v.syncIds(n);
//                         break;
//                     }
//                 }
//             } else missing.push(k);
//         }
//         for(let k of missing){
//             for(let n of input.sections??[]){
//                 if(!found.includes(n.id)){
//                     this.sections[k].id = n.id;
//                     found.push(n.id);
//                 }
//             }
//         }
//     }
// }

export class Keyed{
    constructor(){ if(!this.key) this.generateKey(); }

    public key:string = '';

    public generateKey(){
        let key = '';
        for(let i=0;i<32;i++){
            let g;
            do{ g = Math.floor(48+Math.random()*75); } while((g>57&&g<65)||(g>90&&g<97));
            key += String.fromCharCode(g);
        }
        this.key = key;
    }
}