import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApexTooltip, ChartComponent } from "ng-apexcharts";
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart } from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  colors: string[] | any;
};

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @ViewChild("chart", { static: false }) chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  @Input() pieChartData: any;
  public seriesArray: any[];
  public tooltipDesc: any[];
  public isLoaded: boolean = false;
  @Input() pieChartWidth: number = 215;
  constructor() { 
    this.seriesArray = [];
    this.tooltipDesc = [];
  }

  ngOnInit(): void {
    this.pieChartData.percentageView.map((e: any) => {
      this.seriesArray.push(e.percentage);
    })
    this.pieChartData.percentageView.map((e: any) => {
      this.tooltipDesc.push(e.desc);
    })
    let self = this;
    this.chartOptions = {
      series: this.seriesArray,
      chart: {
        width: this.pieChartWidth,
        type: "pie"
      },
      labels: [],
      colors: ['#149e89', '#42b1a0', '#70c3b6', '#9ed5cd', '#cce8e3'],
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
         // console.log(w.config.colors);
          return `<div class="arrow_box"><span style="color: ${w.config.colors[seriesIndex]};">${series[seriesIndex]}%</span> ${self.tooltipDesc[seriesIndex]}</div>`
        }
      },
      responsive: []
    };
    setTimeout(()=>{
      this.isLoaded = true;
    }, 300);
    
  }
}
