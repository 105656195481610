import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { MailEvents } from 'src/app/model/MailEvents';
import { MailingGroup } from 'src/app/model/MailingGroup';
import { NotyficationMessageStateModel } from 'src/app/model/NotyficationMessageStateModel';
import { UserTypes } from 'src/app/model/User';
import { LoaderComponent } from 'src/app/partials/loader/loader.component';
import { MailEventsService } from 'src/app/services/mail-events.service';
import { MailingGroupService } from 'src/app/services/mailing-group.service';
import { PartialService } from 'src/app/services/partial.service';

@Component({
  selector: 'app-mail-events-edit',
  templateUrl: './mail-events-edit.component.html',
  styleUrls: ['./mail-events-edit.component.scss']
})
export class MailEventsEditComponent implements OnInit {
  public new:boolean = false;
  public mailEvent:MailEvents = new MailEvents();

  public mailingGroupOrginal: Array<MailingGroup> = [];
  public mailingGroup: Array<MailingGroup> = [];

  public filter:string = '';

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private eventApi:MailEventsService,
    private partials:PartialService,
    private jwt:JwtHelperService,
  ) {
    if(![UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type))
      this.router.navigate(['/']);
    if (this.route.snapshot.data.new) {
      this.new = true;
    } else {
      this.getMailEvent();
    }
    //this.getMailingGroups();
  }

  ngOnInit(): void {
  }

  // private getMailingGroups(){
  //   LoaderComponent.show.emit(true);
  //   this.mailGroupApi.getAll().subscribe(res => {
  //     this.mailingGroupOrginal = [];
  //     let response = new CodedResponseModel();
  //     response.map(res);
  //     for(let i of response.decodeData()){
  //       let mailGroup = new MailingGroup();
  //       mailGroup.map(i);
  //       this.mailingGroupOrginal.push(mailGroup);
  //     }
  //     this.setMailing();
  //     if(this.new) LoaderComponent.show.emit(false); 
  //     else this.getMailEvent();
  //   }, err => {
  //     LoaderComponent.show.emit(false);
  //     this.showNotyfication = true;
  //     this.notyficationMessage = err.error.message;
  //     this.notyficationMessageStateModel.errors_save = true;
  //   })
  // }

  private getMailEvent(){
    let id: any;
    LoaderComponent.show.emit(true);
    this.route.paramMap
        .subscribe(params => {
          id = params.get('id');
        });
    this.eventApi.get(parseInt(id)).subscribe(
      response => {
        let codedResponseModel = new CodedResponseModel();
        codedResponseModel.map(response);
        let orginalResponse = codedResponseModel.decodeData();
        this.mailEvent.map(orginalResponse);
        for(let group of this.mailingGroupOrginal) if(this.mailEvent.mail_to_a.includes(group.id)) group.checked = true;
        LoaderComponent.show.emit(false);
      }, err => {
        LoaderComponent.show.emit(false);
        this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 3000 });
      }
    );
  }

  public setMailing(){
    if(this.filter=='') this.mailingGroup = this.mailingGroupOrginal;
    else{
      let tmp = [],
          lcf = this.filter.toLowerCase();
      for(let i of this.mailingGroupOrginal){
        if(i.name.toLowerCase().includes(lcf))
          tmp.push(i);
      }
      this.mailingGroup = tmp;
    }
  }

  public save(){
    LoaderComponent.show.emit(true);
    if(this.mailEvent.mail_to_mailing_groups) for(let i of this.mailingGroup){
      if(i.checked) this.mailEvent.mail_to_a.push(i.id);
    }
    if(this.new){
      this.eventApi.add(this.mailEvent).subscribe(res => {
        this.router.navigate(['/mail-events']);
      }, err => {
        LoaderComponent.show.emit(false);
        this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 3000 });
      });
    } else {
      this.eventApi.update(this.mailEvent).subscribe(res => {
        this.router.navigate(['/mail-events']);
      }, err => {
        LoaderComponent.show.emit(false);
        this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 3000 });
      });
    }
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
}
