import { User, user_response } from "../User";
import { Attachment, attachment_response } from "./Attachment";

export interface conversation_message_response{
    id:number;
    conversation_id:number;
    user_id:number;
    message:string;
    read:boolean;
    created_at?:string;
    updated_at?:string;

    user?:user_response;
    attachments?:attachment_response[];
}
export class ConversationMessage{
    public id:number = 0;
    public conversationId:number = 0;
    public userId:number = 0;
    public message:string = '';
    public read:boolean = false;
    public createdAt?:Date;
    public updatedAt?:Date;

    public user?:User;
    public attachments:Attachment[] = [];

    public map(data:conversation_message_response){
        this.id = data.id;
        this.conversationId = data.conversation_id;
        this.userId = data.user_id;
        this.message = data.message;
        this.read = data.read;
        if(data.created_at) this.createdAt = new Date(data.created_at);
        if(data.updated_at) this.updatedAt = new Date(data.updated_at);

        if(data.user) this.user = User.create(data.user);

        this.attachments = [];
        for(let a of data.attachments??[])
            this.attachments.push(Attachment.create(a));

        return this;
    }
    public responsify():conversation_message_response{
        return {
            id: this.id,
            conversation_id: this.conversationId,
            user_id: this.userId,
            message: this.message,
            read: this.read,

            attachments:(()=>{
                let arr = [];
                for(let a of this.attachments) arr.push(a.responsify());
                return arr;
            })()
        }
    }
    public static create(data:conversation_message_response){
        let r = new this;
        r.map(data);
        return r;
    }
}