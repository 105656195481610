import { Component, OnInit } from '@angular/core';
import { PartialService } from "src/app/services/partial.service";

/**
 * @param {string} message Message to be displayed
 * @param {number} timeout Time in ms before the notification disappears. If null or 0, notification will not disappear automatically.
 * @param {enum} type Serverity of notification message
 * @param {string} details Text to be shown when clicking "More" button next to the message
 * @param {boolean} dismissable Whether the notification can be manually dismissed by clicking the close button, by default it's false unless timeout is also unset or set to null or 0
 */
 export interface NotificatorCall{
  message:string;
  timeout?:number; 
  type:'default'|'info'|'success'|'error'|'warning';
  details?:string;
  dismissable?:boolean;
}

@Component({
  selector: 'app-notificator',
  templateUrl: './notificator.component.html',
  styleUrls: ['./notificator.component.scss']
})

export class NotificatorComponent {
  public message:string = '';
  public exists:boolean = false;
  public display:boolean = false;
  public type:'default'|'info'|'success'|'error'|'warning' = 'success';
  public dismissable:boolean = true;
  public details:string = '';
  public showDetails:boolean = false;
//TODO 5 second always
  constructor(
      private comm:PartialService,
  ){
      this.comm.notificator.subscribe(res => {
          this.exists = true;
          this.message = res.message;
          this.type = res.type;
          this.showDetails = false;
          this.details = res.details??'';
          this.dismissable = res.dismissable??(!res.timeout);
          setTimeout(() => { this.display = true }, 20);
          if(res.timeout) setTimeout(() => { this.dismiss() }, res.timeout+500);
      })
  }

  public dismiss(){
      this.display = false;
      setTimeout(() => { this.exists = false; }, 250);
  }
}