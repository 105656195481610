import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { CodedResponseModel } from "../../model/CodedResponseModel";
import { Router } from "@angular/router";
import { GlobalsAPI } from 'src/app/services/responsible/globals.service';
import { LoaderComponent } from 'src/app/partials/loader/loader.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loaded: boolean = false;
    public mode:'login'|'forgot' = 'login';

    public sent:boolean = false;

    public email: string = '';
    public password: string = '';
    public rememberMe: boolean = false;

    public titleSmall: string = 'Certification Portal';
    public titleBig: string = 'Welcome back';
    public logo1ImgSrc: string = 'assets/svg/RIAA-Logo.svg';
    public logo2ImgSrc: string = 'assets/svg/RR-Logo-login.svg';
    public loginBtnText: string = 'Log in';
    public registerBtnText: string = 'Register';
    public registerLink: string = 'https://responsibleinvestment.org/ri-certification/';
    public errorMsg: string = 'Uh oh! You entered an incorrect email or password, try again';
    public error: boolean = false;

    public resetSubhead:string = 'Forgot your password?';
    public setSubhead:string = 'Set your password';

    public bannerSettings = {
        banner:'assets/jpg/login_image.jpg',
        featured:true,
        featuredHead: '',
        featuredStars:0,
        featuredLogo:'',
        research:true,
        researchHead:'',
        researchSubhead:'',
        researchLink:'',
        researchLinkTxt:'',
    }

    constructor(
        private loginApi: LoginService,
        private gloablsApi:GlobalsAPI,
        private router: Router
    ) {
        LoaderComponent.menuHidden.emit(true)
        LoaderComponent.show.emit(true);
        if (this.loginApi.isLoggedIn()) {
            this.router.navigate(['/']);
        }
        this.gloablsApi.loginData().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.titleSmall = response.login_heading;
            this.titleBig = response.login_subheading;
            this.logo1ImgSrc = response.login_riaa_logo;
            this.logo2ImgSrc = response.login_rr_logo;
            this.registerLink = response.login_rfc_link;
            this.errorMsg = response.login_error_msg;
            this.resetSubhead = response.forgot_pass_subheading;
            this.setSubhead = response.set_pass_subheading;

            this.bannerSettings.banner = response.login_banner;
            this.bannerSettings.featured = response.login_featured==='true';
            this.bannerSettings.featuredHead = response.login_featured_heading;
            this.bannerSettings.featuredStars = Number(response.login_featured_stars);
            this.bannerSettings.featuredLogo = response.login_featured_logo;
            this.bannerSettings.research = response.login_research==='true';
            this.bannerSettings.researchHead = response.login_research_heading;
            this.bannerSettings.researchSubhead = response.login_research_subheading;
            this.bannerSettings.researchLink = response.login_research_link;
            this.bannerSettings.researchLinkTxt = response.login_research_link_text;

            this.loaded = true;
            LoaderComponent.show.emit(false);
        });
    }

    ngOnInit(): void {
    }

    public login() {
        this.error = false;
        this.loginApi.login(this.email, this.password, this.rememberMe).subscribe(
            response => {
                let codedResponseModel = new CodedResponseModel();
                codedResponseModel.map(response);
                let orginalResponse = codedResponseModel.decodeData();
                localStorage.setItem("access_token", orginalResponse.access_token);
                this.router.navigate(['/']);
            },
            err => {
                this.error = true;
            }
        )
    }
    public forgot(){
        this.error = false;
		this.loginApi.forgotPassword(this.email).subscribe(res => {
			let response = new CodedResponseModel();
			response.map(res);
			if (response.success) {
				this.sent = true;
			}
		}, err => {
			this.error = true;
			console.log(err);
		});
    }
}
