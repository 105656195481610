import { Component, OnInit } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { AssetClass, Market, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { PartialService } from 'src/app/services/partial.service';
import { CustomIntegrationAPI } from 'src/app/services/responsible/custom-integrations.service';
import { LoaderComponent } from '../../../partials/loader/loader.component';
@Component({
    selector: 'app-edit-search-filters',
    templateUrl: './edit-search-filters.component.html',
    styleUrls: ['../admins.component.scss', './edit-search-filters.component.scss']
})
export class EditSearchFiltersComponent implements OnInit {
    public markets:Market[] = [];
    public targets:ProductTarget[] = [];
    public strategies:RIStrategy[] = [];
    public classes:AssetClass[] = [];

    constructor(
        private integrationApi:CustomIntegrationAPI,
        private partials:PartialService,
    ){
        this.integrationApi.all().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            for(let m of response.markets)
                this.markets.push(Market.create(m));

            for(let t of response.targets)
                this.targets.push(ProductTarget.create(t));

            for(let s of response.strategies)
                this.strategies.push(RIStrategy.create(s));

            this.classes = response.classes.map((c:AssetClass[]) => AssetClass.create(c));
            
            LoaderComponent.show.emit(false);
        })
    }

    ngOnInit():void{}

    public addNewMarket() {
        this.markets.push(new Market());
    }
    public deleteMarket(e:Market) {
        this.markets.splice(this.markets.indexOf(e), 1);
    }
    public saveMarkets(){
        this.integrationApi.markets.update(this.markets).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:"Markets have been updated", timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        });
    }

    public addNewTarget() {
        this.targets.push(new ProductTarget());
    }
    public deleteTarget(e:ProductTarget) {
        this.targets.splice(this.targets.indexOf(e), 1);
    }
    public saveTargets(){
        this.integrationApi.productTargets.update(this.targets).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:"Product targets have been updated", timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error',  message:err.error.message, timeout: 5000 });
        });
    }

    public addNewStrategy() {
        this.strategies.push(new RIStrategy());
    }
    public deleteStrategy(e:RIStrategy){
        this.strategies.splice(this.strategies.indexOf(e), 1);
    }
    public saveStrategies(){
        this.integrationApi.riStrategies.update(this.strategies).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'RI Strategies have been updated', timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error',  message:err.error.message, timeout: 5000 });
        });
    }

    public addNewClass(){
        this.classes.push(new AssetClass());
    }
    public deleteClass(e:AssetClass){
        this.classes.splice(this.classes.indexOf(e), 1);
    }
    public saveClasses(){
        this.integrationApi.assetClasses.update(this.classes).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Asset Classes have been updated', timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error',  message:err.error.message, timeout: 5000 });
        })
    }
}
