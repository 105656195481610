import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConvertVideoTimeService {

  constructor() { }

  public convertTime(duration: any) {
    var date = new Date(duration * 1000);
    var hh: any = date.getUTCHours();
    var mm: any = date.getUTCMinutes();
    var ss: any = date.getSeconds();

    if (hh < 10) { hh = "0" + hh; }
    hh = hh > 0 ? hh + ":" : '';
    if (mm < 10) { mm = "0" + mm; }
    if (ss < 10) { ss = "0" + ss; }
    var time = hh + mm + ":" + ss;
    return (duration != undefined && duration != 0) ? time : '';
  }

}
