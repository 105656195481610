<div class="column" [ngClass]="{'active': isFocused, 'accepted': isAccepted, 'error': isError,'error-bcg': isError && isEmpty, 'suf': isSuffix, 'placeholder_top': validatePlaceholder()}">
    <div class="row" [class.small]="small">
        <input #inputNumber [class.disabled]="isDisabled" (keydown.enter)="enterClicked.emit()" inputmode="numeric" type="text" [formControl]="val" pattern="{{pattern}}" (keydown)="arrowStep($event)" (focus)="onFocus($event)" (blur)="onBlur()" [min]="min" [max]="max">
        <div class="placeholder-container">
            <span class="placeholder">{{placeholderText}}</span>
        </div>
        <span *ngIf="isSuffix" class="sufix"><span class="suf">{{sufix}}</span></span>
    </div>
    <!-- <div *ngIf="isError" class="container ">
        <span>{{errorText}}</span>
    </div> -->
</div>