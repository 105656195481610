<div class="icon_header">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='body'>
    <h4>DENY APPLICATION</h4>
    <h3>{{data.application.productName}}</h3>
    <div class='form'>
        <app-form-select [list]="approvalConditions" placeholderText="Approval Condition" [(model)]="selectedCondition" [isDisabled]="true"></app-form-select>
        <app-form-select [list]="finalScores" placeholderText="Final Scoring" [(model)]="selectedScore" [isDisabled]="true"></app-form-select>
        <app-text-field placeholderText="Comments" [model]="assessment.comments||''" (modelChange)="assessment.comments=$event" [limit]="1000" [multiline]="true"></app-text-field>
        <app-drag-drop appFileDrop [fileList]="documents" (fileEvent)="uploadDocument($event)" (fileRemoved)="deleteDocument($event)" (openModal)="openUploader()"></app-drag-drop>
        <!-- <div class="stars">
            <h3>Set Star Rating</h3>
            <app-filters-star [rating]="assessment.stars||0" (ratingChange)="assessment.stars=$event"></app-filters-star>
        </div> -->
    </div>
    <app-button-primary buttonContent="Deny" [mat-dialog-close]="assessment"></app-button-primary>
    <app-button-secondary [isGreen]="false" [isText]="true" buttonText="Cancel" [mat-dialog-close]="false"></app-button-secondary>
</div>