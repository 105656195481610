import { Attachment, attachment_response } from "./Attachment";

export interface resource_file_response{
    id:number;
    file_id:number;
    type:boolean;

    file?:attachment_response;
}
export class ResourceFile{
    public id:number = 0;
    public fileId:number = 0;
    public type:boolean = false;

    public file?:Attachment;

    public map(data:resource_file_response){
        this.id = data.id;
        this.fileId = data.file_id;
        this.type = data.type;

        this.file = data.file?Attachment.create(data.file):undefined;

        return this;
    }
    public responsify():resource_file_response{
        return {
            id:this.id,
            file_id:this.fileId,
            type:this.type,
        }
    }
    public static create(data:resource_file_response){
        let r = new this;
        r.map(data);
        return r;
    }
}