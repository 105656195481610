<app-auth></app-auth>
<app-menu (expanded)="getOffset($event)"></app-menu>
<main role="main">
    <div #main class='main' [ngClass]="{'menu_expanded': !expanded, 'table': currTab == 1 || currTab == 0}">
        <app-top-tabs [tabs]="tabs" [ngClass]="{z_in: currTab==2 || currTab==3|| currTab==4}"></app-top-tabs>
        <div class="top" *ngIf="currTab == 0 ||currTab == 1">
            <h4>{{total}} <span *ngIf="currTab==0">Users</span><span *ngIf="currTab==1">Providers</span></h4>
            <h1 *ngIf="currTab==0">Members and Collaborators</h1>
            <h1 *ngIf="currTab==1">All Providers</h1>
        </div>
        <ng-container>
            <!-- <div class="container" *ngIf="currTab==0 ">
                <div #tableM class="table-container" (click)="scrollToTable()">
                    <app-index-table (itemsCount)="getItemsCount($event)" [isMembers]="true" [ngClass]="{'m_expand': expanded}"></app-index-table>
                </div>
            </div> -->
            <ng-container *ngIf="currTab==0">
                <app-index-table (itemsCount)="getItemsCount($event)" [isMembers]="true" [ngClass]="{'m_expand': expanded}"></app-index-table>
            </ng-container>
            <ng-container *ngIf="currTab==1">
                <app-index-table (itemsCount)="getItemsCount($event)" [isMembers]="false" [ngClass]="{'m_expand': expanded}"></app-index-table>
            </ng-container>
            <!-- <div class="container" *ngIf="currTab==1 ">
                <div #tableP class="table-container" (click)="scrollToTable()">
                    <app-index-table (itemsCount)="getItemsCount($event)" [isMembers]="false" [ngClass]="{'m_expand': expanded}"></app-index-table>
                </div>
            </div> -->
            <div class="content">
                <div class="member_tab add_member" *ngIf="[2,3].includes(currTab)">
                    <app-add-member [isMember]='isMember' [isEdit]="isEdit" [target]="target"></app-add-member>
                </div>
                <div class="member_tab add_provider" *ngIf="currTab==4">
                    <app-add-provider [isEdit]="isEdit" [target]="target"></app-add-provider>
                </div>
            </div>
        </ng-container>
    </div>
</main>