<div class="label" *ngIf="isLabel">
    {{ labelText }}
</div>
<div class="select_wrap" [class.active]="active" [ngClass]="classes" [ngClass]="{'placeholder_top': placeholderValidate(), 'selected': placeholderValidate() && model != null || selectedValues.length > 0, 'disabled': isDisabled }">
    <button type="button" class="select_btn" [class.active]="active" [disabled]="isDisabled" (click)="slideToggle();">
        <span class="value" *ngIf="model">
            <span class="prefix" *ngIf="prefix">{{ prefix }}</span> 
            <ng-container *ngIf="isDefaultSelect">
                <!-- {{ model?.selectVale() }} -->
            </ng-container>
            <ng-container *ngIf="!isDefaultSelect">
                {{ model[displayProperty] }}
            </ng-container>
        </span>
        <span class="value" *ngIf="(!model || model === noneValue) && noneSelected">
            {{noneLabel}}
        </span>
         <svg-icon class="arrow" src="assets/svg/select_arrow.svg"></svg-icon>
         <div class="placeholder-container">
            <span class="placeholder">{{placeholderText}}</span>
         </div>
    </button>

    <div class="select_list" #selectList *ngIf="list.length" [ngModel]="" [ngClass]="selectPosition" [@expandCollapse]="active ? 'expanded' : 'collapsed'" (@expandCollapse.done)="animFinish($event)">
        <ul #ul>
            <li *ngIf="isNoneValue" [class.active]="noneSelected" (click)="selectNone()">
                {{noneLabel}}
            </li>
            <li *ngFor="let item of searchResult;" [class.active]="compareValue?isSelected(item):item.active" [class.grid]="!isDefaultSelect" (click)="change(item)">
                <ng-container *ngIf="isDefaultSelect && !isMulitSelect">
                    <span class="prefix" *ngIf="prefix">{{ prefix }}</span> {{ item[displayProperty] }}
                </ng-container>
                <ng-container *ngIf="!isDefaultSelect && isMulitSelect">
                    <app-tick-box [value]="compareValue?isSelected(item):item.active" [text]="item[displayProperty]">
                        <!-- <span class="prefix" *ngIf="prefix">{{ prefix }}</span> {{ item?.name }} -->
                    </app-tick-box>
                </ng-container>
                <ng-container *ngIf="isDefaultSelect && isMulitSelect">
                    <app-tick-box>
                        <span class="prefix" *ngIf="prefix">{{ prefix }}</span> {{ item[displayProperty] }}
                    </app-tick-box>

                </ng-container>
                <ng-container *ngIf="!isDefaultSelect && !isMulitSelect">
                    <span class="prefix" *ngIf="prefix">{{ prefix }}</span> {{ item[displayProperty] }}
                </ng-container>
            </li>
            <div class='loader' *ngIf="loading">
                <mat-spinner [diameter]="30" [strokeWidth]="3"></mat-spinner>
            </div>
        </ul>
    </div>
    <div class="mulit_select_wrap" *ngIf="isMulitSelect && selectedValues.length && !active">
        <div class="single_item" *ngFor="let item of selectedValues;">
            <span class="prefix" *ngIf="prefix">{{ prefix }}</span>
            <span>{{ isDefaultSelect ? item?.selectVale() : item[displayProperty] }}</span>
        </div>
    </div>
    <div class="search" *ngIf="active">
        <input placeholder="Search..." [(ngModel)]="searchString" (ngModelChange)="search($event)">
    </div>
</div>