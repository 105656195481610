import { Component, Input, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tab_interface } from 'src/app/core/top-tabs/top-tabs.component';
import { Application } from 'src/app/model/responsible/Application';
import { Assessment } from 'src/app/model/responsible/Assessment';
import { UserTypes } from 'src/app/model/User';

@Component({
    selector: 'app-application-result',
    templateUrl: './application-result.component.html',
    styleUrls: ['./application-result.component.scss']
})
export class ApplicationResultComponent implements OnInit {
    @Input() isAccepted: boolean = false;
    @Input() application: Application = new Application()

    public assessment: Assessment = new Assessment();
    public scoreMsg: string = ''
    public scoreComment: string = ''
    public approvalCondition: string = ''

    public isAdmin:boolean = false;

    public tabs: tab_interface[] = [
        { label:'RIAA Assessment', onClick:() => { this.assessment = this.application.riaaAssessment!; this.tabs[0].active = true; this.tabs[1].active = false; }, active: true },
        { label:'IVP Assessment', onClick:() => { this.assessment = this.application.ivpAssessment!; this.tabs[1].active = true; this.tabs[0].active = false; }, active: false },
    ];
    constructor(
        private jwt:JwtHelperService,
    ){}

    ngOnInit(): void {
        // this.setScore(this.assesment.score)
        //this.setCondition(this.assesment.condition)
        // this.setCondition(0)
        // this.setScore(5)
        this.assessment = this.application.riaaAssessment!;
        if([UserTypes.RIAA, UserTypes.IVP].includes(this.jwt.decodeToken().user.type)) this.isAdmin = true;
    }

    // private setCondition(condition: number) {
    //     switch (condition) {
    //         case 3: {
    //             this.approvalCondition = "A"
    //             break;
    //         }
    //         case 2: {
    //             this.approvalCondition = "B"
    //             break;
    //         }
    //         case 1: {
    //             this.approvalCondition = "C"
    //             break;
    //         }
    //         case 0: {
    //             this.approvalCondition = "Not Approved"
    //             break;
    //         }
    //     }
    // }
    public getCondition(condition:number){
        switch(condition){
            case 3: return "C";
            case 2: return "B";
            case 1: return "A";
            case 0: return "Not Approved";
            default: return "Invalid";
        }
    }
    public getScoreMsg(score:number){
        switch(score){
            case 5: return "Best Practice";
            case 4: return "Good Practice";
            case 3: return "Room to improve";
            case 2: return "Does not meet standard but has a Remediation Plan";
            case 1: return "Fail & No Remediation Plan";
            default: return "Invalid";
        }
    }
    public getScoreComment(score:number){
        switch(score){
            case 5: return "The product meets the requirements of the certification standard well above RIAA’s expectations and can bereferred to as best practice by the certification team.";
            case 4: return "The product meets the requirements of thecertification standard in line with good industry practice.";
            case 3: return "The product meets the requirements forcertification, however there is potential for improvement and shouldbe followed with a recommendation from RIAA staff.";
            case 2: return "The productdoes not meet the requirements for certification and the applicant canarticulate how they intend to pass the standard as well as a timely response to remedy the issue.";
            case 1: return "The product does not meet the requirements for certification and the applicant cannot articulate how they intend to pass the standard as well as a reasonable timeline for when they will remedy the issue.";
            default: return "";
        }
    }
    // private setScore(score: number) {
    //     switch (score) {
    //         case 5: {
    //             this.scoreMsg = "Best Practice";
    //             this.scoreComment = "The product meets the requirements of the certification standard well above RIAA’s expectations and can bereferred to as best practice by the certification team.";
    //             break;
    //         }
    //         case 4: {
    //             this.scoreMsg = "Good Practice"
    //             this.scoreComment = "The product meets the requirements of thecertification standard in line with good industry practice.";
    //             break;

    //         }
    //         case 3: {
    //             this.scoreMsg = "Room to improve"
    //             this.scoreComment = "The product meets the requirements forcertification, however there is potential for improvement and shouldbe followed with a recommendation from RIAA staff.";
    //             break;
    //         }
    //         case 2: {
    //             this.scoreMsg = "Does not meet standard but has a Remediation Plan"
    //             this.scoreComment = "The productdoes not meet the requirements for certification and the applicant canarticulate how they intend to pass the standard as well as a timely response to remedy the issue.";
    //             break;
    //         }
    //         case 1: {
    //             this.scoreMsg = "Fail & No Remediation Plan"
    //             this.scoreComment = "The product does not meet the requirements for certification and the applicant cannot articulate how they intend to pass the standard as well as a reasonable timeline for when they will remedy the issue.";
    //             break;

    //         }
    //         default: {
    //             this.scoreMsg = "Something went wrong"
    //             break;

    //         }
    //     }
    // }
}
