<div class='field' [ngSwitch]="field.type" [ngClass]="{'radio' : field.type=='radio'}" [ngClass]="{'single-line': field.type=='single-line','multi-line': field.type=='multi-line','numeric': field.type=='numeric', 'radio': field.type=='radio', 'check': field.type=='check','slider-tick': field.type=='slider-tick','slider-numeric': field.type=='slider-numeric','slider-percent': field.type=='slider-percent','url':field.type=='url','date': field.type==='date','file': field.type=='file','order': field.type=='order'}">
    <ng-container *ngSwitchCase="'single-line'">
        <app-text-field [isDisabled]="true" placeholderText="Single text field" [limit]="field.charlimit||500"></app-text-field>
    </ng-container>
    <ng-container *ngSwitchCase="'multi-line'">
        <app-text-field [isDisabled]="true" placeholderText="Large text field" [multiline]="true" [limit]="field.charlimit||500"></app-text-field>
    </ng-container>
    <ng-container *ngSwitchCase="'numeric'">
        <app-text-field [isDisabled]="true" placeholderText="0.00 (Numeric)"></app-text-field>
    </ng-container>
    <ng-container *ngSwitchCase="'radio'">
        <div class="row f_row">
            <mat-radio-button disabled></mat-radio-button>
            <app-text-field style="width: 100%;" [isTextLimit]="false" [isAutoSize]="true" placeholderText="Option label" [(model)]="field.label" (modelChange)="changesMade()"></app-text-field>
        </div>

    </ng-container>
    <ng-container *ngSwitchCase="'check'">
        <div class="row f_row">
            <app-tick-box text="" [disabled]="true"></app-tick-box>
            <app-text-field style="width: 100%;" [isTextLimit]="false" [isAutoSize]="true" placeholderText="Option label" [(model)]="field.label" (modelChange)="labelChange.emit($event); changesMade()"></app-text-field>
        </div>

    </ng-container>
    <ng-container *ngSwitchCase="'slider-tick'">
        <div class="warp_sc" style='flex-grow:1'>
            <div class='cont field_slider'>
                <app-tick-box text="" [disabled]="true"></app-tick-box>
                <app-text-field [isAutoSize]="true" [isTextLimit]="false" placeholderText="Option label" [(model)]="field.label" (modelChange)="changesMade()"></app-text-field>
            </div>
            <div>
                <app-slider-template [(min)]="field.rangeStart" [(max)]="field.rangeEnd" (minChange)="changesMade()" (maxChange)="changesMade()"></app-slider-template>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'slider-percent'">
        <app-slider-template [percentage]="true" [(min)]="field.rangeStart" [(max)]="field.rangeEnd" (minChange)="changesMade()" (maxChange)="changesMade()"></app-slider-template>
    </ng-container>
    <ng-container *ngSwitchCase="'slider-numeric'">
        <app-slider-template [(min)]="field.rangeStart" [(max)]="field.rangeEnd" (minChange)="changesMade()" (maxChange)="changesMade()"></app-slider-template>
    </ng-container>
    <ng-container *ngSwitchCase="'file'">
        <app-drag-drop [types]="field.filetypes||''"></app-drag-drop>
    </ng-container>
    <ng-container *ngSwitchCase="'url'">
        <app-text-field [isDisabled]="true" placeholderText="URL (must validate URLs input)"></app-text-field>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
        <app-form-builder-calendar [disabled]="true"></app-form-builder-calendar>
    </ng-container>
    <ng-container *ngSwitchCase="'order'">
        <div class="row f_row">
            <div class='handle'></div>
            <app-text-field [isTextLimit]="false" [isAutoSize]="true" placeholderText="Option label" [(model)]="field.label" (modelChange)="changesMade()"></app-text-field>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'radio-group'">
        <div class="f_row">
            <app-text-field [isTextLimit]="false" [isAutoSize]="true" placeholderText="Label" [(model)]="field.label" (modelChange)="changesMade()"></app-text-field>
            <app-radio-group-editor [fieldData]="field" (changesMade)="changesMade()"></app-radio-group-editor>
        </div>
    </ng-container>
    <div class="btns_container">
        <div class='btn-group pm_btns'>
            <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addAnother.emit()"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="delete.emit()"></app-button-secondary>
        </div>
        <div class='btn-group arrow_btns'>
            <app-button-secondary [class.disabled]="cantMoveUp" [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" (click)="moveUp.emit()" [disabled]="cantMoveUp" style="transform:rotate(180deg)"></app-button-secondary>
            <app-button-secondary [class.disabled]="cantMoveDown" [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" (click)="moveDown.emit()" [disabled]="cantMoveDown"></app-button-secondary>
        </div>
        <div class='btn-group condition_btn'>
            <app-button-secondary [class.disabled]="!hasMenu()" [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/menu.svg'" [disabled]="!hasMenu()" [mat-menu-trigger-for]="fMenu"></app-button-secondary>
        </div>
    </div>

    <mat-menu #fMenu="matMenu" panelClass="test">
        <button disableRipple="true" mat-menu-item *ngIf="custom==1" (click)="openExclusions()">Set Exclusions</button>
        <button disableRipple="true" mat-menu-item *ngIf="custom==2" (click)="openThemes()">Set Themes</button>
        <button disableRipple="true" mat-menu-item *ngIf="['single-line','multi-line'].includes(field.type)" (click)="setCharLimit()">Add character limit</button>
        <button disableRipple="true" mat-menu-item *ngSwitchCase="'file'" (click)="setFileTypes()">Set file type</button>
        <button disableRipple="true" mat-menu-item *ngIf="conditionable()" (click)="getAllConditions()">Conditions</button>
    </mat-menu>
</div>