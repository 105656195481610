import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { MenuComponent } from 'src/app/menu/menu.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Application } from 'src/app/model/responsible/Application';
import { Assessment, AssessmentType } from 'src/app/model/responsible/Assessment';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';

import { SectionBundle } from 'src/app/model/responsible/FormSectionBundle';
import { User, UserTypes } from 'src/app/model/User';

import { PartialService } from 'src/app/services/partial.service';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';
import { AssessmentAPI } from 'src/app/services/responsible/assessment.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';

import { UsersAPIService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from '../../../partials/loader/loader.component';
import { debounceTime, switchMap, take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
@Component({
    selector: 'app-assess-application',
    templateUrl: './assess-application.component.html',
    styleUrls: ['./assess-application.component.scss']
})
export class AssessApplicationComponent implements OnInit, OnDestroy {
    public form: SectionBundle = new SectionBundle();
    public application: Application = new Application();
    public assessment: Assessment = new Assessment();

    public currSection: number = -1;

    public pCategories: ProductCategory[] = [];
    public pTargets: ProductTarget[] = [];
    public markets: Market[] = [];
    public strategies: RIStrategy[] = [];

    public documents:file_interface[] = [];

    public ivps:User[] = [];
    public approvalConditions:{name:string,value:number}[] = [
        { name: 'Approval Condition A', value: 1 },
        { name: 'Approval Condition B', value: 2 },
        { name: 'Approval Condition C', value: 3 },
        { name: 'Not approved', value: 0 },
    ];
    public finalScores:{name:string,value:number}[] = [
        { name: '5 - Best Practice', value: 5 },
        { name: '4 - Good Practice', value: 4 },
        { name: '3 - Room for improvement', value: 3 },
        { name: '2 - Does not meet standard but has Remediation plan', value: 2 },
        { name: '1 - Fail and no Remediation plan', value: 1 },
        { name: 'N/A - Not Applicable', value: 0 },
    ];

    public selectedCondition?:{name:string,value:number};
    public selectedScore?:{name:string,value:number};
    public collaborators:string = '';
    public preuploads:file_interface[] = [];

    public showAll:boolean = true;

    public static changed:boolean = false;

    public capSummary:any;
    public ivpSections:SectionBundle = new SectionBundle();

    public selType:string = '';
    public pdfTypes = [
        { label:'Download full application', type:'answers' },
        // { label:'Download star rating questions', type:'starred' },
        { label:'Download front-end questions', type:'front' },
        { label:'Download exclusion list', type:'exclusions' }
    ];
    public downloadPdf:any;
    public isAdmin: boolean;
    public readonly sustainabilityClasses = Application.sustainabilityClasses;

    private unsubscribe = new Subject<void>();
    public applicationChangesMadeSubject = new ReplaySubject<void>();

    constructor(
        private userApi: UsersAPIService,
        private applicationApi: ApplicationAPI,
        private assessmentApi: AssessmentAPI,
        private attachmentApi: AttachmentAPI,
        private route: ActivatedRoute,
        private router: Router,
        private partial: PartialService,
        private dialog: MatDialog,
        private jwt:JwtHelperService,
    ) {
        this.isAdmin = ([UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type));
        LoaderComponent.show.emit(true);
        MenuComponent.show.emit(false);

        this.route.paramMap.subscribe(p => {
            let id = Number(p.get('id'));
            this.applicationApi.get(id).subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.application.map(response);

                let collabs = [];
                for(let i of this.application.collaborators)
                    collabs.push(i.fullName);
                this.collaborators = collabs.join(', ');

                if(this.jwt.decodeToken().user.type==UserTypes.RIAA){
                    if(!this.application.riaaAssessment){
                        this.application.riaaAssessment = new Assessment();
                        this.application.riaaAssessment.type = AssessmentType.RIAA;
                    }
                    this.assessment = this.application.riaaAssessment;
                } else if(this.jwt.decodeToken().user.type==UserTypes.IVP){
                    if(     //? Gatekeep for IVP
                        !this.application.riaaAssessment||
                        (this.application.riaaAssessment.ivpId!=this.jwt.decodeToken().user.id&&
                        (() => { for(let i of this.application.assigned??[]) if(i.id===this.jwt.decodeToken().user.id) return false; return true; })())
                    ){
                        this.router.navigate(['/']);
                        return;
                    }

                    if(!this.application.ivpAssessment){
                        this.application.ivpAssessment = new Assessment();
                        this.application.ivpAssessment.type = AssessmentType.IVP;
                    }
                    this.assessment = this.application.ivpAssessment;
                }

                if(this.assessment.id){
                    for(let i of this.approvalConditions)
                        if(i.value==this.assessment.condition){ this.selectedCondition = i; break; }
                    for(let i of this.finalScores)
                        if(i.value==this.assessment.score){ this.selectedScore = i; break; }

                    setTimeout(() => { this.attemptAutosave() }, 5000);
                }

                this.form = this.application.form!;
                this.ivpSections = SectionBundle.create(response.form);
                this.ivpSections.sections = this.ivpSections.sections.filter(s => s.questions.find(q => q.ivp||q.subquestions.find(u => u.ivp)));

                this.form.mapAnswers(this.application.answers);
                this.form.mapRIAAAnswers(this.assessment.answers);

                this.preuploads = this.application.pFilesList.map(f => ({
                    file: f,
                    filename: f.filename,
                    extension: f.getExtension()??'',
                    progress:100,
                    status:'ok'
                }));

                this.partial.conversations.emit({ applicationId:this.application.id, formId: this.application.formId, name:this.application.productName });

                AssessApplicationComponent.changed = false;

                this.fetchFormData();
            })
        });

        //Could be nice to have autosave as a service.
        this.applicationChangesMadeSubject
        .pipe(
            debounceTime(1000),
            switchMap(() =>
                this.applicationApi.update(this.application, true)
            ),
            takeUntil(this.unsubscribe)
        )
        .subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void { LoaderComponent.show.emit(true);}

    private fetchFormData()
    {
        LoaderComponent.show.emit(true);
        this.userApi.index({ filters:{ type: `${UserTypes.IVP}` } }).subscribe(res => {
            let response = CodedResponseModel.decode(res);
            for(let u of response.data)
                this.ivps.push(User.create(u));
                LoaderComponent.show.emit(false);
        })
    }

    private attemptAutosave(){
        if(AssessApplicationComponent.changed){
            this.assessmentApi.autosave(this.assessment.id, this.assessment).subscribe(res => {
                //
            })
            AssessApplicationComponent.changed = false;
        }
        setTimeout(() => { this.attemptAutosave() }, 5000);
    }

    public beginAssessment(){
        if(!this.assessment.id){
            this.assessment.finished = false;
            this.assessmentApi.initAssessment(this.application.id).subscribe(res => {
                let response = CodedResponseModel.decode(res);

                this.assessment = Assessment.create(response);
                setTimeout(() => { this.attemptAutosave(); }, 5000);
                AssessApplicationComponent.changed = false;
            });
        }
        this.currSection = 0;
    }

    public send(){
        if(!this.selectedScore&&this.assessment.type===AssessmentType.RIAA){
            this.partial.notificator.emit({ type:'error', message:'Score must be selected', timeout:5000 });
            return;
        }
        this.assessment.applicationId = this.application.id;
        this.assessment.score = this.selectedScore?.value;
        this.assessment.condition = this.selectedCondition?.value??undefined;

        this.assessmentApi.assess(this.application.id, this.assessment)
        .pipe(
            take(1)
        )
        .subscribe(res => {
            //this.partial.notificator.emit({ type:'success', message:'Application assessment has been saved', timeout:3000 });
            this.currSection = -4;
        }, err => {
            this.partial.notificator.emit({ type:'error', message:'An error has occured while trying to save the assessment', timeout: 5000 });
        });

        // Currently only used for updating sustainability field.
        this.applicationApi.update(this.application, false)
        .pipe(
            take(1)
        )
        .subscribe();
    }

    public uploadDocument(flist: FileList) {
        if (flist.item(0)) {
            let added = false,
                index: number,
                handle: file_interface;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        index = this.documents.push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        this.documents[index].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.documents[index].file = att;
                        this.documents[index].progress = 100;
                        break;
                }
            }, err => {
                if (added) {
                    this.documents.splice(index, 1);
                }
                console.warn(err);
            });
        }
    }
    public deleteDocument(file: file_interface) {
        let index = this.documents.indexOf(file);
        this.documents.splice(index, 1);
    }
    public openUploader() {
        let d = this.dialog.open(UploadModalComponent, {
            panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) this.uploadDocument(res.file);
        });
    }

    public nextSection() {
        if (this.currSection == this.sections.length - 1) this.currSection = -3;
        else this.currSection++;
    }

    public generatePdf(){
        LoaderComponent.show.emit(true);
        this.assessmentApi.getPdf(this.application.id).subscribe(r => {
            LoaderComponent.show.emit(false);
            let res = CodedResponseModel.decode(r);
            console.log(res);
            this.capSummary = {
                size: this.fSize(res.size),
                url: `${environment.pdfUrl}${res.fname}`
            };
        }, err => {
            LoaderComponent.show.emit(false);
        })
    }
    public generateAppPdf(){
        LoaderComponent.show.emit(true);
        if(!this.selType) return;
        if(this.selType==='exclusions'){
            this.applicationApi.getExclusionsPdf(this.application.id).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.downloadPdf = {
                    size: this.fSize(res.size),
                    url: `${environment.pdfUrl}${res.fname}`,
                    name: 'EXCLUSIONS'
                }
                LoaderComponent.show.emit(false);
            })
        } else {
            this.applicationApi.getPdf(this.application.id, this.selType).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.downloadPdf = {
                    size: this.fSize(res.size),
                    url: `${environment.pdfUrl}${res.fname}`,
                    name: this.selType==='answers'?'APPLICATION':this.selType==='starred'?'STARRED':'FRONT-END'
                }
                LoaderComponent.show.emit(false);
            });
        }
    }
    public fSize(size:number){
        if(size<1024) return `${size}B`;
        size /= 1024;
        if(size<1024) return `${Math.round(size*10)/10}KB`;
        size /= 1024;
        return `${Math.round(size*10)/10}MB`;
    }

    public scrollToTop(){
        window.scrollTo(0, 0);
    }

    public get sections(){
        if(this.assessment.type === AssessmentType.IVP) return this.ivpSections.sections;
        else return this.form.sections;
    }

    public debug(v?:any){
        if(v === undefined)
            console.log(this);
        else
            console.log(v);
    }
}
