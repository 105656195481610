import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { OverlayComponent } from '../overlay/overlay.component';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
export interface tab_interface {
  label: string;
  active: boolean;
  onClick: CallableFunction;
  badge?: number;
  hidden?: boolean;
}

@Component({
  selector: 'app-top-tabs', 
  templateUrl: './top-tabs.component.html',
  styleUrls: ['./top-tabs.component.scss']
})
export class TopTabsComponent implements OnInit {
  @ViewChild('tab') tab?: ElementRef;
  @Input() tabs: tab_interface[] = [];
  public expanded: boolean = false;
  constructor(public scroll: BodyScrollService,private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
   
  }
  public canExpand() {
    if ((window.innerWidth <= 1024) || this.tabs.length > 5) {
      this.expanded = !this.expanded
      if (this.expanded == true) this.scroll.disableBodyScroll()
      else this.scroll.enableBodyScroll()
    } else {
      this.scroll.enableBodyScroll()
      this.expanded = false
    }
  }
  public canTransformDesktop() {
    if (this.tabs.length > 5 && window.innerWidth < 1430) return true
    else return false
  }
  public closeOverlay(active: boolean) {
    if(!active) OverlayComponent.show.emit(false)
  }
  public scrollTop(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  )
  }
}
