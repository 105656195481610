import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
    this.loginService.refreshToken();
    if (!this.loginService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.isTokenExpired();
  }
  public isTokenExpired() {
    if (this.loginService.isTokenExpired()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    } else {
      setTimeout(() => {
        this.isTokenExpired();
      }, 30000);
    }
  }

}
