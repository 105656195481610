import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-heading1',
  templateUrl: './heading1.component.html',
  styleUrls: ['./heading1.component.scss']
})
export class Heading1Component implements OnInit {
  @Input() text: string = ''
  @Input() isTextBlack: boolean = true
  constructor() { }

  ngOnInit(): void {
  }

}
