import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-share-application',
  templateUrl: './share-application.component.html',
  styleUrls: ['./share-application.component.scss']
})
export class ShareApplicationComponent implements OnInit {
public email:string = '';
  constructor( @Inject(MAT_DIALOG_DATA) public data:any) { 
     
  }

  ngOnInit(): void {
   }
}
