import { Component, Input, OnInit } from '@angular/core';
import { Assessment } from 'src/app/model/responsible/Assessment';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { BodyScrollService } from 'src/app/services/body-scroll.service';

@Component({
    selector: 'app-assessment-panel',
    templateUrl: './assessment-panel.component.html',
    styleUrls: ['./assessment-panel.component.scss']
})
export class AssessmentPanelComponent implements OnInit {
    @Input() public questions:FormQuestion[] = [];
    @Input() public assessment:Assessment = new Assessment();

    public open:boolean = false;

    constructor(public scroll:BodyScrollService){}

    ngOnInit():void{}

    public filledQuestions(){
        let f = 0;
        for(let i of this.questions)
            if(i.filled()) f++;
        return f;
    }
    public openclose(o: boolean){
        if(o == true){
            this.open = true
            if(window.innerWidth < 767) this.scroll.disableBodyScroll()
        }
        else{
            this.open = false
          if(window.innerWidth < 767)  this.scroll.enableBodyScroll()
        }
    }
}
