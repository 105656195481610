import { Condition, condition_response } from "./Condition";
import { Exclusion, exclusion_response } from "./Exclusion";
import { Keyed } from "./Form";
import { FormQuestion, form_question_response } from "./FormQuestion";
import { Theme, theme_response } from "./Theme";

export interface form_field_response{
    id:number;
    label:string;
    type:string;
    order:number;
    hidden:boolean;

    filetypes?:string;
    char_limit?:number;
    customs?:number;

    question_id:number;

    question?:form_question_response;
    conditions?:condition_response[];

    key?:string;
}
export class FormField extends Keyed{
    public id:number = 0;
    public label:string = '';
    public type:string = '';
    public order:number = 0;
    public hidden:boolean = false;

    public filetypes?:string;
    public charlimit?:number;
    public customs?:number;

    public questionId:number = 0;

    public question?:FormQuestion;
    public conditions:Condition[] = [];

    public map(data:form_field_response){
        this.id = data.id;
        this.label = data.label;
        this.type = data.type;
        this.order = data.order;
        this.hidden = data.hidden;

        this.questionId = data.question_id;
        this.customs = data.customs;
        
        this.filetypes = data.filetypes;
        this.charlimit = data.char_limit;
        
        this.question = data.question ? FormQuestion.create(data.question) : undefined;
        
        this.conditions = [];
        for(let c of data.conditions??[])
            this.conditions.push(Condition.create(c));

        return this;
    }
    public responsify():form_field_response{
        return {
            id: this.id,
            label: this.label,
            type: this.type,
            order: this.order,
            hidden: this.hidden,
            question_id: this.questionId,
            filetypes: this.filetypes,
            char_limit: this.charlimit,
            customs: this.customs,
            conditions:(() => {
                let arr:condition_response[] = [];
                for(let c of this.conditions) arr.push(c.responsify());
                return arr;
            })(),

            key:this.key,
        }
    }
    public static create(data:form_field_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public get rangeStart(){
        if(this.filetypes==undefined) this.filetypes = '0:100';
        let a = this.filetypes.split(':');
        return Number(a[0]);
    }
    public set rangeStart(n:number){
        if(this.filetypes==undefined) this.filetypes = '0:100';
        let a = this.filetypes.split(':');
        a[0] = String(n);
        if(isNaN(Number(a[1]))||a[1]==null) a[1] = "100";
        this.filetypes = `${a[0]}:${a[1]}`;
    }
    public get rangeEnd(){
        if(this.filetypes==undefined) this.filetypes = '0:100';
        let a = this.filetypes.split(':');
        return Number(a[1]);
    }
    public set rangeEnd(n:number){
        if(this.filetypes==undefined) this.filetypes = '0:100';
        let a = this.filetypes.split(':');
        a[1] = String(n);
        if(isNaN(Number(a[0]))) a[0] = "0";
        this.filetypes = `${a[0]}:${a[1]}`;
    }

    public typeLabel(){
        switch(this.type){
            case 'single-line': return 'Single Text Field';
            case 'multi-line': return 'Large Text Field';
            case 'numeric': return 'Numeric Field';
            case 'radio': return 'Radio Button';
            case 'check': return 'Tickbox';
            case 'slider-tick': return 'Tickbox with Slider';
            case 'slider-numeric': return 'Numeric Slider';
            case 'slider-percent': return 'Percentage Slider';
            case 'file': return 'File Upload';
            case 'url': return 'URL Field';
            case 'date': return 'Date Picker';
            case 'order': return 'Order List';
            default: return '';
        }
    }
}