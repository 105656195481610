import { Component, Input, OnInit } from '@angular/core';
import {User } from '../../model/User'
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
@Input() model: any = {name: "Test test", imgUrl: ''} 
 public isImg: boolean = true
  constructor() { }

  ngOnInit(): void {

    if(this.model.imgUrl == '' || this.model.imgUrl == undefined) this.isImg = false
    else this.isImg = true
  }
 public getName(){
   return this.model.name.charAt(0)
 }
 public getUrl(){
   return this.model.imgUrl
 }
}
