<div class="icon_header_add">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='container add_fr_container'>
    <span>Question {{tag}}</span>
    <h1>Responsible Returns Front-End</h1>
    <div class='card' [ngClass]="{'isbcg': pages.length>0}">
        <div class='page' *ngFor="let page of pages; let i = index">
            <div class='row a_row'>
                <app-form-select (click)="setClick($event);" (animationEnd)="scroll()" class='fg-1' [list]="pageList" [model]="getPage(page.page)" (modelChange)="page.page=$event.value" [isMulitSelect]="false"></app-form-select>
                <app-button-secondary class="action" [isText]="true" buttonText="Reset" (click)="reset()" *ngIf="i==0"></app-button-secondary>
                <app-button-secondary class="action" [isText]="true" buttonText="Delete" (click)="deletePage(i)" *ngIf="i!=0"></app-button-secondary>
            </div>
            <div class='row'>
                <app-form-select (click)="setClick($event);" (animationEnd)="scroll()" class='fg-4 mr-16' [list]="sectionList" [model]="getSection(page.section)" (modelChange)="page.section=$event.value" [isMulitSelect]="false"></app-form-select>
                <app-form-select (click)="setClick($event);" (animationEnd)="scroll()" class='fg-1' [list]="positionList" [model]="{name:page.position}" (modelChange)="page.position=$event.name" [isMulitSelect]="false"></app-form-select>
            </div>
            <div class='row'>
                <app-text-field [(model)]="page.label" placeholderText="Label" class='fg-4'></app-text-field>
            </div>
            <hr class="hr_first">
        </div>
        <app-form-builder-button buttonText="Add another page" (click)="addPage()"></app-form-builder-button>
    </div>
    <app-button-primary [mat-dialog-close]="pages" text="Save"></app-button-primary>
    <app-button-secondary [isText]="true" [isGreen]="false" buttonText="Cancel" [isGreen]="false" [mat-dialog-close]="false"></app-button-secondary>
</div>