<div class='main-container' *ngIf="question.appearing&&(!isIvp||question.ivp)" [id]="question.key">
    <div class='question-container' [ngClass]="{'comments': lastComments}">
        <div [class.active]="!question.filled()" [class.inactive]="question.filled()"></div>
        <div class="row a_center">
            <h4 class='qnumber' *ngIf="question.category!=='riaa'">Question {{question.tag}} <span *ngIf="question.custom">Custom</span></h4>
            <h4 class='qnumber' *ngIf="question.category==='riaa'">{{question.subheading}}</h4>
            <div class="icons" *ngIf="buttons">
                <svg-icon class="visible_rr i_active" src="assets/svg/eye.svg" *ngIf="question.fronts?.length"></svg-icon>
                <svg-icon class="share" src="assets/svg/share.svg" (click)="shareQuestion()"></svg-icon>
                <!-- <svg-icon class="comments" src="../../../assets/svg/message.svg"></svg-icon> -->
                <app-message class="comments" [isFixed]="false" [product]="application" [qId]="question.id"></app-message>
            </div>
        </div>

        <h2 class='question_label'>{{question.question}}</h2>
        <!--* Field Types *-->
        <ng-container *ngIf="question.custom<3">
            <div class='fields' *ngIf="question.type!=='order'">
                <mat-radio-group (change)="setRadio($event.value)">
                    <ng-container *ngFor="let o of question.fields">
                        <div class="field_container" [ngClass]="{'single-line':
                            o.type=='single-line','multi-line':
                            o.type=='multi-line','numeric': o.type=='numeric',
                            'radio': o.type=='radio', 'check':
                            o.type=='check','slider-tick':
                            o.type=='slider-tick','slider-numeric':
                            o.type=='slider-numeric','slider-percent':
                            o.type=='slider-percent','url':o.type=='url','date':
                            o.type==='date','file': o.type=='file','order':
                            o.type=='order'}">
                            <ng-container *ngIf="o.type==='single-line'">
                                <app-text-field [isDisabled]="readonly" [isAutoSize]="true" [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-text-field>
                            </ng-container>
                            <ng-container *ngIf="o.type==='multi-line'">
                                <!-- <app-text-field class="multi-line" [multiline]="true" [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-text-field> -->
                                <app-large-text-field [isDisabled]="readonly" [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-large-text-field>
                            </ng-container>
                            <ng-container *ngIf="o.type==='numeric'">
                                <app-number-input [isDisabled]="readonly" [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-number-input>
                            </ng-container>
                            <ng-container *ngIf="o.type==='radio'">
                                <app-radio-button-group [disabled]="readonly" class="radio" [singular]="true" [label]="o.label" [value]="o.id" [checked]="o.id==getRadio()?.id"></app-radio-button-group>
                            </ng-container>
                            <ng-container *ngIf="o.type==='check'">
                                <app-tick-box [disabled]="readonly" [text]="o.label" [value]="getAnswer(o.id)" (valueChange)="setAnswer(o.id, $event)"></app-tick-box>
                            </ng-container>
                            <ng-container *ngIf="o.type==='slider-tick'">
                                <ng-container>
                                    <app-tick-box [disabled]="readonly" #tick [text]="o.label" [value]="getAnswer(o.id)?true:false" (valueChange)="setAnswer(o.id,
                                        ($event?o.rangeStart:$event))"></app-tick-box>
                                    <app-slider [disabled]="readonly" class="slider-tick-slider" *ngIf="tick.value" [min]="o.rangeStart" [max]="o.rangeEnd" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-slider>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="o.type==='slider-numeric'">
                                <app-slider [disabled]="readonly" [min]="o.rangeStart" [max]="o.rangeEnd" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-slider>
                            </ng-container>
                            <ng-container *ngIf="o.type==='slider-percent'">
                                <app-slider [disabled]="readonly" [min]="o.rangeStart" [max]="o.rangeEnd" [percentage]="true" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-slider>
                            </ng-container>
                            <ng-container *ngIf="o.type==='url'">
                                <a *ngIf="readonly" [href]="getAnswer(o.id)">{{getAnswer(o.id)}}</a>
                                <app-url-field *ngIf="!readonly" placeholderText="URL" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-url-field>
                                <!-- <app-text-field placeholderText="URL" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-text-field> -->
                            </ng-container>
                            <ng-container *ngIf="o.type==='date'">
                                <app-form-builder-calendar [disabled]="readonly" (date)="setDateAnswer(o.id, $event)" [input]="getDateAnswer(o.id)"></app-form-builder-calendar>
                            </ng-container>
                            <ng-container *ngIf="o.type==='file'">
                                <ng-container *ngIf="readonly">
                                    <app-download-button *ngFor="let f of files[o.id]" [name]="f.file?.filename" [fileType]="f.file?.getExtension()" 
                                        [fileSize]="''+(f.file?.filesize||'')" [fileUrl]="f.file?.getUrl()"></app-download-button>
                                </ng-container>
                                <app-drag-drop *ngIf="!readonly" [fileList]="files[o.id]" [types]="o.filetypes||''" (fileEvent)="uploadFile($event, o.id)" (fileRemoved)="deleteFile($event, o.id)" (openModal)="openUploader(o.id)"></app-drag-drop>
                            </ng-container>
                            <ng-container *ngIf="o.type==='radio-group'">
                                <div class="radio-label">{{ o.label }}</div>
                                <app-radio-group-field [fieldData]="o" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)" [readonly]="readonly"></app-radio-group-field>
                            </ng-container>
                        </div>
                    </ng-container>
                </mat-radio-group>
            </div>
            <div class='fields' *ngIf="question.type==='order'">
                <div class='field_container item' *ngFor="let id of question.answer!.value.order; let i= index">
                    <app-button-secondary *ngIf="!readonly" [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="i==0" style='transform:rotate(180deg)' (click)="moveOrderUp(i)"></app-button-secondary>
                    <app-button-secondary *ngIf="!readonly" [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="i==question.answer!.value.order!.length-1" (click)="moveOrderDown(i)"></app-button-secondary>
                    <span>{{question.getFieldById(id)?.label}}</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="question.custom>2">
            <div class='fields'>
                <div class="field_container check" *ngFor="let o of question.options">
                    <app-tick-box [disabled]="readonly" [text]="o.name" [value]="getAnswer(o.id)" (valueChange)="setAnswer(o.id, $event)"></app-tick-box>
                </div>
            </div>
        </ng-container>
        <!--* Field types end *-->
        <div class='hint' *ngIf="question.category==='riaa'&&question.hint">{{question.hint}}</div>
    </div>
    <div class='last-activity' *ngIf="lastComments" [ngClass]="{'comments': lastComments, 'closed': !isExpandedMessages}">
        <!-- <h1 *ngIf="question.lastMessages.length">Last Update</h1>
        <div class='main' *ngFor="let msg of question.lastMessages">
            <h3>{{msg.createdAt | date:'dd/MM/yyyy'}} AT {{msg.createdAt | date:'HH:mm a'}}</h3>
            <h2>Question {{sub?sub:question.order+1}}{{sub?ntl(question.order):''}}
            </h2>
            <p>{{msg.message}}</p>
        </div>
        <button (click)="isExpandedMessages = !isExpandedMessages">
            <span *ngIf="isExpandedMessages">close history</span>
            <span *ngIf="!isExpandedMessages">open history</span>
        </button> -->
        <h1 *ngIf="hasHistory">Answer History</h1>
        <div class='main' *ngFor="let update of qHistory">
            <h3>{{update.createdAt | date:'dd/MM/yyyy'}} AT {{update.createdAt | date:'hh:mm a'}}</h3>
            <div class='answers'>
                <ng-container *ngFor="let i of update.value">
                    <div class="answer" *ngIf="i.type == 'files'">
                        <a *ngFor="let f of i.val" [href]="makeLink(f.filename)" [download]="f.label">{{f.label}}</a>
                    </div>
                    <div class="answer" *ngIf="i.type != 'files'" [innerHTML]="i.val"></div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-question *ngFor="let sub of question.subquestions" [lastComments]="lastComments" [readonly]="readonly" [question]="sub" [sub]="question.order+1" 
    [section]="section" [application]="application" [preuploads]="preuploads" [assessment]="assessment" [isIvp]="isIvp"></app-question>