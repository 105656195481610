import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Theme } from "src/app/model/responsible/Theme";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root',
})
export class ThemeAPI{
    constructor(
        private http:HttpClient,
        private epEncryptor:EpEncryptionService,
    ){}

    public index(){
        return this.http.get(`${environment.apiUrl}themes`);
    }
    public update(themes:Theme[]){
        return this.http.put(`${environment.apiUrl}themes`,
            this.epEncryptor.sendPayload((
                ()=>{
                    let arr = [];
                    for(let t of themes) arr.push(t.responsify());
                    return arr;
                })()
            ))
    }
}
