<main role="main">
    <div class="main_wrap">
        <div class="login">
            <div class="left">
                <div class="login_logos">
                    <div class="img_wrap" *ngIf="logo1ImgSrc">
                        <img [src]="logo1ImgSrc" alt="Login logo">
                    </div>
                    <div class="divider" *ngIf="logo1ImgSrc&&logo2ImgSrc"></div>
                    <div class="img_wrap second_logo" *ngIf="logo2ImgSrc">
                        <img [src]="logo2ImgSrc" alt="Login logo">
                    </div>
                </div>
                <div class="login_register_box">
                    <app-heading4 [text]="titleSmall"></app-heading4>
                    <app-heading1 [text]="mode==='login'?titleBig:mode==='forgot'?resetSubhead:setSubhead"></app-heading1>
                    <div class="box_wrap" *ngIf="mode==='login'">
                        <div class="login_box">
                            <app-input-field [name]="'email'" [type]="'email'" [placeholderText]="'Email'"
                                [(model)]="email"></app-input-field>
                            <app-input-field [name]="'password'" [type]="'password'" [placeholderText]="'Password'"
                                [(model)]="password" (keydown.enter)="login()"></app-input-field>
                            <div class="error_wrap" *ngIf="error">
                                <img src="assets/svg/error.svg" alt="Error icon">
                                <div class="error">{{ errorMsg }}</div>
                            </div>
                            <app-tick-box [text]="'Remember me'" [(value)]="rememberMe"></app-tick-box>
                            <app-button-primary (click)="login()" [isFullWidth]="true" [text]="loginBtnText">
                            </app-button-primary>
                        </div>

                        <div class="bottom">
                            <a (click)="mode='forgot'" class="bottom_link">Forgot your password?</a>
                            <div class="divider"></div>
                            <a [href]="registerLink" class="bottom_link" target="_blank">Register for certification</a>
                        </div>
                    </div>
                    <div class="box_wrap" *ngIf="mode==='forgot'">
                        <div class="login_box">
                            <div *ngIf="!sent">
                                <app-input-field [name]="'email'" [type]="'email'" [placeholderText]="'Email'"
                                    [isError]="error" [errorText]="''" [(model)]="email"></app-input-field>
                                <app-button-primary (click)="forgot()" [isFullWidth]="true" text="Reset your password">
                                </app-button-primary>
                            </div>
                            <div class="desc" *ngIf="sent">
                                <div>Email with the instructions to reset your password has been sent to your account's
                                    email address.</div>
                            </div>
                        </div>

                        <div class="bottom">
                            <a (click)="mode='login'" class="bottom_link">Go back to log in</a>
                        </div>
                    </div>
                </div>
            </div>
            <app-login-featured-content [settings]="bannerSettings"></app-login-featured-content>
        </div>
    </div>
</main>