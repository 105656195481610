import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Application } from 'src/app/model/responsible/Application';
import { Assessment } from 'src/app/model/responsible/Assessment';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { User, UserTypes } from 'src/app/model/User';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';
import { AssessmentAPI } from 'src/app/services/responsible/assessment.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { UsersAPIService } from 'src/app/services/users.service';

@Component({
    selector: 'app-approve-modal',
    templateUrl: './approve-modal.component.html',
    styleUrls: ['./approve-modal.component.scss']
})
export class ApproveModalComponent implements OnInit {
    public ready:boolean = false;
    public assessment:Assessment = new Assessment();

    public documents:file_interface[] = [];
    public selectRef:any

    public ivps:User[] = [];
    public approvalConditions:{name:string,value:number}[] = [
        { name: 'Approval Condition A', value: 1 },
        { name: 'Approval Condition B', value: 2 },
        { name: 'Approval Condition C', value: 3 },
    ];
    public finalScores:{name:string,value:number}[] = [
        { name: '5 - Best Practice', value: 5 },
        { name: '4 - Good Practice', value: 4 },
        { name: '3 - Room for improvement', value: 3 },
        { name: '2 - Does not meet standard but has Remediation plan', value: 2 },
        { name: 'N/A - Not Applicable', value: 0 }
        //{ name: '1 - Fail and no Remediation plan', value: 1 },
    ];

    public isIVP:boolean = false;

    public selectedCondition?:{name:string,value:number};
    public selectedScore?:{name:string,value:number};

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:{ application:Application },
        private attachmentApi:AttachmentAPI,
        private userApi:UsersAPIService,
        private dialog:MatDialog,
        private ref:MatDialogRef<ApproveModalComponent>,
        private jwt:JwtHelperService,
    ){
        this.isIVP = this.jwt.decodeToken().user.type===UserTypes.IVP;
        this.userApi.index({filters:{ type: `${UserTypes.IVP}` }}).subscribe(res => {
            let response = CodedResponseModel.decode(res);

            for(let i of response.data)
                this.ivps.push(User.create(i));

            this.ready = true;
        });
    }

    ngOnInit():void{}

    public uploadDocument(flist: FileList) {
        if (flist.item(0)) {
            let added = false,
                index: number,
                handle: file_interface;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        index = this.documents.push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        //console.log(Math.round(res.loaded/res.total!*100));
                        this.documents[index].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.documents[index].file = att;
                        this.documents[index].progress = 100;
                        break;
                }
            }, err => {
                if (added) {
                    this.documents.splice(index, 1);
                }
                console.warn(err);
            });
        }
    }
    public confirm(){
        this.assessment.condition = this.selectedCondition?.value;
        this.assessment.score = this.selectedScore?.value;
        this.ref.close(this.assessment);
    }
    public deleteDocument(file: file_interface) {
        let index = this.documents.indexOf(file);
        this.documents.splice(index, 1);
    }
    public openUploader() {
        let d = this.dialog.open(UploadModalComponent, {
            panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) this.uploadDocument(res.file);
        });
    }
    public scroll(item: any) {
        if(this.selectRef !== undefined){
            if(this.selectRef.path !== undefined){ 
                this.selectRef.path[2].scrollIntoView({behavior: "smooth"})
               }else if(this.selectRef.target !== undefined){
                this.selectRef.target.scrollIntoView({behavior: "smooth"})
               }else if(this.selectRef) {
                    try{this.selectRef.scrollIntoView({behavior: "smooth"})}
                    catch(err){
                       // console.log(err)
                    }
               }
        }
    }
    
    public setRef(select: any){
        this.selectRef = select
    }
}
