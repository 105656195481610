import { FormField } from "./FormField";

export class CustomIntegration{
    public id:number = 0;
    public name:string = '';
    public active:boolean = true;
    public advisors:boolean = true;
    public order:number = 0;

    public map(data:any){
        this.id = data.id;
        this.name = data.name;
        this.active = data.active;
        this.advisors = data.advisors;
        this.order = data.order;

        return this;
    }

    public get fieldified(){
        let f = FormField.create({
            id: this.id,
            label: this.name,
            type: 'check',
            order:this.order,
            hidden: false,
            question_id: 0
        });
        return f;
    }
    public set fieldified(field:FormField){
        this.name = field.label;
    }
}

export class ProductCategory extends CustomIntegration{
    public static latest:ProductCategory[] = [];

    public static create(data:any){
        let r = new this;
        r.map(data);
        return r;
    }
}

export class Market extends CustomIntegration{
    public static latest:Market[] = [];

    public static create(data:any){
        let r = new this;
        r.map(data);
        return r;
    }
}

export class ProductTarget extends CustomIntegration{
    public static latest:ProductTarget[] = [];

    public static create(data:any){
        let r = new this;
        r.map(data);
        return r;
    }
}

export class RIStrategy extends CustomIntegration{
    public static latest:RIStrategy[] = [];

    public static create(data:any){
        let r = new this;
        r.map(data);
        return r;
    }
}

export class AssetClass extends CustomIntegration{
    public static latest:AssetClass[] = [];

    public static create(data:any){
        let r = new this;
        r.map(data);
        return r;
    }
}