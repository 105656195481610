import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Condition } from 'src/app/model/responsible/Condition';
import { FormField } from 'src/app/model/responsible/FormField';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';

@Component({
    selector: 'app-conditions',
    templateUrl: './conditions.component.html',
    styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent implements OnInit {
    public questions:FormQuestion[] = [];
    public tag:string = '';
    public field:FormField = new FormField();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any
    ){}

    ngOnInit():void{
        this.questions = this.data.list;
        this.tag = this.data.tag;
        this.field = this.data.field;
        console.log(this);
    }
}
