import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-circled-add-edit-link',
  templateUrl: './circled-add-edit-link.component.html',
  styleUrls: ['./circled-add-edit-link.component.scss']
})
export class CircledAddEditLinkComponent implements OnInit {
  @Input() label: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
