<div class="accordion_wrap">
    <h2>Frequently Asked Questions</h2>
    <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let accordion_item of accordion">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{accordion_item[titleProperty]}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-desc">{{accordion_item[descProperty]}}</div>
        </mat-expansion-panel>
    </mat-accordion>
</div>