import { Component, OnInit, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { GlobalsAPI } from 'src/app/services/responsible/globals.service';
import { LoginService } from '../../services/login.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	public loaded:boolean = false;

	public password: string = '';
	public passConfirm: string = '';
	public token: string | null = '';
	public sent: boolean = false;

	public titleSmall: string = 'Certification Portal';
	public titleBig: string = 'Set your password';
	public logo1ImgSrc: string = 'assets/svg/RIAA-Logo.svg';
	public logo2ImgSrc: string = 'assets/svg/RR-Logo-login.svg';
	public rightSideImgSrc: string = 'assets/jpg/login_image.jpg';
	public setBtnText: string = 'Set your password';

	public passwordStrength: any = 'Low';
	public passwordToCheck: string = '';
	public showTick: boolean = false;
	public isError: boolean = false;
	public error: boolean = false;
	public errorMsg: string = '';

	public bannerSettings = {
        banner:'assets/jpg/login_image.jpg',
        featured:true,
        featuredHead: '',
        featuredStars:0,
        featuredLogo:'',
        research:true,
        researchHead:'',
        researchSubhead:'',
        researchLink:'',
        researchLinkTxt:'',
    }

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private globalsApi:GlobalsAPI,
		private loginApi: LoginService,
	) {
		this.token = this.route.snapshot.paramMap.get('token');
		if (!this.token) {
			console.error("Token missing");
			//this.router.navigate(['/']);
		}
		this.globalsApi.loginData().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.titleSmall = response.login_heading;
            this.titleBig = response.set_pass_subheading;
            this.logo1ImgSrc = response.login_riaa_logo;
            this.logo2ImgSrc = response.login_rr_logo;
            //this.errorMsg = response.login_error_msg;

            this.bannerSettings.banner = response.login_banner;
            this.bannerSettings.featured = response.login_featured==='true';
            this.bannerSettings.featuredHead = response.login_featured_heading;
            this.bannerSettings.featuredStars = Number(response.login_featured_stars);
            this.bannerSettings.featuredLogo = response.login_featured_logo;
            this.bannerSettings.research = response.login_research==='true';
            this.bannerSettings.researchHead = response.login_research_heading;
            this.bannerSettings.researchSubhead = response.login_research_subheading;
            this.bannerSettings.researchLink = response.login_research_link;
            this.bannerSettings.researchLinkTxt = response.login_research_link_text;

            this.loaded = true;
        });
	}

	ngOnInit(){}

	public send() {
		this.isError = false;
		this.error = false;
		try{
			console.log(this.passwordRule(this.password), this.password);
			if(!this.passwordRule(this.password))
				throw "Include 8 characters and one special character";

			if(this.password!=this.passConfirm)
				throw "Passwords must match";

			this.loginApi.resetPassword(String(this.token), this.password, this.passConfirm).subscribe(res => {
				this.sent = true;
			}, err => {
				this.isError = true;
				console.log(err);
			})
		} catch(e:any){
			this.error = true;
			this.errorMsg = e;
		}
	}

	public passwordComparsion() {
		if (this.password === this.passConfirm && this.passConfirm.length > 0) {
			this.showTick = true;
		} else {
			this.showTick = false;
		}
	}
	public passwordRule(pass: string) {
		if (pass.length > 7 && /\W/.test(pass))
			return true;
		else
			return false;
	}
}
