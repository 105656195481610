import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PartialService } from "src/app/services/partial.service";
import { BodyScrollService } from 'src/app/services/body-scroll.service';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {

  public exists: boolean = false;
  public display: boolean = false;
  public videoUrl: string = '';
  @Output() closeOverlay = new EventEmitter();
  @Output() openOverlay = new EventEmitter();

  constructor(
    private comm: PartialService,
    private bodyScroll: BodyScrollService,
  ) {
    this.comm.videoModal.subscribe(res => {

      if (res.isActive) {
        this.bodyScroll.disableBodyScroll();
        this.openOverlay.emit();
        this.exists = true;
        setTimeout(() => { this.display = true }, 20);
      }
      if (!res.isActive) {
        this.dismiss();
      }
      if (res.videoUrl) {
        this.videoUrl = res.videoUrl;
      }
    })
  }

  ngOnInit(): void {
  }

  public dismiss() {
    this.bodyScroll.enableBodyScroll();
    this.display = false;
    setTimeout(() => { this.exists = false; }, 400);
    this.closeOverlay.emit();
  }

}

export interface VideoModalCall {
  isActive: boolean;
  videoUrl?: string;
}
