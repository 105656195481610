import { Component, OnInit } from '@angular/core';
import { CodedResponseModel } from '../../model/CodedResponseModel';
import { LoginService } from '../../services/login.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	public email: string = '';
	public sent: boolean = false;

	public titleSmall: string = 'Certification Portal';
	public titleBig: string = 'Forgot your password?';
	public logo1ImgSrc: string = 'assets/svg/RIAA-Logo.svg';
	public logo2ImgSrc: string = 'assets/svg/RR-Logo-login.svg';
	public rightSideImgSrc: string = 'assets/jpg/login_image.jpg';
	public sendBtnText: string = 'Reset your password';
	public isFeatureBrand: boolean = true;
	public error: boolean = false;

	public isFeaturedResearchVisible: boolean = true;
	public featuredResearchTitle: string = '30 characters lipsum dolor sit';
	public featuredResearchDesc: string = '80 characters lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod';
	public featuredResearchLinkUrl: string = 'http://www.google.com';
	public featuredResearchLinkText: string = 'learn more';

	public isFeaturedBrandVisible: boolean = true;
	public featuredBrandStars: number = 5;
	public featuredBrandName: string = 'Australian Equities Trust';
	public featuredBrandLogo: string = 'assets/svg/u-ethical-logo.svg';

	constructor(
		private loginApi: LoginService,
	) { }
	ngOnInit() { }

	public send() {
		this.error = false;
		this.loginApi.forgotPassword(this.email).subscribe(res => {
			let response = new CodedResponseModel();
			response.map(res);
			if (response.success) {
				this.sent = true;
			}
		}, err => {
			this.error = true;
			console.log(err);
		})
	}

	public counter(i: number) {
		return new Array(i);
	}

	public showFeaturedBrand() {
		this.isFeatureBrand = true;
	}
	public showFeaturedResearch() {
		this.isFeatureBrand = false;
	}
}
