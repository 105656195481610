import { Component, EventEmitter, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShareApplicationComponent } from 'src/app/application/elements/share-application/share-application.component';
import { bulkAction } from 'src/app/core/bulk-actions/bulk-actions.component';
import { ApproveModalComponent } from 'src/app/dashboard/approve-modal/approve-modal.component';
import { DenyModalComponent } from 'src/app/dashboard/deny-modal/deny-modal.component';
import { DeleteConfirmComponent } from 'src/app/form-builder/elements/delete-confirm/delete-confirm.component';
import { ConversationsComponent } from 'src/app/menu/conversations/conversations.component';
import { MenuComponent } from 'src/app/menu/menu.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { Application } from 'src/app/model/responsible/Application';
import { ProductCategory, ProductTarget } from 'src/app/model/responsible/CustomIntegration';
import { column } from 'src/app/model/Table';
import { User, UserTypes } from 'src/app/model/User';
import { PartialService } from 'src/app/services/partial.service';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';
import { AssessmentAPI } from 'src/app/services/responsible/assessment.service';
import { UsersAPIService } from 'src/app/services/users.service';
import { tab_interface } from '../../core/top-tabs/top-tabs.component';
import { LoaderComponent } from '../../partials/loader/loader.component';
@Component({
    selector: 'app-admins',
    templateUrl: './admins.component.html',
    styleUrls: ['./admins.component.scss'] 
})
export class AdminsComponent implements OnInit {
    public newApplications: Application[] = []; 
    public ongoingApplications: Application[] = [];
    public refresher = new EventEmitter();
    public total: number = 1
    public currTab: number = 0;
    public currPage: number = 1
    public isLoaded: boolean = false;
    public query: IndexQuery = {
        filters: {},
        page: 1
    }
    public adminsCount: any;

    public filterCooldown: any;
    public userType: UserTypes;

    public admins: User[] = [];
    public expanded: boolean = false;

    public isEdit:boolean = false;
    public target:number = 0;
 

    public tabs: tab_interface[] = [
        { label: 'All admins', onClick: () => { this.router.navigate(['/admins']); }, active: true, badge: AdminsComponent.totalMemory },
        { label: 'Add new admins', onClick: () => { this.router.navigate(['/admins/new']); }, active: false },
        { label: 'Edit exclusions', onClick: () => { this.router.navigate(['/exclusions']); }, active: false, hidden:this.jwt.decodeToken().user.type===UserTypes.IVP },
        { label: 'Edit themes', onClick: () => { this.router.navigate(['/themes']); }, active: false, hidden:this.jwt.decodeToken().user.type===UserTypes.IVP },
        { label: 'Edit categories', onClick: () => { this.router.navigate(['/categories']); }, active: false, hidden:this.jwt.decodeToken().user.type===UserTypes.IVP },
        { label: 'Edit search filters', onClick: () => { this.router.navigate(['/filters']); }, active: false, hidden:this.jwt.decodeToken().user.type===UserTypes.IVP },
        { label: 'Edit settings', onClick: () => { this.router.navigate(['/edit-login']); }, active:false, hidden:this.jwt.decodeToken().user.type===UserTypes.IVP },
    ];

    public columns: column[] = [
        { name: 'Name', key: 'fullName', type: 1, filterKey:'name' },
        { name: 'Email', key: 'email', type: 1 },
        { name: 'New Assigned', key: 'newAssigned', type: 7, },
        { name: 'Ongoing assigned', key: 'ongoingAssigned', type: 7, },
        { name: 'Type', key: 'userType', type: 5, filterKey:'type', filters:[{name:'RIAA Admin', type:UserTypes.RIAA}, {name:'IVP Admin', type:UserTypes.IVP}] },
        { name: 'Actions', key: '', type: 8, actions: [
            { label:'Edit', action:(i:User) => { this.router.navigate([`/admins/${i.id}`]); } },
            { label:'Delete', action:(i:User) => { this.delUser(i.id); } },
        ]}
    ];

    public bulkActions: bulkAction[] = [
        { isPlus: false, btnText: 'Delete all selected',  type: 'admins', imgUrl: '/assets/svg/minus-icon.svg', function: (u:User[]) => { this.massDelete(u); } }
    ]

    public static totalMemory:number = 0;

    constructor(
        private router: Router,
        private partials: PartialService,
        private jwt: JwtHelperService,
        private dialog: MatDialog,
        private userApi: UsersAPIService,
        private route:ActivatedRoute,
    ) {
        LoaderComponent.show.emit(true);
        this.userType = this.jwt.decodeToken().user.type;
        if(![UserTypes.Admin, UserTypes.RIAA, UserTypes.IVP].includes(this.userType)) this.router.navigate(['/']);
        this.route.data.subscribe(d => {
            if(d.directive){
                switch(d.directive){
                    case 'admins':              // /admins
                        this.goToTab(0);
                        this.isEdit = false;
                        break;
                    case 'add-admin':     
                          // /admins/new
                         this.goToTab(1);
                        this.isEdit = false;
                        break;
                    case 'edit-exclusions':     // /exclusions
                        if(this.userType===UserTypes.IVP) this.router.navigate(['/admins']);
                         this.goToTab(2);
                        this.isEdit = false;
                        break;
                    case 'edit-themes':         // /themes
                        if(this.userType===UserTypes.IVP) this.router.navigate(['/admins']);
                         this.goToTab(3);
                        this.isEdit = false;
                        break;
                    case 'edit-categories':     // /categories
                        if(this.userType===UserTypes.IVP) this.router.navigate(['/admins']);
                        LoaderComponent.show.emit(true);
                        this.goToTab(4);
                        this.isEdit = false;
                        break;
                    case 'edit-filters':        // /filters
                        if(this.userType===UserTypes.IVP) this.router.navigate(['/admins']);
                        LoaderComponent.show.emit(true);
                        this.goToTab(5);
                        this.isEdit = false;
                        break;
                    case 'edit-admin':          // /admins/:id
                        for(let t of this.tabs) t.active = false;
                        LoaderComponent.show.emit(true);
                        this.currTab = 1;
                        this.isEdit = true;
                        this.target = Number(this.route.snapshot.paramMap.get('id'));
                        break;
                    case 'edit-login':
                        LoaderComponent.show.emit(true);
                        this.goToTab(6);
                        this.isEdit = false;
                        break;
                }
            }
        });
    }

    ngOnInit(): void {
        LoaderComponent.show.emit(true);
    }

    private fetchData() {
     if(this.currTab !== 0)   LoaderComponent.show.emit(true);
         this.query.filters!.group = 'admins';
        this.userApi.index(this.query).subscribe(res => {
            let response = CodedResponseModel.decode(res);
            this.tabs[0].badge = response.extra.admins;
            AdminsComponent.totalMemory = response.extra.admins;

            this.admins = [];
            for (let user of response.data) {
                this.admins.push(User.create(user));
            }
            this.total = response.filtered;
            LoaderComponent.show.emit(false);
            this.filterCooldown = undefined
        })
    }

    public goToTab(i: number) {
        this.currTab = i;
        for (let t of this.tabs) t.active = false;
        this.tabs[i].active = true;
        this.query.page = 1;
        if (i === 0) this.fetchData();
    }

    getFilters(filters: any) {
        if (this.filterCooldown) clearTimeout(this.filterCooldown);
        this.filterCooldown = setTimeout(() => {
            if (this.query.filters) {
                this.query.filters = filters;
                this.query.page = 1       
            }
            this.fetchData()
        }, 800);
    }

    getPage(page: number) {
        this.query.page = page
        this.fetchData()
        if(this.filterCooldown) clearTimeout(this.filterCooldown); 
        this.filterCooldown = setTimeout(() => { 
            this.query.page = page
        }, 200);
    }

    public getOffset(value: boolean) {
        this.expanded = value
    }

    public delUser(id:number){
        let d = this.dialog.open(DeleteConfirmComponent, { panelClass:'modal-white' });
        d.afterClosed().subscribe(r => {
            if(r){
                this.userApi.delete(id).subscribe(res => {
                    this.partials.notificator.emit({ type:'success', message:"User has been removed", timeout:5000 });
                    this.fetchData();
                }, err => {
                    this.partials.notificator.emit({ type:'error',  message:'Something went wrong', timeout:5000 });
                });
            }
        })
    }
    public massDelete(users:User[]){
        let ids = [];
        for(let u of users) ids.push(u.id);
        this.userApi.massDelete(ids).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Users have been deleted', timeout: 5000 });
            this.fetchData();
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
}
