import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormField } from 'src/app/model/responsible/FormField';

@Component({
    selector: 'app-char-limit',
    templateUrl: './char-limit.component.html',
    styleUrls: ['./char-limit.component.scss']
})
export class CharLimitComponent implements OnInit {
    public charlimit:number = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:{ field:FormField, index:string, qIndex:string }
    ){}

    ngOnInit():void{
        this.charlimit = this.data.field.charlimit??0;
    }

}
