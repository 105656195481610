<div class="overlay" *ngIf="open" (click)="open = false"></div>
<div class='floater' *ngIf="!open" (click)="openclose(true)">
    <div class='progress' [style.width]="(filledQuestions()/questions.length*100)+'%'"></div>
    <span>{{filledQuestions()}}/{{questions.length}}</span>
</div>
<div class='panel' *ngIf="open">
    <div class='header'>
        <div class='progress' [style.width]="(filledQuestions()/questions.length*100)+'%'"></div>
        <span>{{filledQuestions()}}/{{questions.length}}</span>
        <button (click)="openclose(false)">Close</button>
    </div>
    <div class='content'>
        <app-question *ngFor="let q of questions" [question]="q" [assessment]="assessment"></app-question>
    </div>
</div>