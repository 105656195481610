import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NotyficationMessageStateModel } from "../model/NotyficationMessageStateModel";
import { User } from "../model/User";
import { UsersAPIService } from "../services/users.service";

@Component({
    selector:'app-new-user',
    templateUrl:'./new.component.html'
})
export class UserCreateComponent{
    //loader
    public showLoader: boolean = false;
    public showNotyfication: boolean = false;
    public notyficationMessage: string = '';
    public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
    //loader END

    user:User = new User();
    err:string = '';
    constructor(
        private userApi:UsersAPIService,
        private router:Router,
    ){}

    public send(){
        try{
            if(!this.user.name) throw "Username cannot be empty";
            if(!this.user.email) throw "Email cannot be empty";
            if(!this.user.password) throw "Password cannot be empty";
            if(this.user.password!=this.user.passConfirm) throw "Passwords don't match";
            this.userApi.create(this.user).subscribe(res => {
                console.log(res);
                this.router.navigate(['/users']);
            }, err => {
                this.showNotyfication = true;
                this.notyficationMessage = err.error.message;
                this.notyficationMessageStateModel.errors_save = true;
            })
        } catch(e) { this.err = e }
    }
}