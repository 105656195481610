<app-auth></app-auth>
<app-menu></app-menu>
<!-- <appLoader [showLoader]="showLoader" [showNotyfication]="showNotyfication" [notyficationMessage]="notyficationMessage"
    [notyficationMessageStateModel]="notyficationMessageStateModel">
</appLoader> -->
<main role="main">
    <div class="main_wrap">
        <div class="offset" *ngIf="loaded">
            <div class="main_content">

                <div class='heading_wrap'>
                    <div class="text_wrap">
                        <div class="subheading">Edit profile</div>
                        <h2>Update your profile details</h2>
                    </div>
                </div>

                <div class="form_block update_data">
                    <app-input-field placeholderText="First name" [(model)]="editable.name">
                    </app-input-field>
                    <app-input-field placeholderText="Last name" [(model)]="editable.lname">
                    </app-input-field>
                    <app-input-field placeholderText="Email" [(model)]="editable.email">
                    </app-input-field>
                    <!-- TODO Phone number-->
                    <app-input-field placeholderText="Phone number"></app-input-field>
                    <app-button-primary text="Update" (click)="saveChanges()"></app-button-primary>
                </div>

                <div class='heading_wrap'>
                    <div class="text_wrap">
                        <div class="subheading">Account security</div>
                        <h2>Change your password</h2>
                    </div>
                </div>
                <div class="form_block login_box">
                    <div class="password_wrap">
                        <app-input-field [name]="'current_password'" [type]="'current_password'" [isError]="isError"
                            [errorText]="''" [placeholderText]="'Current password'" [(model)]="editable.password!">
                        </app-input-field>
                    </div>
                    <div class="password_wrap">
                        <app-input-field [name]="'password'" [type]="'password'" [isError]="isError" [errorText]="''"
                            [placeholderText]="'Password'" [(model)]="password" [isPasswordComparsion]="true"
                            (passwordComparsion)="passwordComparsion()" [isPasswordStrenght]="true">
                        </app-input-field>
                    </div>
                    <div class="password_rule">Include 8 characters and one special character</div>
                    <div class="password_confirm_wrap">
                        <app-input-field [name]="'confirm_password'" [type]="'password'" [isError]="isError"
                            [errorText]="''" [placeholderText]="'Confirm password'" [isPasswordComparsion]="true"
                            (passwordComparsion)="passwordComparsion()" [(model)]="passConfirm">
                        </app-input-field>
                        <img src="assets/svg/confirm_pass_tick.svg" alt="Tick" [class.show]="showTick">
                    </div>
                    <div class="error_wrap" *ngIf="error">
                        <img src="assets/svg/error.svg" alt="Error icon">
                        <div class="error">{{ errorMsg }}</div>
                    </div>

                    <div class="desc" *ngIf="passwordChanged">
                        <div>Password has been reset!</div>
                    </div>

                    <app-button-primary text="Change password" (click)="saveChanges()"></app-button-primary>
                </div>

                <div class='heading_wrap'>
                    <div class="text_wrap">
                        <div class="subheading">Deleting account</div>
                        <h2>Delete your account</h2>
                    </div>
                </div>

                <div class="form_block">
                    <app-input-field placeholderText="Enter password" [(model)]="editable.name">
                    </app-input-field>
                    <app-button-primary text="Delete account" (click)="del()"></app-button-primary>
                </div>
                
                <div>
                    {{formErr}}
                </div>
            </div>
        </div>
    </div>
</main>