import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CodedResponseModel } from "../model/CodedResponseModel";
import { environment } from "../../environments/environment";
import { EpEncryptionService } from "./ep-encryption.service";
import { MailEvents } from '../model/MailEvents';


@Injectable({
  providedIn: 'root'
})
export class MailEventsService {

  constructor(private http: HttpClient, private epEncryptionService: EpEncryptionService) { }


  public getAll() {
    return this.http.get<CodedResponseModel>(
      environment.apiUrl + "mail_events"
    );
  }

  public get(id:number){
    return this.http.get<CodedResponseModel>(`${environment.apiUrl}mail_events/${id}`);
  }
  public add(event:MailEvents){
    return this.http.post<CodedResponseModel>(`${environment.apiUrl}mail_events`,
      this.epEncryptionService.sendPayload(event));
  }
  public update(event:MailEvents){
    return this.http.put<CodedResponseModel>(`${environment.apiUrl}mail_events/${event.id}`,
      this.epEncryptionService.sendPayload(event));
  }
  public delete(id:number){
    return this.http.delete<CodedResponseModel>(`${environment.apiUrl}mail_events/${id}`);
  }

}
