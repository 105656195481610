import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { FilterMap, IndexQuery } from 'src/app/model/IndexQuery';
import { User } from 'src/app/model/User';
import { UsersAPIService } from 'src/app/services/users.service';


import { LoaderComponent } from '../../partials/loader/loader.component';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  public users: User[] = []
  public loading: boolean = false
  public date: Date = new Date()
  public filters: IndexQuery = {}

  public isNew: boolean = true
  public rating: number = 3

  public users2: Array<any> = []
  //public functions: Array<Function> = [this.test1, this.test2, this.test3]
  public bam: Array<{isPlus: boolean, btnText: string, imgUrl: string, function: CallableFunction}> =[
    {isPlus: true, btnText:'approve ALL Selected', imgUrl: '../../../assets/svg/plus-icon.svg', function: () => {console.log("+")}}, {isPlus: false, btnText:'Deny all selected', imgUrl: '../../../assets/svg/minus-icon.svg', function: () => {console.log("-")}}
  ]
public active: boolean = false 


  constructor(private userApi: UsersAPIService) {
     this.users2 = [
      { id: 0, name: "1Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 5, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Admin", date: this.date, count: 3 },
      { id: 1, name: "2Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 4, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "IVP Admin", date: this.date, count: 3 },
      { id: 2, name: "3Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 3, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "IVP Admin", date: this.date, count: 3 },
      { id: 3, name: "4Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 2, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "RIAA Admin", date: this.date, count: 3 },
      { id: 4, name: "5Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 1, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Collaborator", date: this.date, count: 3 },
      { id: 5, name: "6Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 0, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Member", date: this.date, count: 3 },
      { id: 6, name: "7Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 5, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Member", date: this.date, count: 3 },]
  }

  public fetchData() {
    console.log(this.filters)
    this.userApi.index(this.filters).subscribe(res => {
      let response = CodedResponseModel.decode(res)
      this.users = []
      for (let user of response.data) {
        this.users.push(User.create(user))
      }
      // console.log(this.users)
      this.loading = false
    })
  }
  public column =
    [{ name: "Product name", type: 1, key: "name" },
    // { name: "Product name second", type: 1, key: "lname" }, { name: "users", type: 1, key: "email" }, { name: "email", type: 1, key: "email" },
    // { name: "Join Date", type: 6, key: "createdAt" }, { name: "Type", type: 5, key: "userType", filters: [{ name: 'Admin', active: false, type: 0 }, { name: 'IVP Admin', active: false, type: 1 }, { name: 'RIAA Admin', active: false, type: 2 }, { name: 'Collaborator', active: false, type: 4 }, { name: 'Member', active: false, type: 3 }] },
    {
      name: "assigned", type: 3, key: "assigned", filters: [
        { id: 0, name: "1Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 5, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Admin", date: this.date, count: 3 },
        { id: 1, name: "2Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 4, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "IVP Admin", date: this.date, count: 3 },
        { id: 2, name: "3Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 3, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "IVP Admin", date: this.date, count: 3 },
        { id: 3, name: "4Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 2, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "RIAA Admin", date: this.date, count: 3 },
        { id: 4, name: "5Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 1, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Collaborator", date: this.date, count: 3 },
        { id: 5, name: "6Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 0, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Member", date: this.date, count: 3 },
        { id: 6, name: "7Altius Sustainable Bond Fund", assigned: ["../../../assets/svg/alert.svg", "../../../assets/svg/alert.svg"], rating: 5, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Member", date: this.date, count: 3 },]
    }, { name: "rating", type: 4, key: "rating" }, { name: "comments", type: 2, key: "isNew" },
    { name: "Date", type: 6, key: "date" }, { name: "Count", type: 7, key: "count" },
    { name: "email", type: 1, key: "email" },
     { name: "Common List", type: 9, key: "name", filters: [
        { id: 0, name: "1Altius Sustainable Bond Fund", rating: 5, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Admin", date: this.date, count: 3 },
        { id: 1, name: "2Altius Sustainable Bond Fund" ,rating: 4, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "IVP Admin", date: this.date, count: 3 },
        { id: 2, name: "3Altius Sustainable Bond Fund", rating: 3, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "IVP Admin", date: this.date, count: 3 },
        { id: 3, name: "4Altius Sustainable Bond Fund", rating: 2, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "RIAA Admin", date: this.date, count: 3 },
        { id: 4, name: "5Altius Sustainable Bond Fund", rating: 1, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Collaborator", date: this.date, count: 3 },
        { id: 5, name: "6Altius Sustainable Bond Fund", rating: 0, isNew: false, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Member", date: this.date, count: 3 },
        { id: 6, name: "7Altius Sustainable Bond Fund", rating: 5, isNew: true, email: "Altius Asset Managemement@test.com", fullName: "Altius Asset Managemement", userType: "Member", date: this.date, count: 3 },]
     },
    { name: "Full name", type: 1, key: "fullName" },
    { name: "Type", type: 5, key: "userType", filters: [ {  name: "Admin"},{  name: "IVP Admin"}, {  name: "RIAA Admin"},{  name: "Collaborator"},{name: "Member"},]
   },
    {
      name: "Actions", type: 8, key: "actions",
      actions: [{ label: "View", action: (id: any) => { console.log(id) } }, { label: "Edit", action: (id: any) => { console.log("2") } }, { label: "Delete", action: (id: any) => { console.log("3") } }]
    },
    ]
  ngOnInit(): void { 
 //   LoaderComponent.show.emit(true);
    //  this.fetchData()
   }
 
  test3() {
    console.log("third function")
  }

  getFilters(fil: any) {
    this.filters = { filters: fil }
    // this.fetchData()
  }
}
