import { Component, Input, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { SupportFile } from 'src/app/model/responsible/SupportFile';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { FormBuilderComponent } from 'src/app/form-builder/form-builder/form-builder.component';


@Component({
  selector: 'app-documents-n-templates',
  templateUrl: './documents-n-templates.component.html',
  styleUrls: ['../../form-builder/support-content/support-content.component.scss','./documents-n-templates.component.scss']
})

export class DocumentsNTemplatesComponent implements OnInit {
  @Input() public section:FormSection = new FormSection();
  @Input() title: string = 'Documents and Templates';
  @Input() public desc: string = '';
  constructor(
    private dialog: MatDialog, 
    private attachmentApi: AttachmentAPI
  ) { }

  ngOnInit(): void {
  }

  //? Files
  public uploadFile(flist: FileList) {
    if (flist.item(0)) {
      let added = false,
        handle: file_interface,
        index: number;
      this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
        let file = flist.item(0)!;
        handle = {
          filename: file.name,
          extension: file.name.split('.').pop() ?? '',
          status: 'new',
          progress: 0
        };
        switch (res.type) {
          case HttpEventType.Sent:
            index = this.section.supportFileList.push(handle) - 1;
            added = true;
            break;
          case HttpEventType.UploadProgress:
            console.log(Math.round(res.loaded / res.total! * 100));
            this.section.supportFileList[index].progress = Math.round(res.loaded / res.total! * 99);
            break;
          case HttpEventType.Response:
            let sf = new SupportFile(),
              response = CodedResponseModel.decode(res.body);
            let att = Attachment.create(response);
            sf.attachmentId = response.id;
            sf.attachment = att;
            this.section.supportFiles.push(sf);
            this.section.supportFileList[index].file = att;
            this.section.supportFileList[index].progress = 100;
            FormBuilderComponent.changed = true;
            break;
        }
      }, err => {
        if (added) {
          this.section.supportFileList.splice(this.section.supportFileList.indexOf(handle), 1);
        }
        console.warn(err);
      });
    }
  }
  public deleteFile(file: file_interface) {
    let index = this.section.supportFileList.indexOf(file);
    this.section.supportFileList.splice(index, 1);
    this.section.supportFiles.splice(index, 1);
    FormBuilderComponent.changed = true;
  }
  public openUploader() {
    let d = this.dialog.open(UploadModalComponent, {
      panelClass: 'modal-white'
    });
    d.afterClosed().subscribe(res => {
      if (res) {
        this.uploadFile(res.file);
      }
    });
  }

}
