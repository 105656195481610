import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appFileDrop]'
})
export class FileDropDirective {

  background: string = '';
  
  @Output() private filesChangeEmiter: EventEmitter<FileList> = new EventEmitter();

  @HostListener('drop',['$event']) public onDrop(evt: any){
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
    let files = evt.dataTransfer.files;
    if(files.length > 0){
      this.filesChangeEmiter.emit(files);
    } else {
      //console.log('no file');
    }
  }
 
  @HostListener('dragover',['$event']) onGragOver(event: any){
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; 
  }
}
