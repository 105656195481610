import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Application } from 'src/app/model/responsible/Application';
import { Assessment } from 'src/app/model/responsible/Assessment';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';

@Component({
    selector: 'app-deny-modal',
    templateUrl: './deny-modal.component.html',
    styleUrls: ['./deny-modal.component.scss']
})
export class DenyModalComponent implements OnInit {
    public ready:boolean = false;
    public assessment:Assessment = new Assessment();

    public documents:file_interface[] = [];

    public approvalConditions:{name:string,value:number}[] = [
        { name: 'Not Approved', value: 0 },
    ];
    public finalScores:{name:string,value:number}[] = [
        { name: '1 - Fail and no Remediation plan', value: 1 },
        { name: 'N/A - Not Applicable', value: 0 }
    ];

    public selectedCondition:{name:string,value:number} = this.approvalConditions[0];
    public selectedScore:{name:string,value:number} = this.finalScores[0];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:{ application:Application },
        private attachmentApi:AttachmentAPI,
        private dialog:MatDialog,
    ){}

    ngOnInit(): void {
        this.assessment.condition = this.selectedCondition.value;
        this.assessment.score = this.selectedScore.value;
    }

    public uploadDocument(flist: FileList) {
        if (flist.item(0)) {
            let added = false,
                index: number,
                handle: file_interface;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        index = this.documents.push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        //console.log(Math.round(res.loaded/res.total!*100));
                        this.documents[index].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.documents[index].file = att;
                        this.documents[index].progress = 100;
                        break;
                }
            }, err => {
                if (added) {
                    this.documents.splice(index, 1);
                }
                console.warn(err);
            });
        }
    }
    public deleteDocument(file: file_interface) {
        let index = this.documents.indexOf(file);
        this.documents.splice(index, 1);
    }
    public openUploader() {
        let d = this.dialog.open(UploadModalComponent, {
            panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) this.uploadDocument(res.file);
        });
    }
}
