import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Exclusion } from "src/app/model/responsible/Exclusion";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root',
})
export class ExclusionAPI{
    constructor(
        private http:HttpClient,
        private epEncryptor:EpEncryptionService,
    ){}

    public index(){
        return this.http.get(`${environment.apiUrl}exclusions`);
    }
    public update(exclusions:Exclusion[]){
        return this.http.put(`${environment.apiUrl}exclusions`,
            this.epEncryptor.sendPayload((
                ()=>{
                    let arr = [];
                    for(let e of exclusions) arr.push(e.responsify());
                    return arr;
                })()
            ))
    }
}
