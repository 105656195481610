import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CodedResponseModel } from "../model/CodedResponseModel";
import { buildQueryParams, IndexQuery } from "../model/IndexQuery";
import { User, UserTypes } from "../model/User";
import { environment } from "../../environments/environment";
import { EpEncryptionService } from "./ep-encryption.service";

interface UserSearchQuery {
    query:string,
    types:UserTypes[],
    limit?:number
}

@Injectable({
    providedIn:'root'
})
export class UsersAPIService{
    constructor(
        private http:HttpClient,
        private epEncryptionService:EpEncryptionService,
    ){}

    public index(query?:IndexQuery):Observable<CodedResponseModel>{
        let params = buildQueryParams(query);
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}users${params}`);
    }
    public search(query:UserSearchQuery){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}user/search?${new URLSearchParams(query as any).toString()}`);
    }
    public show(id:number):Observable<CodedResponseModel>{
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}user/${id}`);
    }
    public edit(data:User):Observable<CodedResponseModel>{
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}user/${data.id}`,
            this.epEncryptionService.sendPayload(data.responsify()));
    }
    public delete(id:number):Observable<CodedResponseModel>{
        return this.http.delete<CodedResponseModel>(`${environment.apiUrl}user/${id}`);
    }
    public massDelete(ids:number[]){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}user/bulkDelete`,
            this.epEncryptionService.sendPayload({ids:ids}));
    }
    public create(data:User):Observable<CodedResponseModel>{
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}user`,
            this.epEncryptionService.sendPayload(data.responsify()));
    }
    public standardDelete(target:number, options:any){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}user/delete`,
            this.epEncryptionService.sendPayload({ target: target, options: options }));
    }

    public getCurrUser():Observable<CodedResponseModel>{
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}me`);
    }
    public updateCurrUser(data:User):Observable<CodedResponseModel>{
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}me`,
            this.epEncryptionService.sendPayload(data.responsify()));
    }
    public updatePassword(data:{password:string,newPass:string,passConfirm:string}){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}password`,
            this.epEncryptionService.sendPayload(data));
    }
    public uploadAvatar(file:File):Observable<CodedResponseModel>{
        let data = new FormData();
        data.append('avatar', file);
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}avatar`, data);
    }
    public deleteSelf(password:string){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}delete-self`,
            this.epEncryptionService.sendPayload({ password: password }));
    }
}
