<app-button-icon class="close_sc" *ngIf="mobileExpand" (click)="mobileExpand=false; scroll.enableBodyScroll()" [imgUrl]="'../../../../assets/svg/close_menu_cross.svg'"></app-button-icon>
<div #container class='container' [ngClass]="{'expanded': mobileExpand }">
    <div class='top'>
        <h4 (click)="mobileExpand=true; scroll.disableBodyScroll()">Knowledge and support hub</h4>
        <div class="wrap_ic">
            <app-message [isFixed]="false" [product]="app"></app-message>
        </div>
    </div>
    <div class='main'>
        <div *ngIf="mobileExpand" class="pull_down_mobile" (touchmove)="swipeDown($event)">
            <div class="puller"></div>
        </div>
        <div class='section section_faq'>
            <h3 (click)="closeAll('showFAQ')" [ngClass]="{'rotate': showFAQ}">FAQs
                <svg-icon src="../../../assets/svg/arrow_top.svg"></svg-icon>
            </h3>
            <ng-container *ngIf="showFAQ">
                <div class='faq' *ngFor="let faq of isResources?resFaq:section.supportFAQs">
                    <div class="row j_space" [ngClass]="{'faq_active':faq.active}">
                        <p class='question' (click)="faq.active= !faq.active">{{faq.question}}</p>
                        <svg-icon class="faq_arrow" src="../../../assets/svg/arrow_top.svg"></svg-icon>
                    </div>
                    <p *ngIf="faq.active" class='answer '>{{faq.answer}}</p>
                </div>
            </ng-container>
        </div>
        <hr>
        <div class='section s_videos'>
            <h3 (click)="closeAll('showVideos')" [ngClass]="{'rotate': showVideos}">Videos
                <svg-icon src="../../../assets/svg/arrow_top.svg"></svg-icon>
            </h3>
            <ng-container *ngIf="showVideos">
                <app-video *ngFor="let video of section.supportVideos; let i=
                    index;" [videoUrl]="video.link" [index]="i" [openModal]="true" [isMetadataVisible]="true"></app-video>
            </ng-container>
        </div>
        <hr>
        <div class='section files '>
            <h3 (click)="closeAll('showFiles')" [ngClass]="{'rotate':
                showFiles}">Documents and Templates
                <svg-icon src="../../../assets/svg/arrow_top.svg"></svg-icon>
            </h3>
            <ng-container *ngIf="showFiles">
                <!-- <div class='file ' *ngFor="let file of section.supportFiles" (click)="link.click()">
                    <a #link [href]='file.getURL() ' target='_blank ' download></a>
                    <span class='fname '>{{file.attachment?.filename}}</span>
                    <div class='info '>
                        <span>{{file.attachment?.getExtension()}}</span>
                        <svg-icon [src]="'assets/svg/download.svg '"></svg-icon>
                    </div>
                </div> -->
                <div>
                    <ng-container *ngIf="section">
                        <app-download-button *ngFor="let file of section.supportFiles" [name]="file.attachment?.label||file.attachment?.filename" [fileType]="file.attachment?.getExtension()" [fileUrl]="file.attachment?.getUrl()"></app-download-button>
                    </ng-container>
                    <ng-container *ngIf="resFiles">
                        <app-download-button *ngFor="let download of resFiles" [name]="download.file!.label||download.file!.filename" [fileUrl]="download.file!.getUrl()" fileSize="{{download.file?.filesize}}"></app-download-button>
                    </ng-container>
                </div>


            </ng-container>
        </div>
        <hr>
        <div class='section ticks'>
            <h3 (click)="closeAll('showChecklist')" [ngClass]="{'rotate':
                showChecklist}">Checklist
                <svg-icon src="../../../assets/svg/arrow_top.svg"></svg-icon>
            </h3>
            <div *ngIf="showChecklist">
                <app-tick-box *ngFor="let check of isResources?resChecks:section.supportChecks" [text]="check.label"></app-tick-box>
            </div>
        </div>
    </div>
</div>