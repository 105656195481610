import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormQuestionFront, form_question_front_response } from 'src/app/model/responsible/FormQuestionFront';

@Component({
    selector: 'app-add-to-frontend',
    templateUrl: './add-to-frontend.component.html',
    styleUrls: ['./add-to-frontend.component.scss']
})
export class AddToFrontendComponent implements OnInit {
    public pages:FormQuestionFront[] = [];
    public tag:string = '';

    public pageList = [{ name:'Product page', value:'product' },{ name:'Provider page', value:'provider' }];
    public sectionList = [{ name:'Product overview', value:'overview' },{ name:'Product details', value:'details' },{ name:'Description', value:'description' },{ name:'Primary CTA', value:'primary' },{ name:'Secondary CTA', value:'secondary' }];
    public positionList = [{ name:'1' },{ name:'2' },{ name:'3' },{ name:'4' },{ name:'5' },{ name:'6' },{ name:'7' },{ name:'8' },{ name:'9' },{ name:'10' }];

    public clicked: any = ''
    constructor(
        @Inject(MAT_DIALOG_DATA) private data:{ types:string[], tag:string, pages:form_question_front_response[] },
        private ref:MatDialogRef<AddToFrontendComponent>
    ){}

    ngOnInit():void{
        this.tag = this.data.tag;
        this.pages = (() => {
            let arr:FormQuestionFront[] = [];
            for(let i of this.data.pages) arr.push(FormQuestionFront.create(i));
            return arr;
        })()
        // for(let t of this.data.types){
        //     if(['single-line', 'multi-line'].includes(t)&&)
        // }
    }

    public getSection(value:string){
        for(let s of this.sectionList) if(s.value===value) return s;
        return undefined;
    }
    public getPage(value:string){
        for(let p of this.pageList) if(p.value===value) return p;
        return undefined;
    } 

    public reset(){
        this.pages = [];
    }
    public deletePage(index:number){
        this.pages.splice(index, 1);
    }
    public addPage(){
        this.pages.push(new FormQuestionFront());
    }
        public scroll() { 
         if(this.clicked.path !== undefined){ 
             this.clicked.path[2].scrollIntoView({behavior: "smooth"})
            }else if(this.clicked.target !== undefined){
                 this.clicked.target.scrollIntoView({behavior: "smooth"})
            }
    }

    public setClick(e :any){
          if(e) this.clicked = e
     }
}
