import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Application } from 'src/app/model/responsible/Application';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { FormAPI } from 'src/app/services/responsible/forms.service';

@Component({
  selector: 'app-new-conversation',
  templateUrl: './new-conversation.component.html',
  styleUrls: ['./new-conversation.component.scss']
})
export class NewConversationComponent implements OnInit, OnDestroy {

  @Input() exists: boolean = false;
  @Input() display: boolean = false;
  @Input() formId: number = 0;
  @Input() applicationId: number = 0;
  @Output() closeNewConversation = new EventEmitter<{aId:number,qId?:number}|null>();

  public sections:FormSection[] = [];

  private subs:Subscription[] = [];

  public static refresh = new EventEmitter<void>();

  constructor(
    private formApi:FormAPI,
  ) { }

  ngOnInit(): void { 
    this.subs.push(NewConversationComponent.refresh.subscribe(r => {
      setTimeout(() => {
        this.formApi.get(this.formId).subscribe(res => {
          let response = CodedResponseModel.decode(res);
          this.sections = [];
          for(let s of response.form)
            this.sections.push(FormSection.create(s));
        });
      },0); // Weird black magic
    }));
  }

  ngOnChanges(changes:SimpleChanges) {
    if(this.exists) {
      setTimeout(() => { this.display = true }, 20);
    }
  }

  ngOnDestroy(){
    for(let s of this.subs) s.unsubscribe();
  }

  public dismiss() {
    this.display = false;   
    setTimeout(() => { this.closeNewConversation.emit(); }, 400);  
  }
  
emitObject(item: any){
  setTimeout(()=>{
    this.closeNewConversation.emit(item)
  },500)
 }
 public sectionName(s:FormSection){
   if(s.title.length>10) return `${s.title.slice(0, 10)}...`;
   else return s.title;
 }
 
}
