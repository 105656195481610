<div class="text_wrap center">
    <h1>Edit Front-End Categories</h1>
</div>
<div class="items_wrap">
    <div class="item" *ngFor="let c of categories">
        <div class="row1">
            <app-tick-box [text]="''" [(value)]="c.active"></app-tick-box>
        </div>
        <div class="inputs_wrap">
            <app-input-field [placeholderText]="'Category name'" [class]="'full'" [(model)]="c.name"></app-input-field>
        </div>
        <div class='btn_group'>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNew()"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="del(c)"></app-button-secondary>
        </div>
    </div>
</div>
<div class="cta_wrap">
    <app-button-primary text="Save" (click)="send()"></app-button-primary>
    <div class="grey_btn" routerLink="/admins">cancel</div>
</div>