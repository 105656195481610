import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-move-category',
    templateUrl: './move-category.component.html',
    styleUrls: ['./move-category.component.scss']
})
export class MoveCategoryComponent implements OnInit {
    public sections:{id:number,name:string}[] = [  ];
    public value = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any
    ){}

    ngOnInit():void{
        for(let s of this.data.sections)
            if(s.id != this.data.current) this.sections.push({ id: s.id, name: s.title });
    }
}
