import { DOCUMENT } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { Conversation } from 'src/app/model/responsible/Conversation';
import { ConversationMessage } from 'src/app/model/responsible/ConversationMessage';
import { PartialService } from "src/app/services/partial.service";
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { ConversationAPI } from 'src/app/services/responsible/conversation.service';
import { ConversationsComponent } from '../conversations.component';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

    @Input() exists: boolean = false;
    @Input() display: boolean = false;
    @Input() id: number = 0;
    @Input() loadChat = new EventEmitter<{cId?:number,aId?:number,qId?:number}>();
    @Output() closeChat = new EventEmitter();
    @ViewChild('newMessage') newMessage: ElementRef | any;
    @ViewChild('chatContent') chatContent: ElementRef | any;
    @ViewChild('chatContentScroll') chatContentScroll: ElementRef | any;

    public isPlaceholderHidden: boolean = false;
    public attachment = new Attachment();
    public chatArray: ConversationMessage[] = [];
    public defaultValue: string = '';
    public participantsExists: boolean = false;
    public participantsDisplay: boolean = false;
    public participants: Array<string> = [];
    public loading:boolean = false;
    public files:file_interface[] = [];

    public conversation:Conversation = new Conversation();
    public currentUser:number = 0;

    constructor(
        private comm: PartialService,
        private renderer: Renderer2,
        private conversationApi: ConversationAPI,
        private jwt:JwtHelperService,
        private dialog:MatDialog,
        private attachmentApi:AttachmentAPI,
        @Inject(DOCUMENT) private document: Document
    ) { }

    ngOnInit(): void {
        this.attachment.filename = 'assets/pdf/dummy.pdf';
        this.attachment.id = 1;
        //this.getChat();
        this.currentUser = this.jwt.decodeToken().user.id;
        //this.participants = ["Gerald Ellis", "Katelynn Hopkins", "Thomas Wischer"];
        this.loadChat.subscribe(r => {
            setTimeout(() => {
                if(r.cId){
                    this.loading = true;
                    this.conversationApi.get(r.cId).subscribe(res => {
                        let response = CodedResponseModel.decode(res);
                        this.conversation = Conversation.create(response);
                        this.chatArray = this.conversation.messages;
                        this.updateLastSeen();
                        this.loading = false;
                    });
                } else if(r.aId){
                    this.loading = true;
                    this.conversationApi.getByRelations(r.aId, r.qId).subscribe(res => {
                        let response = CodedResponseModel.decode(res);
                        this.conversation = Conversation.create(response);
                        this.chatArray = this.conversation.messages;
                        this.updateLastSeen();
                        this.loading = false;
                    });
                }
            }, 0);
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.exists) {
            setTimeout(() => {
                this.display = true;
                this.adjustChatScroll();
            }, 20);
            // if (changes.id.previousValue != changes.id.currentValue && changes.id.currentValue!=0) {
            //     this.getChat();
            // }
        }
    }

    public getChat() {
        this.loading = true;
        this.conversationApi.get(this.id).subscribe(res => {
            let response = CodedResponseModel.decode(res);
            this.conversation = Conversation.create(response);
            this.chatArray = this.conversation.messages;
            this.loading = false;
        });
    }

    public dismiss() {
        this.display = false;
        ConversationsComponent.refresh.emit();
        setTimeout(() => { this.closeChat.emit(); }, 400);
    }

    public comparePreviousMessage(message: ConversationMessage, index: number): boolean {
        if (message.user && index > 0 && this.chatArray[index - 1]) {
            return message.user.name != this.chatArray[index - 1].user?.name;
        } else if (this.chatArray[index - 1] == undefined) {
            return true;
        } else {
            return false;
        }
    }

    public compareNextMessage(message: ConversationMessage, index: number): boolean {
        if (message.user && index > 0 && this.chatArray[index + 1]) {
            return message.user.name != this.chatArray[index + 1].user?.name;
        } else if (this.chatArray[index + 1] == undefined) {
            return true;
        } else {
            return false;
        }
    }

    public hidePlaceholder(event: any) {
        if (event.target.textContent.length > 0) {
            this.isPlaceholderHidden = true;
        } else {
            this.isPlaceholderHidden = false;
        }
    }

    public submitMessage(event: any) {
        let message = event.target.innerHTML;
        this.defaultValue = message;
        if (message.length > 0) {
            let fs = [];
            for(let f of this.files)
                if(f.file) fs.push(f.file);
            
            this.conversationApi.sendMessage(message, this.conversation.id, fs).subscribe(res => {
                let response = CodedResponseModel.decode(res);

                // this.chatArray.push(ConversationMessage.create(response));

                this.conversation = Conversation.create(response);
                this.chatArray = this.conversation.messages;
            });

            this.adjustChatScroll();
            this.newMessage.nativeElement.innerHTML = '';
            this.files = [];
            this.isPlaceholderHidden = false;
        }


    }

    public preventNewLine(event: any) {
        event.preventDefault();
    }

    public adjustChatScroll() {
        setTimeout(() => {
            let chatHeight = this.chatContentScroll.nativeElement.scrollHeight;
            this.chatContentScroll.nativeElement.scrollTop = chatHeight;
        }, 100);
    }

    public typing(event: any) {
        if (event.key == '@') {
            this.participantsExists = true;
            setTimeout(() => {
                this.participantsDisplay = true;
            }, 20);
        }
        if (event.target.textContent.length === 0) {
            this.newMessage.nativeElement.innerHTML = '';
        }
    }

    public addParticipantToText(event: any) {
        let participant = event.target.textContent;
        let currentText = this.newMessage.nativeElement.innerHTML;
        let end = this.newMessage.nativeElement.textContent.length;

        currentText += `<span class="participant">@${participant}</span> <span></span>`;
        currentText = currentText.replace('@<sp', '<sp');
        this.newMessage.nativeElement.innerHTML = currentText;

        this.participantsDisplay = false;
        setTimeout(() => {
            this.participantsExists = false;
            this.setMessageCursor();
            this.newMessage.nativeElement.focus();
        }, 20);
    }

    public setMessageCursor() {
        let el = this.newMessage.nativeElement;
        let end = el.textContent.length;
        let range = document.createRange();
        let sel = window.getSelection();
        range.setStart(el.childNodes[el.childNodes.length - 1], 0);
        range.collapse(true);

        sel?.removeAllRanges();
        sel?.addRange(range);
    }

    public openAttachmentModal(id: number) {
        //this.comm.popUps.emit({ isActive: true, id: id });
        let d = this.dialog.open(UploadModalComponent, {
            panelClass:'modal-white',
        });
        d.afterClosed().subscribe(res => {
            if(res){
                this.uploadFile(res.file, id);
            }
        });
    }
    public uploadFile(flist:FileList, id:number){
        if(flist.item(0)){
            let acceptedTypes = ['image/png','image/jpeg','application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'];
            if(!acceptedTypes.includes(flist.item(0)!.type)){
                console.error(`Invalid file type.`);
                return;
            }
            let added = false,
                handle:file_interface,
                index = 0;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = { 
                    filename: file.name, 
                    extension: file.name.split('.').pop()??'',
                    status: 'new',
                    progress: 0
                };
                switch(res.type){
                    case HttpEventType.Sent:
                        index = this.files.push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        console.log(Math.round(res.loaded/res.total!*100));
                        this.files[index].progress = Math.round(res.loaded/res.total!*99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.files[index].file = att;
                        this.files[index].progress = 100;
                        break;
                }
            }, err => {
                if(added){
                    this.files.splice(index, 1);
                }
                console.warn(err);
            });
        }
    }

    public updateLastSeen(){
        let c = localStorage.getItem('convHistory'),
            d:any;
        if(!c) d = {};
        else d = JSON.parse(c);
        d[this.conversation.id] = new Date().getTime();
        localStorage.setItem('convHistory', JSON.stringify(d));
    }

}


// export interface userMessage {
//     user: {
//         name?: string;
//         avatar?: string;
//         currentUser?: boolean;
//     };
//     message?: string;
//     attachments?: Attachment[];
// }


