<div class="column" [ngClass]="{'active': isFocused, 'accepted': isAccepted, 'error': isError,'error-bcg': isError && isEmpty, 'auto_size': isAutoSize,'placeholder_top': placeholderVal()}">
    <div class="row">
        <textarea *ngIf="isTextLimit" (keydown.enter)="getKey($event)" #textarea [class.disabled]="isDisabled" [disabled]="isDisabled" (focus)="onFocus($event)" (blur)="onBlur()" [class.w-100]="fullWidth" [(ngModel)]="model" (ngModelChange)="modelChange.emit(model);"
            [class.multiline]="multiline" [maxlength]="limit"></textarea>
        <textarea *ngIf="!isTextLimit" (keydown.enter)="getKey($event)" #textarea [class.disabled]="isDisabled" [disabled]="isDisabled" (focus)="onFocus($event)" (blur)="onBlur()" [class.w-100]="fullWidth" [(ngModel)]="model" (ngModelChange)="modelChange.emit(model) "
            [class.multiline]="multiline"></textarea>
        <div class="placeholder-container">
            <span class="placeholder">{{placeholderText}}</span>
        </div>
    </div>
    <div *ngIf="isError" class="container">
        <span>{{errorText}}</span>
    </div>
</div>