<div #container class="container" [ngClass]="{'ba_padding': !isBaExpanded(),'loading': isContentLoading, 'no_results': rows.length == 0 }" (touchmove)="canScroll($event)" (touchstart)="getTouchStart($event)">
    <table>
        <thead>
            <app-overlay style="display: table-row; padding: 0 !important;
                width: 0px !important;" aria-colcount="0" colspan="0">
            </app-overlay>
            <th class="tb_cell">
                <app-tick-box #mainChcbx [text]="''" (click)="checkAll();"></app-tick-box>
            </th>
            <th *ngFor="let item of column; let index= index" class="tab_min_content" [ngClass]="{'color_head':
                item.type==5,'cell_rating': item.type==4,'cell_com':
                item.type==2,'cell_actions': item.type==8, 'disable_padding':
                item.type == 5 && column[index - 1].type == 5 && rows.length>0}">
                <div class="cell_header" (click)="scrollToTable()">
                    <app-filters-search *ngIf="item.type==1" [cellName]="item.name" [model]="filter[item.filterKey||item.key]||''" (modelChange)="getTextInput($event,
                        item.filterKey||item.key)" (clearField)="clearField(item.filterKey||item.key)" [widnowWidth]="container.offsetWidth"></app-filters-search>
                    <app-filters-list *ngIf="item.type==2" [isComment]="true" class="cell_comment tab_min_content" [filterName]="item.name" (change)="getComments($event,
                        item.filterKey||item.key)" (clearField)="clearField(item.filterKey||item.key)" [widnowWidth]="container.offsetWidth"></app-filters-list>
                    <app-filters-list *ngIf="item.type==3" [isAvatar]="true" class="tab_min_content cell_col" [filterName]="item.name" [isMultiSelect]="true" [list]="getFiltersList(item)" (change)="getSelectedListUser($event,
                        item.filterKey||item.key)" (clearField)="clearField(item.filterKey||item.key)" [widnowWidth]="container.offsetWidth"
                        [selectedValuesObject]="selectedList(item.filters||[], filter[item.filterKey||item.key])"></app-filters-list>
                    <app-filters-list *ngIf="item.type==4" [isRating]="true" class="tab_min_content cell_star" [filterName]="item.name" (changeStar)="getStar($event,
                        item.filterKey||item.key)" (clearField)="clearField(item.filterKey||item.key)" [widnowWidth]="container.offsetWidth"></app-filters-list>
                    <app-filters-list *ngIf="item.type==5" [isColorList]="true" class="tab_min_content cell_color" [isMultiSelect]="true" [filterName]="item.name" (change)="getSelectedListColor($event,
                        item.filterKey||item.key)" [list]="getFiltersList(item)" (clearField)="clearField(item.filterKey||item.key)" [widnowWidth]="container.offsetWidth"
                        [selectedValuesObject]="selectedList(item.filters||[], filter[item.filterKey||item.key], 'name')"></app-filters-list>
                    <app-filters-calendar *ngIf="item.type==6" [filterName]="item.name" (date)="getSelectedDate($event, item.filterKey||item.key)"
                        (clearField)="clearFieldDate(item.filterKey||item.key)" [initialValue]="setCalendarValue(item)"></app-filters-calendar>
                    <app-filters-number class="cell_number" *ngIf="item.type==7" [filterName]="item.name" [number]="0" (change)="getNumber($event, item.filterKey||item.key)" (clearNumber)="clearField(item.filterKey||item.key)" [widnowWidth]="container.offsetWidth"></app-filters-number>
                    <app-filters-list *ngIf="item.type==9" [isAvatar]="false" class="tab_min_content cell_col" [filterName]="item.name" [isMultiSelect]="true" [list]="getFiltersList(item)" (change)="getSelectedListUser($event,
                        item.filterKey||item.key)" (clearField)="clearField(item.filterKey||item.key)" [widnowWidth]="container.offsetWidth"
                        [selectedValuesObject]="selectedList(item.filters||[], filter[item.filterKey||item.key])"></app-filters-list>
                    <span class="actions_header" *ngIf="item.type==8">{{item.name}}</span>
                </div>
            </th>
        </thead>
        <tbody #bd>
            <tr class="cell_row" *ngFor="let row of rows; let rowindex=index">
                <td class="tb_cell">
                    <div (click)="change(row)">
                        <div class="pointer">
                            <app-tick-box [text]="''" [value]="checkboxModel[rowindex].active"></app-tick-box>
                        </div>
                    </div>
                </td>
                <td *ngFor="let i of column; let ii=index" [ngClass]="{
                    'color_head': i.type==5, 'cell_stars': i.type==4,
                    'disable_padding': i.type==5 && column[ii -1].type==5 &&
                    rows.length> 0, 'actions': i.type==8}" (click)="handleClick(i, row)" [class.clickable]="(i.onClick||defaultOnClick)&&i.type!=8">
                    <div *ngIf="i.type == 1">
                        <ng-container *ngIf="((i.name.toLowerCase() == 'provider'  && row[i.key] !== undefined )  && ii> 0)">
                            <!-- {{row[i.key].split('').length > 30? row[i.key].split('').splice(30, row[i.key].length - 1 ).join('') + '...': row[i.key] }} -->
                            {{row[i.key].split('').length > 30? row[i.key].substring(0, 30)+ '...': row[i.key] }}
                        </ng-container>
                        <ng-container *ngIf="i.name.toLowerCase() !== 'provider' || ii == 0">
                            {{row[i.key]}}
                        </ng-container>
                    </div>
                    <div class="cell_comment tab_min_content" *ngIf="i.type ==
                        2" [ngClass]="{'new_com': row[i.key]}">
                        <svg-icon src="../../../assets/svg/message.svg"></svg-icon>
                    </div>
                    <div class="cell_col" *ngIf="i.type == 3">
                        <div class="cell_img" *ngFor="let user of row[i.key] |
                            slice:0:2; let imgCount= index;">
                            <div *ngIf="imgCount <2">
                                <!-- <img src="{{img}}"> -->
                                <app-avatar [model]="getAvatarData(user)"></app-avatar>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;" *ngIf="getColabolatorsCount(row,
                            i.key)> 2">
                            <span>+{{getColabolatorsCount(row, i.key) - 2}}</span>
                        </div>
                    </div>
                    <div class="cell_star" *ngIf="i.type==4">
                        <app-filters-star [rating]="getRating(row, i.key)"></app-filters-star>
                    </div>

                    <div class="cell_color" *ngIf="i.type==5" [ngClass]="{
                        'grey': ['Admin','Not Required',
                        'In Progress','Recertification Upcoming','Disclosures Upcoming'].includes(row[i.key]),
                        'purple': ['IVP Admin','IVP Assessment'].includes(row[i.key]),
                        'd_blue': ['RIAA Admin','RIAA Assessment','More Info'].includes(row[i.key]),
                        'orange':['Collaborator','Outstanding','Declined','Decertified','Disclosures Now Due','Disclosures Outstanding','Expired'].includes(row[i.key]),
                        'l_blue': ['Member','Invoiced','New Application','Recertification Updated','Updated','Material Change','Disclosures Updated'].includes(row[i.key]),
                        'green': ['Paid','Approved'].includes(row[i.key]),
                        'reddish': ['Did not proceed'].includes(row[i.key]) }">{{row[i.key]}}</div>
                    <div class="cell_calleander" *ngIf="i.type==6">
                        {{row[i.key] | date:'dd/MM/yy'}}
                    </div>
                    <div class="cell_number" *ngIf="i.type==7">
                        {{row[i.key]}}
                    </div>
                    <div class="cell_actions" *ngIf="i.type==8">
                        <ng-container>
                            <mat-menu #menu="matMenu" yPosition="below" [overlapTrigger]="false">
                                <ng-container *ngFor="let menuItem of
                                    i.actions">
                                    <button mat-menu-item disableRipple="true" *ngIf="menuItem.condition===undefined||menuItem.condition(row)" (click)="menuItem.action(row)">
                                        <span>{{getLabel(menuItem, row)}}</span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </ng-container>
                        <button #trigger="matMenuTrigger" mat-icon-button [matMenuTriggerFor]="menu"><svg-icon
                                src="../../../assets/svg/table_menu.svg"></svg-icon></button>
                    </div>
                    <div class="cell_col" *ngIf="i.type == 9">
                        {{row[i.key]}}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination_container">
        <app-pagination [currentPage]="currentPage" [pages]="pages" (modelChange)="getCurrPage($event)"></app-pagination>
    </div>
    <div class="ba_container">
        <app-bulk-actions [selectedItems]="selectedValues" [model]="bulkActionsModel"></app-bulk-actions>
    </div>
    <div *ngIf="isContentLoading || rows.length == 0" class="centred_container" [style.width]="container.offsetWidth+ 'px' " [style.height]="container.offsetHeight+ 'px' ">
        <mat-spinner *ngIf="isContentLoading " [strokeWidth]="3 "></mat-spinner>
        <div *ngIf="rows.length==0 && !isContentLoading" class="no_results_container ">
            <span>No results</span>
        </div>
    </div>
</div>