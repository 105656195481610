import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { buildQueryParams, IndexQuery } from "src/app/model/IndexQuery";
import { Application } from "src/app/model/responsible/Application";
import { ShareGroup } from "src/app/model/responsible/ShareGroup";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

interface ApplicationSearchQuery {
    query:string,
    limit?:number,
    users?:string
}

@Injectable({
    providedIn:'root',
})
export class ApplicationAPI{
    constructor(
        private http:HttpClient,
        private epEncryption:EpEncryptionService,
    ){}

    public index(query?:IndexQuery){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}applications${buildQueryParams(query)}`);
    }
    public get(id:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}applications/${id}`);
    }
    public search(query:ApplicationSearchQuery){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}applications/search?${new URLSearchParams(query as any).toString()}`);
    }
    public create(data:Application){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}applications`,
            this.epEncryption.sendPayload(data.responsify()));
    }
    public essentials(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}applications/essentials`);
    }
    public update(data:Application, autosave:boolean){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${data.id}${autosave?'?auto=true':''}`,
            this.epEncryption.sendPayload(data.responsify()));
    }
    public delete(id:number){
        return this.http.delete<CodedResponseModel>(`${environment.apiUrl}applications/${id}`);
    }
    public massDelete(ids:number[]){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}applications/bulkDelete`,
            this.epEncryption.sendPayload({ ids:ids }));
    }
    public togglePublish(id:number){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${id}/publish`, null);
    }
    public changeStatus(id:number, status:string){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${id}/status`,
            this.epEncryption.sendPayload({ status: status }));
    }
    public changeUpdates(id:number, status:string){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${id}/updates`,
            this.epEncryption.sendPayload({ status: status }));
    }
    public changeInvoice(id:number, invoice:string){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${id}/invoice`,
            this.epEncryption.sendPayload({ invoice: invoice }));
    }
    public changeAssigned(id:number, assigned:number[]){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${id}/assign`,
            this.epEncryption.sendPayload({ assignments: assigned }));
    }

    public sharedQuestions(key:string){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}shared/${key}`);
    }
    public shareQuestion(questionId:number, applicationId:number, email:string){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}shared`,
            this.epEncryption.sendPayload({ application: applicationId, question: questionId, email: email }));
    }
    public shareApplication(applicationId:number, email:string){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}shareApp`,
            this.epEncryption.sendPayload({ application: applicationId, email: email }));
    }
    public postShareAnswers(key:string, data:ShareGroup){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}shared/${key}`,
            this.epEncryption.sendPayload(data.responsify()));
    }

    public getPdf(id:number, type?:string){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}applications/pdf?application=${id}${type?'&type='+type:''}`);
    }
    public getExclusionsPdf(id:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}applications/exclusions-pdf?application=${id}`);
    }

    public editCertStartDate(id: number, certStart: string){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${id}/certStart`,
            this.epEncryption.sendPayload({ cert_start: certStart }));
    }
}
