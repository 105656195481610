import { Condition, condition_response } from "./Condition";
import { Keyed } from "./Form";
import { FormQuestion, form_question_response } from "./FormQuestion";

export interface condition_group_response{
    id:number;
    question_id:number;
    target_id:number;

    question?:form_question_response;
    target?:form_question_response;
    conditions?:condition_response[];

    target_key?:string;
    key?:string;
}
export class ConditionGroup extends Keyed{
    public id:number = 0;
    public questionId:number = 0;
    public targetId:number = 0;

    public targetKey:string = '';

    public question?:FormQuestion;
    public target?:FormQuestion;
    public conditions:Condition[] = [];

    public map(data:condition_group_response){
        this.id = data.id;
        this.questionId = data.question_id;
        this.targetId = data.target_id;
        
        if(data.question) this.question = FormQuestion.create(data.question);
        if(data.target) this.target = FormQuestion.create(data.target);
        this.conditions = [];
        for(let c of data.conditions??[])
            this.conditions.push(Condition.create(c));

        return this;
    }
    public responsify():condition_group_response{
        return {
            id: this.id,
            question_id: this.questionId,
            target_id: this.targetId,
            conditions:(()=>{
                let arr:condition_response[] = [];
                for(let c of this.conditions??[])
                    arr.push(c.responsify());
                return arr;
            })(),
            target:this.target?.responsify(),
            question:this.question?.responsify(),

            target_key:this.targetKey,
            key:this.key,
        }
    }
    public static create(data:condition_group_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public syncIds(input:ConditionGroup){
        let found = [], missing = [];
        for(let [k,v] of this.conditions.entries()){
            if(v.id) found.push(v.id);
            else missing.push(k);
        }
        for(let c of missing){
            for(let n of input.conditions??[]){
                if(!found.includes(n.id)){
                    this.conditions[c].id = n.id;
                    this.conditions[c].fieldId = n.fieldId;
                    found.push(n.id);
                    break;
                }
            }
        }
    }

    public answerList:any[] = [];
}