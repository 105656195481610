import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { ShareGroup } from 'src/app/model/responsible/ShareGroup';
import { PartialService } from 'src/app/services/partial.service';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';

@Component({
    selector: 'app-shared-questions',
    templateUrl: './shared-questions.component.html',
    styleUrls: ['./shared-questions.component.scss']
})
export class SharedQuestionsComponent implements OnInit {
    private key:string = '';

    public share:ShareGroup = new ShareGroup();
    public loaded:boolean = false;
    public errorMsg:string = '';

    // public customs:{pCategories:ProductCategory[],pTargets:ProductTarget[],markets:Market[],strategies:RIStrategy[]} = {
    //     pCategories:[],
    //     pTargets:[],
    //     markets:[],
    //     strategies:[],
    // }

    constructor(
        private route:ActivatedRoute,
        private applicationApi:ApplicationAPI,
        private partials:PartialService,
    ){
        this.route.paramMap.subscribe(p => {
            this.key = p.get('key')??'';
            if(!this.key){ 
                this.errorMsg = 'Missing key';
                return;
            }
            this.applicationApi.sharedQuestions(this.key).subscribe(res => {
                let response = CodedResponseModel.decode(res);

                this.share = ShareGroup.create(response.share);

                if(this.share.full){
                    this.share.application?.form?.mapAnswers(this.share.application.answers);
                }

                // this.customs.pCategories = [];
                // for (let c of response.extra.categories)
                //     this.customs.pCategories.push(ProductCategory.create(c));

                // this.customs.pTargets = [];
                // for (let t of response.extra.targets)
                //     this.customs.pTargets.push(ProductTarget.create(t));

                // this.customs.markets = [];
                // for (let m of response.extra.markets)
                //     this.customs.markets.push(Market.create(m));

                // this.customs.strategies = [];
                // for (let s of response.extra.strategies)
                //     this.customs.strategies.push(RIStrategy.create(s));

                this.loaded = true;
            }, err => {
                this.errorMsg = err.error.message;
            })
        })
    }

    ngOnInit(): void {}

    public send(){
        this.applicationApi.postShareAnswers(this.key, this.share).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:res.message, timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error',message:err.error.message, timeout: 5000 });
        })
    }
}
