<app-auth></app-auth>
<app-menu></app-menu>
<div class='container'>
    <div class='form'>
        <h2 *ngIf="new">Add Mail Event</h2>
        <h2 *ngIf="!new">Edit Mail Event</h2>
        <app-input-field [(model)]="mailEvent.event_slug" placeholderText="Slug"></app-input-field>
        <app-input-field [(model)]="mailEvent.subject" placeholderText="Subject"></app-input-field>
        <app-input-field [(model)]="mailEvent.mail_from" placeholderText="From"></app-input-field>
        <app-input-field [(model)]="mailEvent.mail_to" placeholderText="To"></app-input-field>
        <app-large-text-field [(model)]="mailEvent.template" placeholderText="Template" limit="-1" [isTextLimit]="false"></app-large-text-field>
        <!-- <app-input-field [(model)]="mailEvent.mail_log_type" placeholderText="Log Type"></app-input-field> -->
        <div>Values:</div>
        <div class='value' *ngFor="let v of mailEvent.main_values; let i = index; trackBy: trackByIndex">
            <app-input-field [(model)]="mailEvent.main_values[i]" placeholderText="Value"></app-input-field>
            <app-button-secondary [isText]="true" buttonText="Remove" (click)="mailEvent.main_values.splice(i, 1)"></app-button-secondary>
        </div>
        <app-button-secondary class='add-btn' (click)="mailEvent.main_values.push('')" [isText]="true" buttonText="Add" imgUrl="/assets/svg/plus-icon.svg"></app-button-secondary>
    <!-- <div>
        <label>Slug</label>
        <input [(ngModel)]="mailEvent.event_slug">
    </div>
    <div>
        <label>Subject</label>
        <input [(ngModel)]="mailEvent.subject">
    </div>
    <div>
        <label>From:</label>
        <input [(ngModel)]="mailEvent.mail_from">
    </div>
    <div>
        <label>To mailing group:</label>
        <input type='checkbox' [(ngModel)]="mailEvent.mail_to_mailing_groups">
    </div>
    <div>
        <label>To:</label>
        <input *ngIf="!mailEvent.mail_to_mailing_groups" [(ngModel)]="mailEvent.mail_to">
        <div *ngIf="mailEvent.mail_to_mailing_groups">
            <input [(ngModel)]="filter" (input)="setMailing()">
            <div *ngFor="let group of mailingGroup">
                <input type="checkbox" [(ngModel)]="group.checked">
                <div>{{group.name}}</div>
                <div>{{group.users.length}} member(s)</div>
            </div>
        </div>
    </div>
    <div>
        <label>Template:</label>
        <textarea [(ngModel)]="mailEvent.template"></textarea>
    </div>
    <div>
        <label>Template Number:</label>
        <input type="number" [(ngModel)]="mailEvent.template_no">
    </div>
    <div>
        <label>Log type:</label>
        <input type="number" [(ngModel)]="mailEvent.mail_log_type">
    </div>
    <div>
        <label>Main values:</label>
        <div *ngFor="let v of mailEvent.main_values; let i = index; trackBy: trackByIndex">
            <input [(ngModel)]="mailEvent.main_values[i]">
            <button (click)="mailEvent.main_values.splice(i, 1)">Remove</button>
        </div>
        <button (click)="mailEvent.main_values.push('')">Add</button>
    </div> -->
        <app-button-primary text="Save" (click)="save()"></app-button-primary>
        <app-button-secondary [isText]="true" routerLink="/mail-events" buttonText="Return"></app-button-secondary>
    </div>
</div>