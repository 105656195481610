import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-lock-modal',
    templateUrl: './lock-modal.component.html',
    styleUrls: ['./lock-modal.component.scss']
})
export class LockModalComponent implements OnInit {
    constructor(){}
    ngOnInit():void{}
}
