<div class="chat" *ngIf="exists" [class.active]="display">
    <div class="top">
        <div class="heading">
            <div class="back_wrap" (click)="dismiss()">
                <div class="back"></div>
                <app-heading5 [text]="'Back'" [isTextBlack]="false" class="hidden-xs"></app-heading5>
            </div>
            <app-heading3 [text]="loading?'Loading...':conversation.questionId?'Question '+conversation.question?.tag:'General Enquiry'" [isTextBlack]="false"></app-heading3>
        </div>
    </div>
    <div class="chat_content" #chatContentScroll>
        <div class="chat_content_wrap" #chatContent *ngIf="!loading">
            <ng-container *ngFor="let message of chatArray; index as i">
                <div class="single_message" [class.current]="message.user?.id==currentUser" [class.only]="comparePreviousMessage(message, i) && compareNextMessage(message,i)" [class.first]="comparePreviousMessage(message, i) && !compareNextMessage(message,i)" [class.middle]="!comparePreviousMessage(message, i) && !compareNextMessage(message,i)"
                    [class.last]="!comparePreviousMessage(message, i) && compareNextMessage(message,i)">
                    <div class="message_content">
                        <div class="avatar_wrap">
                            <app-avatar [model]="{name: message.user?.fullName, imgUrl:message.user?.avatar?message.user?.avatarURL:''}" *ngIf="!comparePreviousMessage(message, i) && compareNextMessage(message,i) || comparePreviousMessage(message, i) && compareNextMessage(message,i)">
                                <!-- <img src="{{message.user.avatar}}" alt="User avatar" *ngIf="message.user.avatar">
                                <div class="letter" *ngIf="!message.user.avatar">{{message.user?.name | firstLetter}}</div> -->
                            </app-avatar>
                        </div>
                        <div class="message_area">
                            <div class="name" *ngIf="comparePreviousMessage(message, i) && !compareNextMessage(message,i) || comparePreviousMessage(message, i) && compareNextMessage(message,i)">
                                {{message.user?.fullName}}</div>
                            <div class="message" [innerHTML]="message.message"></div>
                        </div>
                    </div>
                </div>
                <app-download-button *ngFor="let attachment of message?.attachments" [fileUrl]="attachment.filename" [fileType]="attachment.getExtension()" 
                    [name]="attachment.label" [fileSize]="attachment.filesize + 'KB'"></app-download-button>
            </ng-container>
        </div>
    </div>
    <div class="message_box">
        <div class="message_wrap">
            <div class="text_wrap">
                <div class="text" contenteditable="true" role="textbox" spellcheck="true" tabindex="0" (keydown.enter)="preventNewLine($event)" (keyup)="hidePlaceholder($event)" (keyup.enter)="submitMessage($event)" (keyup)="typing($event)" #newMessage></div>
                <div class='files'>
                    <div *ngFor="let f of files; let i = index">
                        {{f.filename}}
                        <app-button-secondary [isText]="true" buttonText="Remove" [imgUrl]="'/assets/svg/close.svg'"
                            (click)="files.splice(i, 1)"></app-button-secondary>
                    </div>
                </div>
            </div>
            <div class="placeholder" *ngIf="!isPlaceholderHidden">
                <span>Message...</span>
            </div>
            <div class="attachment">
                <img src="assets/svg/attachment.svg" alt="Attachment icon" (click)="openAttachmentModal(1)">
            </div>
        </div>
        <div class="all_participants" *ngIf="participantsExists" [class.active]="participantsDisplay">
            <div class="participant" (click)="addParticipantToText($event)" *ngFor="let participant of participants">{{participant}}</div>
        </div>
    </div>
</div>