import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  constructor() { }
  @Input() public currentPage: number = 1
  @Input() public pages: number = 1
  @Output() modelChange = new EventEmitter<number>();

  ngOnInit(): void {}
  public goToPage(pageNum: number) {
    if (pageNum <= this.pages && pageNum > 0) this.currentPage = pageNum; this.modelChange.emit(pageNum)
  }
  public counter(current: number) {
    let tmp = []
    if (window.innerWidth >= 768) {
      if (this.pages > 3) {
        if (current == 1) tmp = [current, current + 1, current + 2]
        else if (current == this.pages) tmp = [current - 2, current - 1, current]
        else tmp = [current - 1, current, current + 1]
        return tmp
      }
      else {
        for (let i = 1; i <= this.pages; i++) {
          tmp.push(i)
        }
         return tmp
      }
    }else {
      if(this.pages == 2 && this.currentPage == 2) return [1, 2]
      
     return [current]
    } 
  }

}
