import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tick-box',
  templateUrl: './tick-box.component.html',
  styleUrls: ['./tick-box.component.scss']
})
export class TickBoxComponent implements OnInit {
  @Input() text: string = "no text";
  @Input() public disabled: boolean = false;
  @Input() public value: boolean = false;
  @Input() public isLinks: boolean = false
  @Input() public links: Array<{ value: string, replaceKEY: string, href: string }> = []
  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  public textLink: Array<{ value: string, isLink: boolean, href?: string }> = []
  ngOnInit(): void {
    if (this.isLinks) this.placeLinks()
  }
  public valueEvent() {
    this.valueChange.emit(this.value);
  }
  public placeLinks() {
    this.text.split(" ").forEach(word => {
      if (word !== "") this.textLink.push({ value: word, isLink: false })
    })
    this.links.forEach(link => {
      this.textLink.find(elem => {
        if (link.replaceKEY == elem.value) {
          this.textLink[this.textLink.indexOf(elem)] = { value: link.value, isLink: true, href: link.href }
        }

      })
    })

  }
}