import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filters-comments',
  templateUrl: './filters-comments.component.html',
  styleUrls: ['./filters-comments.component.scss']
})
export class FiltersCommentsComponent implements OnInit {
  @Input() model: any = false;
  @Input() text: string = "new comment"
  @Input() isActive: boolean = false
  @Output() modelChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  public change(newValue: any) {
    this.model = newValue;
    this.modelChange.emit(newValue);
  }

  public clear() {
    this.isActive = false
  }
}
