<app-auth></app-auth>
<app-menu></app-menu>
<!-- <appLoader [showLoader]="showLoader" [showNotyfication]="showNotyfication"
  [notyficationMessage]="notyficationMessage" [notyficationMessageStateModel]="notyficationMessageStateModel">
</appLoader> -->
<div>
    <button routerLink="/user/new">Create new</button>
    <div>
        <input [(ngModel)]="filter" (input)="filterData()">
        <table>
            <thead>
                <th>ID</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Actions</th>
            </thead>
            <tr *ngFor="let user of displayedData">
                <td>{{user.id}}</td>
                <td><a routerLink="/user/{{user.id}}">{{user.name}}</a></td>
                <td>{{user.lname}}</td>
                <td>{{user.email}}</td>
                <!-- <td>{{user.user_role}}</td> -->
                <td>
                    <button routerLink="/user/{{user.id}}/edit">Edit</button>
                    <button (click)="del(user)">Delete</button>
                </td>
            </tr>
        </table>
    </div>
</div>
<div *ngIf="showDeleteModal">
    <p>Are you sure you want to delete the selected item?</p>
    <button (click)="confirmDeletion()">Yes</button>
    <button (click)="showDeleteModal=false">No</button>
</div>