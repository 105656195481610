import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
 import { tab_interface } from 'src/app/core/top-tabs/top-tabs.component';
import { MenuComponent } from 'src/app/menu/menu.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { PartialService } from 'src/app/services/partial.service';
import { UserTypes } from '../../model/User';
import { JwtHelperService } from '@auth0/angular-jwt';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { ConfettiService } from 'src/app/services/confetti.service';
import { ResourceAPI } from 'src/app/services/responsible/resources.service';
import { ResourceFAQ } from 'src/app/model/responsible/ResourceFAQ';
import { ResourceCheck } from 'src/app/model/responsible/ResourceCheck';
import { ResourceFile } from 'src/app/model/responsible/ResourceFile';
import { QuizQuestion } from 'src/app/model/responsible/QuizQuestion'; 
import { Attachment } from 'src/app/model/responsible/Attachment';
import { HttpEventType } from '@angular/common/http';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { LoaderComponent } from '../../partials/loader/loader.component';
import { filter } from 'rxjs/operators';
@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['../../form-builder/form-builder/form-builder.component.scss', './resources.component.scss']
})
export class ResourcesComponent implements OnInit {
    //to focus on time input
    @ViewChild('time') time?: ElementRef
    @ViewChild('ttcinput') ttcinput!: ElementRef;
    
    public userType: UserTypes;

    public faq:ResourceFAQ[] = [];
    public checks:ResourceCheck[] = [];
    public files:ResourceFile[] = [];
    public quiz:QuizQuestion[] = [];
    public settings:any = {
        quiz_title:'',
        quiz_desc:'',
        quiz_time:'',
        quiz_t1_title:'',
        quiz_t1_desc:'',
        quiz_t2_title:'',
        quiz_t2_desc:'',
        quiz_t3_title:'',
        quiz_t3_desc:'',
        quiz_t4_title:'',
        quiz_t4_desc:'',
    }

    public logoFiles:file_interface[] = [];
    public templateFiles:file_interface[] = [];
    // public getUser = new Promise((resolve,reject)=>{
    //      resolve(this.jwt.decodeToken().user.type)
    // })
    public currTab: number = 0;
    public loaded: boolean = false;
    public quizScore:number = 0;

    public expanded: boolean = false;
    public quizOpened: boolean = false;
    public width: any
    public quizCompleted: boolean = false;
    public quizNotCompleted: boolean = false;
    @ViewChild('confetti') newConfetti?: ElementRef;
   
    public tabs: tab_interface[] = [
        { label: 'Resources viewer', onClick: () => { this.setActiveTab(0); }, active: true },
        { label: 'Edit resources', onClick: () => { this.setActiveTab(1); }, active: false },
        { label: 'Edit readiness quiz', onClick: () => { this.setActiveTab(2); }, active: false },
    ];

    constructor(
        private comm: PartialService,
        private jwt: JwtHelperService,
        private confetti: ConfettiService,
        private resourceApi:ResourceAPI,
        private attachmentApi:AttachmentAPI,private router: Router, private activatedRoute: ActivatedRoute
    ){
      //  this.loaded = true;
        MenuComponent.show.emit(false);
       // this.userType = this.jwt.decodeToken().user.type;
        this.userType = this.getUser()
        LoaderComponent.menuHidden.emit(false)
        LoaderComponent.show.emit(true);
        this.fetchData();
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        )
     }
     public getUser(){
         const usr = this.jwt.decodeToken().user.type;
         if(usr !== undefined){
            if(this.userType !== undefined && this.files && this.quiz &&  this.faq &&  this.checks ) LoaderComponent.show.emit(false);
            //if(this.quiz  && this.faq && this.checks ) LoaderComponent.show.emit(false)
            return usr
         }
     }

    private fetchData() {    
        LoaderComponent.show.emit(true);
        this.resourceApi.fetch().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            for(let q of response.quiz)
                this.quiz.push(QuizQuestion.create(q));

            for(let f of response.faq)
                this.faq.push(ResourceFAQ.create(f));

            for(let c of response.checks)
                this.checks.push(ResourceCheck.create(c));

            for(let f of response.files){
                let file = ResourceFile.create(f);
                this.files.push(file);
                if(f.type) this.templateFiles.push({ file:file.file, filename:file.file?.label??file.file?.filename??'', extension:file.file?.getExtension()??'', status:'', progress:100 });
                else this.logoFiles.push({ file:file.file, filename:file.file?.label??file.file?.filename??'', extension:file.file?.getExtension()??'', status:'', progress:100 });
            }
            this.settings = response.settings;
            this.loaded = true;
            console.log(this.faq)
          //  if(this.userType !== undefined && this.files &&   this.quiz &&  this.faq &&  this.checks ) 
          LoaderComponent.show.emit(false);
     
        });
    }
    private setActiveTab(i: number) {
        //LoaderComponent.show.emit(true);
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        )
        if(i == 2 )this.getWidth()
         this.currTab = i;
        this.tabs[i].active = true;
        for (let [j, t] of this.tabs.entries()) if (i != j) t.active = false;
 
    }

    public counter(i: number) {
        return new Array(i);
    }

    public sendData(autosave?: boolean) {
        this.quizOpened = false;

        // Evaluate score
        let total = this.quiz.length,
            correct = 0;
        for(let i of this.quiz) if(i.answer===i.userAnswer) correct++;
        this.quizScore = Math.floor((correct/total)*100);

        if(this.quizScore>60){
            this.quizCompleted = true;
            this.confetti.confetti = this.newConfetti;
            this.confetti.setupConfetti();
        } else {
            this.quizNotCompleted = true;
        }
    }
    public saveChanges(){
        //LoaderComponent.show.emit(true);
        for(let [i, c] of this.checks.entries()) c.order = i;
        for(let [i, f] of this.faq.entries()) f.order = i;
        this.resourceApi.update(this.faq, this.checks, this.files).subscribe(res => {
            this.comm.notificator.emit({ type:'success', message:'Resources have been updated', timeout: 5000 });
            this.setActiveTab(0);
          //  LoaderComponent.show.emit(false);
        }, err => {
            this.comm.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        });
      //  LoaderComponent.show.emit(false);
     }
    public saveQuiz(){
        for(let [i, q] of this.quiz.entries()) q.order = i;
        this.resourceApi.updateQuiz(this.quiz, this.settings).subscribe(res => {
            this.comm.notificator.emit({ type:'success', message:'Quiz has been updated', timeout: 5000 });
            this.setActiveTab(0);
        }, err => {
            this.comm.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        });
       // LoaderComponent.show.emit(false);
    }

    public uploadFile(flist: FileList, type:'logo'|'template') {
        if (flist.item(0)) {
          //  LoaderComponent.show.emit(true);
            if (type==='logo'&&!['image/png', 'image/jpeg', 'application/pdf', 'application/msword'].includes(flist.item(0)!.type)) {
                console.error('Banner image must be of PNG or JPEG file type');
                return;
            }
            let added = false,
                handle: file_interface,
                index = 0,
                target = type==='logo'?this.logoFiles:this.templateFiles;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        index = target.push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        console.log(Math.round(res.loaded / res.total! * 100));
                        target[index].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.files.push(ResourceFile.create({ id:0, file:att, file_id:att.id, type:type==='template' }));
                        target[index].file = att;
                        target[index].progress = 100;
                        break;
                }
            }, err => {
                if (added) {
                    target.splice(index, 1);
                }
                this.comm.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
            });
        }
    }
    public removeFile(file:file_interface, type:'logo'|'templates'){
        let target = type==='logo'?this.logoFiles:this.templateFiles;
        let index = target.indexOf(file);
        if(target[index].file){
            for(let [i, f] of this.files.entries()){
                if(f.fileId===target[index].file!.id){ 
                    this.files.splice(index, 1);
                    break;
                }
            }
        }
        target.splice(index, 1);
    }

    //fosuc ttc
    public ttcFocus() {
        if (this.ttcinput) this.ttcinput.nativeElement.classList.add('focus')
    }
    public ttcBlur() {
        if (this.ttcinput) this.ttcinput.nativeElement.classList.remove('focus')
    }

    public  getWidth() {
        if (window.innerWidth < 768) {
        setTimeout(()=>{ 
            if (this.time !== undefined) {
                if (this.time.nativeElement.value.length !== 0) {
                    this.time.nativeElement.style.width = '0';
                    this.time.nativeElement.style.width = this.time.nativeElement.scrollWidth + 1 + 'px';
                }
                else this.time.nativeElement.style.width = '115px';
                }
        },0)
       }
    }

    public isTablet() {
        if (window.innerWidth < 1025)
            return true;
        else
            return false
    }
    public getOffset(value: boolean) {
        this.expanded = value
    }
    public openQuiz() {
        this.quizOpened = true;
        this.quizCompleted = false;
        this.quizNotCompleted = false;
    }
    public backToResources() {    
        this.quizOpened = false;
        this.quizCompleted = false;
        this.quizNotCompleted = false;
        this.setActiveTab(0);
     }
    public getSupportContentWidth(width: number) {
        if (width) this.width = width + 'px'
    }
    public debug(){
        console.log(this);
    }


    public quizAddQuestion(){
        this.quiz.push(new QuizQuestion());
    }
    public quizRemoveQuestion(q:QuizQuestion){
        this.quiz.splice(this.quiz.indexOf(q), 1);
    }
    public quizMoveUp(index:number){
        let item = this.quiz.splice(index, 1);
        this.quiz.splice(index-1, 0, item[0]);
    }
    public quizMoveDown(index:number){
        let item = this.quiz.splice(index, 1);
        this.quiz.splice(index+1, 0, item[0]);
    }
    public quizPercentage(){
        let total = this.quiz.length;
        let done = 0;
        for(let i of this.quiz) if(i.userAnswer!==undefined) done++;
        return Math.floor((done/total)*100);
    }

    public getQuizTitle(score:number){
        if(score>=90) return this.settings.quiz_t1_title;
        if(score>60) return this.settings.quiz_t2_title;
        if(score>30) return this.settings.quiz_t3_title;
        return this.settings.quiz_t4_title;
    }
    public getQuizDesc(score:number){
        if(score>=90) return this.settings.quiz_t1_desc;
        if(score>60) return this.settings.quiz_t2_desc;
        if(score>30) return this.settings.quiz_t3_desc;
        return this.settings.quiz_t4_desc;
    }

    public getAllTemplates(){
         this.resourceApi.getAllTemplates().subscribe(res => {
            let b = new Blob([res], { type:'application/zip' });

            let url = window.URL.createObjectURL(b);
            let link = document.createElement('a');
            link.href = url;
            link.download = 'templates.zip';
            link.click();
 
        }, err => {
            this.comm.notificator.emit({ type:'error', message:"Could not retrieve the template files", timeout: 5000 });
         });
    }
    public getAllLogos(){
         
        this.resourceApi.getAllLogos().subscribe(res => {
            let b = new Blob([res], { type:'application/zip' });

            let url = window.URL.createObjectURL(b);
            let link = document.createElement('a');
            link.href = url;
            link.download = 'logos.zip';
            link.click();    
         }, err => {
            this.comm.notificator.emit({ type:'error', message:"Could not retrieve the logo files", timeout: 5000 });     
        });
    }
}

export interface accordion {
    title: string;
    desc: string;
}

