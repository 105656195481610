export class MailEvents{
    public id: number = 0;
    public event_slug: string = '';
    public main_values: Array<string> = [];
    public template: string = '';
    public mail_from: string = '';
    public mail_to: string = '';
    public mail_to_a: Array<number> = [];
    public subject: string = '';
    public template_no:number = 0;
    public mail_to_mailing_groups:boolean = false;
    public mail_log_type:number = 0;

    public map(_data: any){
		this.id = _data.id;
        this.event_slug = _data.event_slug;
        this.main_values = _data.main_values.split('|');
		this.template = _data.template;
		this.mail_from = _data.mail_from;
		this.mail_to = _data.mail_to;
		this.subject = _data.subject;
        this.template_no = _data.template_no;
        this.mail_to_mailing_groups = _data.mail_to_mailing_groups;
        this.mail_log_type = _data.mail_log_type;
        if(_data.mailing_groups) 
            for(let group of _data.mailing_groups)
                this.mail_to_a.push(group.id);
	} 
}