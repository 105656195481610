<div class='container' *ngIf="!share.full">
    <div class='header'>
        <a routerLink="/"><img [src]="'/assets/svg/rr-logo-full.svg'" /></a>
    </div>
    <div class='main' *ngIf="loaded">
        <div class='tab'>
            <div class='product'>{{share.application?.productName}}</div>
            <div class='timer'>{{share.remaining}}</div>
        </div>
        <div class='desc'>
            <h1>Shared questions with <span>{{share.email}}</span></h1>
            <p>You have 24 hours to complete these question(s) before the link expires</p>
        </div>
        <app-question *ngFor="let q of share.questions" [question]="q" [buttons]="false"></app-question>
        <app-button-primary text="Save Answers" (click)="send()"></app-button-primary>
    </div>
    <div class='main' *ngIf="errorMsg">
        <div class='desc'>
            <h1>Error!</h1>
            <p>{{errorMsg}}</p>
        </div>
    </div>
</div>
<app-shared-application *ngIf="share.full" [share]="share" [errorMsg]="errorMsg" (send)="send()"></app-shared-application>