import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-slider-template',
    templateUrl: './slider-template.component.html',
    styleUrls: ['./slider-template.component.scss']
})
export class SliderTemplateComponent implements OnInit {
    @Input() public percentage: boolean = false;
    @Input() public max: number = 100;
    @Input() public min: number = 0;
    @Output() public maxChange = new EventEmitter<number>();
    @Output() public minChange = new EventEmitter<number>();

    constructor() { }

    ngOnInit(): void { }
}
