<app-auth></app-auth>
<app-menu [minimise]="false" (expanded)="getOffset($event)"></app-menu>
<div #main class='main' [ngClass]="{'menu_expanded': !expanded}">
    <!-- <ng-container *ngIf="(tabs[0].badge > 0 || tabs[1].badge > 0) && isLoaded"> -->
    <ng-container>
        <app-top-tabs [tabs]="tabs"></app-top-tabs>
        <h4 (click)="debug()">{{tabs[currTab].badge}} products</h4>
        <h1>
            {{currTab?'Ongoing':'New'}} products
            <app-button-secondary *ngIf="userType!==1" [isText]="true" [isGreen]="true" buttonText="Add new Product" [imgUrl]="'assets/svg/plus-icon.svg'" routerLink="/applications/new"></app-button-secondary>
        </h1>
        <div #table class="table_container">
            <app-table *ngIf="isLoaded" [isContentLoading]="filterCooldown !== undefined" [totalElements]="total" (pageChange)="getPage($event)" 
                (filtersChange)="getFilters($event)" [refresher]="refresher" [column]="currTab?ongoingColumns:columns" [currentPage]="currPage"
                [rows]="currTab?ongoingApplications:newApplications" [ngClass]="{'m_expand': expanded}" [defaultOnClick]="openProductCard"
                [bulkActionsModel]="isAdmin?adminBulkActions:userBulkActions" [filter]="query.filters!"></app-table>
        </div>
    </ng-container>
    <div class="no_products" *ngIf="tabs[0].badge == 0 && tabs[1].badge == 0 && isLoaded">
        <app-no-products></app-no-products>
    </div>
</div>