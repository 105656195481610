import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-preuploaded-files',
    templateUrl: './preuploaded-files.component.html',
    styleUrls: ['./preuploaded-files.component.scss']
})
export class PreuploadedFilesComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any
    ){}

    ngOnInit():void{}

}
