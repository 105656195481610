import { Component, ElementRef, Input, OnInit, ViewChild, ViewChildren, AfterViewInit, HostListener } from '@angular/core';
import Player from '@vimeo/player';
import { PartialService } from "src/app/services/partial.service";
import { VimeoService } from "src/app/services/vimeo.service";
import { ConvertVideoTimeService } from "src/app/services/convert-video-time.service"

@Component({
  selector: 'app-vimeo',
  templateUrl: './vimeo.component.html',
  styleUrls: ['../video.component.scss', './vimeo.component.scss']
})
@HostListener('window:resize', ['$event'])

export class VimeoComponent implements OnInit, AfterViewInit {

  @ViewChild('video') video?: ElementRef;
  @Input() index: number = 0
  @Input() videoUrl: string = '';
  @Input() placeholder: string = '.';
  @Input() openModal: boolean = false;
  @Input() isMetadataVisible: boolean = true;
  public closePlaceholder: boolean = false;
  public player: any;
  public vimeoId: any;
  public duration: any;
  public title: string = '';
  public innerWidth: any;
  
  constructor(
    private comm: PartialService,
    private vimeo: VimeoService,
    private videoTime: ConvertVideoTimeService,
  ) { }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    if (this.placeholder === null || this.placeholder === '') {
      this.placeholder = '';
    }
  }
  ngAfterViewInit(): void {
    this.vimeoId = this.getVimeoId(this.videoUrl);
    this.player = new Player(this.video?.nativeElement, {
      id: this.vimeoId,
      width: 640
    });
    this.getVimeoMetadata(this.vimeoId);
  }

  public playVideo() {
    if (this.openModal && this.innerWidth > 767) {
      this.comm.videoModal.emit({ isActive: true, videoUrl: this.videoUrl });
    } else {
      this.closePlaceholder = !this.closePlaceholder;
      this.player.play();
    }
  }

  public getVimeoId(_url: any) {
    var match = /vimeo.*\/(\d+)/i.exec(_url);
    if (match) {
      return match[1];
    } else {
      return;
    }
  }

  public getDuration(duration: any) {
    return this.duration = this.videoTime.convertTime(duration);
  }

  public getVimeoMetadata(id: any) {
    this.vimeo.getVimeoMetadata(id)
      .subscribe((res: any) => {
        this.title = res.title;
        this.duration = this.getDuration(res.duration);
      }, err => {
        console.log(err);
      });
  }

  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
