<div class='support-content'>
    <app-faq-questions [section]="section" [title]="'FAQ'"></app-faq-questions>
    <div class='card'>
        <h2>Videos</h2>
        <!-- <button *ngIf="!section.supportVideos.length" (click)="addNewVideo()">Add</button> -->
        <app-button-secondary [isGreen]="true" [imgUrl]="'assets/svg/plus-icon.svg'" *ngIf="!section.supportVideos.length" (click)="addNewVideo()"></app-button-secondary>

        <div *ngFor="let video of section.supportVideos; let i = index" class='field video'>
            <div class='content'>
                <app-text-field [isTextLimit]="false" [isAutoSize]="true" [(model)]="video.link" (modelChange)="changeMade()" placeholderText="Video Link"></app-text-field>
            </div>
            <div class='controls'>
                <div class='btn-group'>
                    <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNewVideo()"></app-button-secondary>
                    <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="deleteVideo(i)"></app-button-secondary>
                </div>
                <div class='btn-group'>
                    <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/arrow.svg'" (click)="moveVideoUp(i)" [disabled]="i==0" style="transform:rotate(180deg)"></app-button-secondary>
                    <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/arrow.svg'" (click)="moveVideoDown(i)" [disabled]="i==section.supportVideos.length-1"></app-button-secondary>
                </div>
            </div>
        </div>
    </div>
    <app-documents-n-templates [section]="section" [title]="'Documents and Templates'"></app-documents-n-templates>
    <app-checklist [section]="section" [title]="'Checklist'"></app-checklist>
</div>