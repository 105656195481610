import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MailingGroupService } from "../services/mailing-group.service";
import { MailingGroup } from "../model/MailingGroup";
import { CodedResponseModel } from "../model/CodedResponseModel";
import { NotyficationMessageStateModel } from '../model/NotyficationMessageStateModel';

@Component({
  selector: 'app-mailing-group',
  templateUrl: './mailing-group.component.html',
  styleUrls: ['./mailing-group.component.scss']
})
export class MailingGroupComponent implements OnInit {
  //loader
  public showLoader: boolean = false;
  public showNotyfication: boolean = false;
  public notyficationMessage: string = '';
  public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
  //loader END

  public mailingGroupOrginal: Array<MailingGroup> = [];
  public mailingGroup: Array<MailingGroup> = [];

  public filter:string = '';

  constructor(
    private router: Router,
    private mailingGroupService: MailingGroupService
  ) { }

  ngOnInit(): void {
    this.getAll();
  }
  public add() {
    this.router.navigate(['mailing-group/add']);
  }
  public edit(id: number) {
    this.router.navigate(['mailing-group/'+id]);
  }

  private getAll() {
    this.showLoader = true;
    this.mailingGroupService.getAll().subscribe(
      response => {
        this.mailingGroupOrginal = [];
        let codedResponseModel = new CodedResponseModel();
        codedResponseModel.map(response);
        let orginalResponse = codedResponseModel.decodeData();
        if (orginalResponse) {
          orginalResponse.forEach((item: MailingGroup) => {
            let mailing = new MailingGroup();
            mailing.map(item);
            this.mailingGroupOrginal.push(mailing);
          });
        }
        this.setMailing();
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        this.showNotyfication = true;
        this.notyficationMessage = err.error.message;
        this.notyficationMessageStateModel.errors_save = true;
      }
    );
  }

  public setMailing(){
    if(this.filter=='') this.mailingGroup = this.mailingGroupOrginal;
    else{
      let tmp = [],
          lcf = this.filter.toLowerCase();
      for(let i of this.mailingGroupOrginal){
        if(i.name.toLowerCase().includes(lcf))
          tmp.push(i);
      }
      this.mailingGroup = tmp;
    }
  }


}
