import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CodedResponseModel } from "../model/CodedResponseModel";
import { NotyficationMessageStateModel } from "../model/NotyficationMessageStateModel";
import { User } from "../model/User";
import { EpEncryptionService } from "../services/ep-encryption.service";
import { UsersAPIService } from "../services/users.service";

@Component({
    selector:'app-user-show',
    templateUrl:'./show.component.html',
    styleUrls: ['./show.components.scss']
})
export class UserShowComponent{
    //loader
    public showLoader: boolean = false;
    public showNotyfication: boolean = false;
    public notyficationMessage: string = '';
    public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
    public loaded: boolean = false;
    //loader END

    id:number = 0;
    data:User = new User();
    editable:User = new User();
    editMode:boolean = false;
    formErr:string = '';

    public password: string = '';
	public passConfirm: string = '';
    public passwordStrength: any = 'Low';
	public passwordToCheck: string = '';
    public passwordChanged: boolean = false;
	public showTick: boolean = false;
	public isError: boolean = false;
	public error: boolean = false;
	public errorMsg: string = 'Include 8 characters and one special character';

    constructor(
        private userApi:UsersAPIService,
        private route:ActivatedRoute,
        private router:Router,
    ){
        this.loaded = true;
        if(this.route.snapshot.data.editMode) this.editMode = true;
        this.id = Number(this.route.snapshot.paramMap.get('id'));
        this.showLoader = true;
        this.userApi.show(this.id).subscribe(res => {
            let response = new CodedResponseModel();
            response.map(res);
            this.data.map(response.decodeData());
            this.editable = this.data;
            this.showLoader = false;
            console.log(this.data);
        }, err => {
			this.showLoader = false;
			this.showNotyfication = true;
			this.notyficationMessage = err.error.message;
			this.notyficationMessageStateModel.errors_save = true;
		})
    }

    public editToggle(){
        this.editMode = !this.editMode;
    }
    public del(){
        if(confirm("Are you sure you want to delete your account?")){
            this.userApi.delete(this.id).subscribe(res => {
                console.log(res);
                this.router.navigate(['/login']);
            }, err => {
                this.showNotyfication = true;
                this.notyficationMessage = err.error.message;
                this.notyficationMessageStateModel.errors_save = true;
            })
        }
    }
    public saveChanges(){
        try {
            if(!this.editable.name) throw "Username cannot be empty";
            if(!this.editable.email) throw "Email cannot be empty";
            if(this.editable.changePassword){
                if(!this.editable.passConfirm) throw "You need to confirm the new password in order to change it.";
                if(!this.editable.password) throw "You need to input the old password in order to change it.";
                if(!this.editable.newPass) throw "New password cannot be empty";
                if(this.editable.newPass!=this.editable.passConfirm) throw "Passwords don't match";
            }
            this.userApi.edit(this.editable).subscribe(res => {
                console.log(res);
                this.editMode = false;
                this.data = this.editable;
            }, err => {
                this.showNotyfication = true;
                this.notyficationMessage = err.error.message;
                this.notyficationMessageStateModel.errors_save = true;
            })
        } catch(e){ this.formErr = e }
    }
    public passwordComparsion() {
        console.log(this.password, this.passConfirm)
		if (this.password === this.passConfirm && this.passConfirm.length > 0) {
			this.showTick = true;
		} else {
			this.showTick = false;
		}
	}
	public passwordRule(pass: string) {
		if (pass.length > 7 && /\W/.test(pass))
			return true;
		else
			return false;
	}
}