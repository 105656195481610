import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { User, UserTypes } from 'src/app/model/User';
import { PartialService } from 'src/app/services/partial.service';
import { UsersAPIService } from 'src/app/services/users.service';

import { LoaderComponent } from '../../../partials/loader/loader.component';

@Component({
    selector: 'app-edit-admin',
    templateUrl: './edit-admin.component.html',
    styleUrls: ['../admins.component.scss', './edit-admin.component.scss']
})
export class EditAdminComponent implements OnInit {
    @Input() public target:number = 0;
    @Input() public editing:boolean = false;
    public user: User = new User();
    public isIVP:boolean = false;

    public adminTypes = [
        { name:'RIAA Admin', type:UserTypes.RIAA },
        { name:'IVP Admin', type:UserTypes.IVP },
    ];

    constructor(
        private userApi: UsersAPIService,
        private partials: PartialService,
        private jwt:JwtHelperService,
        private router: Router,
    ){
        this.isIVP = this.jwt.decodeToken().user.type===UserTypes.IVP;
    }

    ngOnInit():void{
        if(this.editing == false) LoaderComponent.show.emit(false);
        if(this.editing&&this.target){
            this.userApi.show(this.target).subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.user = User.create(response);
                if(this.user.type!==UserTypes.IVP&&this.isIVP) this.router.navigate(['/admins']);
                LoaderComponent.show.emit(false);
            });
        } else if(this.isIVP) {
            this.user.type = UserTypes.IVP;
         }
    }

    public send(){
        if(this.editing&&this.target){
            this.userApi.edit(this.user).subscribe(res => {
                this.partials.notificator.emit({ type:'success', message:"Admin has been updated", timeout: 5000 });
                this.router.navigate(['/admins']);
            }, err => {
                this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
            });
        } else {
            this.userApi.create(this.user).subscribe(res => {
                this.partials.notificator.emit({ type:'success', message:'Admin has been created', timeout: 5000 });
                this.router.navigate(['/admins']);
            }, err => {
                this.partials.notificator.emit({ type:'error',  message:err.error.message, timeout: 5000 });
            });
        }
    }

    public del(pass:string) {
        this.userApi.standardDelete(this.user.id, { password: pass }).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Admin has been deleted', timeout: 5000 });
            this.router.navigate(['/admins']);
        }, err => {
            this.partials.notificator.emit({ type: 'error', message:err.error.message, timeout: 5000 });
        });
    }
}
