<div class="icon_header">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='body'>
    <h4>Approve for IVP</h4>
    <h3>{{data.application.productName}}</h3>
    <div class='form' *ngIf="!isIVP">
        <app-form-select (click)="setRef($event)" [list]="approvalConditions" placeholderText="Approval Condition" [(model)]="selectedCondition"></app-form-select>
        <app-form-select (click)="setRef($event)" [list]="finalScores" placeholderText="Final Scoring" [(model)]="selectedScore"></app-form-select>
        <app-text-field placeholderText="Comments" [model]="assessment.comments||''" (modelChange)="assessment.comments=$event" [limit]="1000" [multiline]="true"></app-text-field>
        <app-drag-drop [fileList]="documents" (fileEvent)="uploadDocument($event)" (fileRemoved)="deleteDocument($event)" (openModal)="openUploader()"></app-drag-drop>
        <p>For approval and conditional approval, select an IVP admin to start their assessment</p>
        <app-form-select (animationEnd)="scroll($event)" (click)="setRef($event)" placeholderText="Select an IVP" [list]="ivps" displayProperty="fullName" [(model)]="assessment.ivp"></app-form-select>
        <!-- <div class="stars">
            <h3>Set Star Rating</h3>
            <app-filters-star [rating]="assessment.stars||0" (ratingChange)="assessment.stars=$event"></app-filters-star>
        </div> -->
    </div>
    <div class='form' *ngIf="isIVP">
        <app-form-select [list]="approvalConditions" placeholderText="Approval Condition" [(model)]="selectedCondition"></app-form-select>
        <!-- <app-large-text-field [model]="assessment.procedures||''" (modelChange)="assessment.procedures=$event" placeholderText="Procedures performed"></app-large-text-field>
        <app-large-text-field [model]="assessment.findings||''" (modelChange)="assessment.findings=$event" placeholderText="Factural findings"></app-large-text-field>
        <app-large-text-field [model]="assessment.errors||''" (modelChange)="assessment.errors=$event" placeholderText="Errors or Exceptions identified"></app-large-text-field> -->
        <app-text-field [multiline]="true" [model]="assessment.procedures||''" (modelChange)="assessment.procedures=$event" placeholderText="Procedures performed"></app-text-field>
        <app-text-field [multiline]="true" [model]="assessment.findings||''" (modelChange)="assessment.findings=$event" placeholderText="Factual findings"></app-text-field>
        <app-text-field [multiline]="true" [model]="assessment.errors||''" (modelChange)="assessment.errors=$event" placeholderText="Errors or Exceptions identified"></app-text-field>
        <app-drag-drop [fileList]="documents" (fileEvent)="uploadDocument($event)" (fileRemoved)="deleteDocument($event)" (openModal)="openUploader()"></app-drag-drop>
    </div>
    <app-button-primary text="Approve" (click)="confirm()"></app-button-primary>
    <app-button-secondary [isGreen]="false" [isText]="true" buttonText="Cancel" [mat-dialog-close]="false"></app-button-secondary>
</div>