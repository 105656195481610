<div *ngIf="showLoader" class="loader loader--style5" style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; background-color: rgba(255,255,255,0.8); z-index: 9999">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50; position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto;" xml:space="preserve">
    <rect x="0" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="10" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.2s" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="20" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.4s" dur="0.6s" repeatCount="indefinite" />
    </rect>
  </svg>
</div>

<div class="small_loader" [class.active]="showNotyfication" [class.error]="notyficationMessageStateModel.errors_save"
  [class.autosave]="notyficationMessageStateModel.save_info" (click)="hideNotyfication()">

  <div class="error" *ngIf="notyficationMessageStateModel.errors_save">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    {{notyficationMessage}}
  </div>
  <div class="autosave" *ngIf="notyficationMessageStateModel.save_info">
    <i class="fa fa-check-square-o" aria-hidden="true"></i>
    {{notyficationMessage}}
  </div>

</div>