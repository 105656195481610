import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../services/ep-encryption.service";

export class CodedResponseModel {
    private readonly decryptPayload = environment.decryptPayload;

	private epEncryptionService: EpEncryptionService = new EpEncryptionService();


	public success: boolean = false;
	public data: string = '';
	public message: string = '';


	public map(_data: any){
		this.success = _data.success;
		this.data = _data.data;
		this.message = _data.message;
	}
	public decodeData(){
		if(this.decryptPayload){
			return this.epEncryptionService.decrypt(this.data);
		}else{
			return this.data;
		}
	}

	public static create(data:any){
		let r = new this;
		r.map(data);
		return r;
	}
	public static decode(data:any){
		return this.create(data).decodeData();
	}
}
