export interface answer_history_response {
    id:number;
    answer_id:number;
    value:string;
    created_at?:string;
}

export class AnswerHistory {
    public id:number = 0;
    public answerId:number = 0;
    public value:any;
    public createdAt:Date = new Date();

    
    public map(data:answer_history_response){
        this.id = data.id;
        this.answerId = data.answer_id;
        this.value = JSON.parse(data.value);
        this.createdAt = new Date(data?.created_at ?? '');
        
        return this;
    }
    
    public responsify():answer_history_response {
        return {
            id: this.id,
            answer_id: this.answerId,
            value: JSON.stringify(this.value),
        }
    }


    public static create(data:answer_history_response){
        let r = new this;
        return r.map(data);
    }
}