import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { CodedResponseModel } from '../model/CodedResponseModel';
import { Application } from '../model/responsible/Application';
import { Attachment } from '../model/responsible/Attachment';
import { User, UserTypes } from '../model/User';
import { BodyScrollService } from '../services/body-scroll.service';
import { PartialService } from '../services/partial.service';
import { ApplicationAPI } from '../services/responsible/application.service';
import { UsersAPIService } from '../services/users.service';
 interface ProductCardCall{
    visibility?:boolean, 
    product?:Application,
}

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {
    public activeFilter: string = 'details';
    public exists: boolean = false;
    public display: boolean = false;
    public searchPhrase: string = '';

    public product:Application = new Application();
    //public userType:UserTypes = UserTypes.Member;
    public userTypes = UserTypes;
    public newStatuses = Application.newStatuses;
  
     public statuses:any[] = [
        { name:'In Progress', active:false },
        { name:'New Application', active:false },
        { name:'RIAA Assessment', active:false },
        { name:'IVP Assessment', active:false },
        { name:'More Info', active:false },
        { name:'Approved', active:false },
        { name:'Conditional', active:false },
        { name:'Declined', active:false },
        { name:'Decertified', active:false },
        { name:'Did not proceed', active:false }
    ]
    public invoices:any[] = [
        { name:'Not Required', active:false },
        { name:'Invoiced', active:false },
        { name:'Expired', active:false },
    ]
    public updates:any[] = [
        { name:'Updated', active:false },
        { name:'Recertification Upcoming', active:false },
        { name:'Recertification Updated', active:false },
        { name:'Disclosures Upcoming', active:false },
        { name:'Disclosures Now Due', active:false },
        { name:'Disclosures Updated', active:false },
        { name:'Disclosures Outstanding', active:false },
        { name:'RIAA Assessment', active:false },
        { name:'Approved', active:false },
        { name:'Conditional', active:false },
        { name:'Declined', active:false },
    ]
    public admins:User[] = [];

    private statusCd:any;
    private updatesCd:any;
    private invoiceCd:any;
    private assignCd:any;
    private subs:Subscription[] = [];


    public selectRef: any
    //filelist
    public searchResults: Attachment[] = []

    public static exec = new EventEmitter<ProductCardCall>();

    constructor(
        public bodyScroll: BodyScrollService,
        private applicationApi: ApplicationAPI,
        private userApi:UsersAPIService,
        private partials:PartialService,
        private jwt:JwtHelperService,
    ){
        //this.userType = this.jwt.decodeToken().user.type;
        this.subs.push(ProductCardComponent.exec.subscribe(r => {
            if(r.product)
                this.product = r.product;
            
            if(r.visibility){
                this.exists = true;
                if(this.display) this.bodyScroll.disableBodyScroll()
                setTimeout(() => { 
                    this.display = true; 
                    this.bodyScroll.disableBodyScroll();
                }, 50);
                if(!this.admins.length){
                    this.userApi.index({ filters:{ type:`${UserTypes.RIAA};${UserTypes.IVP}` } }).subscribe(res => {
                        let response = CodedResponseModel.decode(res);
                        for(let u of response.data)
                            this.admins.push(User.create(u));
                    })
                }
            }
        }));
    }

    ngOnInit(): void {
        this.searchResults = this.product.files;
        this.bodyScroll.enableBodyScroll()
    }
    ngOnDestroy(){
        for(let s of this.subs) s.unsubscribe();
    }

    public filterNotifs(filter: string) {
        this.activeFilter = filter;
    }

    public getSearchPhrase(value?: any) {
        if (value) {
            this.searchResults = this.searchResults.filter(item => {
                if(item.label) item.label.includes(value.target.value.toLowerCase().trim());
                else item.filename.includes(value.target.value.toLowerCase().trim());
            });
            if (this.searchResults.length == 0 || value.target.value.length == 0) this.searchResults = this.product.files;
        } else this.searchResults = this.product.files;
    }

    public getAssigned(list: any) {
        if(this.assignCd) clearTimeout(this.assignCd);
        this.assignCd = setTimeout(() => {
            console.log(list);
        }, 800);
    }

    public changeStatus(status:{ name:string, active:boolean }){
        if(this.statusCd) clearTimeout(this.statusCd);
        this.statusCd = setTimeout(() => {
            let old = this.product.status;
            this.product.status = status.name;
            this.applicationApi.changeStatus(this.product.id, status.name).subscribe(res => {
                console.log('ok');
            }, err => {
                this.product.status = old;
                console.error(err);
            });
        }, 800);
    }
    public changeUpdate(status:{ name:string, active:boolean }){
        if(this.updatesCd) clearTimeout(this.updatesCd);
        this.updatesCd = setTimeout(() => {
            let old = this.product.updateStatus;
            this.product.updateStatus = status.name;
            this.applicationApi.changeUpdates(this.product.id, status.name).subscribe(res => {
                console.log('ok');
            }, err => {
                this.product.updateStatus = old;
                console.error(err);
            });
        }, 800);
    }
    public changeInvoice(invoice:{ name:string, active:boolean }){
        if(this.invoiceCd) clearTimeout(this.invoiceCd);
        this.invoiceCd = setTimeout(() => {
            let old = this.product.invoice;
            this.product.invoice = invoice.name;
            this.applicationApi.changeInvoice(this.product.id, invoice.name).subscribe(res => {
                console.log('ok');
            }, err => {
                this.product.invoice = old;
                console.error(err);
            });
        }, 800);
    }
    public changeAssigned(assigned:number[]){
        console.log('caught', assigned);
        if(this.assignCd) clearTimeout(this.assignCd);
        this.assignCd = setTimeout(() => {
            let old = [ ...this.product.assigned ];
            let newAssigned = this.admins.filter(a => assigned.includes(a.id));
            this.product.assigned = newAssigned;
            this.applicationApi.changeAssigned(this.product.id, assigned).subscribe(res => {
                console.log('ok');
            }, err => {
                this.product.assigned = old;
                console.error(err);
            })
        }, 800);
    }
    public assigneds(){
        return [...this.product.assigned];
    }

    public dismiss(){
        this.display = false;
         setTimeout(() => { 
            this.exists = false;
         }, 300);
    }
    public getRef(item: any){
        this.selectRef = item
    }
    public scroll() {
        if(this.selectRef !== undefined){
            if(this.selectRef.path !== undefined){ 
                this.selectRef.path[2].scrollIntoView({behavior: "smooth"})
               }else if(this.selectRef.target !== undefined){
                this.selectRef.target.scrollIntoView({behavior: "smooth"})
               }else if(this.selectRef) {
                    try{this.selectRef.scrollIntoView({behavior: "smooth"})}
                    catch(err){
                     }
               }
        }
    }

    public get userType(){
        return this.jwt.decodeToken()?.user?.type??UserTypes.Member;
    }
}
