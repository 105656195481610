import { Application, application_response } from "./Application";
import { ConversationMessage, conversation_message_response } from "./ConversationMessage";
import { FormQuestion, form_question_response } from "./FormQuestion";
import { FormSection, form_section_response } from "./FormSection";

export interface conversation_response{
    id:number;
    application_id:number;
    question_id?:number;

    messages?:conversation_message_response[];
    question?:form_question_response;
    section?:form_section_response;
    application?:application_response;
    last_message?:conversation_message_response;
}
export class Conversation{
    public id:number = 0;
    public applicationId:number = 0;
    public questionId?:number;

    public messages:ConversationMessage[] = [];
    public question?:FormQuestion;
    public section?:FormSection;
    public application?:Application;
    public lastMessage?:ConversationMessage;

    public map(data:conversation_response){
        this.id = data.id;
        this.applicationId = data.application_id;
        this.questionId = data.question_id;

        this.messages = [];
        for(let m of data.messages??[])
            this.messages.push(ConversationMessage.create(m));

        if(data.question) this.question = FormQuestion.create(data.question);
        if(data.application) this.application = Application.create(data.application);
        if(data.section) this.section = FormSection.create(data.section);
        if(data.last_message) this.lastMessage = ConversationMessage.create(data.last_message);
        else this.lastMessage = (() => { let max:any; for(let m of this.messages) if(!max||(m.createdAt??new Date(0))>max.createdAt) max = m; return max; })()

        return this;
    }
    public responsify():conversation_response{
        return {
            id: this.id,
            application_id: this.applicationId,
            question_id: this.questionId,

            messages:(() => {
                let arr:conversation_message_response[] = [];
                for(let m of this.messages) arr.push(m.responsify());
                return arr;
            })()
        }
    }
    public static create(data:conversation_response){
        let r = new this;
        r.map(data);
        return r;
    }
}