import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { Assessment } from "src/app/model/responsible/Assessment";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root'
})
export class AssessmentAPI{
    constructor(
        private http:HttpClient,
        private encryptor:EpEncryptionService,
    ){}

    public initAssessment(id:number){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}applications/${id}/assess`, null);
    }
    public autosave(id:number, data:Assessment){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}assessment/${id}/autosave`,
            this.encryptor.sendPayload(data.responsify()));
    }
    public assess(id:number, data:Assessment){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}applications/${id}/assess`,
            this.encryptor.sendPayload(data.responsify()));
    }
    public massAssess(ids:number[], assessment:Assessment){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}applications/bulkAssess`,
            this.encryptor.sendPayload({ ids:ids, assess:assessment }));
    }

    public getPdf(id:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}assessment/pdf?application=${id}`);
    }
}
