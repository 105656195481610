<div class="icon_header_u">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='body'>
    <h4>Question {{data.qIndex}} / {{data.field.type=='single-line'?"Single":"Long"}} File Upload {{data.field.order}}</h4>
    <h3>File types</h3>
    <div class="tb_container">
        <app-tick-box [(value)]="documents" text="Documents"></app-tick-box>
        <app-tick-box [(value)]="images" text="Images"></app-tick-box>
        <app-tick-box [(value)]="spreadsheets" text="Spreadsheets"></app-tick-box>
    </div>
    <app-button-primary buttonContent="Save" [mat-dialog-close]="{documents:documents,images:images,spreadsheets:spreadsheets}"></app-button-primary>
    <app-button-secondary [isGreen]="false" [isText]="true" buttonText="Cancel" [mat-dialog-close]="false"></app-button-secondary>
</div>