<div class="text_wrap center">
    <h1>Edit Market Filters</h1>
</div>
<div class="items_wrap">
    <div class="checkboxes_titles">
        <div class="title">General</div>
        <div class="title">Advisors</div>
    </div>
    <div class="item" *ngFor="let m of markets">
        <div class="row1">
            <app-tick-box [text]="''" [(value)]="m.active"></app-tick-box>
            <app-tick-box [text]="''" [(value)]="m.advisors"></app-tick-box>
        </div>
        <div class="inputs_wrap">
            <app-input-field [placeholderText]="'Market name'" [class]="'full'" [(model)]="m.name"></app-input-field>
        </div>
        <div class='btn_group'>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNewMarket()"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="deleteMarket(m)"></app-button-secondary>
        </div>
    </div>
</div>
<div class="cta_wrap">
    <app-button-primary text="Save" (click)="saveMarkets()"></app-button-primary>
    <div class="grey_btn" routerLink="/admins">cancel</div>
</div>

<div class="text_wrap center">
    <h1>Edit Product Target Filters</h1>
</div>
<div class="items_wrap">
    <div class="checkboxes_titles">
        <div class="title">General</div>
        <div class="title">Advisors</div>
    </div>
    <div class="item" *ngFor="let t of targets">
        <div class="row1">
            <app-tick-box [text]="''" [(value)]="t.active"></app-tick-box>
            <app-tick-box [text]="''" [(value)]="t.advisors"></app-tick-box>
        </div>
        <div class="inputs_wrap">
            <app-input-field [placeholderText]="'Target name'" [class]="'full'" [(model)]="t.name"></app-input-field>
        </div>
        <div class='btn_group'>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNewTarget()"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="deleteTarget(t)"></app-button-secondary>
        </div>
    </div>
</div>
<div class="cta_wrap">
    <app-button-primary text="Save" (click)="saveTargets()"></app-button-primary>
    <div class="grey_btn" routerLink="/admins">cancel</div>
</div>

<div class="text_wrap center">
    <h1>Edit RI Strategies Filters</h1>
</div>
<div class="items_wrap">
    <div class="checkboxes_titles">
        <div class="title">General</div>
        <div class="title">Advisors</div>
    </div>
    <div class="item" *ngFor="let s of strategies">
        <div class="row1">
            <app-tick-box [text]="''" [(value)]="s.active"></app-tick-box>
            <app-tick-box [text]="''" [(value)]="s.advisors"></app-tick-box>
        </div>
        <div class="inputs_wrap">
            <app-input-field [placeholderText]="'Strategy name'" [class]="'full'" [(model)]="s.name"></app-input-field>
        </div>
        <div class='btn_group'>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNewStrategy()"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="deleteStrategy(s)"></app-button-secondary>
        </div>
    </div>
</div>
<div class="cta_wrap">
    <app-button-primary text="Save" (click)="saveStrategies()"></app-button-primary>
    <div class="grey_btn" routerLink="/admins">cancel</div>
</div>

<div class="text_wrap center">
    <h1>Edit Asset Claases Filters</h1>
</div>
<div class="items_wrap">
    <div class="checkboxes_titles">
        <div class="title">General</div>
        <div class="title">Advisors</div>
    </div>
    <div class="item" *ngFor="let c of classes">
        <div class="row1">
            <app-tick-box [text]="''" [(value)]="c.active"></app-tick-box>
            <app-tick-box [text]="''" [(value)]="c.advisors"></app-tick-box>
        </div>
        <div class="inputs_wrap">
            <app-input-field [placeholderText]="'Class name'" [class]="'full'" [(model)]="c.name"></app-input-field>
        </div>
        <div class='btn_group'>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNewClass()"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="deleteClass(c)"></app-button-secondary>
        </div>
    </div>
</div>
<div class="cta_wrap">
    <app-button-primary text="Save" (click)="saveClasses()"></app-button-primary>
    <div class="grey_btn" routerLink="/admins">cancel</div>
</div>