import { ElementRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss']
})
export class ConfettiComponent implements OnInit {
  @ViewChild('confetti') confetti?: ElementRef

  constructor() { }

  ngOnInit(): void {
    this.setupConfetti()
  }
  setupConfetti() {
    const colors = ['#4159D7', '#0098BA', '#993AC6', '#D68000', '#4BBD48', '#CB4546'];
    const animations = ['s', 'm', 'f'];
    this.renderConfetti(colors, animations)
}
renderConfetti(colors: Array<any>, animations: Array<any>) {
    const time = setInterval(() => {
        const elem: any = document.createElement('div')
        const elemS = (Math.floor(Math.random() * 3) + 7) + 'px';
        const colorsC = colors[Math.floor(Math.random() * colors.length)];
        let spaceLeft
        if (this.confetti) spaceLeft = (Math.floor(Math.random() * this.confetti.nativeElement.offsetWidth)) + 'px';
        const confettiAnimation = animations[Math.floor(Math.random() * animations.length)];
        elem.classList.add('confetti', 'c-a-' + confettiAnimation);
        elem.style.left = spaceLeft;
        elem.style.width = elemS;
        elem.style.height = elemS;
        elem.style.backgroundColor = colorsC;
        this.confetti?.nativeElement.appendChild(elem)
        setTimeout(() => {
            if (this.confetti) this.confetti.nativeElement.removeChild(elem)
        }, 3000)
    }, 20)
    setTimeout(() => {
        clearInterval(time)
    }, 3000)
}
}
