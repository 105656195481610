import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

type DateRange = { startDate: Moment, endDate: Moment };

@Component({
    selector: 'app-single-calendar',
    templateUrl: './single-calendar.component.html',
    styleUrls: ['./single-calendar.component.scss']
})
export class SingleCalendarComponent implements OnInit {
    @Input() public label = '';
    @Input() public dropDown = false;
    @Input() public relative = false;
    @Input() public disabled = false;
    @Input() public fullwidth = false;
    @Input() public input?: string;
    @Input() public model = '';
    @Output() public modelChange = new EventEmitter<string>();

    @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective?: DaterangepickerDirective;

    public selection?: DateRange;

    public days: any = {
        daysOfWeek: ['S', 'M', 't', 'w', 't', 'f', 's'],
        format: 'DD-MM-YYYY',
        monthNames: moment.months(),
        inline: true,
        adaptivePosition: true
    };

    constructor(
        private el: ElementRef
    ) {}

    ngOnInit(): void {
        if(!this.model)
            this.model = moment().format('DD-MM-YYYY');

        this.selection = {
            startDate: moment(this.model, 'DD-MM-YYYY').startOf('day'),
            endDate: moment(this.model, 'DD-MM-YYYY').endOf('day')
        };
    }

    openDatepicker(): void {
        if (this.pickerDirective) {
            this.el.nativeElement = document.getElementsByClassName('md-drppicker');
            this.pickerDirective.open();
        } else {
            this.openDatepicker();
        }
    }

    change(event: any): void {
        this.model = event.startDate.format('DD-MM-YYYY');
        console.log(this.model);
        this.modelChange.emit(this.model);
    }

}
