import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PartialService } from "src/app/services/partial.service";
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { NotificationAPI } from 'src/app/services/responsible/notifications.service';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Notification, NotificationType } from 'src/app/model/responsible/Notification';
import { MenuComponent } from '../menu.component';
import { ProductCardComponent } from 'src/app/product-card/product-card.component';

export interface NotificationsCall {
    isActive: boolean;
}
@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    public activeFilter: string = 'all';
    public notifications:Notification[] = [];
    public original:Notification[] = [];
    public timestamp:Date = new Date();
    public loaded:boolean = false;

    public exists: boolean = false;
    public display: boolean = false;
    @Output() public showOverlay = new EventEmitter<number>();

    constructor(
        private comm: PartialService,
        private bodyScroll: BodyScrollService,
        private notificationApi:NotificationAPI,
    ){
        if(!localStorage.getItem('notifView')) localStorage.setItem('notifView', `${new Date().getTime()}`);
        this.timestamp = new Date(Number(localStorage.getItem('notifView')!));
        this.notificationApi.fetch().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.notifications = [];
            for(let n of response)
                this.notifications.push(Notification.create(n));

            this.confirmUnread();
        });
        this.comm.notifications.subscribe(res => {
            if (res.isActive) {
                this.loaded = false;
                this.bodyScroll.disableBodyScroll();
                this.timestamp = new Date(Number(localStorage.getItem('notifView')!));
                this.notificationApi.fetch().subscribe(res => {
                    let response = CodedResponseModel.decode(res);

                    this.notifications = [];
                    for(let n of response)
                        this.notifications.push(Notification.create(n));
                    this.original = [...this.notifications];

                    this.loaded = true;

                    localStorage.setItem('notifView', `${new Date().getTime()}`);
                });
                this.exists = true;
                this.showOverlay.emit();
                setTimeout(() => { this.display = true }, 20);
            }
            if (!res.isActive) {
                this.dismiss();
                this.timestamp = new Date(Number(localStorage.getItem('notifView')!));
                this.confirmUnread();
            }
        })
    }

    ngOnInit():void{}

    public dismiss() {
        this.bodyScroll.enableBodyScroll();
        this.display = false;
        setTimeout(() => { this.exists = false; }, 400);
    }

    public filterNotifs(filter: string) {
        this.activeFilter = filter;
        let aw = () => {
            if(this.loaded){
                switch(filter){
                    case 'all':
                        this.notifications = [...this.original];
                        break;
                    case 'new':
                        this.notifications = [];
                        for(let n of this.original)
                            if(n.type===NotificationType.New) this.notifications.push(n);
                        break;
                    case 'ongoing':
                        this.notifications = [];
                        for(let n of this.original)
                            if(n.type===NotificationType.Ongoing) this.notifications.push(n);
                        break;
                }
            } else setTimeout(() => { aw() }, 150);
        }
        aw();
    }

    public counter(i: number) {
        return new Array(i);
    }

    public confirmUnread(){
        for(let i of this.notifications)
            if(i.createdAt>this.timestamp){ 
                console.log('new');
                return MenuComponent.unreadNotifs.emit(true);
            }
        return MenuComponent.unreadNotifs.emit(false);
    }
    public clearNotif(notif:Notification){
        let index = this.notifications.indexOf(notif);
        let item = this.notifications.splice(index, 1);
        this.notificationApi.clear(notif.id).subscribe(res => {
            console.log('ok');
        }, err => {
            this.notifications.splice(index, 0, item[0]);
            console.error(err);
        })
    }
    public clickNotif(notif:Notification){
        this.dismiss();
        MenuComponent.hideOverlay.emit();
        if(notif.conversationId){
            this.comm.conversations.emit({ isActive:true, name:notif.product?.productName, applicationId:notif.productId, formId:notif.product?.formId, cId: notif.conversationId });
            MenuComponent.chatViewOpen.emit(true);
        } else
            ProductCardComponent.exec.emit({ visibility:true, product:notif.product });
    }
}
