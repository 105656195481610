import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-share-question',
    templateUrl: './share-question.component.html',
    styleUrls: ['./share-question.component.scss']
})
export class ShareQuestionComponent implements OnInit {
    public email:string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any
    ){}
    ngOnInit():void{}
}
