<app-auth></app-auth>
<app-menu [minimizable]="false" [exists]="false" [display]="false"></app-menu>
<main role="main">
    <div class="main_wrap">
        <app-form-menu [isAssessment]="true" [isEnabled]="assessment.id!=0" [display]="true" [form]="assessment.type==1?ivpSections:form"
            [currSection]="currSection" (changeTab)="currSection=$event; scrollToTop()" [overview]="true" [cap]="true"
            [certStart]="application.certStart"></app-form-menu>
        <!--* Overview Section *-->
        <div class='offset app-overview start' *ngIf="currSection==-1">
            <div class='form'>
                <app-message [product]="application"></app-message>
                <h4>Let's get started</h4>
                <h1>Start your application</h1>
                <app-text-field [isDisabled]="true" [isAutoSize]="true" placeholderText="Product name" [model]="application.productName"></app-text-field>
                <div class='banner'>
                    <h4>Banner</h4>
                    <img [src]="application.banner?.getUrl()">
                </div>
                <app-large-text-field [isTextLimit]="false" [isDisabled]="true" placeholderText="Product description" [model]="application.description"></app-large-text-field>
                <app-text-field [isDisabled]="true" [isAutoSize]="true" [model]="application.provider?.name||''" placeholderText="Provider"></app-text-field>
                <app-text-field [isDisabled]="true" [isAutoSize]="true" [model]="collaborators" placeholderText="Collaborators"></app-text-field>

                <app-form-select [isDisabled]="!isAdmin" [list]="sustainabilityClasses" displayProperty="label" placeholderText="Select Sustainability Classification"
                    [(model)]="application.sustainability" (modelChange)="applicationChangesMadeSubject.next()"
                ></app-form-select>

                <h4>Preuploaded files</h4>
                <div class='files'>
                    <app-download-button *ngFor="let p of preuploads" [fileType]="p.extension" [name]="p.file?.label || p.filename"
                        [fileSize]="''+p.file?.filesize" [fileUrl]="p.file?.getUrl()"></app-download-button>
                </div>
                <app-button-primary text="Begin assessment" (click)="beginAssessment()"></app-button-primary>
                <app-message class="notifi"></app-message>
            </div>
        </div>
        <!--* Form Sections *-->
        <div class='offset completing' *ngIf="currSection>=0">
            <app-message [isLeft]="true" [product]="application"></app-message>
            <div class='main'>
                <div class="progres" (click)="debug()">
                    <div class='pname'>
                        <span>{{application.productName}}</span>
                        <button *ngIf="application.certStart" (click)="showAll=true" [class.active]="showAll">Show All</button>
                        <button *ngIf="application.certStart" (click)="showAll=false" [class.active]="!showAll">Only Updated</button>
                    </div>
                </div>
                <div class='top'>
                    <h4 *ngIf="sections[currSection].time">Time to complete: {{sections[currSection].time}}</h4>
                    <h1>{{sections[currSection].title}}</h1>
                    <p>{{sections[currSection].description||''}}</p>
                </div>
                <div class='questions'>
                    <ng-container *ngFor="let question of sections[currSection].questions; let i = index">
                        <app-question class='question' *ngIf="(showAll||!application.certStart||(question.answer?.updatedAt||1)>(application.certStart))"
                            [question]="question" [section]="sections[currSection]" [application]="application" [readonly]="true"
                            [lastComments]="true" [isIvp]="assessment.type==1"></app-question>
                    </ng-container>
                </div>
                <div class="btns_container">
                    <div class='autosave'>
                        <span>Last autosave ( static )</span>
                        <span>03/03/2021 at 11:09 pm</span>
                    </div>
                    <app-button-primary text="Go to next section" (click)="nextSection()"></app-button-primary>
                </div>
            </div>
            <app-assessment-panel [questions]="sections[currSection][assessment.type==0?'riaaQuestions':'ivpQuestions']" [assessment]="assessment"></app-assessment-panel>
        </div>
        <!--* CAP Summary section *-->
        <div class='offset app-overview cap' *ngIf="currSection==-3">
            <app-message></app-message>

            <div class="progres" (click)="debug()">
                <div class='pname'><span>{{application.productName}}</span></div>
            </div>
            <div class='form'>
                <h4>Complete your assessment</h4>
                <h1>Download your CAP Summary</h1>
                <p>Your PDF Cap Summary includes all completed </p>
                <p>CAP questions from all application sections. </p>
                <p> When ready, go to the next section to submit your assessment.</p>
                <app-button-secondary [isText]="true" [imgUrl]="'assets/svg/plus-icon.svg'" buttonText="Generate a new PDF" [isGreen]="true" (click)="generatePdf()"></app-button-secondary>
                <app-download-button *ngIf="capSummary" [fileSize]="capSummary.size" [fileType]="'PDF'" [name]="'CAP-SUMMARY.pdf'" [downloadable]="true"
                    [fileUrl]="capSummary.url"></app-download-button>
                <app-button-primary text="Go to next Section" (click)="currSection=-2"></app-button-primary>
            </div>
        </div>
        <!--* RIAA Submit *-->
        <div class='offset app-overview submit' *ngIf="currSection==-2&&assessment.type==0">
            <app-message></app-message>
            <div class="progres" (click)="debug()">
                <div class='pname'><span>{{application.productName}}</span></div>
            </div>
            <div class='form'>
                <h4>Submit your assessment</h4>
                <h1>Certification Assessment</h1>
                <app-form-select [list]="approvalConditions" placeholderText="Approval Condition" [(model)]="selectedCondition"></app-form-select>
                <app-form-select [list]="finalScores" placeholderText="Final Scoring" [(model)]="selectedScore"></app-form-select>
                <app-text-field placeholderText="Comments" [model]="assessment.comments||''" (modelChange)="assessment.comments=$event"></app-text-field>
                <app-drag-drop [fileList]="documents" (fileEvent)="uploadDocument($event)" (fileRemoved)="deleteDocument($event)" (openModal)="openUploader()"></app-drag-drop>
                <app-form-select placeholderText="Select an IVP" [list]="ivps" displayProperty="fullName" [(model)]="assessment.ivp"></app-form-select>
                <app-single-calendar label="Certification Start Date" [(model)]="assessment.certificationStart!" (modelChange)="assessment.certificationStart = $event"></app-single-calendar>
                <app-tick-box text="Don't send email notifications" [(value)]="assessment.noNotif"></app-tick-box>
                <!-- <div class="stars">
                    <h3>Set Star Rating</h3>
                    <app-filters-star [rating]="assessment.stars||0" (ratingChange)="assessment.stars=$event"></app-filters-star>
                </div> -->
                <app-button-primary text="Submit Assessment" (click)="send()"></app-button-primary>
            </div>
        </div>
        <!--* IVP Submit *-->
        <div class='offset app-overview submit ivp' *ngIf="currSection==-2&&assessment.type==1">
            <app-message></app-message>
            <div class="progres" (click)="debug()">
                <div class='pname'><span>{{application.productName}}</span></div>
            </div>
            <div class='form'>
                <h4>Submit your assessment</h4>
                <h1>IVP Assessment</h1>
                <app-form-select [list]="approvalConditions" placeholderText="Approval Condition" [(model)]="selectedCondition"></app-form-select>
                <!-- <app-large-text-field [model]="assessment.procedures||''" (modelChange)="assessment.procedures=$event" placeholderText="Procedures performed"></app-large-text-field>
                <app-large-text-field [model]="assessment.findings||''" (modelChange)="assessment.findings=$event" placeholderText="Factural findings"></app-large-text-field>
                <app-large-text-field [model]="assessment.errors||''" (modelChange)="assessment.errors=$event" placeholderText="Errors or Exceptions identified"></app-large-text-field> -->
                <app-text-field [multiline]="true" [model]="assessment.procedures||''" (modelChange)="assessment.procedures=$event" placeholderText="Procedures performed"></app-text-field>
                <app-text-field [multiline]="true" [model]="assessment.findings||''" (modelChange)="assessment.findings=$event" placeholderText="Factual findings"></app-text-field>
                <app-text-field [multiline]="true" [model]="assessment.errors||''" (modelChange)="assessment.errors=$event" placeholderText="Errors or Exceptions identified"></app-text-field>
                <app-drag-drop [fileList]="documents" (fileEvent)="uploadDocument($event)" (fileRemoved)="deleteDocument($event)" (openModal)="openUploader()"></app-drag-drop>
                <app-button-primary text="Submit Assessment" (click)="send()"></app-button-primary>
            </div>
        </div>
        <!--* Thank You Page *-->
        <div class='offset app-overview submited' *ngIf="currSection==-4">
            <app-message></app-message>
            <div class="progres">
                <div class='pname'><span>{{application.productName}}</span></div>
            </div>
            <div class='form'>
                <svg-icon class="animated_tick" [class.denied]="assessment.condition==0||assessment.score==1" src="assets/svg/big_tick.svg"></svg-icon>
                <app-confetti></app-confetti>
                <h4>Submitted Assessment</h4>
                <h1>{{assessment.condition==0||assessment.score==1?'Declined':'Congratulations'}}!</h1>
                <p class='thanks-label' *ngIf="assessment.type==0">
                    {{ assessment.condition==0||assessment.score==1 ?
                        'We need further information, your analyst will be in touch':
                        'Your assessment is submitted and is now in the IVP assessment queue.'
                    }}
                </p>
                <app-button-primary text="Back to Dashboard" routerLink="/"></app-button-primary>
                <app-button-secondary [isText]="true" buttonText="View progress" (click)="currSection=-3" style='align-self:center;' *ngIf="assessment.type==0"></app-button-secondary>
                <!-- <hr *ngIf="assessment.type==0"> -->
                <!-- <app-button-secondary [isText]="true" buttonText="Download as PDF" (click)="currSection=-3" style='align-self:center;'></app-button-secondary> -->
                <div class="pdf_gen" *ngIf="assessment.type==0">
                    <h3>Need a copy of your application? Download PDFs from our options below:</h3>
                    <app-form-select [list]="pdfTypes" displayProperty="label" (modelChange)="selType=$event.type"></app-form-select>
                    <app-button-secondary *ngIf="selType" [isText]="true" [imgUrl]="'assets/svg/plus-icon.svg'" buttonText="Generate a new PDF" [isGreen]="true" (click)="generateAppPdf()"></app-button-secondary>
                    <app-download-button *ngIf="downloadPdf" [fileSize]="downloadPdf.size" [fileType]="'PDF'" [name]="downloadPdf.name" [downloadable]="true"
                        [fileUrl]="downloadPdf.url"></app-download-button>
                </div>
            </div>
        </div>
    </div>
</main>
