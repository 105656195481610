import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-heading4',
  templateUrl: './heading4.component.html',
  styleUrls: ['./heading4.component.scss']
})
export class Heading4Component implements OnInit {
  @Input() text: string = ''
  @Input() isTextBlack: boolean = true
  constructor() { }

  ngOnInit(): void {
  }

}
