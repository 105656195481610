import { Attachment, attachment_response } from "./Attachment";
import { Provider, provider_response } from "./Provider";

export interface default_answer_response{
    id:number;
    question_id:number;
    provider_id:number;
    value:string;

    provider?:provider_response;
    files?:attachment_response[];
}
export class DefaultAnswer{
    public id:number = 0;
    public questionId:number = 0;
    public providerId:number = 0;
    public value:any = {};

    public provider?:Provider;
    public files:Attachment[] = [];

    public map(data:default_answer_response){
        this.id = data.id;
        this.questionId = data.question_id;
        this.providerId = data.provider_id;
        this.value = JSON.parse(data.value);

        this.provider = data.provider?Provider.create(data.provider):undefined;
        this.files = [];
        for(let f of data.files??[])
            this.files.push(Attachment.create(f));

        return this;
    }
    public responsify():default_answer_response{
        return {
            id: this.id,
            question_id: this.questionId,
            provider_id: this.providerId,
            value: JSON.stringify(this.value),
            files:(() => {
                let arr:attachment_response[] = [];
                for(let f of this.files) arr.push(f.responsify());
                return arr;
            })()
        }
    }
    public static create(data:default_answer_response){
        let r = new this;
        r.map(data);
        return r;
    }
}