import { Component, OnInit, Input, Output ,EventEmitter, ViewChild,ElementRef} from '@angular/core';
 
export interface bulkAction{
  isPlus:boolean,
  btnText:string,
  imgUrl:string,
  type?: string,
  function:CallableFunction
}

@Component({
  selector: 'app-bulk-actions',
  templateUrl: './bulk-actions.component.html',
  styleUrls: ['./bulk-actions.component.scss']
})
export class BulkActionsComponent implements OnInit {
  @Input() model: bulkAction[] = []   
  @Input() selectedItems: Array<any> = []
  //members ,items etc
 
  @Input() itemsCount: number = 0
  @Input() btnPText: string = "add new"
  @Input() btnMText: string = "delete new"

  @Output() expanded = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    // console.log(this.model)
 
   }
   getType(){
    //  if(this.model.length <= 1) return this.type
    //  else if(this.type.charAt(this.model.length) == 's') return this.type
    //  else return this.type +"s"
    return this.model[0].type
   }

   getItemsLength(){
      return this.selectedItems.length
   }
  public reset(){
    this.selectedItems = []
}
}
