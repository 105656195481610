<div class="main">
    <div class="content" [ngClass]="{'editing': isEdit}" *ngIf="isLoaded">
        <h2 [class.small]="isEdit" (click)="debug()">{{isEdit?'Edit':'Add new'}} provider</h2>
        <h2 *ngIf="isEdit">{{provider.name}}</h2>
        <p>Please note: All content, except members, will be visible on the Responsible Returns website.</p>
        <app-text-field [isAutoSize]="true" [placeholderText]="'Provider name'" [(model)]="provider.name"></app-text-field>
        <app-text-field [isAutoSize]="true" [placeholderText]="'Website'" [(model)]="provider.website"></app-text-field>
        <app-text-field [isAutoSize]="true" [placeholderText]="'Provider email'" [(model)]="provider.email"></app-text-field>
        <app-text-field [isAutoSize]="true" [placeholderText]="'Provider contact number'" [(model)]="provider.phone"></app-text-field>
        <app-form-select [placeholderText]="'Select member'" *ngIf="isAdmin" [list]="members" [model]="provider.owner" [isMulitSelect]="false" (modelChange)="provider.owner=$event; provider.ownerId=$event.id" displayProperty="fullName"></app-form-select>
        <!-- <app-form-select [placeholderText]="'Select product(s)'"></app-form-select> -->
        <app-large-text-field [limit]="'800'" [(model)]="provider.about" [placeholderText]="'About your provider'"></app-large-text-field>
        <app-tick-box *ngIf="isAdmin" [text]="'Show on Responsible Returns'" [(value)]="provider.show"></app-tick-box>
        <h3>Logos and banner image for Responsible Returns</h3>
        <app-drag-drop textLabel="Drag and Drop Logo" types="images" [fileList]="logoFile" (fileEvent)="uploadImage($event, 'logo')" (fileRemoved)="removeImage('logo')" (openModal)="openUploader('logo')"></app-drag-drop>
        <app-drag-drop textLabel="Drag and Drop Banner Image" types="images" [fileList]="bannerFile" (fileEvent)="uploadImage($event, 'banner')" (fileRemoved)="removeImage('banner')" (openModal)="openUploader('banner')"></app-drag-drop>
        <app-button-primary [text]="'Save'" (click)="send()"></app-button-primary>
        <div *ngIf="isEdit" class="editing colab">
            <h3>Provider application questions</h3>
            <div class="questions_container">
                <app-default-question *ngFor="let q of defaultables; let i = index" [question]="q" [provider]="provider"></app-default-question>
                <app-button-primary [text]="'Save'" (click)="send()"></app-button-primary>
            </div>
            <h3>Delete your provider and all products related to the provider</h3>
            <app-input-field type="password" [placeholderText]="'Enter password'" [(model)]="deleteInputs.password"></app-input-field>
            <app-tick-box [text]="'Move products to another provider'" [(value)]="deleteInputs.moveProducts"></app-tick-box>
            <app-form-select [placeholderText]="'Select provider'" [list]="providers" (modelChange)="deleteInputs.moveTo=$event.id"></app-form-select>
            <app-button-primary [text]="'Delete provider'" [isActive]="deleteInputs.password.length>0" (click)="del()"></app-button-primary>
        </div>
    </div>
</div>