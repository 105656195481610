import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-conversation-preview',
  templateUrl: './conversation-preview.component.html',
  styleUrls: ['./conversation-preview.component.scss']
})
export class ConversationPreviewComponent implements OnInit {
  @Input() head: string = "this is head"
  @Input() name: string = "conversation is name length test test testn"
  @Input() time: string = "2 weeks ago"
  @Input() even: boolean = false;
  public sufix: string = "..."

  public isMobile: boolean = false
  getName = () => {
    if (this.isMobile) {
      return this.name.split("").splice(0, 29).join("") + this.sufix
    } else {
      return this.name.split("").splice(0, 39).join("") + this.sufix
    }
  }
  constructor(private deviceService: DeviceDetectorService) { this.checkDevice() }

  ngOnInit(): void {
  }
  checkDevice = () => {
    this.isMobile = this.deviceService.isMobile();
    // this.isTablet = this.deviceService.isTablet();
    // this.isDesktopDevice = this.deviceService.isDesktop();
  }
}
