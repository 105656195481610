import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, ViewChild, ElementRef } from '@angular/core';
// import { CdkTextareaAutosize } from '@angular/cdk/text-field';
@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
}) 
//TODO delete ngModel, use only form Control
export class TextFieldComponent implements OnInit, AfterContentChecked {
  constructor() { }

  @Input() public model: string = ''
  @Input() public isError: boolean = false
  @Input() public errorText: string = 'This is example error message'
  @Input() public placeholderText: string = 'Input text here'
  @Input() public multiline: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() isTextLimit: boolean = true
  @Input() limit: number = 100
  @Input() isAutoSize: boolean = false
  @Output() public modelChange = new EventEmitter<string>();
  @Output() enterPressed = new EventEmitter()
  @ViewChild('textarea') textarea?: ElementRef;

  public isFocused: boolean = false
  public isAccepted: boolean = false
  public isEmpty: boolean = true

  ngOnChanges(changes: any){
    if(changes.model !== undefined && changes.model.currentValue !== undefined) this.istextAreaEmpty(this.model)
  }
  ngOnInit(): void {
    // this.istextAreaEmpty(this.model)
    if (this.multiline == true && this.limit == 100) this.limit = 500
  }

  onFocus(event: any) {
    if (event) {
      this.isFocused = true;
      this.isAccepted = false
    }
  }
  ngAfterContentInit(){
    if (this.isAutoSize) this.autoGrow()
  }
  // ngAfterContentInit(): void {
  // }
  ngAfterContentChecked(): void {
    this.istextAreaEmpty(this.model)
    if (this.isAutoSize) this.autoGrow()
  }
  onBlur() {
    if (this.isEmpty) {
      this.isFocused = false;
    } else {
      this.isAccepted = true
    }
  }

  istextAreaEmpty(text: string) {
    if (!text || text.length == 0) {
      this.isEmpty = true
    } else {
      this.isEmpty = false
    }
  }
  autoGrow() {
    if (this.textarea) {
      this.textarea.nativeElement.style.height = '0';
      this.textarea.nativeElement.style.height = this.textarea.nativeElement.scrollHeight + 'px';
    }
  }
  public placeholderVal() {
     if (this.model || this.model !== undefined) {
      if (this.isFocused) {
        if (this.model == null) return true
        else if (this.model.length > 0) return true
        else return true
      }
      else {
        if (this.model == null) return false
        else if (this.model.length > 0) return true
        else return false
      }
    } else return false
  }
public getKey(event: Event){
  event.preventDefault()
 this.enterPressed.emit()
 }
}
