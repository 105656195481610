import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/form-builder/elements/delete-confirm/delete-confirm.component';
import { SectionConditionBuilderComponent } from 'src/app/form-builder/section-condition-builder/section-condition-builder.component';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { SectionConditionGroup } from 'src/app/model/responsible/SectionConditionGroup';
import { PartialService } from 'src/app/services/partial.service';
import { MenuComponent } from '../menu.component';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { SectionBundle } from 'src/app/model/responsible/FormSectionBundle';

@Component({
    selector: 'app-form-menu',
    templateUrl: './form-menu.component.html',
    styleUrls: ['./form-menu.component.scss']
})
export class FormMenuComponent implements OnInit {
    @Input() public display: boolean = false;
    @Input() public expanded: boolean = false;
    @Input() public form: SectionBundle = new SectionBundle();
    @Input() public currSection: number = 0;
    @Input() public editable: boolean = false; 
    @Input() public overview: boolean = false;
    @Input() public submitted: boolean = false;
    @Input() public cap:boolean = false;
    @Input() public assessment:boolean = false;
    @Input() public isAssessment: boolean = false
    @Input() public isEnabled: boolean = true
    @Input() public certStart?:Date;
    
    public notifOpen: boolean = false;
     @Output() public changeTab = new EventEmitter<number>();

    constructor(
        private dialog: MatDialog,
        private comm: PartialService,
        private bodyScroll: BodyScrollService,
    ) { }

    ngOnInit(): void { 
        MenuComponent.formNavMobile.emit(true);
    }
 

    public insertSection() {
        this.form.sections.push(new FormSection());
     }
    public deleteSection(section: FormSection) {
        let d = this.dialog.open(DeleteConfirmComponent, { panelClass: 'modal-green' });
        d.afterClosed().subscribe(res => {
            if (res){ 
                let i = this.form.sections.indexOf(section);
                if(i == this.currSection) this.goToSection(i-1);
                this.form.sections.splice(i, 1);
            }
        });
    }
    public moveUp(index: number) {
        let item = this.form.sections.splice(index, 1);
        this.form.sections.splice(index - 1, 0, item[0]);
        if (this.currSection == index) this.goToSection(index - 1);
        else if (this.currSection == index - 1) this.goToSection(index);
    }
    public moveDown(index: number) {
        let item = this.form.sections.splice(index, 1);
        this.form.sections.splice(index + 1, 0, item[0]);
        if (this.currSection == index) this.goToSection(index + 1);
        else if (this.currSection == index + 1) this.goToSection(index);
    }
    public goToSection(index: number) {
        this.currSection = index;
        this.changeTab.emit(index);
    }

    public openSectionConditions(index: number) {
        let d = this.dialog.open(SectionConditionBuilderComponent, {
            data: {
                type: 'question',
                index: index,
                title: this.form.sections[index].title,
                conditions: (() => {
                    let arr = [];
                    for (let c of this.form.sections[index].conditions)
                        arr.push(c.responsify());
                    return arr;
                })(),
                conditionable: (() => {
                    let arr: any[] = [];
                    for (let [i, v] of this.form.sections.entries()) {
                        if (i == index) break;
                        for (let q of v.questions) {
                            if (q.conditionable) arr.push({ name: `${v.title} - Question ${q.order + 1}`, object: q });
                            for (let [j, k] of q.subquestions.entries())
                                if (k.conditionable) arr.push({ name: `${v.title} - Question ${q.order + 1}${this.ntl(j)}`, object: k });
                        }
                    }
                    return arr;
                })(),
                hidden: this.form.sections[index].hidden
            }, panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) {
                this.form.sections[index].conditions = [];
                this.form.sections[index].hidden = res.hidden;
                for (let i of res.condis)
                    this.form.sections[index].conditions.push(SectionConditionGroup.create(i.responsify()));
            }
        });
    }
    public confirmConditions(section:FormSection){
        if(!section.conditions.length) return true;
        for(let g of section.conditions){
            let qRef = this.form.getQuestionById(g.targetId),
                answer = qRef?.answer;
            if(qRef){
                if(qRef.custom<3){
                    let final = false,
                        radioFinal = undefined;
                    for(let c of g.conditions){
                        let fRef = (()=>{ for(let f of qRef.fields) if(f.id==c.fieldId) return f; return null; })();
                        if(fRef){
                            if(fRef.type==='radio'){
                                if(radioFinal) continue;    //? Radio conditions have OR relation with one another, if one is already true, there's no need to verify any further ones
                                if(radioFinal===undefined) radioFinal = false;
                                if(!answer) radioFinal = c.condition==='false';
                                else if(answer.value.radio==fRef.id) radioFinal = c.condition==='true';
                                final = radioFinal;
                            } else if(fRef.type==='check'){
                                if(!answer) final = c.condition==='false';
                                else if(answer.value[fRef.id]===(c.condition==='true')||(answer.value[fRef.id]==undefined&&c.condition==='false')) final = true;
                                else final = false;
                            } else if(['slider-numeric','slider-percent'].includes(fRef.type)){
                                if(!answer&&fRef.rangeStart>=c.rangeStart&&fRef.rangeStart<=c.rangeEnd) final = true; 
                                else if(answer&&Number(answer.value[fRef.id])>=c.rangeStart&&Number(answer.value[fRef.id])<=c.rangeEnd) final = true;
                                else final = false;
                            }
                        }
                    }
                    if(radioFinal!=undefined&&!radioFinal) final = false;
                    if(final) return section.hidden;
                } else {
                    let final = false;
                    for(let c of g.conditions){
                        if(answer?.value[c.customId!]===(c.condition==='true')||(answer?.value[c.customId!]==undefined&&c.condition==='false')) final = true;
                        else final = false;
                    }
                    if(final) return section.hidden;
                }
            }
        }
        return !section.hidden;
    }

    public ntl(n: number) { return String.fromCharCode(65 + n); }

    public toggleMainMenu() {

        if (window.innerWidth < 768) {
            this.expanded = !this.expanded;
            this.bodyScroll.disableBodyScroll();

            if(this.expanded) {
                this.bodyScroll.disableBodyScroll();
            } else {
                this.bodyScroll.enableBodyScroll();
            }
        } else {
            MenuComponent.show.emit();
            this.bodyScroll.enableBodyScroll();
        }
    }

    public openNotif() {
        this.comm.notifications.emit({ isActive: true });
        if(window.innerWidth < 768) {
            this.notifOpen = true;
            MenuComponent.hideOverlay.emit(true);
            this.expanded = false;
        } 
    }

    public closeNotifs() {
        if(window.innerWidth < 768) {
            this.comm.notifications.emit({ isActive: false });
            this.notifOpen = false;
            MenuComponent.hideOverlay.emit(true);
            this.expanded = false;
        } 
    }

    public closeMenu() {
        this.expanded = !this.expanded;
        if(window.innerWidth < 768) {
            this.comm.notifications.emit({ isActive: false });
            this.notifOpen = false;
            MenuComponent.hideOverlay.emit(true);

            if(this.expanded) {
                this.bodyScroll.disableBodyScroll();
            } else {
                this.bodyScroll.enableBodyScroll();
            }
        } 
        
    }

    public openMainMenu() {
        MenuComponent.show.emit();
    }

    public hasEdits(section:FormSection){
        if(!this.certStart) return false;
        for(let q of section.questions){
            if((q.answer?.updatedAt??1) > this.certStart) return true;
        }
        return false;
    }

    public backBtn(){
        history.back();
    }
}
