import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {

  transform(value: string | any): string | boolean {
    if (value == null) {
      return value;
    }
    return value.substring(0,1);
  }

}
