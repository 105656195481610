import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { AssetClass, Market, ProductCategory, ProductTarget, RIStrategy } from "src/app/model/responsible/CustomIntegration";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root',
})
export class CustomIntegrationAPI{
    constructor(
        private http:HttpClient,
        private epEncryptor:EpEncryptionService
    ){}

    public all(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}filters`);
    }

    public productCategories = {
        index: () => {
            return this.http.get<CodedResponseModel>(`${environment.apiUrl}product-categories`);
        },
        update: (list:ProductCategory[]) => {
            return this.http.put<CodedResponseModel>(`${environment.apiUrl}product-categories`,
                this.epEncryptor.sendPayload(list));
        }
    }
    public markets = {
        index: () => {
            return this.http.get<CodedResponseModel>(`${environment.apiUrl}markets`);
        },
        update: (list:Market[]) => {
            return this.http.put<CodedResponseModel>(`${environment.apiUrl}markets`,
                this.epEncryptor.sendPayload(list));
        }
    }
    public productTargets = {
        index: () => {
            return this.http.get<CodedResponseModel>(`${environment.apiUrl}product-targets`);
        },
        update: (list:ProductTarget[]) => {
            return this.http.put<CodedResponseModel>(`${environment.apiUrl}product-targets`,
                this.epEncryptor.sendPayload(list));
        }
    }
    public riStrategies = {
        index: () => {
            return this.http.get<CodedResponseModel>(`${environment.apiUrl}ri-strategies`);
        },
        update: (list:RIStrategy[]) => {
            return this.http.put<CodedResponseModel>(`${environment.apiUrl}ri-strategies`,
                this.epEncryptor.sendPayload(list));
        }
    }

    public assetClasses = {
        index: () => {
            return this.http.get<CodedResponseModel>(`${environment.apiUrl}asset-classes`);
        },
        update: (list:AssetClass[]) => {
            return this.http.put<CodedResponseModel>(`${environment.apiUrl}asset-classes`,
                this.epEncryptor.sendPayload(list));
        }
    }
}
