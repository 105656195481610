import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { Provider } from 'src/app/model/responsible/Provider';
import { User, UserTypes } from 'src/app/model/User';
import { PartialService } from 'src/app/services/partial.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { FormAPI } from 'src/app/services/responsible/forms.service';
import { ProviderAPI } from 'src/app/services/responsible/provider.service';
import { UsersAPIService } from 'src/app/services/users.service';
import { LoaderComponent } from '../../../partials/loader/loader.component';
@Component({
    selector: 'app-add-provider',
    templateUrl: './add-provider.component.html',
    styleUrls: ['./add-provider.component.scss']
})
export class AddProviderComponent implements OnInit {
    @Input() isEdit: boolean = false;
    @Input() target: number = 0;
    public isAdmin: boolean = false;
    public isLoaded: boolean = false
    public bannerFile: file_interface[] = [];
    public logoFile: file_interface[] = [];
    public deleteInputs = {
        password: '',
        moveProducts: true,
        moveTo: undefined
    }

    public provider: Provider = new Provider();

    public members: User[] = [];
    public providers: Provider[] = [];
    public defaultables: FormQuestion[] = [];

    constructor(
        private userApi: UsersAPIService,
        private providerApi: ProviderAPI,
        private attachmentApi: AttachmentAPI,
        private formApi: FormAPI,
        private dialog: MatDialog,
        private partials: PartialService,
        private jwt: JwtHelperService,
        private router: Router,
    ) {
        this.isAdmin = [UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type);
    }

    ngOnInit(): void {
        LoaderComponent.show.emit(true);
        // console.log(this.isEdit)

        // console.log(this.target)
        if (this.isEdit && this.target) {
             this.providerApi.get(this.target).subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.provider = Provider.create(response);
                console.log(res)
                if (this.defaultables.length) {
                    for (let q of this.defaultables) {
                        for (let d of this.provider.defaults) {
                            if (d.questionId == q.id) {
                                q.default = d;
                                break;
                            }
                        }
                    }
                } else console.log('q');
                if(this.provider.logo)
                    this.logoFile[0] = {
                        file: this.provider.logo,
                        filename: this.provider.logo.filename,
                        extension: this.provider.logo.getExtension()||'',
                        status:'ok',
                        progress:100
                    }
                if(this.provider.banner)
                    this.bannerFile[0] = {
                        file: this.provider.banner,
                        filename: this.provider.banner.filename,
                        extension: this.provider.banner.getExtension()||'',
                        status:'ok',
                        progress:100
                    }
             });
            this.formApi.getDefaultables().subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.defaultables = [];
                for (let q of response)
                    this.defaultables.push(FormQuestion.create(q));

                if (this.provider.defaults.length) {
                    for (let q of this.defaultables) {
                        for (let d of this.provider.defaults) {
                            if (d.questionId == q.id) {
                                q.default = d;
                                break;
                            }
                        }
                    }
                } else console.log('w');
                
                if(this.provider !== undefined && this.members !== undefined  )  LoaderComponent.show.emit(false);  
            })
        } else {
            this.provider = new Provider();
        }
        this.userApi.index({ filters: { type: `${UserTypes.Member}` } }).subscribe(res => {
            let response = CodedResponseModel.decode(res);
            this.members = [];
            for (let m of response.data)
                this.members.push(User.create(m));
            this.isLoaded = true
            if(this.provider !== undefined && this.members !== undefined  )  LoaderComponent.show.emit(false);
            
            this.providerApi.index().subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.providers = [];
                for (let p of response.data)
                    if (p.id != this.provider.id) this.providers.push(Provider.create(p));
                if(this.provider !== undefined && this.members !== undefined  )  LoaderComponent.show.emit(false);
            });
        });
    }

    public send() {
        if (this.isEdit) {
            this.providerApi.update(this.provider).subscribe(res => {
                this.partials.notificator.emit({ type: 'success', message: 'Provider data updated', timeout: 5000 });
                this.router.navigate(['/providers']);
            }, err => {
                this.partials.notificator.emit({ type: 'error', message:err.error.message, timeout: 5000 });
            });
        } else {
            this.providerApi.create(this.provider).subscribe(res => {
                this.partials.notificator.emit({ type: 'success', message: 'Provider has been created', timeout: 5000 });
                this.router.navigate(['/providers']);
            }, err => {
                this.partials.notificator.emit({ type: 'error', message:err.error.message, timeout: 5000 });
            });
        }
    }

    public uploadImage(flist: FileList, type: 'logo' | 'banner') {
        if (flist.item(0)) {
            if (!['image/png', 'image/jpeg'].includes(flist.item(0)!.type)) {
                console.error('Banner image must be of PNG or JPEG file type');
                return;
            }
            let added = false,
                handle: file_interface,
                target = type === 'logo' ? this.logoFile : this.bannerFile;
            if (target[0]) target.pop();
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        target[0] = handle;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        console.log(Math.round(res.loaded / res.total! * 99));
                        target[0].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        if(type==='logo'){
                            this.provider.logo = att;
                            this.provider.logoId = att.id;
                        } else {
                            this.provider.banner = att;
                            this.provider.bannerId = att.id;
                        }
                        target[0].file = att;
                        target[0].progress = 100;
                        break;
                }
            }, err => {
                if (added) {
                    this.provider.banner = undefined;
                    target.pop();
                }
                this.partials.notificator.emit({ type: 'error',message:err.error.message, timeout: 5000 });
            });
        }
    }
    public removeImage(type: 'logo' | 'banner') {
        let target = type === 'logo' ? this.logoFile : this.bannerFile;
        target.pop();
        if(type==='logo')
            this.provider.logoId = null;
        else
            this.provider.bannerId = null;
    }
    public openUploader(type: 'logo' | 'banner') {
        let d = this.dialog.open(UploadModalComponent, {
            panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) {
                this.uploadImage(res.file, type);
            }
        });
    }

    public del() {
        this.providerApi.standardDelete(this.provider.id, this.deleteInputs).subscribe(res => {
            this.partials.notificator.emit({ type: 'success', message: res.message, timeout: 5000 });
            this.router.navigate(['/providers']);
        }, err => {
            this.partials.notificator.emit({ type: 'error', message:err.error.message, timeout: 5000 });
        })
    }

    public debug() {
        console.log(this);
    }
}
