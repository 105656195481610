import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root'
})
export class GlobalsAPI{
    constructor(
        private http:HttpClient,
        private encryptor:EpEncryptionService,
    ){}

    public loginData(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}login_data`);
    }
    public set(data:any){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}set`,
            this.encryptor.sendPayload(data));
    }
}
