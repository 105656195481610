import { environment } from "src/environments/environment";

export interface attachment_response{
    id:number;
    label?:string;
    filename:string;
    filesize?:number;
}
export class Attachment{
    public id:number = 0;
    public label:string = '';
    public filename:string = '';
    public filesize?:number = 0;

    public map(data:attachment_response){
        this.id = data.id;
        this.label = data.label??'';
        this.filename = data.filename;
        this.filesize = data.filesize;

        return this;
    }
    public responsify():attachment_response{
        return {
            id: this.id,
            label: this.label,
            filename: this.filename,
            filesize: this.filesize,
        }
    }
    public static create(data:attachment_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public getExtension(){
        let x = this.filename.split('.');
        return x.pop();
    }
    public getUrl(){
        return `${environment.attachmentsUrl}${this.filename}`;
    }
}
