import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CodedResponseModel } from "../model/CodedResponseModel";
import { MailingGroup } from "../model/MailingGroup";
import { environment } from "../../environments/environment";
import { EpEncryptionService } from "./ep-encryption.service";

@Injectable({
  providedIn: 'root'
})
export class MailingGroupService {

  constructor(private http: HttpClient, private epEncryptionService: EpEncryptionService) { }

  public getAll() {
    return this.http.get<CodedResponseModel>(
      environment.apiUrl + "mailing_groups"

    );
  }
  public get(id: number) {
    return this.http.get<CodedResponseModel>(
      environment.apiUrl + "mailing_groups/"+id

    );
  }
  public save(_mailing_groups: MailingGroup) {
    return this.http.post<CodedResponseModel>(
      environment.apiUrl + "mailing_groups",this.epEncryptionService.sendPayload(_mailing_groups)
    );
  }
  public update(_mailing_groups: MailingGroup) {
    return this.http.put<CodedResponseModel>(
      environment.apiUrl + "mailing_groups/"+_mailing_groups.id,this.epEncryptionService.sendPayload(_mailing_groups)
    );
  }
}
