<div class='card n_mar'>
    <h2>{{title}}</h2>
    <div class="desc" *ngIf="desc.length > 0">{{desc}}</div>
    <!-- <button *ngIf="!section.supportChecks.length" (click)="addNewCheck()">Add</button> -->
    <app-button-secondary [isGreen]="true" [imgUrl]="'assets/svg/plus-icon.svg'" *ngIf="type==='support'?!section.supportChecks.length:!checks.length" (click)="addNewCheck()"></app-button-secondary>

    <div class='check' *ngFor="let check of (type==='support'?section.supportChecks:checks); let i=index " class='field tick'>
        <div class='content checkbox'>
            <app-tick-box [disabled]="true" text=""></app-tick-box>
            <app-text-field [isTextLimit]="false" [isAutoSize]="true" [(model)]="check.label" (modelChange)="changeMade()" placeholderText="Tickbox label"></app-text-field>
        </div>
        <div class='controls'>
            <div class='btn-group'>
                <app-button-secondary [isGreen]="false" [imgUrl]=" 'assets/svg/plus-icon.svg'" (click)="addNewCheck()"></app-button-secondary>
                <app-button-secondary [isGreen]="false" [imgUrl]=" 'assets/svg/minus-icon.svg'" (click)="deleteCheck(i)"></app-button-secondary>
            </div>
            <div class='btn-group'>
                <app-button-secondary [isGreen]="false" [imgUrl]=" 'assets/svg/arrow.svg'" (click)="moveCheckUp(i)" [disabled]="i==0" style="transform:rotate(180deg)"></app-button-secondary>
                <app-button-secondary [isGreen]="false" [imgUrl]=" 'assets/svg/arrow.svg'" (click)="moveCheckDown(i)" [disabled]="i==section.supportChecks.length-1"></app-button-secondary>
            </div>
        </div>
    </div>
</div>