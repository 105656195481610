import { Component, OnInit } from '@angular/core';
import { MailEventsService } from '../services/mail-events.service';
import { MailEvents } from '../model/MailEvents';
import { CodedResponseModel } from "../model/CodedResponseModel";
import { NotyficationMessageStateModel } from '../model/NotyficationMessageStateModel';
import { Router } from '@angular/router';
import { LoaderComponent } from '../partials/loader/loader.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserTypes } from '../model/User';

@Component({
  selector: 'app-mail-events',
  templateUrl: './mail-events.component.html',
  styleUrls: ['./mail-events.component.scss']
})
export class MailEventsComponent implements OnInit {
  //loader
  public showLoader: boolean = false;
  public showNotyfication: boolean = false;
  public notyficationMessage: string = '';
  public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
  //loader END

  public mailEventsOrgin: Array<MailEvents> = [];
  public mailEvents:MailEvents[] = [];

  public showDeleteModal:boolean = false;
  public deleteTarget:MailEvents = new MailEvents();

  public filter:string = '';
  constructor(
    private mailEventsService: MailEventsService,
    private router:Router,
    private jwt:JwtHelperService,
  ) {
    if(![UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type))
      this.router.navigate(['/']);
  }

  ngOnInit(): void {
    this.getMailEvents();
  }

  private getMailEvents(){
    LoaderComponent.show.emit(true);
    this.mailEventsService.getAll().subscribe(
      response => {
        let codedResponseModel = new CodedResponseModel();
        codedResponseModel.map(response);
        let orginalResponse = codedResponseModel.decodeData();
        orginalResponse.forEach((item: MailEvents) => {
          let event = new MailEvents();
          event.map(item);
          this.mailEventsOrgin.push(event);
        });
        this.setEvents();
        LoaderComponent.show.emit(false);
      }, err => {
        this.showLoader = false;
        this.showNotyfication = true;
        this.notyficationMessage = err.error.message;
        this.notyficationMessageStateModel.errors_save = true;
      }
    );
  }

  public setEvents(){
    if(this.filter=='') this.mailEvents = this.mailEventsOrgin;
    else {
      let tmp = [],
          lcf = this.filter.toLowerCase();
      for(let i of this.mailEventsOrgin){
        if(i.event_slug.toLowerCase().includes(lcf)||i.subject.toLowerCase().includes(lcf))
          tmp.push(i);
      }
      this.mailEvents = tmp;
    }
  }
  public add() {
    this.router.navigate(['mail-events/add']);
  }
  public edit(id: number) {
    this.router.navigate(['mail-events/'+id]);
  }
  public del(event:MailEvents){
    this.deleteTarget = event;
    this.showDeleteModal = true;
  }
  public confirmDeletion(){
    this.mailEventsService.delete(this.deleteTarget.id).subscribe(res => {
      this.showNotyfication = true;
      this.notyficationMessage = res.message;
      this.notyficationMessageStateModel.save_info = true;
      this.mailEventsOrgin.splice(this.mailEventsOrgin.indexOf(this.deleteTarget), 1);
      this.showDeleteModal = false;
    }, err => {
      this.showNotyfication = true;
      this.notyficationMessage = err.error.message;
      this.notyficationMessageStateModel.errors_save = true;
      this.showDeleteModal = false;
    });
  }

}
