import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-builder-button',
  templateUrl: './form-builder-button.component.html',
  styleUrls: ['./form-builder-button.component.scss']
})
export class FormBuilderButtonComponent implements OnInit {
  @Input() buttonText = "Add new"
  @Input() additive:boolean = true;
  @Input() isActive: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

}
