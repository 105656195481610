<div class="new_conversations" *ngIf="exists" [class.active]="display">
    <div class="top">
        <div class="heading">
            <div class="back_wrap" (click)="dismiss()">
                <div class="back"></div>
                <app-heading5 [text]="'Back'" [isTextBlack]="false" class="hidden-xs"></app-heading5>
            </div>
            <app-heading3 [text]="'New conversation'" [isTextBlack]="false"></app-heading3>
        </div>
    </div>
    <div class="conversations_content">
        <app-notification-overlay head="General Enquiry" name="Ask us anything" [isToogle]="true" (click)="emitObject({ aId:applicationId, qId:0 })"></app-notification-overlay>
        <ng-container *ngFor="let s of sections; let i = index">
            <ng-container *ngFor="let q of s.questions">
                <app-notification-overlay head="{{sectionName(s)}}, Question {{q.tag}}" [name]="q.question||''" [isToogle]="true" (click)="emitObject({aId:applicationId, qId:q.id})"></app-notification-overlay>
                <ng-container *ngFor="let u of q.subquestions">
                    <app-notification-overlay head="{{sectionName(s)}}, Question {{u.tag}}" [name]="u.question||''" [isToogle]="true" (click)="emitObject({aId:applicationId, qId:u.id})"></app-notification-overlay>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>