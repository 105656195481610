import { Component, OnInit } from '@angular/core';
import { CodedResponseModel } from '../../model/CodedResponseModel';
import { Router } from "@angular/router";
import { User } from '../../model/User';
import { UsersAPIService } from '../../services/users.service';
import { PartialService } from 'src/app/services/partial.service';
import { LoaderComponent } from '../../partials/loader/loader.component';
@Component({
	selector: 'app-profile-edit',
	templateUrl: './profile-edit.component.html',
	styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

	public id:number = 0;
    public password: string = '';
	public newPass: string = '';
	public passConfirm: string = '';
    public passwordStrength: any = 'Low';
    public passwordChanged: boolean = false;
	public loaded:boolean = false;
	public showTick: boolean = false;
	public isError: boolean = false;
	public error: boolean = false;
	public errorMsg: string = 'Include 8 characters and one special character';
	
	public user:User = new User();
	public avatar?:File;
	
    public expanded: boolean = false;

	constructor(
		private userApi: UsersAPIService,
		private partials:PartialService,
		private router:Router,
	){			
 		this.partials.loader.emit(true);
		 LoaderComponent.show.emit(true)
		this.userApi.getCurrUser().subscribe(res => {
			let response = CodedResponseModel.decode(res);
			this.user = User.create(response);
			this.loaded = true;
			//this.partials.loader.emit(false);
			LoaderComponent.show.emit(false);

		}, err => {
			this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
			LoaderComponent.show.emit(false);
		});
	}
	ngOnInit(){
 	}

	public updateData(){
		this.userApi.updateCurrUser(this.user).subscribe(res => {
			this.partials.notificator.emit({ type:'success', message:res.message, timeout: 5000 });
		}, err => {
			this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
		});
	}
	public updatePass(){
		if(!this.password) this.partials.notificator.emit({ type:'error', message:"Old password is required", timeout: 5000 });
		if(!this.newPass) this.partials.notificator.emit({ type:'error', message:"New password is required", timeout: 5000 });
		if(this.newPass.length<8) this.partials.notificator.emit({ type:'error', message:"Password must be at least 8 characters long", timeout: 5000 });
		if(this.newPass!=this.passConfirm) this.partials.notificator.emit({ type:'error', message:"Passwords do not match", timeout: 5000 });

		this.userApi.updatePassword({ password:this.password, newPass:this.newPass, passConfirm:this.passConfirm }).subscribe(res => {
			this.partials.notificator.emit({ type:'success', message:res.message, timeout: 5000 });
		}, err => {
			this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
		})
	}
	public uploader(e:Event){
		this.avatar = (e.target as any).files.item(0);
		if(this.avatar) this.userApi.uploadAvatar(this.avatar as File).subscribe(res => {
			console.log(res);
			let response = CodedResponseModel.decode(res);
			this.user.avatar = response.fname;
		}, err => {
			this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
		})
	}
	public getOffset(value: boolean){
		this.expanded = value;
   	}

	public del(password:string){
		this.userApi.deleteSelf(password).subscribe(res => {
			this.partials.notificator.emit({ type:'success', message: res.message, timeout: 5000 });
			this.router.navigate(['/users']);
		}, err => {
			this.partials.notificator.emit({ type:'error', message: err.error.message, timeout: 5000 });
		});
    }

	public passwordComparsion(){
		if (this.newPass === this.passConfirm && this.passConfirm?.length) {
			this.showTick = true;
		} else {
			this.showTick = false;
		}
	}
	public passwordRule(pass: string) {
		if (pass.length > 7 && /\W/.test(pass))
			return true;
		else
			return false;
	}
}
