<div class="global-overlay" (click)="dismiss()" [class.active]="showOverlay"></div>

<div class='menu' *ngIf="exists" [class.gone]="!display" [class.minimise]="minimise" [class.formNavMobileToggle]="formNavMobileToggle" [class.notifOpen]="notifOpen">
    <div class='top' [class.hidden-xs]="formNavMobileToggle">
        <svg-icon class='banner hidden-xs' [src]="'assets/svg/rr-logo-full.svg'" [routerLink]="['/']"></svg-icon>
        <div class="visible-xs">
            <div class="burger_btn">
                <app-button-secondary class="openMenu" [isText]="true" [isGreen]="false" [isWhite]="true" buttonText="menu" [imgUrl]="'assets/svg/main-menu.svg'" (click)="toggleMenu()">
                </app-button-secondary>
                <div class="close_wrap" (click)="toggleMenu()">
                    <app-button-icon class="closeMenu visible-xs" [imgUrl]="'assets/svg/close_menu_cross.svg'">
                    </app-button-icon>
                </div>
            </div>
        </div>
        <svg-icon routerLink="/" class='banner visible-xs' [src]="'assets/svg/rr-logo-full.svg'"></svg-icon>
        <div class="notifs_wrap">
            <div class='notifs' (click)="openNotif()">
                <div class='new' *ngIf="newNotifs"></div>
            </div>
            <div class="cross" (click)="closeNotifs()">
                <app-button-icon class="closeMenu visible-xs" [imgUrl]="'assets/svg/close_menu_cross.svg'">
                </app-button-icon>
            </div>
        </div>
        <app-button-icon class='expand hidden-xs' [imgUrl]="'../../../assets/svg/menu_expand.svg'" (click)="toggleMenu()"></app-button-icon>
    </div>
    <div class="close_main_menu visible-xs" *ngIf="formNavMobileToggle" (click)="closeMainMenu()" (swipedown)="onSwipeDown($event)">
        <div class="close_main_menu_content">
            <div class="line"></div>
            <div class="text">Close main menu</div>
        </div>
    </div>

    <div class="menu_content">
        <!-- [ngClass]="{'active': detectSub(menuItem)}" -->
        <div class="menu_links">
            <ng-container *ngFor="let menuItem of menuItems">
                <div class="single_link" *ngIf="canShow(menuItem)">
                    <a class="link_menu" [class.active]="detectSub(menuItem)" [routerLink]="menuItem.link" [routerLink]="[menuItem.link]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" (click)="menuChangePage(menuItem.link)">
                        <div class="svg_wrap">
                            <svg-icon [src]="menuItem.icon" class="inactive_icon"></svg-icon>
                            <svg-icon [src]="menuItem.iconActive" class="active_icon"></svg-icon>
                        </div>
                        <div class="text">{{menuItem.text}}</div>
                    </a>
                </div>
            </ng-container>
        </div>

        <footer>
            <div class="row">
                <div class="column">
                    <span>{{currUser.userType}}</span>
                    <span>{{currUser.fullName}}</span>
                </div>
                <app-avatar class="user_img" [routerLink]="['/profile']" [model]="{name: currUser.fullName, imgUrl:currUser.avatar?currUser.avatarURL:''}"></app-avatar>
                <!-- <img class="user_img" [routerLink]="['/profile']"> -->
            </div>

            <div class="bottom_links">
                <ng-container *ngFor="let menuItem of bottomMenuItems">
                    <a *ngIf="canShow(menuItem)" [class.active]="detectSub(menuItem)" [routerLink]="menuItem.link" [routerLink]="[menuItem.link]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" (click)="menuChangePage(menuItem.link)">
                        <div class="text">{{menuItem.text}}</div>
                    </a>
                </ng-container>
                <div class="logout" (click)="logOut()">Log out</div>
            </div>
        </footer>
    </div>

</div>

<app-notifications (showOverlay)="openNotifOverlay()"></app-notifications>
<app-conversations (closeOverlay)="closeOverlay()" [isNewConversationViewOpen]="isNewConversationViewOpen" [isChatViewOpen]="isChatViewOpen" (newConversationViewIsOpen)="newConversationViewIsOpen()" (chatViewIsOpen)="openChat()">
</app-conversations>
<app-video-modal (closeOverlay)="closeOverlay()" (openOverlay)="videModalOpen()"></app-video-modal>
<app-pop-up></app-pop-up>