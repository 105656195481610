import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu/public-api';
import { FilterMap } from 'src/app/model/IndexQuery';
import { Table, column } from '../../model/Table'
import * as moment from "moment";
import {unix} from "moment";
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() model: any;
  @Input() selectedValues: Array<any> = [];
  @Input() classes: Array<string> = [];
  @Input() rows: Array<any> = []
  @Input() column: column[] = []

  //@Input() functions: Array<Function> = [] 
  @Input() refresher?: EventEmitter<void>;
  @Output() tableClicked = new EventEmitter()
  @Output() modelChange = new EventEmitter<any>()

  @Output() filtersChange = new EventEmitter<any>()

  @Input() totalElements: number = 1

  @Input() bulkActionsModel: Array<{ isPlus: boolean, btnText: string, imgUrl: string, function: CallableFunction }> = []
  @Output() pageChange = new EventEmitter()
  @ViewChild('mainChcbx') chcbx?: any

  @ViewChild('container') container?: any

  @ViewChild('bd') bd?: any
   
  @Input() public isContentLoading: boolean = false
  @Input() public noResults: boolean = false

  @Input() public defaultOnClick?:CallableFunction;
 
  public containerWidth?: number = 0
    //Mobile scrol start
  public touchstart: number = 0

  ngOnChanges(changes: any) {
      // if (changes.totalElements && changes.totalElements.currentValue !== undefined) this.validateLoading(changes.totalElements.currentValue, this.rows)
    // else if (changes.rows && changes.rows.currentValue !== undefined) this.validateLoading(this.totalElements, changes.rows.currentValue)
    // else if (changes.rows.currentValue && changes.totalElements.currentValue) this.validateLoading(changes.totalElements.currentValue, changes.rows.currentValue)
    this.getWidth()
    this.paginationRefresh()
    this.initCheckbox();
    //reinitialize chcecboxes
    if (changes.totalElements) {
      if (changes.totalElements.currentValue !== undefined && this.chcbx) {
        let tmp = undefined
        if (tmp !== undefined) clearTimeout(tmp)
        else {
          tmp = setTimeout(() => {
            this.chcbx.value = false;
            this.selectedValues = []
            this.initCheckbox();
          }, 0)
        } 
      }
    }
  }
  public checkboxModel: Array<{ rowId: number, active: boolean }> = []
  public isAllChecked: boolean = false
  @Input() public filter: FilterMap = {}
  // public initialized: boolean = false
  @Input() public currentPage: number = 1
  public pages: number = 1

  public cbValue: boolean = false
  constructor() {
  }
  public paginationCooldown: any
  public paginationRefresh() {
    //this.currentPage = 1
    this.pages = Math.ceil(this.totalElements / 15)
    // if(this.chcbx) this.chcbx.value = false
  }



  ngOnInit(): void {
    this.initCheckbox();
    this.pages = Math.ceil(this.totalElements / 15)
    if (this.refresher) this.refresher.subscribe(r => {
      //  if (this.rows.length == 0) this.isContentLoading = false
      this.initCheckbox();
      this.validateLoading(this.totalElements, this.rows)
      //  this.pages = Math.ceil(this.totalElements / 2)
      if (this.chcbx) this.chcbx.value = this.isAllChecked
    })
  }

  selectedList(source: any[], selection: string, key = 'id'): any[] {
    let ids = selection?.split(';');
    return source.filter(i => ids?.includes(`${i[key]}`));
  }

  initCheckbox() {
    this.checkboxModel = []
    for (let row of this.rows) {
      this.checkboxModel.push({ rowId: row.id, active: false })
    }
    //  this.pages = Math.ceil(this.totalElements / 2)
    // console.log(this.checkboxModel)
  }

  getColabolatorsCount(row: any, key: string) {
    const tmp = row[key].length
    if (tmp) return tmp
    else return 0
  }

  getRating(row: any, key: string) {
    const tmp = row[key]
    if (tmp) return tmp
    else return 0
  }

  public changeSelected(newValue: any) {
    let index = this.checkboxModel.findIndex(item => item.rowId == newValue.id)
    this.checkboxModel[index].active = !this.checkboxModel[index].active
    this.isBaExpanded()
  }

  public change(newValue: any) {
    this.changeSelected(newValue)
    if (!this.selectedValues.find(item => item == newValue)) {
      this.selectedValues.push(newValue);
      this.paginationRefresh()
      this.modelChange.emit(this.selectedValues);
    }
    else if (this.selectedValues.find(item => item == newValue)) {
      this.checkboxModel.find(item => item.rowId == newValue.id)?.active === false
      this.removeItem(newValue)
    }
    this.checkAllChange()
    this.isBaExpanded()
  }

  checkAllChange() {
    if (this.selectedValues.length == this.rows.length && this.isAllChecked == false) {
      this.isAllChecked = true;
      this.chcbx.value = true;
    }
    else if (this.isAllChecked == true && this.selectedValues.filter(item => item.active == false)) {
      this.isAllChecked = false
      this.chcbx.value = false
    }
  }

  public removeItem(item: any) {
    this.selectedValues.splice(this.selectedValues.indexOf(item), 1);
    this.paginationRefresh()
    this.modelChange.emit(this.selectedValues);
  }

  public checkAll() {
    this.isAllChecked = !this.isAllChecked
    if (this.isAllChecked == true) {
      this.checkboxModel.forEach(items => items.active == true)
      this.checkboxModel.map((item) => {
        if (item.active == false) item.active = true
      })
      this.selectedValues = this.rows.map((item) => {
        return item
      })
      if (this.chcbx) this.chcbx.value = false
      this.paginationRefresh()
      this.modelChange.emit(this.selectedValues)
    }
    else {
      this.checkboxModel.map((item) => {
        if (item.active == true) item.active = false
      })
      this.selectedValues = []
      this.paginationRefresh()

      this.modelChange.emit(this.selectedValues)
    }
  }

  //download array to display in listColor 
  getFiltersList(item: any) {
    //console.log("wywolano")
    if (item) {
      return item.filters
    } else {
      return [{ name: 'something gone wrong, please refresh the page', active: false, type: 0 }]
    }
  }

  public clearField(key: string) {
    delete this.filter[key]
    this.changeFil()
  }

  public clearFieldDate(key: any) {
    delete this.filter[key + "_s"]
    delete this.filter[key + "_e"]
    this.changeFil()
  }

  //Filters events
  //get text form filter search
  getTextInput(text: string, key: string) {
    this.filter[key] = text
    this.changeFil()
  }

  getSelectedListColor(selected: Array<any>, key: string) {
    let tmp: Array<any> = []
    selected.forEach(elem => {
      tmp.push(elem.type || elem.name)
    })
    this.filter[key] = tmp.join(";")
    this.changeFil()
  }

  getSelectedListUser(selected: Array<any>, key: string) {
    let tmp: Array<number> = []
    selected.forEach(elem => {
      tmp.push(elem.id)
    })
    this.filter[key] = tmp.join(";")
    this.changeFil()
  }

  getSelectedDate(date: any, key: string) {
    if (date) {
      this.filter[key + "_s"] = date.startDate
      this.filter[key + "_e"] = date.endDate
      this.changeFil()
    }
  }
  getComments(data: any, key: string) {
    if (data) {
      this.filter[key] = data.active
      this.changeFil()
    }
  }
  getNumber(data: any, key: string) {
    //if (data!=undefined) {
    this.filter[key] = data
    this.changeFil()
    //}
  }
  getStar(data: any, key: string) {
    // console.log(data)
    if (data) {
      this.filter[key] = data
      this.changeFil()
    }
  }

  setCalendarValue(col: column){
    const key = col.filterKey ?? col.key;
    if(this.filter[`${key}_s`] && this.filter[`${key}_e`]){
      let startDate = unix(Number(this.filter[`${key}_s`])),
          endDate = unix(Number(this.filter[`${key}_e`]));
      return {
        chosenLabel: `${startDate.format('DD-MM-YYYY')} - ${endDate.format('DD-MM-YYYY')}`,
        startDate,
        endDate
      };
    }
    return undefined;
  }

  changeFil() {
    //  console.log(this.filter)
    this.paginationRefresh()
    this.filtersChange.emit(this.filter)
    // this.noResults = false
    // this.isContentLoading = true
  }

  handleClick(column: column, row: any) {
    if (column.onClick) column.onClick(row);
    else if(this.defaultOnClick&&column.type!=8) this.defaultOnClick(row);
  }
  //Bulk actions
  public isBaExpanded() {
    if (this.selectedValues.length > 0) return true
    else return false
  }
  //pagination
  public getCurrPage(page: number) {
    this.currentPage = page
    // if (page) this.isContentLoading = true
    this.pageChange.emit(page)
  }
  getWidth() {
    if (this.container) this.containerWidth = this.container.nativeElement.offsetWidth
  }

  public getLabel(i: any, p: any) {
    if (typeof i.label === 'function') return i.label(p);
    else return i.label;
  }
  public getAvatarData(user: any) {
    return { name: user.fullName, imgUrl: user.avatar ? user.avatarURL : '' };
  }

  public validateLoading(totalElements: number, rows: any) {
    //   if (totalElements) {
    //     if (totalElements !== undefined && totalElements > 0) {
    //      // this.isContentLoading = false
    //       this.noResults = false
    //     }
    //     else if (totalElements !== undefined && totalElements == 0) {
    //       this.noResults = true
    //  //     this.isContentLoading = false
    //     }
    //   } else if (rows) {
    //     //adms
    //     if (rows !== undefined && rows.length > 0) {
    //      // this.isContentLoading = false
    //       this.noResults = false
    //     }
    //     else if (rows !== undefined && rows.length == 0) {
    //       this.noResults = true
    //  //     this.isContentLoading = false
    //     }
    //   }
  }

  public getTouchStart(event: any) {
    this.touchstart = event.changedTouches[0].clientY
  }
  public isreadytimeOut: boolean = true
   
  public resetTimeOut() {     
    setTimeout(() => { this.isreadytimeOut = true }, 200)
  }

  public canScroll(event: any) {
       if ((this.isreadytimeOut && this.container.nativeElement.getBoundingClientRect().top > 65) && (event.changedTouches[0].clientY < this.touchstart)) {
       this.scrollToTable()
      this.isreadytimeOut = false
      this.resetTimeOut()
     } 
  }


  public scrollToTable() {
    if (window.innerWidth < 768 && this.container && this.container.nativeElement.getBoundingClientRect().top > 65) {
      if (this.bd.nativeElement.path !== undefined) { //behavior: 'smooth', block: "end"
        this.bd.nativeElement.path[2].scrollIntoView()
      } else if (this.bd.nativeElement.target !== undefined) {
        this.bd.nativeElement.target.scrollIntoView()
      } else if (this.bd.nativeElement) {
        try { this.bd.nativeElement.scrollIntoView( ) }
        catch (err) {
          // console.log(err)
        }
      }
    }  
   }

}