import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {

  constructor(private http: HttpClient) { }

  public getVimeoMetadata(id: any) {
    return this.http.get(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${id}`);
  }
}
