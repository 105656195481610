<div *ngIf="!isToogle" [ngClass]="{'new_message': newMessage}" class="container row a_center" [class.clearable]="clearable" (swipeleft)="clearSwipe($event)" [class.clearLeftAnim]="clearLeftAnim" [class.clearRightAnim]="clearRightAnim" #notifElement>
    <div class="row a_center j_space">
        <div class="row a_center">
            <div *ngIf="!isToogle" class="avatar">
                <div class="img_wrap" *ngIf="avatarUrl">
                    <img [src]="avatarUrl">
                </div>
                <div class="letter" *ngIf="!avatarUrl">{{getUsernameFirtsLetter()}}</div>
            </div>
            <div class="column">
                <span>{{head}}</span>
                <span>{{getName()}}</span>
            </div>
        </div>
        <div class="bottom" *ngIf="!isToogle">
            <span class="time">{{elapsed()}}</span>
        </div>
        <div class="clearable_tick hidden-sm hidden-xs" *ngIf="clearable" (click)="clearClick($event)">
            <img src="assets/svg/clear_tick.svg" alt="Clear">
        </div>
    </div>
</div>

<div class="toogle_container" *ngIf="isToogle">
    <mat-radio-button [checked]="isRadioTrue">
        <div class="container row a_center toogle">
            <div class="row a_center j_space">
                <div class="row a_center">
                    <div class="column">
                        <span>{{head}}</span>
                        <span>{{getName()}}</span>
                    </div>
                </div>
                <div class="bottom" *ngIf="!isToogle">
                    <span class="time">{{elapsed()}}</span>
                </div>

            </div>
        </div>

    </mat-radio-button>
</div>