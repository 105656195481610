<div class="icon_header">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='body'>
    <div class="chart_wrap">
        <app-pie-chart [pieChartData]="value" [pieChartWidth]="297"></app-pie-chart>
    </div>
    <div class="label">{{value.label}}</div>
    <div class="title">{{value.title}}</div>
    <div class="percentage_list" *ngIf="value.percentageView.length">
        <div class="percentage_wrap" *ngFor="let singlePercentage of value.percentageView">
            <div class="percentage">{{singlePercentage.percentage}}%</div>
            <div class="percentage_desc">{{singlePercentage.desc}}</div>
        </div>
    </div>
    <app-button-secondary [isGreen]="false" [isText]="true" buttonText="Close" [mat-dialog-close]="false"></app-button-secondary>
</div>