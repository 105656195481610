<div class="text_wrap center">
    <h1>Edit Front-End Themes</h1>
    <h4>Please note: You can only upload an .svg icon and .jpg image for each item</h4>
</div>
<div class="items_wrap">
    <div class="item" *ngFor="let t of themes">
        <div class="row1">
            <app-tick-box [text]="''" [(value)]="t.active"></app-tick-box>
            <div class="edit_icon hidden-sm hidden-xs" (click)="fIconInput.click()">
                <div class="img_wrap">
                    <img *ngIf="t.icon" [src]="t.icon.getUrl()" alt="Icon" class="icon">
                    <input type="file" #fIconInput [hidden]="true" (change)="uploadIcon($event, t)">  
                </div>   
            </div>
            <div class="edit_image hidden-sm hidden-xs" (click)="fImageInput.click()">
                <div class="img_wrap">
                    <img *ngIf="t.image" [src]="t.image.getUrl()" alt="Image" class="image">
                    <input type="file" #fImageInput [hidden]="true" (change)="uploadImage($event, t)">    
                </div> 
            </div>
        </div>
        <div class="inputs_wrap">
            <app-input-field [placeholderText]="'Link'" [(model)]="t.link"></app-input-field>
            <app-input-field [placeholderText]="'Theme name'" [(model)]="t.name"></app-input-field>
            <app-input-field [placeholderText]="'Short description (max 300 characters)'" limit="300" [(model)]="t.description" [class]="'full'"></app-input-field>
        </div>
        <div class="btn_group_wrap">
            <div class="edit_images_wrap visible-sm visible-xs">
                <div class="edit_images">
                    <div class="edit_icon" (click)="fIconInput.click()">
                        <div class="img_wrap">
                            <img *ngIf="t.icon" [src]="t.icon.getUrl()" alt="Icon" class="icon">
                            <input type="file" #fIconInput [hidden]="true" (change)="uploadIcon($event, t)"> 
                        </div>   
                    </div>
                    <div class="edit_image" (click)="fImageInput.click()">
                        <div class="img_wrap">
                            <img *ngIf="t.image" [src]="t.image.getUrl()" alt="Image" class="image">
                            <input type="file" #fImageInput [hidden]="true" (change)="uploadImage($event, t)">    
                        </div> 
                    </div>
                </div>
            </div>
            <div class='btn_group'>
                <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addNew()"></app-button-secondary>
                <app-button-secondary [isGreen]="false" [imgUrl]="'assets/svg/minus-icon.svg'" (click)="del(t)"></app-button-secondary>
            </div>
        </div>
    </div>
</div>
<div class="cta_wrap">
    <app-button-primary text="Save" (click)="send()"></app-button-primary>
    <div class="grey_btn" routerLink="/admins">cancel</div>
</div>
