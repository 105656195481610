import { Component, OnInit } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { PartialService } from 'src/app/services/partial.service';
import { GlobalsAPI } from 'src/app/services/responsible/globals.service';
import { LoaderComponent } from '../../../partials/loader/loader.component';
@Component({
    selector: 'app-login-settings',
    templateUrl: './login-settings.component.html',
    styleUrls: ['../admins.component.scss', './login-settings.component.scss']
})
export class LoginSettingsComponent implements OnInit {
    public settings = {
        login_heading:'',
        login_subheading:'',
        login_riaa_logo:'',
        login_rr_logo:'',
        login_error_msg:'',
        login_rfc_link:'',
        forgot_pass_subheading:'',
        set_pass_subheading:'',
        login_banner:'',
        login_featured:false,
        login_featured_heading:'',
        login_featured_stars:1,
        login_featured_logo:'',
        login_research:false,
        login_research_heading:'',
        login_research_subheading:'',
        login_research_link:'',
        login_research_link_text:'',

        default_product_banner:'',
        default_provider_banner:'',
        default_provider_logo:'',
    }

    constructor(
        private globalsApi:GlobalsAPI,
        private partials:PartialService,
    ){
        this.globalsApi.loginData().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.settings.login_heading = response.login_heading;
            this.settings.login_subheading = response.login_subheading;
            this.settings.login_riaa_logo = response.login_riaa_logo;
            this.settings.login_rr_logo = response.login_rr_logo;
            this.settings.login_error_msg = response.login_error_msg;
            this.settings.login_rfc_link = response.login_rfc_link;
            this.settings.forgot_pass_subheading = response.forgot_pass_subheading;
            this.settings.set_pass_subheading = response.set_pass_subheading;
            this.settings.login_banner = response.login_banner;
            this.settings.login_featured = response.login_featured==='true';
            this.settings.login_featured_heading = response.login_featured_heading;
            this.settings.login_featured_stars = Number(response.login_featured_stars);
            this.settings.login_featured_logo = response.login_featured_logo;
            this.settings.login_research = response.login_research==='true';
            this.settings.login_research_heading = response.login_research_heading;
            this.settings.login_research_subheading = response.login_research_subheading;
            this.settings.login_research_link = response.login_research_link;
            this.settings.login_research_link_text = response.login_research_link_text;

            this.settings.default_product_banner = response.default_product_banner;
            this.settings.default_provider_banner = response.default_provider_banner;
            this.settings.default_provider_logo = response.default_provider_logo;
            
            LoaderComponent.show.emit(false);
        })
    }

    ngOnInit():void{}

    public save(){
        this.globalsApi.set(this.settings).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Settings have been updated', timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error' ,message:err.error.message, timeout: 5000 });
        })
    }
}
