import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { OverlayComponent } from '../overlay/overlay.component'
import { Subscription } from 'rxjs';
import { ThemeExclusionComponent } from 'src/app/form-builder/elements/theme-exclusion/theme-exclusion.component';
@Component({
  selector: 'app-filters-list',
  templateUrl: './filters-list.component.html',
  styleUrls: ['./filters-list.component.scss'],
  animations: [
    trigger(
      'filtersOutIn',
      [
        transition(
          ':enter',
          [
            style({ transform: 'scaley(0) ', opacity: 0 }),
            animate('0.3s ease-out',
              style({ transform: 'scaley(1)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'scaley(1)', opacity: 1 }),
            animate('0.3s ease-in',
              style({ transform: 'scaley(0)', opacity: 0 }))
          ]
        )
      ]
    )
  ],
})



export class FiltersListComponent implements OnInit {
  @Input() public list: Array<any> = [{ id: 1,name: 'Admin', active_field: false, imgUrl: "../../../assets/svg/10.svg" }, { id: 2, name: 'Admin', active_field: false, imgUrl: "../../../assets/svg/10.svg" }, { id: 3, name: 'RIAA Admin', active_field: false, imgUrl: "../../../assets/svg/10.svg" }, { id: 4, name: 'Collaborator', active_field: false, imgUrl: "../../../assets/svg/10.svg" }, { id: 5, name: 'Member', active_field: false, imgUrl: "../../../assets/svg/10.svg" }, { id: 6, name: 'Invoced', active_field: false, imgUrl: "../../../assets/svg/10.svg" }, { id: 7, name: 'Paid', active_field: false, imgUrl: "../../../assets/svg/10.svg" }];
  @Input() isAvatar: boolean = false
  @Input() imgUrl: string = '../../../assets/svg/star.svg'
  @Input() filterName: string = "Choose"
  @Input() limit: number = 5
  @Input() isRating: boolean = false
  @Input() isComment: boolean = false
  @Input() isColorList: boolean = false
  @Input() isMultiSelect: boolean = false
  @Input() isProductCard: boolean = false
  @Input() isDefaultValues: boolean = true
  @Input() public selectedValuesObject: Array<any> = []
  @Input() public isOpen: boolean = false
  @Input() widnowWidth?: number = 82
  @Input() checkById:boolean = false;
  @Input() emitSelectedItems:boolean = false;
  @Input() disabled:boolean = false;

  @Output() public change = new EventEmitter<Array<any>>()
  @Output() public changeStar = new EventEmitter<number>()
  @Output() public clearField = new EventEmitter()
  @Output() public showOverlay = new EventEmitter()

  @ViewChild('itemList') itemList?: ElementRef
  constructor(public scroll: BodyScrollService) { }
  public isCommentEnabled = false;
  public ratingValue: number = 0
  public selectedItems: string[] = []
  public oneLine: string = ''
  public ratingPerv: number = 0
  public left: boolean = false
  public right: boolean = false
  public commentValue: any = false
  public isAllSelected: boolean = false
  //position in table
  public positionStyle: string = 'left: unset'

  ngOnInit(): void {
    this.getOverlayState
    this.limit = this.filterName.length
    this.initComment()
    if (this.isDefaultValues) this.initSelectedValues()
  }

  public getOverlayState: Subscription = OverlayComponent.clicked.subscribe((res) => {
    if (!this.isComment) this.open(false)
    else {
      this.isOpen = false
      OverlayComponent.show.emit(false)
      this.scroll.enableBodyScroll()
    }
  })

  ngOnDestroy() {
    this.getOverlayState.unsubscribe()
  }

  initComment() {
    if (this.isComment) {
      this.list = [{ name: 'new comments' }]
      this.oneLine = 'Comments'
    }
  }
  private getAllSelected(item: any, push: boolean) {
    if (push) {
      this.selectedValuesObject.push(item)
    } else {
      if(this.isColorList) this.selectedValuesObject.splice(this.selectedItems.indexOf(item), 1)
      else 
        if(this.checkById) this.selectedValuesObject.splice(this.selectedValuesObject.findIndex(i => item.id == i.id), 1);
        else this.selectedValuesObject.splice(this.selectedValuesObject.indexOf(item), 1);
    }
 
  }

  public onChange(value: any) {
     if (!this.isComment) {
      if (this.isMultiSelect) {
        this.getSelected(value)
        if(this.emitSelectedItems)
          this.change.emit(this.selectedItems);
        else
          this.change.emit(this.selectedValuesObject)
      } else {
        this.getSelected(value)
        if (this.isComment) {
        }
        else this.change.emit(value)
      }
    } else {
      this.commentValue = !this.commentValue
      this.selectedItems = this.list
      this.oneLine = 'comments'
      this.getSelected(value)
      if (this.commentValue == true) {
        this.change.emit(this.selectedValuesObject);
        this.isCommentEnabled = true
      }
      else {
        // this.isCommentEnabled = false;
        //  this.isOpen = false;
        this.clearField.emit();
        this.scroll.enableBodyScroll()
      }
      // this.change.emit(this.selectedValuesObject)
      this.change.emit(this.commentValue)

      // if (this.isCommentEnabled) {
      //   this.commentValue = !this.commentValue
      //   this.selectedItems = this.list
      //   this.getSelected(value)
      //   if(this.commentValue == true) this.change.emit(this.selectedValuesObject)
      //   else  this.closeComment()
      //   // this.change.emit(this.selectedValuesObject)
      //    this.change.emit(this.commentValue)
      // }
    }
  }

  public convert() {
  if(!this.isComment)  this.oneLine = ''
    const selectedItemsClone: Array<any> = []
    if (!this.isComment && !this.isColorList) {
      this.selectedValuesObject.forEach((elem) => {
        if (this.selectedItems.includes(elem.id)) selectedItemsClone.push(elem.name)
      })
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (i == 0) {
          this.oneLine = selectedItemsClone[0]
        } else {
          this.oneLine = this.oneLine + ", " + selectedItemsClone[i]
        }
      }
      if (this.oneLine.length > this.limit) {
        this.oneLine = this.oneLine.split("").splice(0, this.limit).join("") + "..."
      }
    } else if (this.isColorList) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (i == 0) {
          this.oneLine = this.selectedItems[i]
        } else {
          this.oneLine = this.oneLine + ", " + this.selectedItems[i]
        }
      }
      if (this.oneLine.length > this.limit) {
        this.oneLine = this.oneLine.split("").splice(0, this.limit).join("") + "..."
      }
    }
    else if (this.isComment) {
      this.oneLine = "Comments"
    }
  }

  public getSelected(item: any) {
    //console.log(item)
    if (!this.isComment && !this.isColorList) {
      if (this.selectedItems.find(val => val == item.id)) {
          this.selectedItems.splice(this.selectedItems.indexOf(item.id), 1)
         this.getAllSelected(item, false)
      } else {
        this.getAllSelected(item, true)
        this.selectedItems.push(item.id)
      }
      this.checkSelectAll()
      //  console.log(this.selectedItems)
    } else if (this.isColorList) {
      if (this.selectedItems.find(val => val == item.name)) {
        this.selectedItems.splice(this.selectedItems.indexOf(item.name), 1)
        this.getAllSelected(item, false)
      } else {
        this.getAllSelected(item, true)
        this.selectedItems.push(item.name)
      }
      this.checkSelectAll()
    }
    this.convert()
  }

  open(open: boolean) {
    this.isOpen = open
    if (this.isComment) {
      //this.isCommentEnabled = true
      //   this.change.emit([false])
      if (!this.isOpen) {
        this.scroll.enableBodyScroll()
        this.positionStyle = ''
      } else {
        this.scroll.disableBodyScroll()
      }
      if (!this.isProductCard) OverlayComponent.show.emit(true)
    } else {
      if (!this.isOpen) {
        this.scroll.enableBodyScroll()
        this.positionStyle = ''
      } else {
        this.scroll.disableBodyScroll()
      }
      if (!this.isProductCard) OverlayComponent.show.emit(open)
    }
    this.setSelectPostion()
  }

  clear(clear: boolean) {
    if (clear == true && !this.isComment) {
      this.selectedItems = []
      this.selectedValuesObject = []
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].active_field == true) {
          this.list[i].active_field = false
        }
      }
    }
    this.isAllSelected = false
    this.clearField.emit()
    if(this.isComment){ 
      this.oneLine = 'comments'
      this.commentValue = false
    }
  }

  public setSelectPostion() {
    if (this.itemList && this.widnowWidth && window.innerWidth > 767 && this.isOpen) {
      const selectRect = this.itemList.nativeElement.getBoundingClientRect();
      const selectR = selectRect.right;
      let windowWidth = window.innerWidth;
      let menuWidth = 82
      if (this.widnowWidth !== undefined) {
        windowWidth = this.widnowWidth;
        menuWidth = window.innerWidth - this.widnowWidth
      }
      const spaceToLeft = windowWidth - (windowWidth - selectR + 290) - menuWidth
      const spaceToRight = windowWidth - selectR + menuWidth
      // console.log('lewo',spaceToLeft)    
      // console.log('prawo',spaceToRight)
      if (spaceToLeft < 10) {
        if (this.left == false) {
          this.left = true
          this.right = false
        }
        this.positionStyle = 'transform: translateX(' + (Math.abs(spaceToLeft) + 20) + 'px)'
      }
      else if (spaceToRight < 10) {
        if (this.right == false) {
          this.right = true
          this.left = false
        }
        this.positionStyle = 'transform: translateX( -' + (Math.abs(spaceToRight) + 20) + 'px)'
      }
      else if ((spaceToLeft >= 10 && !this.left) && (spaceToRight >= 10 && !this.right)) {
        this.right = false
        this.left = false
        this.positionStyle = ''
      }
    }
  }

  public animFinish(event: any) {
    if (this.isOpen == false) this.positionStyle = ''
  }

  public validateActive() {
    if (!this.isComment) {
      if (!this.isOpen && this.selectedItems.length > 0) {
        return true
      } else {
        return false
      }
    } else {
      return this.commentValue
    }
  }

  getRating(rating: number) {
    if (rating !== this.ratingPerv) {
      this.ratingPerv = rating
      this.ratingValue = rating
      this.changeStar.emit(this.ratingValue)
    }
  }

  public getValue(val: any) {
    if (!this.isComment && !this.isColorList) {
      let tmp = this.selectedItems.find(item => item == val.id)
      if (tmp !== undefined) return true
      else return false
    } else if (this.isColorList) {
      let tmp = this.selectedItems.find(item => item == val.name)
      if (tmp !== undefined) return true
      else return false
    }
    else {
      if (this.commentValue) return true
      return false
    }
  }

  public initSelectedValues() {
    this.selectedValuesObject.forEach((item) => {
      this.getSelected(item)
    })
  }

  public selectAll() {
    this.isAllSelected = !this.isAllSelected
    if (this.isAllSelected) {
      this.selectedValuesObject = []
      this.selectedItems = []
      this.list.forEach((item) => {
        this.selectedItems.push(item.id);
        this.selectedValuesObject.push(item)
      })
    } else {
      this.selectedValuesObject = []
      this.selectedItems = []
    }
    this.convert()
    this.change.emit(this.selectedValuesObject)
  }

  public checkSelectAll() {
    if (this.isAllSelected == false && this.selectedItems.length == this.list.length) this.isAllSelected = true
    if (this.isAllSelected == true && this.selectedItems.length !== this.list.length) this.isAllSelected = false
  }

  // public closeComment(item: any) {
  //   this.isCommentEnabled = false;
  //   this.isOpen = false;
  //   this.clearField.emit(item);
  //   this.scroll.enableBodyScroll()
  // }
  public closeComment() {
    // this.isCommentEnabled = false;
    // this.isOpen = false;
    // this.clearField.emit();
    // this.scroll.enableBodyScroll()
    // // this.clear(true)
    // this.clearField.emit();
    // this.scroll.enableBodyScroll()
  }
}
