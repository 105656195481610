import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, DoCheck, HostListener, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment'
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-form-builder-calendar',
  templateUrl: './form-builder-calendar.component.html',
  styleUrls: ['./form-builder-calendar.component.scss']
})
export class FormBuilderCalendarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public selected:any = {};// { startDate: Moment, endDate: Moment };// = { startDate: moment(), endDate: moment() }
  @Input() public disabled: boolean = false;
  @Input() public fullwidth: boolean = false;
  @Input() public input?: string;
  @Output() public date = new EventEmitter<{ startDate: string, endDate: string }>()

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective?: DaterangepickerDirective;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.disabled) this.detectWrap()
  }

  private sub:any;
  constructor(private el: ElementRef){
  //   this.sub = setInterval(() => {
  //     // console.log(this.input);
  //     if (this.input) {
  //       // console.log(this);
  //       let answer = this.input.split(';');
  //       this.selected.startDate = new Date(Number(answer[0]));
  //       this.selected.endDate = new Date(Number(answer[1]));
  //       this.input = '';
  //     }
  //   }, 500);
  }
  public days: any = {
    daysOfWeek: ["S ", "M ", "t ", "w", "t", "f", "s"],
    format: 'DD-MM-YYYY',
    monthNames: moment.months(),
    inline: true,
    adaptivePosition: true
  }
  ngOnChanges():void{
      if(this.input){
        let answer = this.input.split(';');
        if(isNaN(Number(answer[0]))){
          this.selected.startDate = moment(answer[0]);
          this.selected.endDate = moment(answer[1]);
        } else {
          this.selected.startDate = moment(Number(answer[0]));
          this.selected.endDate = moment(Number(answer[1]));
        }
        this.input = '';
      } else this.selected = '';
  }
  ngDoCheck(): void {
    if (!this.disabled) {
      if (this.el.nativeElement[0]) {
        this.el.nativeElement[0].classList.remove('hidden')
        this.detectWrap()
      }
    }
  }
  openDatepicker() {
    if (this.pickerDirective) {
      this.el.nativeElement = document.getElementsByClassName("md-drppicker")
      this.pickerDirective.open()
    } else {
      this.openDatepicker()
    }
  }
  ngOnInit(): void {
    // if (this.input) {
    //   let answer = this.input.split(';');
    //   this.selected.startDate = new Date(Number(answer[0]));
    //   this.selected.endDate = new Date(Number(answer[1]));
    // }
  }
  ngOnDestroy():void{
    clearInterval(this.sub);
  }
  ngAfterViewInit(): void {
    if (!this.disabled) {
      setTimeout(() => {
        this.openDatepicker()
        this.detectWrap()
      }, 400)
    }
  }
  detectWrap() {
    if (this.el.nativeElement[0]) {
      if (this.el.nativeElement[0].getBoundingClientRect().width <= 667.9) {
        this.el.nativeElement[0].classList.add('wrap')
      } else {
        this.el.nativeElement[0].classList.remove('wrap')
      }
    }
  }

  selectedDate(newValue: { startDate: Moment, endDate: Moment }) {
    this.date.emit({ startDate: newValue.startDate.format('YYYY-MM-DD'), endDate: newValue.endDate.format('YYYY-MM-DD') })
  }
}
