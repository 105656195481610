import { Application, application_response } from "./Application";
import { FormQuestion, form_question_response } from "./FormQuestion";

export interface share_group_response{
    id:number;
    email:string;
    key:string;
    application_id:number;
    full:boolean;
    updated_at?:string;

    application?:application_response;
    questions?:form_question_response[];
}
export class ShareGroup{
    public id:number = 0;
    public email:string = '';
    public key:string = '';
    public applicationId:number = 0;
    public updatedAt:Date = new Date();
    public full:boolean = false;

    public application?:Application;
    public questions:FormQuestion[] = [];

    public map(data:share_group_response){
        this.id = data.id;
        this.email = data.email;
        this.key = data.key;
        this.applicationId = data.application_id;
        this.full = data.full;
        if(data.updated_at) this.updatedAt = new Date(data.updated_at);

        if(data.application) this.application = Application.create(data.application);
        this.questions = [];
        for(let q of data.questions??[])
            this.questions.push(FormQuestion.create(q));

        return this;
    }
    public responsify():share_group_response{
        return {
            id: this.id,
            email: this.email,
            key: this.key,
            application_id: this.applicationId,
            full: this.full,

            application: this.application?.responsify(),
            questions:(() => {
                let arr:form_question_response[] = [];
                for(let q of this.questions) arr.push(q.responsify());
                return arr;
            })()
        }
    }
    public static create(data:share_group_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public get remaining():string{
        let terminus = this.updatedAt.getTime()+86400000,
            now = new Date().getTime();
        let hours = Math.floor((terminus-now)/1000/60/60),
            minutes = Math.floor((terminus-now)/1000/60%60);

        return `${hours}:${('0'+minutes).slice(-2)}`;
    }
}