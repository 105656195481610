<!-- <app-button-secondary [imgUrl]="'assets/svg/close.svg'" [isGreen]="false" class='close' [mat-dialog-close]="false"></app-button-secondary> -->
<div class="icon_header">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class="content">
    <h4>{{data.title}}</h4>
    <h3>Conditional Logic</h3>
    <div class='card'>
        <div class='top row'>
            <app-form-select class="select_grey no" [isLabel]="false" [placeholderText]="''" [(model)]="hidden" [isNoneValue]="false" [isMulitSelect]="false" [isDefaultSelect]="false" [list]="hiddenSelect"></app-form-select>
            <input disabled [value]="data.title">
            <span>If the user:</span>
        </div>
        <hr class="hr_first">
        <div class='condition  ' *ngFor="let group of conditions; let i = index">
            <div class="row a_center">
                <app-form-select #select (click)="setClick($event);" (animationEnd)="scroll()" (modelChange)="setQuestion(group, $event.object)" [list]="conditionable" [isLabel]="false" [isMulitSelect]="false" [isNoneValue]="false" placeholder="Select a question" [model]="getInitialConditionable(group)"></app-form-select>
                <app-button-secondary [isText]="true" buttonText="RESET" *ngIf="i==0" (click)="reset()"></app-button-secondary>
                <app-button-secondary [isText]="true" buttonText="DELETE" *ngIf="i>0" (click)="removeGroup(i)"></app-button-secondary>
            </div>
            <ng-container *ngIf="group.target">
                <div class='subcondition row a_center' *ngFor="let condition of group.conditions; let j = index">
                    <div class='no_condition' *ngIf="!condition.field&&group.target.custom<3" style='flex-grow:1;'>
                        <app-form-select #select (click)="setClick($event);" (animationEnd)="scroll()" [list]="group.answerList" (modelChange)="setAnswer(condition, $event.object)" [model]="getInitialAnswer(group, condition)"></app-form-select>
                    </div>
                    <ng-container *ngIf="['radio','check'].includes(condition.field?.type!)">
                        <app-form-select #select (click)="setClick($event);" (animationEnd)="scroll()" class="select_grey medium_select" [list]="radioSelect" (modelChange)="setCondition(condition, $event.value, condition.field?.type!)" [isLabel]="false" [isMulitSelect]="false"
                            placeholder="Selects" [model]="{name:condition.condition==='true'?'Selected':'Unselected'}"></app-form-select>
                        <app-form-select #select (click)="setClick($event);" (animationEnd)="scroll()" [list]="group.answerList" (modelChange)="setAnswer(condition, $event.object)" [isLabel]="false" [isMulitSelect]="false" placeholder="Select an answer" [model]="getInitialAnswer(group, condition)"></app-form-select>
                    </ng-container>
                    <ng-container *ngIf="['slider-numeric','slider-percent'].includes(condition.field?.type!)">
                        <div class="number_container">
                            <app-form-select #select (click)="setClick($event);" (animationEnd)="scroll()" class="select_grey" [list]="group.answerList" (modelChange)="setAnswer(condition, $event.object)" [isLabel]="false" [isMulitSelect]="false" [model]="getInitialAnswer(group, condition)"></app-form-select>
                            <span>SELECTS RANGE OF</span>
                            <app-number-field [(model)]="condition.rangeStart"></app-number-field>
                            <span>AND</span>
                            <app-number-field [(model)]="condition.rangeEnd"></app-number-field>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="group.target.custom>2">
                        <app-form-select #select (click)="setClick($event);" (animationEnd)="scroll()" class="select_grey medium_select" [list]="radioSelect" (modelChange)="setCondition(condition, $event.value, 'custom')" [isLabel]="false" [isMulitSelect]="false" placeholder="Selects"
                            [model]="{name:condition.condition==='true'?'Selected':'Unselected'}"></app-form-select>
                        <app-form-select #select (click)="setClick($event);" (animationEnd)="scroll()" [list]="group.answerList" (modelChange)="setCustom(condition, $event.object.id)" [isLabel]="false" [isMulitSelect]="false" placeholder="Select an answer" [model]="getInitialAnswer(group, condition)"></app-form-select>
                    </ng-container>
                    <div class="btns_container">
                        <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" (click)="addField(group)"> </app-button-icon>
                        <app-button-icon [ngClass]="{'disabled': group.conditions.length<2 }" [imgUrl]="'assets/svg/minus-icon.svg'" [disabled]="group.conditions.length<2" (click)="removeField(group, j)"> </app-button-icon>
                    </div>
                </div>
            </ng-container>
            <hr class="hr_second">

        </div>
        <app-form-builder-button buttonText="Add another condition " (click)="addGroup(); setNewSelect()"></app-form-builder-button>

    </div>
    <div class="column a_center ">
        <app-button-primary [text]=" 'save' " [mat-dialog-close]="{ condis: conditions, hidden: hidden.value }"></app-button-primary>
        <app-button-secondary [isGreen]="false " [isText]="true " buttonText="Cancel " [matDialogClose]="false"></app-button-secondary>
    </div>
</div>