<div class="bcg" *ngIf="isOpen" (click)="isOpen= false"></div>
<div class="content">
    <div class="row a_center color_btn pos_rel" (click)="canOpen?isOpen=true:false" [ngClass]="{
        'grey': ['Admin','Not Required','In Progress','Recertification Upcoming','Disclosures Upcoming'].includes(selected.name),
        'purple': ['IVP Admin','IVP Assessment'].includes(selected.name),
        'd_blue': ['RIAA Admin','RIAA Assessment','More Info'].includes(selected.name),
        'orange': ['Collaborator','Outstanding','Declined','Decertified','Disclosures Now Due','Disclosures Outstanding','Expired'].includes(selected.name),
        'l_blue': ['Member','Invoiced','New Application','Recertification Updated','Updated','Material Change','Disclosures Updated'].includes(selected.name),
        'green': ['Paid','Approved'].includes(selected.name),
        'reddish': ['Did not proceed'].includes(selected.name)
    }">
        <span>{{selected.name}}</span>
    </div>
    <div #itemList class="column">
        <div class="list_container" (click)="isOpen= false" *ngIf="isOpen" [@filtersOutIn] (@filtersOutIn.done)="animationEnd.emit()">
            <ng-container>
                <div class="row a_center pos_rel" *ngFor="let item of list" [ngClass]="{'active':item.active,
                    'grey': ['Admin','Not Required','In Progress','Recertification Upcoming','Disclosures Upcoming'].includes(item.name),
                    'purple': ['IVP Admin','IVP Assessment'].includes(item.name),
                    'd_blue': ['RIAA Admin','RIAA Assessment','More Info'].includes(item.name),
                    'orange': ['Collaborator','Outstanding','Declined','Decertified','Disclosures Now Due','Disclosures Outstanding','Expired'].includes(item.name),
                    'l_blue': ['Member','Invoiced','New Application','Recertification Updated','Updated','Material Change','Disclosures Updated'].includes(item.name),
                    'green': ['Paid','Approved'].includes(item.name),
                    'reddish': ['Did not proceed'].includes(item.name)
                }">
                    <mat-checkbox (click)="onChange(item)" [(ngModel)]="item.active">
                        <span class="row-label">{{item.name}}</span>
                    </mat-checkbox>
                </div>
            </ng-container>
        </div>
    </div>
</div>