import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss']
})
export class RadioButtonGroupComponent implements OnInit {
  @Input() public singular?: boolean = false;
  @Input() public value: any = "no value";
  @Input() public label: string = '';
  @Input() public list: any = [{ name: 'tessst', active: false }, { name: 'testjedensascie', active: false }, { name: 'ss', active: false }, { name: 'test', active: false }, { name: 'test', active: false }, { name: 'test', active: false }, { name: 'test3', active: false }];
  @Input() public checked:boolean = false;
  @Input() public disabled:boolean = false;
  @Output() change: EventEmitter<MatRadioChange> = new EventEmitter
  public selected = ''
  constructor() { }

  ngOnInit(): void {}
  onChange($event: MatRadioChange) {
     this.change.emit($event);
  }

}
