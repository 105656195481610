<div class='backdrop' *ngIf="display&&expanded"></div>
<div class='form-menu' *ngIf="display" [class.expanded]="expanded" [class.notifOpen]="notifOpen" [ngClass]="{'assessment': isAssessment}">
    <div class='top'>
        <div class="burger_btn" [class.expanded]="expanded">
            <app-button-secondary class="openMenu" [isText]="true" [isGreen]="false" [isWhite]="true" buttonText="menu" [imgUrl]="'assets/svg/main-menu.svg'" (click)="toggleMainMenu()"></app-button-secondary>
            <div class="close_wrap" (click)="closeMenu()">
                <app-button-icon class="closeMenu visible-xs" [imgUrl]="'assets/svg/close_menu_cross.svg'">
                </app-button-icon>
            </div>
        </div>
        <svg-icon routerLink="/" class='banner visible-xs' [src]="'assets/svg/rr-logo-full.svg'"></svg-icon>
        <div class="notifs_wrap">
            <div class='notifs' (click)="openNotif()">
                <div class='new'></div>
            </div>
            <div class="cross" (click)="closeNotifs()">
                <app-button-icon class="closeMenu visible-xs" [imgUrl]="'assets/svg/close_menu_cross.svg'">
                </app-button-icon>
            </div>
        </div>
        <app-button-icon class='expand hidden-xs' [imgUrl]="'../../../assets/svg/menu_expand.svg'" (click)="expanded=!expanded"></app-button-icon>
    </div>
    <div class="back_btn" *ngIf="!expanded">
        <app-button-secondary [isText]="true" [isGreen]="false" [isWhite]="true" imgUrl="assets/svg/pagination_arrow.svg" buttonText="Back"
                              (click)="backBtn()"></app-button-secondary>
    </div>
    <div class="wrapper">
        <div class='sections hidden-xs' *ngIf="!expanded">
            <div class='row a_center' [class.active]="currSection==-1" *ngIf="overview">
                <div class="img-container">
                    <div class="img" (click)="goToSection(-1)"></div>
                </div>
                <button (click)="goToSection(-1)">Overview</button>
            </div>
            <ng-container *ngFor="let section of form.sections; let i=index">
                <div (click)="goToSection(i)" class="row a_center" [ngClass]="{'active': currSection==i, 'disabled': !isEnabled}" *ngIf="editable||section.appearing">
                    <!-- <div class="row a_center "  [ngClass]="{ 'active': currSection==i , 'completed': , 'editing': , 'edited_by_member'} " *ngFor="let section of form.sections; let i=i ndex " > -->
                    <div class="img-container">
                        <div class="img" [class.edited]="hasEdits(section)"></div>
                    </div>
                    <button>
                        {{(section.title.substr(0, 10) + (section.title.length>10?'...':''))||"New Section "}}
                    </button>
                </div>
            </ng-container>
            <!-- CLASSES FOR MENU ASSESSMENT completed edited - ADD ON .IMG -->
            <div class='row a_center' [class.active]="currSection==-3" [class.disabled]="!isEnabled" *ngIf="overview&&cap">
                <div class="img-container" (click)="goToSection(-3)">
                    <div class="img"></div>
                </div><button (click)="goToSection(-3)">CAP Summary</button>
            </div>
            <div class='row a_center' [class.active]="currSection==-2" [class.disabled]="!isEnabled" *ngIf="overview&&!submitted">
                <div class="img-container" (click)="goToSection(-2)">
                    <div class="img  "></div>
                </div><button (click)="goToSection(-2)">Submit</button>
            </div>
            <div class='row a_center' [class.active]="currSection==-3" *ngIf="overview&&submitted">
                <div class="img-container" (click)="goToSection(-3)">
                    <div class="img"></div>
                </div><button (click)="goToSection(-3)">Submitted</button>
            </div>
            <div class='row a_center' [class.active]="currSection==-5" *ngIf="overview&&assessment">
                <div class="img-container" (click)="goToSection(-5)">
                    <div class="img"></div>
                </div><button (click)="goToSection(-5)">Assessment</button>
            </div>
        </div>
        <div class="wrapper" *ngIf="expanded">
            <div class='full-sections'>
                <div (click)="goToSection(-1)" class='row a_center' [class.active]="currSection==-1" *ngIf="overview">
                    <div class="img-container" (click)="goToSection(-1)">
                        <div class="img"></div>
                    </div><span>Overview</span>
                </div>
                <ng-container *ngFor="let section of form.sections; let i=index">
                    <div class="row a_center" [ngClass]="{'active': currSection==i}" *ngIf="editable||section.appearing" (click)="goToSection(i)">
                        <div class="img-container">
                            <div class="img"></div>
                        </div>
                        <span class="section_title">{{section.title||'New Section'}}</span>
                        <span class="question_count">{{section.questions.length}}
                            Questions</span>
                        <div class='buttons' *ngIf="editable">
                            <div class="row">
                                <div class="arrows">
                                    <app-button-secondary [isWhite]="true" [isHoverGreen]="false" [imgUrl]="'assets/svg/arrow.svg'" style='transform:rotate(180deg)' [disabled]="i==0" (click)="moveUp(i)"></app-button-secondary>
                                    <app-button-secondary [isWhite]="true" [isHoverGreen]="false" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="i==form.sections.length-1" (click)="moveDown(i)"></app-button-secondary>
                                </div>
                                <button (click)="deleteSection(section)">Delete</button>
                                <button (click)="openSectionConditions(i)">:</button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class='row a_center' [class.active]="currSection==-3" *ngIf="overview&&cap" (click)="goToSection(-3)">
                    <div class='img-container'>
                        <div class='img'></div>
                    </div>
                    <span>CAP Summary</span>
                </div>
                <div class='row a_center' [class.active]="currSection==-2" *ngIf="overview&&!submitted" (click)="goToSection(-2)">
                    <div class="img-container">
                        <div class="img"></div>
                    </div><span>Submit</span>
                </div>
                <div class='row a_center' [class.active]="currSection==-3" *ngIf="overview&&submitted" (click)="goToSection(-3)">
                    <div class="img-container">
                        <div class="img"></div>
                    </div><span>Submitted</span>
                </div>
                <div class='row a_center' [class.active]="currSection==-5" *ngIf="overview&&assessment" (click)="goToSection(-5)">
                    <div class="img-container">
                        <div class="img "></div>
                    </div><span>Assessment</span></div>
                <app-button-secondary [isText]="true" [buttonText]=" 'Add new category' " [isWhite]="true" [isHoverGreen]="false" [imgUrl]=" 'assets/svg/plus-icon.svg' " (click)="insertSection()" *ngIf="editable"></app-button-secondary>
            </div>
        </div>
        <div class="open_main_menu_wrap visible-xs">
            <div class="open_main_menu" (click)="openMainMenu()">
                <div class="text">Open Main Menu</div>
            </div>
        </div>
    </div>
</div>

<!-- <button [disabled]="i==0 " (click)="moveUp(i) ">^</button>
                <button [disabled]="i==form.sections.length-1 " (click)="moveDown(i) ">V</button>
                <button (click)="deleteSection(section) ">Delete</button>  
               <button>:</button> -->