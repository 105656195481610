<div class='card'>
    <h2>{{title}}</h2>
    <div class="desc" *ngIf="desc.length > 0">{{desc}}</div>
    <!-- <div class='droparea' appFileDrop (filesChangeEmiter)="uploadFile($event)">
        DRAG AND DROP
    </div> -->
    <app-drag-drop class="file" [fileList]="section.supportFileList" (openModal)="openUploader()" (fileEvent)="uploadFile($event)" (fileRemoved)="deleteFile($event)"></app-drag-drop>
    <!-- <div class='filelist'>
        <div class='file' *ngFor="let file of section.supportFiles; let i = index">
            <span class='type'>PDF</span>
            <span class='filename'>{{file.attachment?.filename}}</span>
            <button (click)="deleteFile(i)">Delete X</button>`
        </div>
    </div> -->
</div>