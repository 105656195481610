<div class="column" [ngClass]="{'active': isFocused, 'accepted': isAccepted, 'error': isError,'error-bcg': isError && isEmpty, 'placeholder_top': placeholderVal()}">
    <div class="row">
        <ng-container *ngIf="!isPasswordStrenght">
            <input [type]="type" [name]="name" [disabled]="isDisabled" [class.disabled]="isDisabled" (focus)="onFocus($event)" (blur)="onBlur()" [class.w-100]="fullWidth" [(ngModel)]="model" (ngModelChange)="isInputEmpty(model);modelChange.emit(model)" [class.multiline]="multiline"
                maxlength="{{limit}}" minlength="{{min}}">
        </ng-container>
        <ng-container *ngIf="isPasswordStrenght">
            <input [type]="type" [name]="name" [disabled]="isDisabled" [class.disabled]="isDisabled" (keyup)="onKeyup($event)" (focus)="onFocus($event)" (blur)="onBlur()" [class.w-100]="fullWidth" [(ngModel)]="model" (ngModelChange)="isInputEmpty(model);modelChange.emit(model)"
                [class.multiline]="multiline" maxlength="{{limit}}">
            <div class="password_strength" [class]="passwordStrength" [class.show]="showPasswordStrength">{{passwordStrength}}</div>
        </ng-container>
        <div class="placeholder-container">
            <span class="placeholder">{{placeholderText}}</span>
        </div>
    </div>
    <ng-container *ngIf="isError && errorText.length > 0">
        <div class="container">
            <span>{{errorText}}</span>
        </div>
    </ng-container>
</div>