<div class="column" [ngClass]="{'active': isFocused, 'accepted': isAccepted, 'error': isError,'error-bcg': isError && isEmpty,'placeholder_top': true}">
    <div class="row">
        <jodit-editor [(ngModel)]="model" (ngModelChange)="modelChange.emit(model)" [disabled]="isDisabled" [config]='{
            "toolbarAdaptive":false,
            "buttons":"bold,italic,underline,strikethrough,ul,ol,indent,outdent,fontsize,paragraph,brush,superscript,subscript,image,hr,link,symbol,undo"
        }'></jodit-editor>
        <div class="placeholder-container ">
            <span class="placeholder ">{{placeholderText}}</span>
        </div>
    </div>
    <div *ngIf="isError " class="container ">
        <span>{{errorText}}</span>
    </div>
</div>
