import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-overlay-view',
  templateUrl: './overlay-view.component.html',
  styleUrls: ['./overlay-view.component.scss']
})
export class OverlayViewComponent implements OnInit {

  public label: any;
  public title: any;
  public percentage_list: any;
  public percentageView: any;
  public value: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any
    ){}

    ngOnInit():void{
        this.value = this.data.dataArray;
    }

}
