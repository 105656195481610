<app-auth></app-auth>
<app-menu (expanded)="getOffset($event)"></app-menu>
<main role="main">
    <div class="main_wrap" [ngClass]="{'menu_expanded': !expanded}">
        <div class="confetti_container" #confetti></div>
        <div class='offset' *ngIf="loaded" [ngClass]="{'menu_expanded': !expanded}">
            <div class='page-top'>
                <app-top-tabs [tabs]="tabs" *ngIf="userType !=3 && userType !=4 && !quizOpened"></app-top-tabs>
                <div class='section-data' *ngIf="currTab==2 ">
                    <div #ttcinput class="ttc_container row a_center">
                        <span class="prefix">Time to complete: </span>
                        <input *ngIf="loaded" #time (keyup)="getWidth()" maxlength="30" (focus)="ttcFocus()" (blur)="ttcBlur()" class='inline ttc' [(ngModel)]="settings.quiz_time" placeholder="Completion time">
                    </div>
                    <textarea maxlength="50" autosize [minRows]="1" class='inline title' [class.error]="!settings.quiz_title" [(ngModel)]="settings.quiz_title" placeholder="Quiz title"></textarea>
                    <textarea maxlength="180" autosize [minRows]="1" class='inline description' [(ngModel)]="settings.quiz_desc" placeholder="Quiz description"></textarea>
                </div>
            </div>
        </div>
        <div class="offset" [class.lg_bcg]="currTab==2" [ngClass]="{'menu_expanded': !expanded}" *ngIf="!quizOpened && !quizCompleted && !quizNotCompleted">
            <div class="tabs_content" [ngClass]="{'rm_padd': currTab==2}">
                <div class="tab_0" *ngIf="currTab==0">
                    <div class="columns">
                        <div class="column">
                            <div class="box">
                                <h2 (click)="debug()">Are you ready for certification?</h2>
                                <div class="desc">Take the quiz to see if you are ready to start your product application.
                                </div>
                                <app-circled-add-edit-link [label]="'Start the quiz'" (click)="openQuiz()"></app-circled-add-edit-link>
                            </div>
                            <div class="box">
                                <h2>Data Templates</h2>
                                <div class="desc">Download these templates to help complete question requirements in your applications</div>
                                <div class="download_wrap">
                                    <app-download-button *ngFor="let download of templateFiles" [name]="download.file!.label||download.file!.filename" [fileType]="download.file!.getExtension()" [fileUrl]="download.file!.getUrl()" fileSize="{{download.file?.filesize}}">
                                    </app-download-button>
                                </div>
                                <div class="download_text" (click)="getAllTemplates()">Download all templates</div>
                            </div>
                            <div class="box">
                                <h2>Document Checklist</h2>
                                <div class="desc">Use our list to ensure you have all the documents required to complete your application</div>
                                <div class="tick_box_wrap">
                                    <app-tick-box [text]="check.label" *ngFor="let check of checks"></app-tick-box>
                                </div>
                            </div>
                            <div class="box no-padding-l no-padding-r" *ngIf="isTablet()">
                                <app-accordion [accordion]="faq" titleProperty="question" descProperty="answer"></app-accordion>
                            </div>
                            <div class="box">
                                <h2>Got a certified product?</h2>
                                <div class="desc">Email us for the Certification Symbol pack.</div>
<!--                                <app-circled-add-edit-link [label]="'Download the logos'" (click)="getAllLogos()"></app-circled-add-edit-link>-->
                            </div>
                        </div>
                        <div class="column" *ngIf="!isTablet()">
                            <div class="box no-padding-l no-padding-r">
                                <app-accordion [accordion]="faq" titleProperty="question" descProperty="answer"></app-accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_1" *ngIf="currTab==1 && userType !=3 && userType !=4">
                    <div class="columns">
                        <div class="column">
                            <div class="box">
                                <h2>Are you ready for certification?</h2>
                                <div class="desc">Take the quiz to see if you are ready to start your product application.
                                </div>
                                <app-circled-add-edit-link [label]="'Edit the quiz'"></app-circled-add-edit-link>
                            </div>
                            <div class="box no-padding-l no-padding-r">
                                <div class='card'>
                                    <h2>Data Templates</h2>
                                    <div class='desc'>Download these templates to help complete question requirements in your applications</div>
                                    <app-drag-drop [fileList]="templateFiles" (fileEvent)="uploadFile($event, 'template')" (fileRemoved)="removeFile($event, 'templates')" [modal]="false"></app-drag-drop>
                                </div>
                            </div>
                            <div class="box no-padding-l no-padding-r">
                                <app-checklist title="Document Checklist" type="resources" [checks]="checks" [desc]="'Use our list to ensure you have all the documents required to complete your application'"></app-checklist>
                            </div>
                            <div class="box faq no-padding-l no-padding-r" *ngIf="isTablet()">
                                <app-faq-questions title="Frequently Asked Questions" type="resources" [faq]="faq"></app-faq-questions>
                            </div>
                            <div class="box no-padding-l no-padding-r">
                                <!-- <app-documents-n-templates [title]="'Got a certified product?'" [desc]="'Click the button below to download all your certified product symbols and guide.'">
                                </app-documents-n-templates> -->
                                <div class='card'>
                                    <h2>Got a certified product?</h2>
                                    <div class='desc'>Email us for the Certification Symbol pack.</div>
<!--                                    <app-drag-drop [fileList]="logoFiles" (fileEvent)="uploadFile($event, 'logo')" (fileRemoved)="removeFile($event, 'logo')" [modal]="false"></app-drag-drop>-->
                                </div>
                            </div>
                        </div>
                        <div class="column" *ngIf="!isTablet()">
                            <div class="box faq no-padding-l no-padding-r">
                                <app-faq-questions title="Frequently Asked Questions" type="resources" [faq]="faq"></app-faq-questions>
                            </div>
                        </div>
                    </div>
                    <div class="row a_center j_space btns_con full_width">
                        <app-button-primary buttonContent="Publish changes" [text]="'Publish changes'" (click)="saveChanges()"></app-button-primary>
                    </div>
                </div>
                <div class="tab_2" *ngIf="currTab==2 && userType !=3 && userType !=4">
                    <div class='' *ngIf="loaded">
                        <div class='section'>
                            <ng-container *ngIf="quiz"></ng-container>
                            <div class='question-list' *ngFor="let question of quiz;let i = index">
                                <app-resources-question [question]="question" (moveUp)="quizMoveUp(i)" (moveDown)="quizMoveDown(i)" (deleted)="quizRemoveQuestion(question)"></app-resources-question>
                            </div>
                            <app-form-builder-button buttonText="Question" (click)="quizAddQuestion()"></app-form-builder-button>
                        </div>
                        <div class="percentage_results">
                            <div class="results_container">
                                <div class="result">
                                    <div class="subheading">Result text</div>
                                    <h2 class="heading">0-30% Correct</h2>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Heading'" [(model)]="settings.quiz_t4_title"></app-text-field>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Description text'" [(model)]="settings.quiz_t4_desc"></app-text-field>
                                </div>
                                <div class="result">
                                    <div class="subheading">Result text</div>
                                    <h2 class="heading">31-60% Correct</h2>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Heading'" [(model)]="settings.quiz_t3_title"></app-text-field>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Description text'" [(model)]="settings.quiz_t3_desc"></app-text-field>
                                </div>
                                <div class="result">
                                    <div class="subheading">Result text</div>
                                    <h2 class="heading">61-89% Correct</h2>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Heading'" [(model)]="settings.quiz_t2_title"></app-text-field>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Description text'" [(model)]="settings.quiz_t2_desc"></app-text-field>
                                </div>
                                <div class="result">
                                    <div class="subheading">Result text</div>
                                    <h2 class="heading">90-100% Correct</h2>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Heading'" [(model)]="settings.quiz_t1_title"></app-text-field>
                                    <app-text-field [isAutoSize]="true" [placeholderText]="'Description text'" [(model)]="settings.quiz_t1_desc"></app-text-field>
                                </div>
                            </div>
                        </div>
                        <div class="row a_center j_space btns_con full_width">
                            <div class='autosave'>
                                <span>Last autosave ( static )</span>
                                <span>03/03/2021 at 11:09 pm</span>
                            </div>
                            <app-button-primary buttonContent="Publish changes" [text]="'Publish changes'" (click)="saveQuiz()"></app-button-primary>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- QUIZ members and collaborators -->
        <div class='quiz offset completing' [ngClass]="{'menu_expanded': !expanded}" *ngIf="quizOpened">
            <div class='main'>
                <div class="mw_container" [ngStyle]="{'max-width':'calc(100% - '+ sccontainer.offsetWidth +'px)'}">
                    <div class="content">
                        <div class="progres">
                            <div class='pname'><span>{{'Certification readiness quiz'}}</span></div>
                            <div><span>{{quizPercentage()}}%</span></div>
                        </div>
                        <div class='top'>
                            <h4>Time to complete: {{settings.quiz_time}}</h4>
                            <h1>{{settings.quiz_title}}</h1>
                            <p>{{settings.quiz_desc}}</p>
                        </div>
                        <div class='questions'>
                            <app-resources-question *ngFor="let q of quiz" [question]="q" [editable]="false"></app-resources-question>
                        </div>
                        <div class="btns_container">
                            <div class='autosave'>
                                <span>Last autosave ( static )</span>
                                <span>03/03/2021 at 11:09 pm</span>
                            </div>
                            <app-button-primary text="Submit" (click)="sendData()"></app-button-primary>
                        </div>
                    </div>
                    <div #sccontainer class="sc_container">
                        <app-support-content-container [isResources]="true" [resFaq]="faq" [resChecks]="checks" [resFiles]="files"></app-support-content-container>
                    </div>
                </div>
                <!-- <app-button-secondary [isText]="true" buttonText="Debug" (click)="debug()"></app-button-secondary> -->
            </div>

        </div>

        <!-- Congratulation -->
        <div class='offset app-overview submited confirmed' *ngIf="quizCompleted">
            <div class='form'>
                <svg-icon class="animated_tick" src="../../../assets/svg/big_tick.svg"></svg-icon>
                <div class="result"><span>{{quizScore}}%</span> ready</div>
                <h1>{{getQuizTitle(quizScore)}}</h1>
                <p class='thanks-label'>{{getQuizDesc(quizScore)}}</p>
                <app-button-primary text="Start an application" [routerLink]="'/applications/new'"></app-button-primary>
                <app-button-secondary [isText]="true" buttonText="Go back to resources" (click)="backToResources()" style='align-self:center;'></app-button-secondary>

            </div>
        </div>

        <!-- Congratulation not -->
        <div class='offset app-overview submited confirmed' *ngIf="quizNotCompleted">
            <div class='form'>
                <svg-icon class="animated_tick cross" src="../../../assets/svg/big_tick.svg"></svg-icon>
                <div class="result"><span>{{quizScore}}%</span> ready</div>
                <h1>{{getQuizTitle(quizScore)}}</h1>
                <p class='thanks-label'>{{getQuizDesc(quizScore)}}</p>
                <app-button-primary text="Re-do quiz" (click)="openQuiz()"></app-button-primary>
                <app-button-secondary [isText]="true" buttonText="Go back to resources" (click)="backToResources()" style='align-self:center;'></app-button-secondary>
                <!-- <app-button-secondary [isText]="true" buttonText="Download as PDF"></app-button-secondary> -->
            </div>
        </div>
    </div>
</main>