<app-auth></app-auth>
<app-menu [minimizable]="false" [exists]="false" [display]="false"></app-menu>
<main role="main">
    <div class="main_wrap">
        <app-form-menu [display]="true" [currSection]="currSection" [form]="form" (changeTab)="currSection=$event; scrollToTop()" [editable]="true"></app-form-menu>
        <div class='offset' *ngIf="loaded">
            <div class='page-top'>
                <app-top-tabs [tabs]="tabs"></app-top-tabs>
                <div class='section-data'>
                    <div *ngIf="loaded" #ttcinput class="ttc_container row a_center">
                        <span class="prefix">Time to complete: </span>
                        <input *ngIf="loaded" #time (keyup)="getWidth()" (change)="getWidth()" (ngModelChange)="getWidth()" maxlength="30" (focus)="ttcFocus()" (blur)="ttcBlur()" class='inline ttc' [(ngModel)]="form.sections[currSection].time" placeholder="Completion time">
                    </div>
                    <textarea maxlength="50" autosize [minRows]="1" class='inline title' [class.error]="!form.sections[currSection].title" [(ngModel)]="form.sections[currSection].title" placeholder="New Section"></textarea>
                    <textarea maxlength="180" autosize [minRows]="1" class='inline description' [(ngModel)]="form.sections[currSection].description" placeholder="Section description"></textarea>
                </div>
            </div>
            <div class='section'>
                <div class='question-list' *ngIf="currTab==0">
                    <app-fb-question class='question' *ngFor="let question of form.sections[currSection].questions; let i = index" [question]="question" [cantMoveDown]="i==form.sections[currSection].questions.length-1" [cantMoveUp]="i==0" [section]="form.sections[currSection]" [form]="form"
                        (moveDown)="questionMoveDown(i)" (moveUp)="questionMoveUp(i)" (deleted)="form.sections[currSection].questions.splice(i, 1); form.sections[currSection].fixQuestionOrdinals()" [options]="question.custom==3?pCategories:question.custom==4?markets:question.custom==5?pTargets:question.custom==6?riStrategies:question.custom==7?assetClasses:[]">
                    </app-fb-question>
                    <app-form-builder-button *ngIf="!adding" buttonText="add new Question" (click)="adding=true">
                    </app-form-builder-button>
                    <app-field-maker *ngIf="adding" (selected)="insertQuestion($event)"></app-field-maker>
                </div>
                <div class='question-list' *ngIf="currTab==1">
                    <app-fb-question class='question' *ngFor="let question of form.sections[currSection].riaaQuestions; let i = index" [question]="question" [cantMoveDown]="i==form.sections[currSection].riaaQuestions.length-1" [cantMoveUp]="i==0" [section]="form.sections[currSection]"
                        (moveDown)="questionMoveDown(i)" (moveUp)="questionMoveUp(i)" (deleted)="form.sections[currSection].riaaQuestions.splice(i, 1); form.sections[currSection].fixRIAAOrdinals()">
                    </app-fb-question>
                    <app-form-builder-button *ngIf="!adding" buttonText="add new Question" (click)="adding=true">
                    </app-form-builder-button>
                    <app-field-maker *ngIf="adding" (selected)="insertQuestion($event)"></app-field-maker>
                </div>
                <div class='question-list' *ngIf="currTab==2">
                    <app-fb-question class='question' *ngFor="let question of form.sections[currSection].ivpQuestions; let i = index" [question]="question" [cantMoveDown]="i==form.sections[currSection].ivpQuestions.length-1" [cantMoveUp]="i==0" [section]="form.sections[currSection]"
                        (moveDown)="questionMoveDown(i)" (moveUp)="questionMoveUp(i)" (deleted)="form.sections[currSection].ivpQuestions.splice(i, 1); form.sections[currSection].fixIVPOrdinals()">
                    </app-fb-question>
                    <app-form-builder-button *ngIf="!adding" buttonText="add new Question" (click)="adding=true">
                    </app-form-builder-button>
                    <app-field-maker *ngIf="adding" (selected)="insertQuestion($event)"></app-field-maker>
                </div>
                <app-support-content *ngIf="currTab==3" [section]="form.sections[currSection]"></app-support-content>
            </div>
            <div class="row a_center j_space btns_con" [ngClass]="{'full_width': currTab==3}">
                <div class='column autosave' (click)="debug()">
                    <span *ngIf="lastAutosave">Last autosave</span>
                    <span *ngIf="lastAutosave">{{lastAutosave | date: 'dd/MM/yyyy'}} at {{lastAutosave | date: 'HH:mm a'}}</span>
                </div>
                <app-button-primary buttonContent="Publish changes" [text]="'Publish changes'" (click)="publish()"></app-button-primary>
            </div>
            <!-- <app-button-secondary buttonText="Debug" [isText]="true" (click)="debug()"></app-button-secondary> -->
        </div>
    </div>
</main>