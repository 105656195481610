<div class="backdrop" *ngIf="expanded" [ngClass]="{'tab_transform': tabs.length > 2,'tab_transform_desktop': canTransformDesktop()  }" (click)="expanded = false; scroll.enableBodyScroll()"></div>
<div class='tabs' [ngClass]="{'tab_transform': tabs.length > 2 , 'tab_transform_desktop': canTransformDesktop() , 'expanded': expanded == true}">
    <ng-container *ngFor="let t of tabs">
        <button *ngIf="!t.hidden" [class.active]="t.active" (click)="canExpand(); t.onClick(); closeOverlay(t.active); scrollTop()">
            {{t.label}}
            <span *ngIf="t.badge!=null" class='badge' [class.inactive]="!t.active">{{t.badge}}</span>
        </button>
    </ng-container>
</div>
<!-- <div class='tabs' [ngClass]="{'tab_transform': tabs.length > 4}">

    <ng-container *ngIf="tabs.length<4">
        <button *ngFor="let t of tabs" [class.active]="t.active" (click)="t.onClick()">
        {{t.label}}
        <span *ngIf="t.badge!=null" class='badge' [class.inactive]="t.badge==0">{{t.badge}}</span>
        </button>
    </ng-container>

    <ng-container *ngIf="tabs.length>4">
        <ul>
            <li *ngFor="let t of tabs">
                <button *ngFor="let t of tabs" [class.active]="t.active" (click)="t.onClick()">
                {{t.label}}
        <span *ngIf="t.badge!=null" class='badge' [class.inactive]="t.badge==0">{{t.badge}}</span>
        </button></li>
        </ul>

    </ng-container>

</div> -->