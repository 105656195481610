<app-auth></app-auth>
<app-menu></app-menu>
<appLoader [showLoader]="showLoader" [showNotyfication]="showNotyfication"
  [notyficationMessage]="notyficationMessage" [notyficationMessageStateModel]="notyficationMessageStateModel">
</appLoader>
<h2 *ngIf="new">Add Mailing group</h2>
<h2 *ngIf="!new">Edit Mailing group</h2>
<div>
    Name
    <input type="text" [(ngModel)]="mailingGroup.name">

    <p>Users</p>
    <input [(ngModel)]="filter" (input)="setUser()">
    <div>
        <div *ngFor="let user of users">
            <!-- <div><input type="checkbox" [(ngModel)]="user.checked"> </div> -->
            <div>{{user.fullName}}</div>
            <div>{{user.email}}</div>
            <div>{{user.userType}}</div>
        </div>
    </div>
    <button (click)="save()">SAVE</button>
</div>
