<a [href]="fileUrl" [download]="fileUrl" target="_blank" class="container" [ngClass]="{'small': isSmall,'full_width': isFullWidth }" *ngIf="downloadable">
    <div class="row a_center j_space">
        <div>
            <span>{{name}}</span>
            <!-- <span *ngIf="this.fileUrl.length>0">{{name}}</span>
            <span *ngIf="this.fileUrl.length==0">{{getName()}}</span> -->
        </div>
        <div class="row a_center">
            <span>{{fileType}}</span>
            <span>{{fileSize}}</span>
            <svg-icon src="../../../assets/svg/download.svg"></svg-icon>
        </div>
    </div>
</a>
<div class="container" [ngClass]="{'small': isSmall,'full_width': isFullWidth }" *ngIf="!downloadable">
    <div class="row a_center j_space">
        <div>
            <span>{{name}}</span>
            <!-- <span *ngIf="this.fileUrl.length>0">{{name}}</span>
            <span *ngIf="this.fileUrl.length==0">{{getName()}}</span> -->
        </div>
        <div class="row a_center">
            <span>{{fileType}}</span>
            <span>{{fileSize}}</span>
            <svg-icon src="../../../assets/svg/download.svg"></svg-icon>
        </div>
    </div>
</div>