import { ApplicationAnswer } from "./ApplicationAnswer";
import { AssessmentAnswer } from "./AssessmentAnswer";
import { FormSection, form_section_response } from "./FormSection";

export class SectionBundle{
    public sections:FormSection[] = [];

    public map(data:form_section_response[]){
        this.sections = [];
        for(let s of data)
            this.sections.push(FormSection.create(s));

        this.sections.sort((a,b) => { return a.order>b.order?1:-1 });
        
        return this;
    }
    public responsify():form_section_response[]{
        let arr = [];
        for(let s of this.sections) arr.push(s.responsify());
        return arr;
    }
    public static create(data:form_section_response[]){
        let r = new this;
        r.map(data);
        return r;
    }

    public syncIds(ids:{[key:string]:number}){
        for(let s of this.sections){
            if(!s.id&&ids[s.key]) s.id = ids[s.key];
            for(let q of s.ivpQuestions){
                if(!q.id&&ids[q.key]) q.id = ids[q.key];
                for(let f of q.fields)
                    if(!f.id&&ids[f.key]) f.id = ids[f.key];
                for(let s of q.subquestions){
                    if(!s.id&&ids[s.key]) s.id = ids[s.key];
                    for(let f of s.fields)
                        if(!f.id&&ids[f.key]) f.id = ids[f.key];
                }
            }
            for(let q of s.riaaQuestions){
                if(!q.id&&ids[q.key]) q.id = ids[q.key];
                for(let f of q.fields)
                    if(!f.id&&ids[f.key]) f.id = ids[f.key];
                for(let s of q.subquestions){
                    if(!s.id&&ids[s.key]) s.id = ids[s.key];
                    for(let f of s.fields)
                        if(!f.id&&ids[f.key]) f.id = ids[f.key];
                }
            }
            for(let q of s.questions){
                if(!q.id&&ids[q.key]) q.id = ids[q.key];
                for(let f of q.fronts)
                    if(!f.id&&ids[f.key]) f.id = ids[f.key];
                for(let f of q.fields)
                    if(!f.id&&ids[f.key]) f.id = ids[f.key];
                for(let c of q.conditions){
                    if(!c.id&&ids[c.key]) c.id = ids[c.key];
                    if(!c.targetId&&ids[c.targetKey]) c.targetId = ids[c.targetKey];
                    for(let s of c.conditions){
                        if(!s.id&&ids[s.key]) s.id = ids[s.key];
                        if(!s.fieldId&&ids[s.fieldKey]) s.fieldId = ids[s.fieldKey];
                    }
                }
                for(let s of q.subquestions){
                    if(!s.id&&ids[s.key]) s.id = ids[s.key];
                    for(let f of s.fronts)
                        if(!f.id&&ids[f.key]) f.id = ids[f.key];
                    for(let f of s.fields)
                        if(!f.id&&ids[f.key]) f.id = ids[f.key];
                    for(let c of s.conditions){
                        if(!c.id&&ids[c.key]) c.id = ids[c.key];
                        if(!c.targetId&&ids[c.targetKey]) c.targetId = ids[c.targetKey];
                        for(let u of c.conditions){
                            if(!u.id&&ids[u.key]) u.id = ids[u.key];
                            if(!u.fieldId&&ids[u.fieldKey]) u.fieldId = ids[u.fieldKey];
                        }
                    }
                }
            }
            for(let g of s.conditions){
                if(!g.id&&ids[g.key]) g.id = ids[g.key];
                if(!g.targetId&&ids[g.targetKey]) g.targetId = ids[g.targetKey];
                for(let c of g.conditions){
                    if(!c.id&&ids[c.key]) c.id = ids[c.key];
                    if(!c.fieldId&&ids[c.fieldKey]) c.fieldId = ids[c.fieldKey];
                }
            }
            for(let c of s.supportChecks)
                if(!c.id&&ids[c.key]) c.id = ids[c.key];
            for(let f of s.supportFAQs)
                if(!f.id&&ids[f.key]) f.id = ids[f.key];
            for(let f of s.supportFiles)
                if(!f.id&&ids[f.key]) f.id = ids[f.key];
            for(let v of s.supportVideos)
                if(!v.id&&ids[v.key]) v.id = ids[v.key];
        }
    }

    public deepOrdinalFix(){
        for(let [i, s] of this.sections.entries()){
            s.order = i;
            s.deepOrdinalFix();
        }
    }
    public mapAnswers(answers:ApplicationAnswer[]){
        for(let a of answers){
            secloop: for(let s of this.sections){
                for(let q of s.questions){
                    if(q.id == a.questionId){ 
                        q.answer = a;
                        break secloop;
                    }
                    for(let u of q.subquestions){
                        if(u.id == a.questionId){
                            u.answer = a;
                            break secloop;
                        }
                    }
                }
            }
        }
    }
    public mapRIAAAnswers(answers:AssessmentAnswer[]){
        for(let a of answers){
            secloop: for(let s of this.sections){
                for(let q of s.riaaQuestions){
                    if(q.id==a.questionId){
                        q.answer = a;
                        break secloop;
                    }
                    for(let u of q.subquestions){
                        if(u.id == a.questionId){
                            u.answer = a;
                            break secloop;
                        }
                    }
                }
            }
        }
    }
    public getQuestionById(id:number){
        for(let s of this.sections){
            let r = s.getQuestionById(id);
            if(r) return r;
        }
        return null;
    }
    public getQuestionByKey(key:string){
        for(let s of this.sections){
            let r = s.getQuestionByKey(key);
            if(r) return r;
        }
        return null;
    }
    public getSectionById(id:number){
        for(let s of this.sections)
            if(s.id===id) return s;
        return null;
    }
    public verifySectionData(){
        for(let [i, s] of this.sections.entries()){
            if(!s.title) return i;
        }
        return null;
    }

    public completion():number{
        let t = 0, f = 0;
        for(let s of this.sections){
            for(let q of s.questions){
                t++;
                if(q.filled()) f++;
                for(let u of q.subquestions){
                    t++;
                    if(u.filled()) f++;
                }
            }
        }
        return Math.floor(f/t*100);
    }

    public confirmAllCondis(){
        for(let s of this.sections){
            s.confirmConditions(this);
            for(let q of s.questions){
                q.confirmConditions(this);
                for(let u of q.subquestions)
                    u.confirmConditions(this);
            }
        }
    }
}