import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { QuizQuestion } from "src/app/model/responsible/QuizQuestion";
import { ResourceCheck } from "src/app/model/responsible/ResourceCheck";
import { ResourceFAQ } from "src/app/model/responsible/ResourceFAQ";
import { ResourceFile } from "src/app/model/responsible/ResourceFile";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root'
})
export class ResourceAPI{
    constructor(
        private http:HttpClient,
        private encryptor:EpEncryptionService,
    ){}

    public fetch(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}resources`);
    }
    public update(faq:ResourceFAQ[], checks:ResourceCheck[], files:ResourceFile[]){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}resources`,
            this.encryptor.sendPayload({
                faq: (() => { let arr = []; for(let f of faq) arr.push(f.responsify()); return arr; })(),
                checks: (() => { let arr = []; for(let c of checks) arr.push(c.responsify()); return arr; })(),
                files: (() => { let arr = []; for(let f of files) arr.push(f.responsify()); return arr; })()
            }));
    }
    public updateQuiz(quiz:QuizQuestion[], settings:any){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}resources/quiz`,
            this.encryptor.sendPayload({
                quiz: (() => { let arr = []; for(let q of quiz) arr.push(q.responsify()); return arr; })(),
                settings: settings
            }));
    }
    public getAllTemplates(){
        return this.http.get(`${environment.apiUrl}resources/templates`, { responseType:'blob' });
    }
    public getAllLogos(){
        return this.http.get(`${environment.apiUrl}resources/logos`, { responseType:'blob' });
    }
}
