import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';

///notyfications state message
import { NotyficationMessageStateModel } from 'src/app/model/NotyficationMessageStateModel';

@Component({
  selector: 'appLoader',
  templateUrl: 'loader.component.html'
})
export class OldLoaderComponent {

  @Input() public showLoader: boolean = false;
  @Input() public showNotyfication: boolean = false;
  @Input() public notyficationMessage: string = '';
  @Input() public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
  @Input() public isLogin: boolean = true;
  // @ViewChild('loader') loader: any;

  public load: boolean = false;

  constructor() {

  }
  ngOnInit() {
  }
  
  ngAfterViewInit(){
  }
  
  public show() {
    this.showLoader = true;
  }
  public hide() {
    this.showLoader = false;
  }

  public hideNotyfication() {
    this.showNotyfication = false;
  }

}
