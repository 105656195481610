import { Component, Inject, OnInit,ViewChildren,QueryList } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Condition } from 'src/app/model/responsible/Condition';
import { ConditionGroup } from 'src/app/model/responsible/ConditionGroup';
import { Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { FormField } from 'src/app/model/responsible/FormField';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { SectionCondition } from 'src/app/model/responsible/SectionCondition';
import { SectionConditionGroup, section_condition_group_response } from 'src/app/model/responsible/SectionConditionGroup';

export interface SectionConditionBuilderInterface {
    type: 'question' | 'field',
    conditions: section_condition_group_response[],
    conditionable: any[],
    index: number,
    hidden: boolean,
    fIndex?: string,
    title: string,
}

type NewType = FormField;

@Component({
    selector: 'app-section-condition-builder',
    templateUrl: './section-condition-builder.component.html', 
    styleUrls: ['./section-condition-builder.component.scss']
})
export class SectionConditionBuilderComponent implements OnInit {
    public hidden = {name:'', value:true};
    public conditions: SectionConditionGroup[] = [];
    public conditionable: any[] = [];

    public hiddenSelect: any[] = [{ name: 'Show', value: true }, { name: 'Hide', value: false }];
    public radioSelect: any[] = [{ name: 'Selected', value: true }, { name: 'Unselected', value: false }];
    public clicked: any
    @ViewChildren('select') selectsList? :QueryList<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SectionConditionBuilderInterface
    ){}

    ngOnInit():void{
        this.conditions = (() => {
            let arr = [];
            for (let i of this.data.conditions)
                arr.push(SectionConditionGroup.create(i));
            return arr;
        })();
        this.hidden = this.data.hidden ? this.hiddenSelect[0] : this.hiddenSelect[1];
        this.conditionable = this.data.conditionable;
        for (let g of this.conditions) {
            for (let i of this.conditionable)
                if (i.object.id == g.targetId) {
                    g.target = i.object;
                    break;
                }
            g.answerList = [];
            switch(g.target?.custom){
                case 3:
                    for(let c of ProductCategory.latest)
                        g.answerList.push({ name: c.name, object: c });
                    break;
                case 4:
                    for(let m of Market.latest)
                        g.answerList.push({ name: m.name, object: m });
                    break;
                case 5:
                    for(let t of ProductTarget.latest)
                        g.answerList.push({ name: t.name, object: t });
                    break;
                case 6:
                    for(let s of RIStrategy.latest)
                        g.answerList.push({ name: s.name, object: s });
                    break;
                default:
                    for (let [k, v] of g.target!.fields.entries())
                        if(['radio','check','slider-numeric','slider-percent'].includes(v.type)) 
                            g.answerList.push({ name: v.label ?? `${v.typeLabel()} ${k}`, object: v });
            }
            for(let c of g.conditions)
                c.field = (()=>{ for(let f of g.target?.fields??[]) if(f.id==c.fieldId) return f; return undefined; })();
        }
        console.log(this);
    }

    public addGroup() {
        let g = new SectionConditionGroup();
        g.conditions.push(new SectionCondition());
        this.conditions.push(g);
    }
    public removeGroup(index: number) {
        this.conditions.splice(index, 1);
    }
    public addField(group: SectionConditionGroup) {
        group.conditions.push(new SectionCondition());
    }
    public removeField(group: SectionConditionGroup, index: number) {
        group.conditions.splice(index, 1);
    }
    public setQuestion(group: SectionConditionGroup, question: FormQuestion) {
        group.target = question;
        group.targetId = question.id;
        group.targetKey = question.key;
        group.answerList = [];
        switch(question.custom){
            case 3:
                for(let c of ProductCategory.latest)
                    group.answerList.push({ name: c.name, object: c });
                break;
            case 4:
                for(let m of Market.latest)
                    group.answerList.push({ name: m.name, object: m });
                break;
            case 5:
                for(let t of ProductTarget.latest)
                    group.answerList.push({ name: t.name, object: t });
                break;
            case 6:
                for(let s of RIStrategy.latest)
                    group.answerList.push({ name: s.name, object: s });
                break;
            default:
                for (let [k, v] of question.fields.entries())
                    if(['radio','check','slider-numeric','slider-percent'].includes(v.type)) 
                        group.answerList.push({ name: v.label || `${v.typeLabel()} ${k}`, object: v });
        }
        // for (let [k, v] of question.fields.entries())
        //     if(['radio','check','slider-numeric','slider-percent'].includes(v.type)) 
        //         group.answerList.push({ name: v.label || `${v.typeLabel()} ${k}`, object: v });
    }

    public setCondition(condition: SectionCondition, value: any, type: string) {
        if (['radio', 'check', 'custom'].includes(type)) {
            condition.condition = value ? 'true' : 'false';
        } else {
            condition.condition = `${value[0]}:${value[1]}`;
        }
    }
    public setAnswer(condition: SectionCondition, answer: NewType) {
        condition.field = answer;
        condition.fieldId = answer.id;
        condition.fieldKey = answer.key;
    }
    public setCustom(condition:SectionCondition, id:number){
        condition.field = undefined;
        condition.fieldId = 0;
        condition.fieldKey = '';
        condition.customId = id;
    }

    public getInitialConditionable(group: SectionConditionGroup) {
        for (let c of this.conditionable) {
            if (c.object.id == group.targetId)
                return c;
        }
        return { name: 'None' };
    }
    public getInitialAnswer(group: SectionConditionGroup, condition: SectionCondition) {
        for (let a of group.answerList)
            if((group.target?.custom??0)>2){
                if (a.object.id == condition.customId) return a;
            } else
                if(a.object.id == condition.fieldId) return a;
    }
    public reset() {
        this.conditions = [];
    }
    
    public scroll() { 
        if(this.clicked.path !== undefined){ 
           this.clicked.path[2].scrollIntoView({behavior: "smooth", block: "center"})
          }else if(this.clicked.target !== undefined){
               this.clicked.target.scrollIntoView({behavior: "smooth" ,block: "center"})
          }else if(this.clicked) {
               try{this.clicked.scrollIntoView({behavior: "smooth", block: "center"})}
               catch(err){
                  // console.log(err)
               }
          }
    }

  public setClick(e :any){
      console.log(e)
        if(e) this.clicked = e
   }
   setNewSelect(){
  if(this.selectsList !== undefined && this.selectsList?.toArray()[this.selectsList?.toArray().length - 1])  this.clicked = this.selectsList?.toArray()[this.selectsList?.toArray().length - 1].eRef.nativeElement
   }
}
