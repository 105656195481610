import { Component, Input, OnInit } from '@angular/core';
import { FormBuilderComponent } from 'src/app/form-builder/form-builder/form-builder.component';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { ResourceCheck } from 'src/app/model/responsible/ResourceCheck';
import { SupportCheck } from 'src/app/model/responsible/SupportCheck';

@Component({
    selector: 'app-checklist',
    templateUrl: './checklist.component.html',
    styleUrls: ['../../form-builder/support-content/support-content.component.scss', './checklist.component.scss']
})
export class ChecklistComponent implements OnInit {
    @Input() public section: FormSection = new FormSection();
    @Input() public title: string = 'Checklist';
    @Input() public desc: string = '';
    @Input() public type: 'support' | 'resources' = 'support';
    @Input() public checks: ResourceCheck[] = [];

    constructor(){}

    ngOnInit():void{}

    // Checks
    public addNewCheck() {
        if (this.type === 'support')
            this.section.supportChecks.push(new SupportCheck());
        else
            this.checks.push(new ResourceCheck());
    }
    public deleteCheck(index: number) {
        if(this.type==='support'){
            this.section.supportChecks.splice(index, 1);
            FormBuilderComponent.changed = true;
        } else 
            this.checks.splice(index, 1);
    }
    public moveCheckUp(index: number) {
        if(this.type==='support'){
            let item = this.section.supportChecks.splice(index, 1);
            this.section.supportChecks.splice(index - 1, 0, item[0]);
            FormBuilderComponent.changed = true;
        } else {
            let item = this.checks.splice(index, 1);
            this.checks.splice(index - 1, 0, item[0]);
        }
    }
    public moveCheckDown(index: number) {
        if(this.type==='support'){
            let item = this.section.supportChecks.splice(index, 1);
            this.section.supportChecks.splice(index + 1, 0, item[0]);
            FormBuilderComponent.changed = true;
        } else {
            let item = this.checks.splice(index, 1);
            this.checks.splice(index + 1, 0, item[0]);
        }
    }

    public changeMade() {
        FormBuilderComponent.changed = true;
    }

}
