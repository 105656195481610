import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreuploadedFilesComponent } from 'src/app/application/elements/preuploaded-files/preuploaded-files.component';

@Component({
    selector: 'app-upload-modal',
    templateUrl: './upload-modal.component.html',
    styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any,
        private dRef:MatDialogRef<UploadModalComponent>,
        private dialog:MatDialog
    ){}

    ngOnInit():void{
        console.log(this.data);
    }

    public fileUploaded(event:any){
        this.dRef.close({ new:true , file: event.target.files });
    }

    public openPreuploads(){
        let d = this.dialog.open(PreuploadedFilesComponent, { panelClass:'modal-white', data:{
            filelist: this.data.filelist
        } });
        d.afterClosed().subscribe(res => {
            if(res){
                this.dRef.close({ new:false, file: res });
            }
        })
    }
}
