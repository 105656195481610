import { Keyed } from "./Form";
import { FormQuestion, form_question_response } from "./FormQuestion";
import { FormSection, form_section_response } from "./FormSection";
import { SectionCondition, section_condition_response } from "./SectionCondition";

export interface section_condition_group_response{
    id:number;
    section_id:number;
    target_id:number;

    section?:form_section_response;
    target?:form_question_response;
    conditions?:section_condition_response[];

    target_key?:string;
    key?:string;
}
export class SectionConditionGroup extends Keyed{
    public id:number = 0;
    public sectionId:number = 0;
    public targetId:number = 0;

    public section?:FormSection;
    public target?:FormQuestion;
    public conditions:SectionCondition[] = [];

    public targetKey:string = '';

    public map(data:section_condition_group_response){
        this.id = data.id;
        this.sectionId = data.section_id;
        this.targetId = data.target_id;
        
        if(data.section) this.section = FormSection.create(data.section);
        if(data.target) this.target = FormQuestion.create(data.target);
        this.conditions = [];
        for(let c of data.conditions??[])
            this.conditions.push(SectionCondition.create(c));

        return this;
    }
    public responsify():section_condition_group_response{
        return {
            id: this.id,
            section_id: this.sectionId,
            target_id: this.targetId,
            conditions:(()=>{
                let arr:section_condition_response[] = [];
                for(let c of this.conditions??[])
                    arr.push(c.responsify());
                return arr;
            })(),
            target:this.target?.responsify(),
            section:this.section?.responsify(),

            target_key: this.targetKey,
            key:this.key,
        }
    }
    public static create(data:section_condition_group_response){
        let r = new this;
        r.map(data);
        return r;
    }
    public syncIds(input:SectionConditionGroup){
        let found = [], missing = [];
        for(let [k,v] of this.conditions.entries()){
            if(v.id) found.push(v.id);
            else missing.push(k);
        }
        for(let c of missing){
            for(let n of input.conditions??[]){
                if(!found.includes(n.id)){
                    this.conditions[c].id = n.id;
                    found.push(n.id);
                    break;
                }
            }
        }
    }

    public answerList:any[] = [];
}