<div class="content">
    <div class="member_tab add_member">
        <h2>Create a new pu9aferdhgf0pu8yoasgfpouasgydfuyh</h2>
        <app-text-field [placeholderText]="'First name'"></app-text-field>
        <app-text-field [placeholderText]="'Last name'"></app-text-field>
        <app-text-field [placeholderText]="'Email'"></app-text-field>
        <app-text-field [placeholderText]="'Phone number'"></app-text-field>
        <app-form-select [placeholderText]="'Type of admin'"></app-form-select>
        <div class="cta_wrap">
            <app-button-primary text="Add admin"></app-button-primary>
        </div>
    </div>
</div>