import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, ViewChild, ElementRef, DoCheck, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-url-field',
  templateUrl: './url-field.component.html',
  styleUrls: ['./url-field.component.scss']
})
export class UrlFieldComponent implements OnInit {

  @Input() public model: string = ''
  @Input() public isError: boolean = false
  @Input() public errorText: string = 'Invalid  URL'
  @Input() public placeholderText: string = 'URL'
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() isAutoSize: boolean = true
  @Input() isAssessment: boolean = false;
  @Output() public modelChange = new EventEmitter<string>();

  @ViewChild('url') url?: ElementRef;
  public correctUrl: boolean = false
  public pattern: string = "((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
  public isFocused: boolean = false
  public isAccepted: boolean = false
  public isEmpty: boolean = true
  val = new FormControl();
  ngOnInit(): void {
    this.istextAreaEmpty(this.model)
    this.val = new FormControl({value: this.model, disabled: this.isDisabled})
  }

  constructor() {
   }
  public urlValidate() {
     this.model = this.val.value
    if (this.model.length <= 0) {
      this.correctUrl = false
      this.isEmpty = true
    }else{
      this.isEmpty = false
    }
    if (this.val.errors?.pattern) {
      this.isError = true
      console.log(this.val.errors?.pattern)
    }
    else {
      this.isError = false
      this.modelChange.emit(this.model)
    }
  }

  ngDoCheck(): void {
    if (this.isError) this.correctUrl = false
  }

  onFocus(event: any) {
    if (event) {
      this.isFocused = true;
      this.isAccepted = false
    }
  }

  ngAfterContentChecked(): void {
    this.istextAreaEmpty(this.model)
    if (this.isAutoSize) this.autoGrow()
  }

  onBlur() {
    this.urlValidate()
    if (this.isEmpty) {
      this.isFocused = false;
    } else {
      this.isAccepted = true
      if (this.model.length > 0 && !this.isError) this.correctUrl = true
    }
    this.modelChange.emit(this.model)
  }

  istextAreaEmpty(text: string) {
    if (!text || text.length == 0) {
      this.isEmpty = true
    } else {
      this.isEmpty = false
      this.isFocused = true
    }
  }
  autoGrow() {
    if (this.url) {
      this.url.nativeElement.style.height = '0';
      this.url.nativeElement.style.height = this.url.nativeElement.scrollHeight + 'px';
    }
  }

}
