<app-top-tabs [tabs]="tabs" *ngIf="isAdmin"></app-top-tabs>
<div class="main" [ngClass]="{'declined': isAccepted}">
    <div class="wrap">
        <div class="column offset">
            <div class="top">
                <h5>{{application.status}}</h5>
                <h2>{{application.productName}}</h2>
                <p>{{application.description}}</p>
            </div>
            <div class="content">
                <h3>Approval Condition</h3>
                <p>Approval Condition {{getCondition(assessment.condition!)}}</p>
            </div>
            <div class="content score" *ngIf="assessment.score">
                <h3>Final Scoring</h3>
                <!-- Replace "4" -->
                <p>{{assessment.score}} - {{getScoreMsg(assessment.score!)}} </p>
                <p>{{getScoreComment(assessment.score!)}}</p>
            </div>
            <div class="content comments" *ngIf="assessment.comments">
                <h3>Comments</h3>
                <p>{{assessment.comments}}</p>
            </div>
            <app-download-button *ngFor="let attachment of assessment.attachments" [fileUrl]="attachment.getUrl()" fileSize="{{attachment.filesize}}" [fileType]="attachment.getExtension()" [name]="attachment.filename"></app-download-button>
            <!-- <div class="content star">
                <h3>Star Rating</h3>
                <app-filters-star [rating]="assessment.stars||0"></app-filters-star>
            </div> -->
            <app-button-primary [text]="'Update certification'"></app-button-primary>
        </div>
    </div>
</div>