export interface FilterMap{
    [filter:string]:string
}
export interface IndexQuery{
    page?:number;
    perPage?:number;
    filters?:FilterMap;
    sortBy?:string;
    sortDir?:'asd'|'desc';
}
export function buildQueryParams(query?:IndexQuery):string{
    if(!query) return '';
    let params = '?';
    if(query.filters){
        let i = 0;
        for(let [k, v] of Object.entries(query.filters)){
            params += `${k}=${encodeURIComponent(v)}`;
            if(++i<Object.entries(query.filters).length) params += '&';
        }
        if(Object.entries(query.filters).length&&(query.page||query.perPage||query.sortBy||query.sortDir)) params += '&';
    }
    if(query.page){ 
        params += `page=${query.page}`;
        if(query.perPage||query.sortBy||query.sortDir) params += '&';
    }
    if(query.perPage){
        params += `perPage=${query.perPage}`;
        if(query.sortBy||query.sortDir) params += '&';
    }
    if(query.sortBy){
        params += `sortBy=${query.sortBy}`;
        if(query.sortDir) params += `&sortDir=${query.sortDir}`;
    }

    return params;
}
export interface filtered_response{
    total:number,
    filtered:number,
    data:any[]
}