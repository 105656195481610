import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Exclusion } from 'src/app/model/responsible/Exclusion';
import { FormField } from 'src/app/model/responsible/FormField';
import { ExclusionAPI } from 'src/app/services/responsible/exclusion.service';

@Component({
    selector: 'app-theme-exclusion',
    templateUrl: './theme-exclusion.component.html',
    styleUrls: ['./theme-exclusion.component.scss']
})
export class ThemeExclusionComponent implements OnInit {
    public list: Exclusion[] = [];
    public selected?: Exclusion;
    public field: FormField = new FormField();
    public loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private exclusionApi: ExclusionAPI,
    ) {
        this.exclusionApi.index().subscribe(res => {
            let response = CodedResponseModel.decode(res);
            for (let e of response){
                let o = Exclusion.create(e);
                this.list.push(o);
                if(o.id == this.field.customs)
                    this.selected = o;
            }

            this.loading = false;
        });
    }

    ngOnInit(): void {
        this.field = FormField.create(this.data.field);
    }
}
