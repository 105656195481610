import { Component, EventEmitter, OnInit } from '@angular/core';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    public display:boolean = false;
    public exists:boolean = false;
    public expanded: boolean = false
    public noMenu: boolean = false
    public static show = new EventEmitter<boolean>();
    public static menuExpanded = new EventEmitter<boolean>();
    public static menuHidden = new EventEmitter<boolean>();
    constructor(private scroll:BodyScrollService,private router: Router, private activatedRoute: ActivatedRoute){
        LoaderComponent.show.subscribe(res => {
            if(res){
                scroll.disableBodyScroll()
                this.exists = true;
                this.display = true;
                this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd)
                )
            } else {
                scroll.enableBodyScroll()
                this.display = false;
                this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd)
                )
                setTimeout(() => { this.exists = false; }, 400);
            }
        })
        LoaderComponent.menuExpanded.subscribe(res => {
            if(res){
                 this.expanded = true;
            } else {
                this.expanded = false;
                
            }
        })
        LoaderComponent.menuHidden.subscribe(res => {
            if(res){
                 this.noMenu = true;
            } else {
                this.noMenu = false;
            }
        })
    }

    ngOnInit():void{}

    public n(n:number){
        return new Array(n);
    }
}
