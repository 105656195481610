import { Component, OnInit } from '@angular/core';
import { tab_interface } from 'src/app/core/top-tabs/top-tabs.component';
import { MatDialog } from '@angular/material/dialog';
import { OverlayViewComponent } from './overlay-view/overlay-view.component';
import { AnalyticsAPI } from 'src/app/services/responsible/analytics.service';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { ApplicationAnswer } from 'src/app/model/responsible/ApplicationAnswer';
import { FormField } from 'src/app/model/responsible/FormField';
import { Router } from '@angular/router';
import { LoaderComponent } from '../../partials/loader/loader.component';
import { NotificatorComponent } from 'src/app/partials/notificator/notificator.component';
import { PartialService } from 'src/app/services/partial.service';
import { UsersAPIService } from 'src/app/services/users.service';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';
import { User, UserTypes, user_response } from 'src/app/model/User';
import { Application, application_response } from 'src/app/model/responsible/Application';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ProviderAPI } from 'src/app/services/responsible/provider.service';
import { Provider, provider_response } from 'src/app/model/responsible/Provider';

interface ListData{
    id:number;
    name:string;
    questions?:ListData[];
    products?:ListData[];
}

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
    public loaded: boolean = false;
    public selectedSections: ListData[]|'all' = [];
    public selectedQuestions: ListData[]|'all' = [];
    public selectedMembers: ListData[]|'all' = 'all';
    public selectedProducts: ListData[]|'all' = 'all';
    public selectedProviders: ListData[]|'all' = 'all';
    public currTab: number = 0;
    public tabs: tab_interface[] = [
        { label: 'Data viewer', onClick: () => { this.setActiveTab(0); }, active: true },
        { label: 'Export', onClick: () => { this.setActiveTab(1); this.fetchExport(); }, active: false },
    ];
    public expanded:boolean = false;
    public sectionsList: ListData[] = [];
    public questionsList: ListData[] = [];
    public membersList: ListData[] = [];
    public productsList: ListData[] = [];
    public providersList: ListData[] = [];

    public hasExport:boolean = false;
    public exportEmail:string = '';

    public exportDone:boolean = false;

    public questions:FormQuestion[] = [];
    public answers:ApplicationAnswer[] = [];
    public fields:FormField[] = [];

    public chartData:{ [id:number]:any } = {};

    public membersLoading:boolean = false;
    public appsLoading:boolean = false;
    public providersLoading:boolean = false;

    public membersCooldown:any;
    public appsCooldown:any;
    public providersCooldown:any;

    constructor(
        private dialog: MatDialog,
        private analyticsApi:AnalyticsAPI,
        private userApi:UsersAPIService,
        private providerApi:ProviderAPI,
        private appApi:ApplicationAPI,
        private router: Router,
        private partials: PartialService,
        private jwt:JwtHelperService
    ){
        this.analyticsApi.fetch().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            for(let q of response.questions)
                this.questions.push(FormQuestion.create(q));

            for(let a of response.answers)
                this.answers.push(ApplicationAnswer.create(a));

            for(let f of response.fields)
                this.fields.push(FormField.create(f));

            this.countData();
            
            LoaderComponent.show.emit(false);
            this.loaded = true;
         });

        this.exportEmail = this.jwt.decodeToken().user.email;
    }

    ngOnInit(): void {
        LoaderComponent.show.emit(true);
    }

    private setActiveTab(i: number) {
        //LoaderComponent.show.emit(true);
        // if(i == 1 )LoaderComponent.show.emit(false)

        this.currTab = i;
        this.tabs[i].active = true;
        for (let [j, t] of this.tabs.entries()) if (i != j) t.active = false;
    }

    public openOverlayView(question:FormQuestion) {
        let d = this.dialog.open(OverlayViewComponent, {
            data: {
                dataArray: this.chartData[question.id]
            },
            panelClass: 'modal-white'
        });
    }

    public debug(){
        console.log(this);
    }

    public countData(){
        for(let q of this.questions){
            for(let a of this.answers){
                if(a.questionId===q.id){
                    for(let [k, v] of Object.entries(a.value)){
                        if(k!=='radio'){
                            if(v){
                                if(q.custom<3){
                                    for(let f of this.fields){
                                        if(Number(k)===f.id){
                                            if(!q.answerCount[f.label]) q.answerCount[f.label] = 1;
                                            else q.answerCount[f.label]++;
                                            q.answerCount.total++;
                                            break;
                                        }
                                    }
                                } else {
                                    for(let o of q.options){
                                        if(Number(k)===o.id){
                                            if(!q.answerCount[o.name]) q.answerCount[o.name] = 1;
                                            else q.answerCount[o.name]++;
                                            q.answerCount.total++;
                                            break;
                                        }
                                    }
                                }
                            } 
                        } else {
                            for(let f of this.fields){
                                if(Number(f.id)===v){
                                    if(!q.answerCount[f.label]) q.answerCount[f.label] = 1;
                                    else q.answerCount[ f.label]++;
                                    q.answerCount.total++;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        for(let q of this.questions)
            this.buildChartData(q);
    }
    public buildChartData(question:FormQuestion){
        let r:any = { percentageView:[] };
        for(let [k, v] of Object.entries(question.answerCount)){
            if(k!=='total')
                r.percentageView.push({ percentage:Math.floor((v/question.answerCount.total)*100), desc:k });
        }
        r.percentageView.sort((a:any, b:any) => { 
            if(a.percentage==b.percentage) return 0;
            return a.percentage>b.percentage?-1:1;
        });
        this.chartData[question.id] = r;
    }
    
    public getOffset(value: boolean){
        this.expanded = value
    }
    public goToPB(){
        this.router.navigateByUrl('/forms/new')
    }

    public fetchExport(){
        if(this.hasExport)
            return;

        LoaderComponent.show.emit(true);
        this.analyticsApi.fetchExport().subscribe(r => { 
            let res = CodedResponseModel.decode(r);

            this.sectionsList = res.sections.map((s:any) => ({ name: s.title, id: s.id, questions: (() => {
                let arr = [];
                for(let i of s.questions){
                    arr.push({ id: i.id, name: `${i.tag}: ${i.question}` });
                    for(let q of i.subquestions){
                        arr.push({ id: q.id, name: `${q.tag}: ${q.question}` });
                    }
                }
                return arr;
            })() }));

            // this.membersList = res.members.map((m:any) => ({ name: `${m.name} ${m.lname}`, id: m.id, products: (() => { 
            //     let arr = [];
            //     for(let i of m.applications){
            //         arr.push({ id:i.id, name:i.product_name });
            //     }
            //     return arr;
            // })() }));
            this.membersList = res.members.map((m:user_response) => User.create(m));
            this.providersList = res.providers.map((p:provider_response) => Provider.create(p));
            this.searchApps('');

            this.hasExport = true;
            LoaderComponent.show.emit(false);
        }, err => {
            this.partials.notificator.emit({ type:'error', message: err.error.message, timeout: 5000 });
            LoaderComponent.show.emit(false);
        })
    }

    public updateQuestionList(){
        this.questionsList = [];
        for(let i of this.selectedSections==='all'?this.sectionsList:this.selectedSections)
            this.questionsList.push(...(i.questions??[]))

        if(this.selectedQuestions!=='all')
            this.selectedQuestions = this.selectedQuestions.filter(q => this.questionsList.find(c => c.id == q.id) );
    }
    public updateProductList(){
        this.productsList = [];
        // for(let i of this.selectedMembers){
        //     this.productsList.push(...(i.products??[]));
        // }
        this.searchApps('');

        if(this.selectedProducts!=='all')
            this.selectedProducts = this.selectedProducts.filter(p => this.productsList.find(c => c.id == p.id) );
    }

    // public requestApplicationExport(){
    //     let sections = this.selectedSections==='all'?'all':this.selectedSections.map(s => s.id);
    //     let questions = this.selectedQuestions==='all'?'all':this.selectedQuestions.map(q => q.id);

    //     LoaderComponent.show.emit(true);
    //     this.analyticsApi.applicationExport(sections, questions).subscribe(r => { 
    //         let res = CodedResponseModel.decode(r);

    //         let el = document.createElement('a');
    //         el.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(res)));
    //         el.setAttribute('download', 'application-data.json');
    //         el.click();

    //         LoaderComponent.show.emit(false);
    //     }, err => {
    //         this.partials.notificator.emit({ type:'error', message: err.error.message, timeout: 5000 });
    //         LoaderComponent.show.emit(false);
    //     });
    // }

    public requestProductExport(type:'csv'|'json'){
        this.exportDone = false;
        let members = this.selectedMembers==='all'?'all':this.selectedMembers.map(s => s.id);
        let products = this.selectedProducts==='all'?'all':this.selectedProducts.map(q => q.id);

        LoaderComponent.show.emit(true);
        this.analyticsApi.productExport(members, products, this.exportEmail, type).subscribe((r:any) => { 
            //let res = CodedResponseModel.decode(r);

            // let el = document.createElement('a');
            // // let url = window.URL.createObjectURL(r);
            // if(type=='json'){
            //     el.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(r.data));
            //     el.setAttribute('download', 'product-data.json');
            // } else {
            //     el.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(r.data));
            //     el.setAttribute('download', 'product-data.csv');
            // }
            // el.click();

            LoaderComponent.show.emit(false);
            this.partials.notificator.emit({ type:'success', message:'Data export requested', timeout: 3000 });
        }, err => {
            console.log(err);
            this.partials.notificator.emit({ type:'error', message: err.error.message, timeout: 5000 });
            LoaderComponent.show.emit(false);
        });
    }

    public requestProviderExport(type:'csv'|'json'){
        this.exportDone = false;
        let providers = this.selectedProviders==='all'?'all':this.selectedProviders.map(p => p.id);

        LoaderComponent.show.emit(true);
        this.analyticsApi.providerExport(providers, this.exportEmail, type).subscribe(r => {
            let res = CodedResponseModel.decode(r);

            // let el = document.createElement('a');
            // if(type=='json'){
            //     el.setAttribute('href', 'data:application/json;chaset=utf-8,' + encodeURIComponent(res.data));
            //     el.setAttribute('download', 'provider-data.json');
            // } else {
            //     el.setAttribute('href', 'data:text/csv;chaset=utf-8,' + encodeURIComponent(res.data));
            //     el.setAttribute('download', 'provider-data.csv');
            // }
            // el.click();

            LoaderComponent.show.emit(false);
            this.partials.notificator.emit({ type:'success', message:'Data export requested', timeout: 3000 });
        }, err => {
            console.log(err);
            this.partials.notificator.emit({ type:'error', message: err.error.message, timeout: 5000 });
            LoaderComponent.show.emit(false);
        });
    }

    public searchMembers(query:string){
        if(this.membersCooldown) clearTimeout(this.membersCooldown);
        this.membersCooldown = setTimeout(() => {
            this.membersLoading = true;
            this.userApi.search({ query: query, limit:15, types: [ UserTypes.Member ] }).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.membersList = res.data.map((m:user_response) => User.create(m));
                this.membersLoading = false;
            })
        }, 500);
    }

    public searchApps(query:string){
        if(this.appsCooldown) clearTimeout(this.appsCooldown);
        this.appsCooldown = setTimeout(() => {
            this.appsLoading = true;
            if(this.selectedMembers==='all')
                this.appApi.search({ query: query, limit: 15 }).subscribe(r => {
                    let res = CodedResponseModel.decode(r);
                    this.productsList = res.data.map((m:application_response) => Application.create(m));
                    this.appsLoading = false;
                })
            else
                this.appApi.search({ query: query, limit: 15, users:[ -1, ...this.selectedMembers.map(m => m.id) ].join(';') }).subscribe(r => {
                    let res = CodedResponseModel.decode(r);
                    this.productsList = res.data.map((m:application_response) => Application.create(m));
                    this.appsLoading = false;
                })
        }, 500);
    }

    public searchProviders(query:string){
        if(this.providersCooldown) clearTimeout(this.providersCooldown);
        this.providersCooldown = setTimeout(() => {
            this.providersLoading = true;
            this.providerApi.search({ query: query, limit: 15 }).subscribe(r => {
                let res = CodedResponseModel.decode(r);
                this.providersList = res.data.map((p:provider_response) => Provider.create(p));
                this.providersLoading = false;
            })
        }, 500);
    }
}
