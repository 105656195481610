<div class='question'>
    <div class='question-container'>
        <div class='active field_btn'></div>
        <h4>Question {{question.order+1}}</h4>
        <textarea autosize [minRows]="1" class='ta_question creating' [(ngModel)]="question.question" placeholder="Question label" *ngIf="editable"></textarea>
        <h2 class='ta_question completing' *ngIf="!editable">{{question.question}}</h2>
        <ng-container *ngIf="!collapsed&&editable">
            <mat-radio-group [(ngModel)]="question.answer">
                <div class="radio row f_row">
                    <mat-radio-button [value]="true"></mat-radio-button>
                    <app-text-field style="width: 100%;" [isTextLimit]="false" [isAutoSize]="true" placeholderText="Option label" [(model)]="question.yesText"></app-text-field>
                </div>
                <div class="radio row f_row">
                    <mat-radio-button [value]="false"></mat-radio-button>
                    <app-text-field style="width: 100%;" [isTextLimit]="false" [isAutoSize]="true" placeholderText="Option label" [(model)]="question.noText"></app-text-field>
                </div>
            </mat-radio-group>
        </ng-container>
        <mat-radio-group *ngIf="!editable" [(ngModel)]="question.userAnswer">
            <div class="radio row f_row">
                <mat-radio-button [value]="true">{{question.yesText}}</mat-radio-button>
            </div>
            <div class="radio row f_row">
                <mat-radio-button [value]="false">{{question.noText}}</mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
    <div class='question-controls' *ngIf="editable">
        <div class='btn-group'>
            <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/collapse.svg'" *ngIf="!collapsed" (click)="collapsed=true"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/expand.svg'" *ngIf="collapsed" (click)="collapsed=false"></app-button-secondary>
        </div>
        <div class='btn-group'>
            <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="cantMoveUp" style='transform:rotate(180deg)' (click)="moveUp.emit()"></app-button-secondary>
            <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="cantMoveDown" (click)="moveDown.emit()"></app-button-secondary>
        </div>
        <div class='btn-group'>
            <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/menu.svg'" [matMenuTriggerFor]="qMenu"></app-button-secondary>
        </div>
        <mat-menu #qMenu="matMenu">
            <button disableRipple="true" mat-menu-item (click)="deleteQuestion()">Delete</button>
        </mat-menu>
    </div>
</div>