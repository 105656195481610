import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { Attachment } from "src/app/model/responsible/Attachment";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn: 'root'
})
export class ConversationAPI{
    constructor(
        private http:HttpClient,
        private epEncryption:EpEncryptionService,
    ){}

    public index(applicationId?:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}conversations${applicationId?'?application='+applicationId:''}`);
    }
    public get(id:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}conversations/${id}`);
    }
    public getByRelations(application:number, question?:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}conversations/${application}/${question}`);
    }
    public sendMessage(msg:string, id:number, files?:Attachment[]){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}conversations`,
            this.epEncryption.sendPayload({message:msg, conversation:id, attachments:files}));
    }
}
