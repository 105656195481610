import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { Exclusion } from 'src/app/model/responsible/Exclusion';
import { PartialService } from 'src/app/services/partial.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { ExclusionAPI } from 'src/app/services/responsible/exclusion.service';
import { LoaderComponent } from '../../../partials/loader/loader.component';
@Component({
    selector: 'app-edit-exclusions',
    templateUrl: './edit-exclusions.component.html',
    styleUrls: ['../admins.component.scss', './edit-exclusions.component.scss']
})
export class EditExclusionsComponent implements OnInit {
    public exclusions:Exclusion[] = [];

    constructor(
        private exclusionApi:ExclusionAPI,
        private attachmentApi:AttachmentAPI,
        private partials:PartialService,
    ){
        this.exclusionApi.index().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.exclusions = [];
            for(let e of response)
                this.exclusions.push(Exclusion.create(e));
                LoaderComponent.show.emit(false);
        })
    }

    ngOnInit():void{}

    public uploadIcon(e: any, target:Exclusion) {
        let flist = e.target.files;
        if (flist.item(0)) {
            if (!['image/png', 'image/jpeg', 'image/svg+xml'].includes(flist.item(0)!.type)) {
                this.partials.notificator.emit({ type:'error', message:'Icon must be of PNG, JPEG or SVG file type.', timeout: 5000 });
                return;
            }
            let added = false;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                switch (res.type) {
                    // case HttpEventType.Sent:
                    // case HttpEventType.UploadProgress:
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        target.icon = att;
                        target.iconId = att.id;
                        break;
                }
            }, err => {
                if (added) {
                    target.icon = undefined;
                    target.iconId = undefined;
                }
                this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
            });
        }
    }
    public uploadImage(e: any, target:Exclusion) {
        let flist = e.target.files;
        if (flist.item(0)) {
            if (!['image/png', 'image/jpeg'].includes(flist.item(0)!.type)) {
                this.partials.notificator.emit({ type:'error', message:'Icon must be of PNG or JPEG file type.', timeout: 5000 });
                return;
            }
            let added = false;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                switch (res.type) {
                    // case HttpEventType.Sent:
                    // case HttpEventType.UploadProgress:
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        target.image = att;
                        target.imageId = att.id;
                        break;
                }
            }, err => {
                if (added) {
                    target.image = undefined;
                    target.imageId = undefined;
                }
                this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
            });
        }
    }

    public send(){
        this.exclusionApi.update(this.exclusions).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Exclusions have been updated', timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        });
    }

    public addNew(){
        this.exclusions.push(new Exclusion());
    }

    public del(e:Exclusion){
        this.exclusions.splice(this.exclusions.indexOf(e), 1);
    }

}
