import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
    @Input() public model: number = 0;
    @Input() public min: number = 0;
    @Input() public max: number = 100;
    @Input() public percentage: boolean = false;
    @Input() public disabled:boolean = false;
    @Output() public modelChange = new EventEmitter<number>();

    constructor() { }

    ngOnInit(): void { }

    public changeEvent(value: number) {
        this.modelChange.emit(value);
    }

    //! Doesn't work for whatever reason
    public display(n: number) {
        if (this.percentage) return `${n}%`;
        else return n;
    }
}
