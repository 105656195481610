<app-auth></app-auth>
<app-menu [minimise]="false" (expanded)="getOffset($event)"></app-menu>
<main role="main">
    <div class="main_wrap">
        <div class="offset" [class.expanded]="!expanded" *ngIf="loaded">
            <div class="main_content">

                <div class='heading_wrap'>
                    <div class="text_wrap">
                        <div class="subheading">Edit profile</div>
                        <h2>Update your profile details</h2>
                    </div>
                </div>

                <div class="form_block update_data">
                    <div class="edit_avatar" (click)="fInput.click()">
                        <img [src]="user.avatarURL" *ngIf="user.avatar" alt="Your avatar">
                        <input type="file" #fInput [hidden]="true" (change)="uploader($event)">
                    </div>
                    <app-input-field placeholderText="First name" [(model)]="user.name"></app-input-field>
                    <app-input-field placeholderText="Last name" [(model)]="user.lname"></app-input-field>
                    <app-input-field placeholderText="Email" [(model)]="user.email"></app-input-field>
                    <app-input-field placeholderText="Phone number" [(model)]="user.phone"></app-input-field>
                    <app-button-primary text="Update" (click)="updateData()"></app-button-primary>
                </div>

                <div class='heading_wrap'>
                    <div class="text_wrap">
                        <div class="subheading">Account security</div>
                        <h2>Change your password</h2>
                    </div>
                </div>
                <div class="form_block login_box">
                    <div class="password_wrap">
                        <app-input-field [name]="'current_password'" [type]="'password'" [isError]="isError" [errorText]="''" [placeholderText]="'Current password'" 
                            [(model)]="password"></app-input-field>
                    </div>
                    <div class="password_wrap">
                        <app-input-field [name]="'password'" [type]="'password'" [isError]="isError" [errorText]="''" [placeholderText]="'Password'" 
                            [(model)]="newPass" [isPasswordComparsion]="true" (passwordComparsion)="passwordComparsion()" 
                            [isPasswordStrenght]="true"></app-input-field>
                    </div>
                    <div class="password_rule">Include 8 characters and one special character</div>
                    <div class="password_confirm_wrap">
                        <app-input-field [name]="'confirm_password'" [type]="'password'" [isError]="isError" [errorText]="''" [placeholderText]="'Confirm password'" 
                            [isPasswordComparsion]="true" (passwordComparsion)="passwordComparsion()" [(model)]="passConfirm"></app-input-field>
                        <img src="assets/svg/confirm_pass_tick.svg" alt="Tick" [class.show]="showTick">
                    </div>
                    <div class="error_wrap" *ngIf="error">
                        <img src="assets/svg/error.svg" alt="Error icon">
                        <div class="error">{{ errorMsg }}</div>
                    </div>

                    <!-- <div class="desc" *ngIf="passwordChanged">
                        <div>Password has been reset!</div>
                    </div> -->

                    <app-button-primary text="Change password" (click)="updatePass()"></app-button-primary>
                </div>

                <div class='heading_wrap'>
                    <div class="text_wrap">
                        <div class="subheading">Deleting account</div>
                        <h2>Delete your account</h2>
                    </div>
                </div>

                <div class="form_block">
                    <app-input-field #delPass placeholderText="Enter password" type="password"></app-input-field>
                    <app-button-primary text="Delete account" (click)="del(delPass.model)" [isActive]="delPass.model.length>0"></app-button-primary>
                </div>
            </div>
        </div>
    </div>
</main>