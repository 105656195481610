<!-- <app-notification-overlay [head]="head" [name]="name" [time]="time"></app-notification-overlay> -->

<div class="container row a_center">
    <mat-radio-button [labelPosition]="'before'">
        <div class="row a_center">
            <div class="column">
                <span>{{head}}</span>
                <span>{{getName()}}</span>
            </div>
        </div>
    </mat-radio-button>
</div>