export interface resource_check_response{
    id:number;
    label:string;
    order:number;
}
export class ResourceCheck{
    public id:number = 0;
    public label:string = '';
    public order:number = 0;

    public map(data:resource_check_response){
        this.id = data.id;
        this.label = data.label;
        this.order = data.order;

        return this;
    }
    public responsify():resource_check_response{
        return {
            id:this.id,
            label:this.label,
            order:this.order,
        }
    }
    public static create(data:resource_check_response){
        let r = new this;
        r.map(data);
        return r;
    }
}