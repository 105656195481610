import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Application } from 'src/app/model/responsible/Application';
import { ApplicationAnswer } from 'src/app/model/responsible/ApplicationAnswer';
import { Assessment } from 'src/app/model/responsible/Assessment';
import { AssessmentAnswer } from 'src/app/model/responsible/AssessmentAnswer';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';
import { CustomIntegrationAPI } from 'src/app/services/responsible/custom-integrations.service';
import { AssessApplicationComponent } from '../../assess/assess-application/assess-application.component';
import { ApplicationEditorComponent } from '../../editor/editor.component';
import { ShareQuestionComponent } from '../share-question/share-question.component';
import { ShareApplicationComponent } from '../share-application/share-application.component';
import { SectionBundle } from 'src/app/model/responsible/FormSectionBundle';
import { AnswerHistory } from 'src/app/model/responsible/AnswerHistory';
import { environment } from 'src/environments/environment';

interface FileLists{
    [key:number]:file_interface[]
}

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
    @Input() public question:FormQuestion = new FormQuestion();
    @Input() public section:FormSection = new FormSection();
    @Input() public form:SectionBundle = new SectionBundle();
    @Input() public application:Application = new Application();
    @Input() public assessment:Assessment = new Assessment();
    @Input() public sub:number = 0;
    @Input() public preuploads:file_interface[] = [];
    @Input() public readonly:boolean = false;
    @Input() public lastComments:boolean = false;
    @Input() public buttons:boolean = true;
    @Input() public isIvp:boolean = false;

    public list:{ name:string, value:any }[] = [];
    public files:FileLists = {};

    public isExpandedMessages: boolean = false
    constructor(
        private attachmentApi:AttachmentAPI,
        private applicationApi:ApplicationAPI,
        private dialog:MatDialog,
    ){}

    ngOnInit():void{
        switch(this.question.type){
            case 'order':
                if(!this.question.answer?.value.order){
                    let ids = [];
                    for(let a of this.question.fields)
                    ids.push(a.id);
                    this.confirmAnswer();
                    this.question.answer!.value.order = ids;
                }
                break;
        }
        if(this.question.answer?.files.length){
            for(let f of this.question.fields){
                if(f.type==='file'){
                    this.files[f.id] = [];
                    for(let a of this.question.answer.value[f.id]??[]){
                        let file = this.question.answer.getFileById(a);
                        if(file) this.files[f.id].push({
                            file: file,
                            filename: file.filename,
                            extension: file.getExtension()??'',
                            progress: 100,
                            status: 'ok'
                        });
                    }
                }
            }
        }
        if(this.question.id==4321) console.log(this);
    }

    public ntl(n:number){ return String.fromCharCode(65+n); }

    // public confirmConditions(){
    //     if(!this.question.conditions.length) return true;
    //     for(let g of this.question.conditions){
    //         let qRef = this.section.getQuestionById(g.targetId),
    //             answer = qRef?.answer;
    //         if(qRef){
    //             if(qRef.custom<3){
    //                 let final = false,
    //                     radioFinal = undefined;
    //                 for(let c of g.conditions){
    //                     let fRef = (()=>{ for(let f of qRef.fields) if(f.id==c.fieldId) return f; return null; })();
    //                     if(fRef){
    //                         if(fRef.type==='radio'){
    //                             if(radioFinal) continue;    //? Radio conditions have OR relation with one another, if one is already true, there's no need to verify any further ones
    //                             if(radioFinal===undefined) radioFinal = false;
    //                             if(!answer) radioFinal = c.condition==='false';
    //                             else if(answer.value.radio==fRef.id) radioFinal = c.condition==='true';
    //                             final = radioFinal;
    //                         } else if(fRef.type==='check'){
    //                             if(!answer) final = c.condition==='false';
    //                             else if(answer.value[fRef.id]===(c.condition==='true')||(answer.value[fRef.id]==undefined&&c.condition==='false')) final = true;
    //                             else final = false;
    //                         } else if(['slider-numeric','slider-percent'].includes(fRef.type)){
    //                             if(!answer&&fRef.rangeStart>=c.rangeStart&&fRef.rangeStart<=c.rangeEnd) final = true;
    //                             else if(answer&&Number(answer.value[fRef.id])>=c.rangeStart&&Number(answer.value[fRef.id])<=c.rangeEnd) final = true;
    //                             else final = false;
    //                         }
    //                         if(!final) return !this.question.hidden;
    //                     }
    //                 }
    //                 if(radioFinal!=undefined&&!radioFinal) return !this.question.hidden;
    //                 if(final) return this.question.hidden;
    //             } else {
    //                 //console.log(this.question);
    //                 let final = false;
    //                 for(let c of g.conditions){
    //                     if(answer?.value[c.customId!]===(c.condition==='true')||(answer?.value[c.customId!]==undefined&&c.condition==='false')) final = true;
    //                     else final = false;
    //                 }
    //                 if(final) return this.question.hidden;
    //             }
    //         }
    //     }
    //     return !this.question.hidden;
    // }

    private confirmAnswer(){
        if(!this.question.answer){
            if(this.question.category=='application'){
                let answer = new ApplicationAnswer();
                answer.questionId = this.question.id;
                this.question.answer = answer;
                this.application.answers.push(answer);
            } else {
                let answer = new AssessmentAnswer();
                answer.questionId = this.question.id;
                this.question.answer = answer;
                this.assessment.answers.push(answer);
            }
        } else if (!this.question.answer.questionId) this.question.answer.questionId = this.question.id;
    }

    public getAnswer(id:number){
        return this.question.answer?.value[id];
    }
    public getRadio(){
        for(let f of this.question.fields)
            if(f.id == this.question.answer?.value.radio) return f;
        return undefined;
        //this.question.answer?.value.radio;
    }
    public getDateAnswer(id:number){
        if(this.question.answer&&this.question.answer.value[id]){
            let answer = this.question.answer.value[id];//.split(';');
            if(Number.isNaN(Number(answer.split(';')[0]))){
                answer = `${moment(answer.split(';')[0]).format('YYYY-MM-DD')};${moment(answer.split(';')[1]).format('YYYY-MM-DD')}`;
                // answer = `${new Date(answer.split(';')[0]).getTime()};${new Date(answer.split(';')[1]).getTime()}`;
            }
            return answer;
        }
        return '';
        // return [moment().unix(),moment().unix()].join(';');
    }

    public setAnswer(id:number, value:string|boolean|number){
        this.confirmAnswer();
        this.question.answer!.value[id] = value;
        this.changesMade();
        this.form.confirmAllCondis();
    }
    public setRadio(id:number){
        if(id){
            this.confirmAnswer();
            this.question.answer!.value.radio = id;
            this.changesMade();
            this.form.confirmAllCondis();
        }
    }
    public setDateAnswer(id:number, range:{startDate:string, endDate:string}){
        this.confirmAnswer();
        console.log(range);
        this.question.answer!.value[id] = `${range.startDate};${range.endDate}`;
        this.changesMade();
    }
    public uploadFile(flist:FileList, id:number){
        this.confirmAnswer();
        if(!this.files[id]) this.files[id] = [];
        if(!this.question.answer!.value[id]) this.question.answer!.value[id] = [];
        if(flist.item(0)){
            if(this.question.getFieldById(id)?.filetypes){
                let acceptedTypes = [],
                    types = this.question.getFieldById(id)?.filetypes?.split('|');
                if(types?.includes('documents')) acceptedTypes.push('application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                if(types?.includes('images')) acceptedTypes.push('image/png','image/jpeg');
                if(types?.includes('spreadsheets') || types?.includes('sheets')) acceptedTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv');
                let tlist = types?.map(t => t=='sheets'?'spreadsheets':t);
                if(!acceptedTypes.includes(flist.item(0)!.type)){
                    console.error(`Only following file types are accepted: ${types?.join(', ')}`);
                    alert(`Only following file types are accepted: ${tlist?.join(', ')}`);
                    return;
                }
            }
            let added = false,
                handle:file_interface,
                index = 0;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop()??'',
                    status: 'new',
                    progress: 0
                };
                switch(res.type){
                    case HttpEventType.Sent:
                        index = this.files[id].push(handle) - 1;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        console.log(Math.round(res.loaded/res.total!*100));
                        this.files[id][index].progress = Math.round(res.loaded/res.total!*99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.question.answer!.files.push(att);
                        this.files[id][index].file = att;
                        this.files[id][index].progress = 100;
                        this.question.answer!.value[id].push(att.id);
                        this.changesMade();
                        break;
                }
            }, err => {
                if(added){
                    this.files[id].splice(index, 1);
                }
                console.warn(err);
            });
        }
    }
    public deleteFile(file:file_interface, id:number){
        this.files[id].splice(this.files[id].indexOf(file), 1);
        if(file.file){
            this.question.answer!.files.splice(this.question.answer!.files.indexOf(file.file), 1);
            this.question.answer!.value[id].splice(this.question.answer!.value[id].indexOf(file.file.id), 1);
        }
        this.changesMade();
    }
    public moveOrderUp(index:number){
        let item = this.question.answer!.value.order!.splice(index, 1);
        this.question.answer!.value.order?.splice(index - 1, 0, item[0]);
        this.changesMade();
    }
    public moveOrderDown(index:number){
        let item = this.question.answer!.value.order!.splice(index, 1);
        this.question.answer!.value.order?.splice(index + 1, 0, item[0]);
        this.changesMade();
    }

    public openUploader(id:number){
        console.log(this.preuploads);
        let d = this.dialog.open(UploadModalComponent, {
            panelClass:'modal-white',
            data: { filelist: this.preuploads, product_name: this.application.productName }
        });
        d.afterClosed().subscribe(res => {
            if(res){
                if(res.new)
                    this.uploadFile(res.file, id);
                else{
                    this.confirmAnswer();
                    if(!this.files[id]) this.files[id] = [];
                    if(!this.question.answer!.value[id]) this.question.answer!.value[id] = [];
                    this.files[id].push(res.file);
                    this.question.answer!.files.push(res.file.file);
                    this.question.answer!.value[id].push(res.file.file.id);
                    this.changesMade();
                }
            }
        });
    }

    public shareQuestion(){
        let d = this.dialog.open(ShareQuestionComponent, {
            panelClass:'modal-white',
            data: { qNumber: `${this.sub?this.sub:this.question.order+1}${this.sub?this.ntl(this.question.order):''}`, productName: this.application.productName }
        });
        d.afterClosed().subscribe(res => {
            if(res){
                this.applicationApi.shareQuestion(this.question.id, this.application.id, res).subscribe(res => {
                    console.log('ok');
                })
            }
        })
    }
    //Share applciation
    public shareApplication(){
        let d = this.dialog.open(ShareApplicationComponent, {
            panelClass:'modal-white',
            data: { qNumber: `${this.sub?this.sub:this.question.order+1}${this.sub?this.ntl(this.question.order):''}`, productName: this.application.productName }
        });
        d.afterClosed().subscribe(res => {
            if(res){
                this.applicationApi.shareQuestion(this.question.id, this.application.id, res).subscribe(res => {
                    console.log('ok');
                })
            }
        })
    }
    public changesMade(){
        ApplicationEditorComponent.changed = true;
        AssessApplicationComponent.changed = true;
    }

    public makeLink(filename:string){
        return `${environment.attachmentsUrl}${filename}`;
    }

    public get hasHistory():boolean{
        if(this.question.answer instanceof ApplicationAnswer && this.question.answer?.history.length)
            return true;
        return false;
    }

    public get qHistory():AnswerHistory[]{
        if(this.question.answer instanceof AssessmentAnswer)
            return [];
        return this.question.answer?.history??[];
    }
}
