import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-secondary',
  templateUrl: './button-secondary.component.html',
  styleUrls: ['./button-secondary.component.scss']
})
export class ButtonSecondaryComponent implements OnInit {

  @Input() imgUrl = ""
  @Input() buttonText = ""
  @Input() isText: boolean = false
  @Input() isHover: boolean = true
  @Input() isGreen: boolean = true
  @Input() isWhite: boolean = false
  @Input() isHoverGreen: boolean = false
  @Input() disabled: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

}
