import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { buildQueryParams, IndexQuery } from "src/app/model/IndexQuery";
import { FormSection } from "src/app/model/responsible/FormSection";
import { SectionBundle } from "src/app/model/responsible/FormSectionBundle";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root',
})
export class FormAPI{
    constructor(
        private http:HttpClient,
        private epEncryption:EpEncryptionService,
    ){}

    // public index(query?:IndexQuery){
    //     let params = buildQueryParams(query);
    //     return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}forms${query}`);
    // }
    public get(id:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}forms/${id}`);
    }
    public getLatest(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}forms/latest`);
    }
    public getDraft(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}forms/draft`);
    }
    public saveDraft(data:SectionBundle){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}forms/draft`,
            this.epEncryption.sendPayload({ sections: data.responsify() }));
    }
    public publishDraft(data:SectionBundle, extras:any){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}forms/publish`,
            this.epEncryption.sendPayload({ sections: data.responsify(), extra: extras }));
    }
    // public getSections(id:number){
    //     return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}forms/${id}/sections`);
    // }
    public getDefaultables(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}questions/defaultable`);
    }
    // public create(data:any){
    //     return this.http.post<CodedResponseModel>(`${apiUrls.apiUrl}forms`,
    //         this.epEncryption.sendPayload(data));
    // }
    // public update(data:any){
    //     return this.http.put<CodedResponseModel>(`${apiUrls.apiUrl}forms/${data.form.id}`,
    //         this.epEncryption.sendPayload(data));
    // }
    // public autosave(data:any){
    //     return this.http.put<CodedResponseModel>(`${apiUrls.apiUrl}forms/${data.form.id}/autosave`,
    //         this.epEncryption.sendPayload(data));
    // }
    // public delete(id:number){
    //     return this.http.delete<CodedResponseModel>(`${apiUrls.apiUrl}forms/${id}`);
    // }

    public tryLock(lock?:string){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}forms/lock`,
            this.epEncryption.sendPayload(lock));
    }
}
