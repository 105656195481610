import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-heading3',
  templateUrl: './heading3.component.html',
  styleUrls: ['./heading3.component.scss']
})
export class Heading3Component implements OnInit {
  @Input() text: any = ''
  @Input() isTextBlack: boolean = true
  constructor() { }

  ngOnInit(): void {
  }

}
