<app-auth></app-auth>
<app-menu [minimizable]="false" [exists]="false" [display]="false"></app-menu>
<app-message *ngIf="(currSection==-2 || currSection==-1 ||currSection==-4 || currSection==-3) && application.id" [product]="application"></app-message>
<main role="main">
    <div class="main_wrap" *ngIf="isContentReady">
        <app-form-menu [display]="true" [isEnabled]="application.id>0" [currSection]="currSection" [form]="form" [submitted]="application.id>0&&application.status!='In Progress'" (changeTab)="currSection=$event; isViewReady = false; scrollToTop()" [overview]="true" [assessment]="application.riaaAssessment?.finished||false"></app-form-menu>
        <div class='offset app-overview start' *ngIf="currSection==-1">
            <div class='form'>
                <h4>Let's get started</h4>
                <h1>Start your application</h1>
                <app-text-field [isAutoSize]="true" placeholderText="Product
                    name" [(model)]="application.productName" (modelChange)="changesMade()"></app-text-field>
                <app-drag-drop textLabel="Drag and Drop banner image" (openModal)="openUploader( 'banner')" (fileEvent)="uploadBanner($event)" (fileRemoved)="deleteBanner()" [fileList]="bannerFile" [isFileLimit]="false" [types]="'JPG'" [isHardcodedTypes]="true"></app-drag-drop>
                <app-large-text-field [isTextLimit]="false" limit="-1" placeholderText="Product description" [(model)]="application.description" (modelChange)="changesMade()"></app-large-text-field>
                <app-form-select *ngIf="isAdmin" [list]="members" placeholderText="Select member" [(model)]="application.user" [loading]="membersLoading"
                    (modelChange)="changesMade()" displayProperty="fullName" [customSearch]="true" (searched)="memberSearch($event)"></app-form-select>
                <app-form-select [list]="providers" placeholderText="Select provider" [(model)]="application.provider" (modelChange)="changesMade()"
                    [customSearch]="true" (searched)="providerSearch($event)" [loading]="providersLoading"></app-form-select>
                <app-form-select *ngIf="application.collaborators" [list]="collaborators" displayProperty="fullName" placeholderText="Select collaborator(s)"
                    [isMulitSelect]="true" [(model)]="application.collaborators" (modelChange)="changesMade()" [customSearch]="true"
                    [selectedValues]="application.collaborators" (searched)="collabsSearch($event)" [loading]="collabsLoading"
                    compareValue="id"></app-form-select>
                <app-tick-box [(value)]="preuploadFiles" text="Pre-upload files for application"></app-tick-box>
                <app-drag-drop *ngIf="preuploadFiles" (openModal)="openUploader(
                    'preupload')" (fileEvent)="preuploadFile($event)" (fileRemoved)="deletePreupload($event)" [fileList]="preuploads"></app-drag-drop>
                <app-tick-box [(value)]="cloneApp" text="Clone another
                    application"></app-tick-box>
                <app-form-select class="extra_height" *ngIf="cloneApp" placeholderText="Select product" [list]="applications" displayProperty="productName"
                    [(model)]="appToClone" [customSearch]="true" (searched)="appsSearch($event)"></app-form-select>
                <app-button-primary [isActive]="application.productName !== '' && application.provider !== undefined && bannerFile !== undefined  && application.description !== ''" text="Start application" (click)="startApplication()"></app-button-primary>
            </div>
        </div>
        <div class='offset app-overview submit' *ngIf="currSection==-2">
            <div class="progres">
                <div class='pname'><span>{{application.productName}}</span></div>
                <div><span>{{application.completion}}%</span></div>
            </div>
            <div class='form'>
                <h4>Submit your application</h4>
                <h1>Final Declarations</h1>
                <app-tick-box #tick1 text="I have provided answers that are
                    truthful, accurate, reliable and complete at the time of
                    submission">
                </app-tick-box>
                <app-tick-box [isLinks]="true" [links]="[
                        {value: 'Concerns and Grievance Statements', replaceKEY: 'LINK1',href: 'assets/pdf/Policy-_ConcernsGrievance.pdf'},
                        {value: 'Program Code of Conduct', replaceKEY: 'LINK2',href: 'assets/pdf/Program-Code-of-Conduct.pdf'}
                    ]" #tick2 text="I have read the LINK1 and the LINK2 and understand our commitment">
                </app-tick-box>
                <app-button-primary text="Submit Application" [isActive]="tick1.value&&tick2.value" (click)="submitApp()"></app-button-primary>
                <hr>
                <h2>Need a copy of your application? <br/> Download PDFs from our options below:</h2>
                <app-form-select [list]="pdfTypes" displayProperty="label" (modelChange)="selType=$event.type"></app-form-select>
                <app-button-secondary *ngIf="selType" [isText]="true" [imgUrl]="'assets/svg/plus-icon.svg'" buttonText="Generate a new PDF" [isGreen]="true" (click)="generatePdf()"></app-button-secondary>
                <app-download-button *ngIf="downloadPdf" [fileSize]="downloadPdf.size" [fileType]="'PDF'" [name]="downloadPdf.name" [downloadable]="true"
                    [fileUrl]="downloadPdf.url"></app-download-button>
            </div>
        </div>
        <div class='offset app-overview app_progres' *ngIf="currSection==-3">
            <div class="progres">
                <div class='pname'><span>{{application.productName}}</span></div>
            </div>
            <app-application-progress [application]="application" (editApp)="editApp()"></app-application-progress>
            <div class="form" [class.mb-10]="!downloadPdf">
                <hr>
                <h2>Need a copy of your application? <br/> Download PDFs from our options below:</h2>
                <app-form-select [list]="pdfTypes" displayProperty="label" (modelChange)="selType=$event.type"></app-form-select>
                <app-button-secondary *ngIf="selType" [isText]="true" [imgUrl]="'assets/svg/plus-icon.svg'" buttonText="Generate a new PDF" [isGreen]="true" (click)="generatePdf()"></app-button-secondary>
                <app-download-button *ngIf="downloadPdf" [fileSize]="downloadPdf.size" [fileType]="'PDF'" [name]="downloadPdf.name" [downloadable]="true"
                    [fileUrl]="downloadPdf.url"></app-download-button>
            </div>
        </div>
        <div class='offset app-overview submited' *ngIf="currSection==-4">
            <app-confetti></app-confetti>
            <div class="progres">
                <div class='pname'><span>{{application.productName}}</span></div>
            </div>
            <div class='form'>
                <svg-icon class="animated_tick" src="../../../assets/svg/big_tick.svg"></svg-icon>
                <h4>Submitted Application</h4>
                <h1>Congratulations!</h1>
                <p class='thanks-label'>Your application is submitted and is now in the assessment queue.</p>
                <app-button-primary text="Back to Dashboard" routerLink="/"></app-button-primary>
                <app-button-secondary [isText]="true" buttonText="View progress" (click)="currSection=-3" style='align-self:center;'></app-button-secondary>
                <hr>
                <h2>Need a copy of your application? <br/> Download PDFs from our options below:</h2>
                <app-form-select [list]="pdfTypes" displayProperty="label" (modelChange)="selType=$event.type"></app-form-select>
                <app-button-secondary *ngIf="selType" [isText]="true" [imgUrl]="'assets/svg/plus-icon.svg'" buttonText="Generate a new PDF" [isGreen]="true" (click)="generatePdf()"></app-button-secondary>
                <app-download-button *ngIf="downloadPdf" [fileSize]="downloadPdf.size" [fileType]="'PDF'" [name]="downloadPdf.name" [downloadable]="true"
                    [fileUrl]="downloadPdf.url"></app-download-button>
                <!-- <app-button-secondary [isText]="true" buttonText="Download as PDF" (click)="generatePdf()" style='align-self:center;'></app-button-secondary>
                <app-button-secondary [isText]="true" buttonText="Download Exclusions PDF" (click)="generateExclusionsPdf()" style='align-self:center;'></app-button-secondary> -->
                <!-- <app-button-secondary [isText]="true" buttonText="Download as PDF"></app-button-secondary> -->
            </div>
        </div>
        <div class="offset app-overview approved" *ngIf="currSection==-5">
            <app-application-result [application]="application"></app-application-result>
        </div>
        <div class='offset completing' *ngIf="currSection>=0">
            <div class='main' *ngIf="scontent.offsetWidth !== undefined" [ngStyle]="{'max-width':'calc(100% - '+ scontent.offsetWidth+'px)'}">
                <div class="mw_container">
                    <div class="progres" (click)="debug()">
                        <div class='pname'><span>{{application.productName}}</span></div>
                        <div><span>{{application.completion}}%</span></div>
                    </div>
                    <div class='top'>
                        <h4 *ngIf="form.sections[currSection].time">Time to complete: {{form.sections[currSection].time}}
                        </h4>
                        <h1>{{form.sections[currSection].title}}</h1>
                        <p>{{form.sections[currSection].description||''}}</p>
                    </div>
                    <div class='questions'>
                        <app-question class='question' *ngFor="let question of form.sections[currSection].questions; let i= index" [question]="question"
                            [section]="form.sections[currSection]" [application]="application" [preuploads]="preuploads" [form]="form"></app-question>
                    </div>
                    <div class="btns_container">
                        <div class='autosave'>
                            <span *ngIf="lastAutosave">Last autosave</span>
                            <span *ngIf="lastAutosave">{{lastAutosave |
                                date:'dd/MM/yyyy'}} at {{lastAutosave | date:
                                'HH:mm a'}}</span>
                        </div>
                        <app-button-primary text="Go to next section" (click)="nextSection()"></app-button-primary>
                    </div>
                </div>
                <!-- <app-button-secondary [isText]="true" buttonText="Debug" (click)="debug()"></app-button-secondary> -->
            </div>
            <div #scontent class='support-content'>
                <app-support-content-container [app]="application" [section]="form.sections[currSection]"></app-support-content-container>
            </div>
        </div>
    </div>
</main>
