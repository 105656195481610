import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FileDropDirective } from './directive/file-drop.directive';
import { EpValidatorDirective } from './directive/ep-validator.directive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtModule } from "@auth0/angular-jwt";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from './auth/auth.component';
import { MenuComponent } from './menu/menu.component';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { UserIndexComponent } from './users/index.component';
import { UserShowComponent } from './users/show.component';
import { UserCreateComponent } from './users/new.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ProfileEditComponent } from './pages/profile-edit/profile-edit.component';
import { MailEventsComponent } from './mail-events/mail-events.component';
import { MailEventsEditComponent } from './mail-events/mail-events-edit/mail-events-edit.component';
import { MailingGroupComponent } from './mailing-group/mailing-group.component';
import { MailingGroupEditComponent } from './mailing-group/mailing-group-edit/mailing-group-edit.component';
import { LoaderComponent } from "./partials/loader/loader.component";
import { FormBuilderComponent } from './form-builder/form-builder/form-builder.component';
import { FormListComponent } from './form-builder/form-list/form-list.component';
import { FBQuestionComponent } from './form-builder/elements/question/question.component';
import { SingleLineFieldComponent } from './form-builder/elements/single-line-field/single-line-field.component';
import { FormMenuComponent } from './menu/form-menu/form-menu.component';
import { FieldMakerComponent } from './form-builder/elements/field-maker/field-maker.component';
import { FieldComponent } from './form-builder/elements/field/field.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonPrimaryComponent } from './core/button-primary/button-primary.component'

import { TextFieldComponent } from './core/text-field/text-field.component';
import { TickBoxComponent } from './core/tick-box/tick-box.component';

import { DragDropComponent } from './core/drag-drop/drag-drop.component';
import { TestComponent } from './core/test/test.component';
import { ButtonSecondaryComponent } from './core/button-secondary/button-secondary.component';
import { Heading1Component } from './core/heading1/heading1.component';
import { Heading2Component } from './core/heading2/heading2.component';
import { Heading3Component } from './core/heading3/heading3.component';
import { Heading4Component } from './core/heading4/heading4.component';
import { Heading5Component } from './core/heading5/heading5.component';
import { Paragraph1Component } from './core/paragraph1/paragraph1.component';
import { Paragraph2Component } from './core/paragraph2/paragraph2.component';
import { ButtonIconComponent } from './core/button-icon/button-icon.component';
import { FormBuilderButtonComponent } from './core/form-builder-button/form-builder-button.component';
import { FiltersComponent } from './core/filters/filters.component';
import { DownloadButtonComponent } from './core/download-button/download-button.component';
import { ConversationPreviewComponent } from './core/conversation-preview/conversation-preview.component';
import { NotificationOverlayComponent } from './core/notification-overlay/notification-overlay.component';

// Angular material
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatExpansionModule } from '@angular/material/expansion';
 // import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatNativeDateModule } from '@angular/material/core';
// import { MatInputModule } from '@angular/material/input';


//calendar
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatListModule } from '@angular/material/list';

// import { NumberFieldComponent } from './core/number-field/number-field.component'
import { FormSelectComponent } from './core/form-select/form-select.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SupportContentComponent } from './form-builder/support-content/support-content.component';
import { ConditionBuilderComponent } from './form-builder/condition-builder/condition-builder.component';
import { FileTypesComponent } from './form-builder/elements/file-types/file-types.component';
import { CharLimitComponent } from './form-builder/elements/char-limit/char-limit.component'
import { RadioButtonGroupComponent } from './core/radio-button-group/radio-button-group.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BulkActionsComponent } from './core/bulk-actions/bulk-actions.component';
import { FiltersNumberComponent } from './core/filters-number/filters-number.component';
import { FiltersSearchComponent } from './core/filters-search/filters-search.component';
import { DeleteConfirmComponent } from './form-builder/elements/delete-confirm/delete-confirm.component';
import { ConditionsComponent } from './form-builder/elements/conditions/conditions.component';
import { UpdateTimeframeComponent } from './form-builder/elements/update-timeframe/update-timeframe.component';
import { SliderTemplateComponent } from './form-builder/elements/slider-template/slider-template.component';
import { TopTabsComponent } from './core/top-tabs/top-tabs.component';
import { AddToFrontendComponent } from './form-builder/elements/add-to-frontend/add-to-frontend.component';

import { FiltersStarComponent } from './core/filters-star/filters-star.component';
import { FiltersCalendarComponent } from './core/filters-calendar/filters-calendar.component';
import { FiltersCategoryComponent } from './core/filters-category/filters-category.component';
import { FiltersCommentsComponent } from './core/filters-comments/filters-comments.component';
import { FiltersListComponent } from './core/filters-list/filters-list.component';
import { NumberFieldComponent } from './core/number-field/number-field.component';
import { ApplicationEditorComponent } from './application/editor/editor.component';
import { QuestionComponent } from './application/elements/question/question.component';
import { SliderComponent } from './core/slider/slider.component';
import { SupportContentContainerComponent } from './application/elements/support-content-container/support-content-container.component';
import { LargeTextFieldComponent } from './core/large-text-field/large-text-field.component';
import { TableComponent } from './core/table/table.component';
import { FieldThemesComponent } from './form-builder/elements/themes/themes.component';
import { InputFieldComponent } from './core/input-field/input-field.component';


import { DragDropModule } from '@angular/cdk/drag-drop';
import { ThemeExclusionComponent } from './form-builder/elements/theme-exclusion/theme-exclusion.component';
import { LoginFeaturedContentComponent } from './core/login-featured-content/login-featured-content.component';
import { MoveCategoryComponent } from './form-builder/elements/move-category/move-category.component';
import { NotificationsComponent } from './menu/notifications/notifications.component';
import { LockModalComponent } from './form-builder/elements/lock-modal/lock-modal.component';
import { FormBuilderCalendarComponent } from './core/form-builder-calendar/form-builder-calendar.component';
import { NumberInputComponent } from './core/number-input/number-input.component';
import { ConversationsComponent } from './menu/conversations/conversations.component';
import { ConversationsOpenDirective } from './directive/conversations-open.directive';
import { NewConversationComponent } from './menu/conversations/new-conversation/new-conversation.component';
import { NotificatorComponent } from './partials/notificator/notificator.component';
import { VideoComponent } from './core/video/video.component';
import { YoutubeComponent } from './core/video/youtube/youtube.component';
import { VimeoComponent } from './core/video/vimeo/vimeo.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VideoModalComponent } from './partials/video-modal/video-modal.component';
import { ChatComponent } from './menu/conversations/chat/chat.component';
import { SectionConditionBuilderComponent } from './form-builder/section-condition-builder/section-condition-builder.component';
import { PopUpsComponent } from './pop_ups/pop-ups.component';
import { UploadFilesComponent } from './pop_ups/pop_up/upload-files/upload-files.component';
import { PreuploadedFilesComponent } from './application/elements/preuploaded-files/preuploaded-files.component';
import { UploadModalComponent } from './core/upload-modal/upload-modal.component';
import { FirstLetterPipe } from './pipe/first-letter.pipe';
import { ApplicationComponent } from './pages/application/application.component';
import { MembersComponent } from './pages/members/members.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { EditSiteComponent } from './pages/edit-site/edit-site.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { ApplicationProgressComponent } from './application/elements/application-progress/application-progress.component';
import { ApplicationResultComponent } from './application/elements/application-result/application-result.component';

//autosize
import { AutosizeModule } from 'ngx-autosize';
import { ShareQuestionComponent } from './application/elements/share-question/share-question.component';
import { UrlFieldComponent } from './core/url-field/url-field.component';
import { AssessApplicationComponent } from './application/assess/assess-application/assess-application.component';
import { AssessmentPanelComponent } from './application/assess/assessment-panel/assessment-panel.component';
import { MessageComponent } from './core/message/message.component';

import * as Hammer from 'hammerjs';
import {HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { AccordionComponent } from './core/accordion/accordion.component';
import { CircledAddEditLinkComponent } from './core/circled-add-edit-link/circled-add-edit-link.component';
import { FaqQuestionsComponent } from './core/faq-questions/faq-questions.component';
import { DocumentsNTemplatesComponent } from './core/documents-n-templates/documents-n-templates.component';
import { ChecklistComponent } from './core/checklist/checklist.component';
import { DashboardComponent } from './dashboard/dashboard.component';
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: false }
  };
}
import { NgApexchartsModule } from "ng-apexcharts";
import { PieChartComponent } from './pages/analytics/pie-chart/pie-chart.component';
import { OverlayViewComponent } from './pages/analytics/overlay-view/overlay-view.component';
import { PaginationComponent } from './core/pagination/pagination.component';
import { SharedQuestionsComponent } from './application/shared/shared-questions/shared-questions.component';
import { ShareApplicationComponent } from './application/elements/share-application/share-application.component';
import { SharedApplicationComponent } from './application/shared/shared-application/shared-application.component';

import { ProductCardComponent } from './product-card/product-card.component';
import { ColorListComponent } from './core/color-list/color-list.component';
import { AvatarComponent } from './core/avatar/avatar.component';
import { ApproveModalComponent } from './dashboard/approve-modal/approve-modal.component';
import { DenyModalComponent } from './dashboard/deny-modal/deny-modal.component';
import { ConfirmDraftComponent } from './form-builder/elements/confirm-draft/confirm-draft.component';
import { AddProviderComponent } from './pages/members/add-provider/add-provider.component';
import { AddMemberComponent } from './pages/members/add-member/add-member.component';
import { IndexTableComponent } from './pages/members/index-table/index-table.component';
import { AddNewAdminComponent } from './pages/admins/add-new-admin/add-new-admin.component';
import { EditExclusionsComponent } from './pages/admins/edit-exclusions/edit-exclusions.component';
import { EditThemesComponent } from './pages/admins/edit-themes/edit-themes.component';
import { EditCategoriesComponent } from './pages/admins/edit-categories/edit-categories.component';
import { EditSearchFiltersComponent } from './pages/admins/edit-search-filters/edit-search-filters.component';
import { EditAdminComponent } from './pages/admins/edit-admin/edit-admin.component';
import { NoProductsComponent } from './dashboard/no-products/no-products.component';
import { ResourcesQuestionComponent } from './pages/resources/resources-question/resources-question.component';
import { DefaultQuestionComponent } from './pages/members/add-provider/default-question/default-question.component';
import { ConfettiComponent } from './core/confetti/confetti.component';
import { LoginSettingsComponent } from './pages/admins/login-settings/login-settings.component';
import { OldLoaderComponent } from './loader/loader.component';
import { OverlayComponent } from './core/overlay/overlay.component';
import { JoditAngularModule } from 'jodit-angular';
import { SingleCalendarComponent } from './core/single-calendar/single-calendar.component';
import { EditCertDateModalComponent } from './dashboard/edit-cert-date-modal/edit-cert-date-modal.component';
import { RadioGroupEditorComponent } from "./form-builder/elements/radio-group-editor/radio-group-editor.component";
import { RadioGroupFieldComponent } from "./application/elements/radio-group-field/radio-group-field.component";

 @NgModule({
  declarations: [
    AppComponent,
    OldLoaderComponent,
    FileDropDirective,
    EpValidatorDirective,
    LoginComponent,
    HomeComponent,
    AuthComponent,
    MenuComponent,
    UserIndexComponent,
    UserShowComponent,
    UserCreateComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProfileEditComponent,
    MailEventsComponent,
    MailEventsEditComponent,
    MailingGroupComponent,
    MailingGroupEditComponent,
    LoaderComponent,
    FormBuilderComponent,
    FormListComponent,
    FBQuestionComponent,
    SingleLineFieldComponent,
    FormMenuComponent,
    FieldMakerComponent,
    FieldComponent,
    ButtonPrimaryComponent,
    TextFieldComponent,
    TickBoxComponent,

    DragDropComponent,
    TestComponent,
    ButtonSecondaryComponent,
    Heading1Component,
    Heading2Component,
    Heading3Component,
    Heading4Component,
    Heading5Component,
    Paragraph1Component,
    Paragraph2Component,
    ButtonIconComponent,
    FormBuilderButtonComponent,
    FiltersComponent,
    DownloadButtonComponent,
    ConversationPreviewComponent,
    NotificationOverlayComponent,
    FormSelectComponent,
    SupportContentComponent,
    ConditionBuilderComponent,
    FileTypesComponent,
    CharLimitComponent,
    RadioButtonGroupComponent,
    BulkActionsComponent,
    FiltersNumberComponent,
    FiltersSearchComponent,
    DeleteConfirmComponent,
    ConditionsComponent,
    UpdateTimeframeComponent,
    SliderTemplateComponent,
    TopTabsComponent,
    AddToFrontendComponent,
    FiltersStarComponent,
    FiltersCalendarComponent,
    FiltersCommentsComponent,
    FiltersListComponent,
    FiltersCategoryComponent,
    NumberFieldComponent,
    ApplicationEditorComponent,
    QuestionComponent,
    SliderComponent,
    SupportContentContainerComponent,
    ThemeExclusionComponent,
    LargeTextFieldComponent,
    TableComponent,
    FieldThemesComponent,
    InputFieldComponent,
    LoginFeaturedContentComponent,
    MoveCategoryComponent,
    NotificationsComponent,
    LockModalComponent,
    FormBuilderCalendarComponent,
    NumberInputComponent,
    ConversationsComponent,
    ConversationsOpenDirective,
    NewConversationComponent,
    FormBuilderCalendarComponent,
    NumberInputComponent,
    LockModalComponent,
    NotificatorComponent,
    VideoComponent,
    YoutubeComponent,
    VimeoComponent,
    VideoModalComponent,
    ChatComponent,
    SectionConditionBuilderComponent,
    PopUpsComponent,
    UploadFilesComponent,
    PreuploadedFilesComponent,
    UploadModalComponent,
    FirstLetterPipe,
    ApplicationComponent,
    MembersComponent,
    AnalyticsComponent,
    ResourcesComponent,
    EditSiteComponent,
    AdminsComponent,
    ApplicationProgressComponent,
    ApplicationResultComponent,
    ShareQuestionComponent,
    UrlFieldComponent,
    AssessApplicationComponent,
    AssessmentPanelComponent,
    MessageComponent,
    AccordionComponent,
    CircledAddEditLinkComponent,
    FaqQuestionsComponent,
    DocumentsNTemplatesComponent,
    ChecklistComponent,
    DashboardComponent,
    PieChartComponent,
    OverlayViewComponent,
    PaginationComponent,
    SharedQuestionsComponent,
    ShareApplicationComponent,
    SharedApplicationComponent,
    ProductCardComponent,
    ColorListComponent,
    AvatarComponent,
    ApproveModalComponent,
    DenyModalComponent,
    ConfirmDraftComponent,
    AddProviderComponent,
    AddMemberComponent,
    IndexTableComponent,
    AddNewAdminComponent,
    EditExclusionsComponent,
    EditThemesComponent,
    EditCategoriesComponent,
    EditSearchFiltersComponent,
    EditAdminComponent,
    NoProductsComponent,
    ResourcesQuestionComponent,
    DefaultQuestionComponent,
    ConfettiComponent,
    LoginSettingsComponent,
    OverlayComponent,
    SingleCalendarComponent,
    EditCertDateModalComponent,

    RadioGroupEditorComponent,
    RadioGroupFieldComponent
   ],
  imports: [
    JoditAngularModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    // MatDatepickerModule,
    // MatNativeDateModule,
    // MatInputModule,
    MatListModule,
    MatFormFieldModule,
    TextFieldModule,
    MatExpansionModule,
    YouTubePlayerModule,
    ReactiveFormsModule,
    AutosizeModule,

     NgxDaterangepickerMd.forRoot({

    }),
    AngularSvgIconModule.forRoot(),
    JwtModule.forRoot({
      config: {
        headerName: 'X-Authorization',
        tokenGetter: tokenGetter,
        allowedDomains: ["laravel", '127.0.0.1:8000','192.168.0.50:8000', 'responsiblereturns-api.jtbsyn.com', 'responsiblereturns-api2.jtbsyn.com', 'api.stg.responsiblereturns.com.au', 'api.responsiblereturns.com.au', 'api.responsiblereturns.local'],
        // disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
    BrowserAnimationsModule,
    MatSliderModule,
    MatMenuModule,
    DragDropModule,
    HammerModule,
    NgApexchartsModule,
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem("access_token");
}
