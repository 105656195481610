<app-auth></app-auth>
<app-menu [minimise]="false" (expanded)="getOffset($event)"></app-menu>
<div #main class='main' [class.table]="currTab==0" [ngClass]="{ 'menu_expanded': !expanded} ">
    <app-top-tabs [tabs]="tabs"></app-top-tabs>
    <div class="table_container " *ngIf="currTab==0">
        <div class="text_wrap ">
            <h4>{{tabs[0].badge}} Admins</h4>
            <h1>{{userType==2?'RIAA and ':''}}IVP Admins</h1>
        </div>
        <div #table class="table_main">
            <app-table [noResults]="total>0?false:true" [isContentLoading]="filterCooldown !== undefined" [totalElements]="total" (pageChange)="getPage($event) " (filtersChange)="getFilters($event) " [refresher]="refresher " [column]="columns " [rows]="admins " [ngClass]="{ 'm_expand': expanded} "
                [bulkActionsModel]="bulkActions "></app-table>

        </div>
    </div>
    <div *ngIf="currTab==1 ">
        <app-edit-admin [target]="target" [editing]="isEdit"></app-edit-admin>
    </div>
    <div *ngIf="currTab==2 ">
        <app-edit-exclusions></app-edit-exclusions>
    </div>
    <div *ngIf="currTab==3 ">
        <app-edit-themes></app-edit-themes>
    </div>
    <div *ngIf="currTab==4 ">
        <app-edit-categories></app-edit-categories>
    </div>
    <div *ngIf="currTab==5 ">
        <app-edit-search-filters></app-edit-search-filters>
    </div>
    <div *ngIf="currTab==6">
        <app-login-settings></app-login-settings>
    </div>
</div>