import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-update-timeframe',
    templateUrl: './update-timeframe.component.html',
    styleUrls: ['./update-timeframe.component.scss']
})
export class UpdateTimeframeComponent implements OnInit {
    public timeframes = [
        { name: 'None', value: 0 },
        { name: '3 months', value: 3 },
        { name: '6 months', value: 6 },
        { name: '12 months', value: 12 },
        { name: '18 months', value: 18 },
        { name: '24 months (recertifications)', value: 24 },
    ]
    public tag:string = '';
    public selected = this.timeframes[0];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:{ tag:string, curr:number }
    ){}

    ngOnInit():void{
        this.tag = this.data.tag;
        this.selected = (() => { for(let t of this.timeframes) if(this.data.curr==t.value) return t; return this.timeframes[0]; })();
    }
}
