<div class="column" [ngClass]="{'active': isFocused, 'accepted': isAccepted,
    'auto_size': isAutoSize, 'correct_url': correctUrl, 'error': isError}">
    <div class="row">
        <textarea #url [class.disabled]="isDisabled" [formControl]="val" pattern="{{pattern}}" (focus)="onFocus($event)" (blur)="onBlur()" [class.w-100]="fullWidth"></textarea>
        <div class="placeholder-container">
            <span class="placeholder">{{placeholderText}}</span>
        </div>
    </div>
    <!-- <div *ngIf="isError" class="container">
        <span>{{errorText}}</span>
    </div> -->
</div>