import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tab_interface } from 'src/app/core/top-tabs/top-tabs.component';
import { MenuComponent } from 'src/app/menu/menu.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { AssetClass, Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { FormField } from 'src/app/model/responsible/FormField';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { SectionBundle } from 'src/app/model/responsible/FormSectionBundle';
import { LoaderComponent } from 'src/app/partials/loader/loader.component';
import { PartialService } from 'src/app/services/partial.service';
import { FormAPI } from 'src/app/services/responsible/forms.service';
import { ConfirmDraftComponent } from '../elements/confirm-draft/confirm-draft.component';
import { LockModalComponent } from '../elements/lock-modal/lock-modal.component';

@Component({
    selector: 'app-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnDestroy {
    private autosaveInterval = 10000;
    public form:SectionBundle = new SectionBundle();

    public currSection: number = 0;
    public currTab: number = 0;
    public adding: boolean = false;
    public locked: boolean = false;
    public loaded: boolean = false;

    public tabs: tab_interface[] = [
        { label: 'Edit Application', onClick: () => { this.setActiveTab(0); }, active: true },
        { label: 'Edit RIAA Assessment', onClick: () => { this.setActiveTab(1); }, active: false },
        { label: 'Edit IVP Assessment', onClick: () => { this.setActiveTab(2); }, active: false },
        { label: 'Edit Support Content', onClick: () => { this.setActiveTab(3); }, active: false },
    ];

    public markets: Market[] = [];
    public pCategories: ProductCategory[] = [];
    public pTargets: ProductTarget[] = [];
    public riStrategies: RIStrategy[] = [];
    public assetClasses: AssetClass[] = [];

    public marketsInitial: string = '';
    public pCategoriesInitial: string = '';
    public pTargetsInitial: string = '';
    public riStrategiesInitial: string = '';
    public assetClassesInitial: string = '';

    public lastAutosave?: Date;
    private autosaver: any;
    private saving:boolean = false;
    private lockTimeout:any;
    
    public static changed:boolean = false;
    private subs:Subscription[] = [];
    
    //to focus on time input
    @ViewChild('ttcinput') ttcinput!: ElementRef;
    @ViewChild('time') time?: ElementRef

    //fosuc ttc
    public ttcFocus() {
        if (this.ttcinput) this.ttcinput.nativeElement.classList.add('focus')
    }
    public ttcBlur() {
        if (this.ttcinput) this.ttcinput.nativeElement.classList.remove('focus')
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private formApi: FormAPI,
        private comm: PartialService,
    ) {
        MenuComponent.show.emit(false);
        LoaderComponent.show.emit(true)
        FormBuilderComponent.changed = false;
        this.handleLock();
        this.formApi.getDraft().subscribe(res => {
            let response = CodedResponseModel.decode(res);
            for(let s of response.form)
                this.form.sections.push(FormSection.create(s));

            this.markets = [];
            for (let m of response.extra.markets)
                this.markets.push(Market.create(m));
            this.marketsInitial = JSON.stringify(this.markets);
            Market.latest = this.markets;

            this.pCategories = [];
            for (let c of response.extra.categories)
                this.pCategories.push(ProductCategory.create(c));
            this.pCategoriesInitial = JSON.stringify(this.pCategories);
            ProductCategory.latest = this.pCategories;

            this.pTargets = [];
            for (let t of response.extra.targets)
                this.pTargets.push(ProductTarget.create(t));
            this.pTargetsInitial = JSON.stringify(this.pTargets);
            ProductTarget.latest = this.pTargets;

            this.riStrategies = [];
            for (let s of response.extra.strategies)
                this.riStrategies.push(RIStrategy.create(s));
            this.riStrategiesInitial = JSON.stringify(this.riStrategies);
            RIStrategy.latest = this.riStrategies;

            this.assetClasses = response.extra.classes.map((c:any) => AssetClass.create(c));
            this.assetClassesInitial = JSON.stringify(this.assetClasses);
            AssetClass.latest = this.assetClasses;

            this.loaded = true;
            LoaderComponent.show.emit(false);

            this.autosaver = setTimeout(() => { this.attemptAutosave() }, this.autosaveInterval);
        }, err => {
            this.comm.notificator.emit({ type:'error', message: err.error.message, timeout: 5000 });
            LoaderComponent.show.emit(false);
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        clearTimeout(this.autosaver);
        clearTimeout(this.lockTimeout);
    }

    private handleLock() {
        this.formApi.tryLock(sessionStorage.getItem('formLock') ?? undefined).subscribe(res => {
            let response = CodedResponseModel.decode(res);
            console.log(res.message);
            switch (response.status) {
                case 0: //? Lock engaged
                    sessionStorage.setItem('formLock', response.key);
                    this.lockTimeout = setTimeout(() => { this.handleLock() }, 55000);
                    break;
                case 1: //? Lock refreshed
                    this.lockTimeout = setTimeout(() => { this.handleLock() }, 55000);
                    break;
                case 2: //? Access denied
                    this.locked = true;
                    let d = this.dialog.open(LockModalComponent, { panelClass: 'modal-white' });
                    d.afterClosed().subscribe(res => {
                        this.router.navigate(['/']);
                    })
                    break;
            }
        })
    }

    private attemptAutosave() {
        if (FormBuilderComponent.changed) {
            FormBuilderComponent.changed = false;
            this.form.deepOrdinalFix();
            this.saving = true;
            this.formApi.saveDraft(this.form).subscribe(res => {
                let response = CodedResponseModel.decode(res);

                this.form.syncIds(response.assigns);
                this.lastAutosave = new Date();
                this.saving = false;
                this.autosaver = setTimeout(() => { this.attemptAutosave() }, this.autosaveInterval);
            }, err => {
                console.error(err);
                this.autosaver = setTimeout(() => { this.attemptAutosave() }, this.autosaveInterval);
            });
        } else this.autosaver = setTimeout(() => { this.attemptAutosave() }, this.autosaveInterval);
    }

    public async publish() {
        await new Promise(p => {
            let staller = () => {
                if(this.saving) setTimeout(() => { staller() }, 100);
                else p(true);
            }
            staller();
        })
        this.form.deepOrdinalFix();
        FormBuilderComponent.changed = false;
        clearTimeout(this.autosaver);
        for (let [i, v] of this.markets.entries())
            v.order = i;
        for (let [i, v] of this.pCategories.entries())
            v.order = i;
        for (let [i, v] of this.pTargets.entries())
            v.order = i;
        for (let [i, v] of this.riStrategies.entries())
            v.order = i;
        for (let [i, v] of this.assetClasses.entries())
            v.order = i;
        let extras = {
            markets: this.marketsInitial == JSON.stringify(this.markets) ? undefined : this.markets,
            categories: this.pCategoriesInitial == JSON.stringify(this.pCategories) ? undefined : this.pCategories,
            targets: this.pTargetsInitial == JSON.stringify(this.pTargets) ? undefined : this.pTargets,
            strategies: this.riStrategiesInitial == JSON.stringify(this.riStrategies) ? undefined : this.riStrategies,
            classes: this.assetClassesInitial == JSON.stringify(this.assetClasses) ? undefined : this.assetClasses,
        }
        this.formApi.publishDraft(this.form, extras).subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.form.syncIds(response.assigns);
            this.comm.notificator.emit({ type:'success', message:'Form changes published', timeout: 5000 });
            this.autosaver = setTimeout(() => { this.attemptAutosave() }, this.autosaveInterval);
        }, err => {
            this.comm.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
            this.autosaver = setTimeout(() => { this.attemptAutosave() }, this.autosaveInterval);
        });
    }

    public insertQuestion(type: string) {
        if (type) {
            let src;
            if (this.currTab == 0) src = this.form.sections[this.currSection].questions;
            else if (this.currTab == 1) src = this.form.sections[this.currSection].riaaQuestions;
            else if (this.currTab == 2) src = this.form.sections[this.currSection].ivpQuestions;
            else return;
            let q = new FormQuestion();
            q.order = src.length;
            q.type = type;
            q.question = "New question";
            q.category = this.currTab == 1 ? 'riaa' : this.currTab == 2 ? 'ivp' : 'application';
            q.sectionId = this.form.sections[this.currSection].id;
            let f = new FormField();
            f.type = type;
            q.fields.push(f);
            src.push(q);
        }
        this.adding = false;
    }
    public questionMoveUp(index: number) {
        let src;
        if (this.currTab == 0) src = this.form.sections[this.currSection].questions;
        else if (this.currTab == 1) src = this.form.sections[this.currSection].riaaQuestions;
        else if (this.currTab == 2) src = this.form.sections[this.currSection].ivpQuestions;
        else return;
        let item = src.splice(index, 1);
        src.splice(index - 1, 0, item[0]);
        if (this.currTab == 0) this.form.sections[this.currSection].fixQuestionOrdinals();
        else if (this.currTab == 1) this.form.sections[this.currSection].fixRIAAOrdinals();
        else if (this.currTab == 2) this.form.sections[this.currSection].fixIVPOrdinals();
        FormBuilderComponent.changed = true;
    }
    public questionMoveDown(index: number) {
        let src;
        if (this.currTab == 0) src = this.form.sections[this.currSection].questions;
        else if (this.currTab == 1) src = this.form.sections[this.currSection].riaaQuestions;
        else if (this.currTab == 2) src = this.form.sections[this.currSection].ivpQuestions;
        else return;
        let item = src.splice(index, 1);
        src.splice(index + 1, 0, item[0]);
        if (this.currTab == 0) this.form.sections[this.currSection].fixQuestionOrdinals();
        else if (this.currTab == 1) this.form.sections[this.currSection].fixRIAAOrdinals();
        else if (this.currTab == 2) this.form.sections[this.currSection].fixIVPOrdinals();
        FormBuilderComponent.changed = true;
    }

    private setActiveTab(i: number) {
        if (i) this.getWidth()
        this.currTab = i;
        this.tabs[i].active = true;
        for (let [j, t] of this.tabs.entries()) if (i != j) t.active = false;
    }

    public scrollToTop(){
        window.scrollTo(0, 0);
    }

    public debug() {
        console.log(this);
        console.log("Changed flag:", FormBuilderComponent.changed);
    }
    public getWidth() {
        if (window.innerWidth > 768) {
            setTimeout(() => {
                if (this.time !== undefined) {
                    this.time.nativeElement.style.transition = '0s'
                    if (this.time.nativeElement.value.length !== 0) {
                        this.time.nativeElement.style.width = '0';
                        this.time.nativeElement.style.width = this.time.nativeElement.scrollWidth + 'px';
                    }
                    else {
                        this.time.nativeElement.style.width = '115px';
                        this.time.nativeElement.style.transition = '0.1s'
                    }
                }
            }, 0)
        }
    }
}
