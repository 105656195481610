import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { file_interface } from 'src/app/core/drag-drop/drag-drop.component';
import { UploadModalComponent } from 'src/app/core/upload-modal/upload-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Attachment } from 'src/app/model/responsible/Attachment';
import { Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { Provider } from 'src/app/model/responsible/Provider';
import { ShareGroup } from 'src/app/model/responsible/ShareGroup';
import { User } from 'src/app/model/User';
import { PartialService } from 'src/app/services/partial.service';
import { ApplicationAPI } from 'src/app/services/responsible/application.service';
import { AttachmentAPI } from 'src/app/services/responsible/attachment.service';

@Component({
    selector: 'app-shared-application',
    templateUrl: './shared-application.component.html',
    styleUrls: ['./shared-application.component.scss']
})
export class SharedApplicationComponent implements OnInit {
    @Input() public share:ShareGroup = new ShareGroup();
    @Input() public errorMsg:string = '';
    @Input() public customs:{pCategories:ProductCategory[],pTargets:ProductTarget[],markets:Market[],strategies:RIStrategy[]} = {
        pCategories:[],
        pTargets:[],
        markets:[],
        strategies:[],
    }

    public currSection:number = -1;
    public bannerFile: file_interface[] = [];

    public providers:Provider[] = [];
    public collaborators:User[] = [];
    public isMenuExpanded: boolean = false
    @Output() public send = new EventEmitter<void>();

    constructor(
        private applicationApi:ApplicationAPI,
        private attachmentApi:AttachmentAPI,
        private partials:PartialService,
        private dialog:MatDialog,
    ){}

    ngOnInit(): void {}

    public nextSection(){
        // do{
        //     this.currSection++;
        // } while(!this.confirmSectionConditions(this.share.application!.form!.sections[this.currSection]));
    }

    public uploadBanner(flist: FileList) {
        if (flist.item(0)) {
            if (!['image/png', 'image/jpeg'].includes(flist.item(0)!.type)) {
                console.error('Banner image must be of PNG or JPEG file type');
                return;
            }
            if (this.bannerFile[0]) this.bannerFile.pop();
            let added = false,
                handle: file_interface;
            this.attachmentApi.upload(flist.item(0)!).subscribe(res => {
                let file = flist.item(0)!;
                handle = {
                    filename: file.name,
                    extension: file.name.split('.').pop() ?? '',
                    status: 'new',
                    progress: 0
                };
                switch (res.type) {
                    case HttpEventType.Sent:
                        this.bannerFile[0] = handle;
                        added = true;
                        break;
                    case HttpEventType.UploadProgress:
                        console.log(Math.round(res.loaded / res.total! * 100));
                        this.bannerFile[0].progress = Math.round(res.loaded / res.total! * 99);
                        break;
                    case HttpEventType.Response:
                        let response = CodedResponseModel.decode(res.body);
                        let att = Attachment.create(response);
                        this.share.application!.banner = att;
                        this.share.application!.bannerId = att.id;
                        this.bannerFile[0].file = att;
                        this.bannerFile[0].progress = 100;
                        break;
                }
            }, err => {
                if (added) {
                    this.share.application!.banner = undefined;
                    this.bannerFile.pop();
                }
                console.warn(err);
            });
        }
    }
    public deleteBanner() {
        this.share.application!.banner = undefined;
        this.bannerFile.pop();
    }
    public openUploader(f: 'banner' | 'preupload') {
        let d = this.dialog.open(UploadModalComponent, {
            panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) {
                if (f === 'banner') this.uploadBanner(res.file);
                //else if (f === 'preupload') this.preuploadFile(res.file);
            }
        });
    }

    public confirmSectionConditions(section:FormSection){
        if(!section.conditions.length) return true;
        for(let g of section.conditions){
            let qRef = new FormQuestion(),//TODO this.share.application!.form!.getQuestionById(g.targetId),
                answer = qRef?.answer;
            if(qRef){
                if(qRef.custom<3){
                    let final = false,
                        radioFinal = undefined;
                    for(let c of g.conditions){
                        let fRef = (()=>{ for(let f of qRef.fields) if(f.id==c.fieldId) return f; return null; })();
                        if(fRef){
                            if(fRef.type==='radio'){
                                if(radioFinal) continue;    //? Radio conditions have OR relation with one another, if one is already true, there's no need to verify any further ones
                                if(radioFinal===undefined) radioFinal = false;
                                if(!answer) radioFinal = c.condition==='false';
                                else if(answer.value.radio==fRef.id) radioFinal = c.condition==='true';
                                final = radioFinal;
                            } else if(fRef.type==='check'){
                                if(!answer) final = c.condition==='false';
                                else if(answer.value[fRef.id]===(c.condition==='true')||(answer.value[fRef.id]==undefined&&c.condition==='false')) final = true;
                                else final = false;
                            } else if(['slider-numeric','slider-percent'].includes(fRef.type)){
                                if(!answer&&fRef.rangeStart>=c.rangeStart&&fRef.rangeStart<=c.rangeEnd) final = true; 
                                else if(answer&&Number(answer.value[fRef.id])>=c.rangeStart&&Number(answer.value[fRef.id])<=c.rangeEnd) final = true;
                                else final = false;
                            }
                        }
                    }
                    if(radioFinal!=undefined&&!radioFinal) final = false;
                    if(final) return section.hidden;
                } else {
                    let final = false;
                    for(let c of g.conditions){
                        if(answer?.value[c.customId!]===(c.condition==='true')||(answer?.value[c.customId!]==undefined&&c.condition==='false')) final = true;
                        else final = false;
                    }
                    if(final) return section.hidden;
                }
            }
        }
        return !section.hidden;
    }

    public isLastSection(i:number){
        // TODO
        // for(let [j, s] of this.share.application!.form!.sections.entries()){
        //     if(j<=i) continue;
        //     if(this.confirmSectionConditions(s)) return false;
        // }
        return true;
    }
}
