import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filters-category',
  templateUrl: './filters-category.component.html',
  styleUrls: ['./filters-category.component.scss']
})
export class FiltersCategoryComponent implements OnInit {
  @Input() public list: Array<any> = [{ name: 'tessst', active: false }, { name: 'testjedensascie', active: true }, { name: 'ss', active: false }, { name: 'test', active: false }, { name: 'test', active: false }, { name: 'test', active: false }, { name: 'test', active: false }];
  @Output() public change = new EventEmitter<boolean>()
  constructor() { }


  ngOnInit(): void {
  }

  public onChange(value: any) {
    this.change.emit(value)
  }
}
