import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {

  @Input() public model: string = '';
  @Input() public isError: boolean = false;
  @Input() public errorText: string = 'This is example error message';
  @Input() public placeholderText: string = 'Input text here';
  @Input() public multiline: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() isTextLimit: boolean = true;
  @Input() limit: string = '100';
  @Input() type: string = 'text';
  @Input() name: string = '';
  public isFocused: boolean = false;
  public isAccepted: boolean = false;
  public isEmpty: boolean = true;
  public min: number | null = 8;
  @Input() isPasswordStrenght: boolean = false;
  @Input() isPasswordComparsion: boolean = false;
  @Output() public modelChange = new EventEmitter<string>();
  @Output() public passwordComparsion = new EventEmitter<string>();
  public passwordStrength: any = 'Low';
  public showPasswordStrength: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isInputEmpty(this.model);
  }

  public onFocus(event: any) {
    if (event) {
        this.isError = false;
      this.isFocused = true;
      this.isAccepted = false;
    }

  }
  public onBlur() {
    if (this.isEmpty) {
      this.isFocused = false;
    } else {
      this.isAccepted = true;
    }
    if(this.isPasswordComparsion) {
      this.passwordComparsion.emit();
    }
  }

  public onKeyup(event: any) {
    let password = event.target.value;
    if(password.length > 0) {
      this.showPasswordStrength = true;
    } else {
      this.showPasswordStrength = false;
    }
    this.passwordStrength = this.getStrength(this.measureStrength(password)).col;
  }

  public isInputEmpty(text: string) {
    if (text.length == 0) {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
     // this.isFocused = true;
    }
  }

  public measureStrength(pass: string) {
    let score = 0;
    //award every unique letter
    let letters: any[] = [];
    let letterDuplications: letterDuplucationScore[] = [];
    for (let i = 0; i < pass.length; i++) {
      let dd: letterDuplucationScore | undefined = letterDuplications.find(item => item.character === pass[i]);
      if (!dd) {
        dd = { character: pass[i], score: 1 };
        letterDuplications.push(dd);
        score += 1;
      } else {
        dd.score = 0;
      }
    }

    // bonus points for mixing it up  
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    let check: keyof typeof variations;
    for (check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 3;
    return Math.trunc((score * 10) / 2);
  }

  public strengthArr = ['Low', 'Medium', 'Strong'];

  public getStrength(score: number) {
    let idx = 0;
    if (score > 80) {
      idx = 2;
    } else if (score > 60) {
      idx = 1;
    }
    return {
      idx: idx,
      col: this.strengthArr[idx]
    };
  }
  
  public placeholderVal() {
    if (this.model || this.model !== undefined) {
      if (this.isFocused) {
        if (this.model == null) return true
        else if (this.model.length > 0) return true
        else return true
      }
      else {
        if (this.model == null) return false
        else if (this.model.length > 0) return true
        else return false
      }
    } else return false
  }
}

export interface letterDuplucationScore {
  character: string;
  score: number;
}