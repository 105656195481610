import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  animations: [trigger('expandCollapse', [
    state('collapsed', style({
      transform: 'scaley(0)',
      overflow: 'hidden',
      paddingTop: 0,
      paddingBottom: 0,
      opacity: 0,
    })),

    state('expanded', style({
      // transform: 'scaley(1)',
      overflow: 'hidden',
      // borderBottom: '1px solid #149E89',
      // // borderRight: '1px solid #149E89',
      // borderBottomLeftRadius: '10px',
      // borderBottomRightRadius: '10px'
    })),


    transition('collapsed => expanded', [
      animate('300ms ease-out')

    ]),

    transition('expanded => collapsed', [
      animate('300ms ease-out')
    ])
  ])],
  host: {
    '(document:click)': 'slideUpList($event)',
  },
})
export class FormSelectComponent implements OnInit {
  @Input() model: any;
  @Input() selectedValues: Array<any> = [];
  @Input() list: Array<any> = []
  @Input() isMulitSelect: boolean = false;
  @Input() placeholder: string = 'test'
  @Input() classes: Array<string> = [];
  @Input() isDisabled: boolean = false;
  @Input() isLabel: boolean = false;
  @Input() labelText: string = 'Label text';
  @Input() isDefaultSelect: boolean = false;
  @Input() isNoneValue: boolean = false;
  @Input() noneLabel:string = 'None';
  @Input() noneValue:any = null;
  @Input() prefix: string = '';
  @Input() placeholderText: string = '';
  @Input() displayProperty: string = 'name';
  @Input() customSearch:boolean = false;
  @Input() loading:boolean = false;
  @Input() compareValue: string = '';
  @Output() modelChange = new EventEmitter();
  @Output() contentInited = new EventEmitter()
  @Output() animationEnd = new EventEmitter()
  @Output() searched = new EventEmitter<string>();

  public searchResult: Array<any> = []

  public searchString:string = '';

  public active: boolean = false;

  public noneSelected: boolean = false;

  public selectPosition: string = 'bottom_el';

  @ViewChild('selectList') selectList?: ElementRef;
  @ViewChild('ul') ul?: ElementRef;
  ngOnChanges(changes: any) {
    if (changes.list !== undefined) this.searchResult = this.list
  }
  constructor(
    private eRef: ElementRef,
  ) { }

  ngOnInit(): void {
    // this.getDefaultSelected()
    this.searchResult = this.list;
    if(this.model === this.noneValue)
        this.noneSelected = true;
  }
  ngAfterContentInit(): void {
    this.contentInited.emit()
    // this.placeholderValidate()
  }
  public change(newValue: any) {
    if (!this.isMulitSelect) {
      this.list.filter(item => item.active = false);
      newValue.active = true;
      this.model = newValue;
      this.modelChange.emit(this.model);
      this.active = false;

    } else {
      if(this.compareValue){
        if(!this.isSelected(newValue)){
          this.selectedValues.push(newValue);
          this.modelChange.emit(this.selectedValues);
        } else {
          this.removeItem(newValue);
        }
      } else {
        if (!newValue.active) {
          newValue.active = true;
          if (!this.selectedValues.find(item => item == newValue)) {
            this.selectedValues.push(newValue);
            this.modelChange.emit(this.selectedValues);
          }
        }
        else {
          newValue.active = false;
          if (this.selectedValues.find(item => item == newValue)) {
            this.removeItem(newValue)
          }
        }
        this.active = true;
      }
    }
    this.noneSelected = false;
    if(this.model === this.noneValue)
      this.noneSelected = true;
  }

  public selectNone() {
    this.list.filter(item => item.active = false);
    this.noneSelected = true;
    this.active = false;
    if (!this.isMulitSelect) {
      this.model = this.noneValue;
      this.modelChange.emit(this.model);
    } else {
      this.selectedValues = [];
      this.model = this.noneValue;
      this.modelChange.emit(this.model);
    }
  }

  public slideToggle() {
    if (!this.isDisabled) {
      this.active = !this.active;
      if (this.active)
        this.setSelectPostion();
    }
  }

  public slideUpList(_event: any) {
    if (!this.eRef.nativeElement.contains(_event.target))
      this.active = false;
    this.searchResult = this.list
  }

  public removeItem(item: any) {
    //item.active = false;
    if(this.compareValue)
      this.selectedValues.splice(this.selectedValues.indexOf(this.isSelected(item), 1));
    else
      this.selectedValues.splice(this.selectedValues.indexOf(item), 1);
    this.modelChange.emit(this.selectedValues);
  }


  private setSelectPostion() {
    if (this.selectList) {
      let selectRect = this.selectList.nativeElement.getBoundingClientRect();
      let selectTop = selectRect.top;
      let windowHeight = window.innerHeight;
      if ((windowHeight - selectTop) < 400) {
        // this.selectPosition = 'top_el';
        this.selectPosition = 'bottom_el';

      } else {
        this.selectPosition = 'bottom_el';
      }
    }
  }
  // private initDefaultSelected = (list: any) => {
  //   this.selectedValues = list.map(list.active = true)
  //   this.modelChange.emit(this.selectedValues);
  // }
  public placeholderValidate = () => {
    if (this.isMulitSelect) {
      if (this.active || this.selectedValues.length > 0 || (this.isNoneValue && this.model === this.noneValue)) {
        return true
      }
      else {
        return false
      }
    } else {
      if (this.active || this.selectedValues.length > 0 || (this.model !== null && this.model !== undefined) || (this.isNoneValue && this.model === this.noneValue)) {
        return true
      }
      else {
        return false
      }
    }
  }

  public search(phrase: any) {
    if(this.customSearch)
      this.searched.emit(phrase.trim());
    else {
      phrase = phrase.toLowerCase().trim()
      this.searchResult = []
      this.find(phrase)
      if (this.searchResult.length == 0 || this.searchResult == undefined) this.searchResult = this.list
    }
  }

  private find(phrase: string) {
    this.list.forEach(elem => {
      if (elem[this.displayProperty].toLowerCase().includes(phrase)) this.searchResult.push(elem)
    })
  }

  private getDefaultSelected() {
    if (this.isMulitSelect == true) {
      let selectedValuesCopy: Array<any> = []
      if (this.selectedValues !== undefined && this.selectedValues.length > 0 && this.list.length !== null) {
        this.selectedValues.forEach(elem => {
          this.list.forEach(item => {
            if (item[this.displayProperty] == elem[this.displayProperty]) {
              this.list[this.list.indexOf(item)].active = true
              selectedValuesCopy.push(this.list[this.list.indexOf(item)])
            }
          })
        })
        this.selectedValues = []
        this.selectedValues = selectedValuesCopy
      }
    } else {
      if (this.model !== undefined) {
        this.list.forEach(item => {
          if (item[this.displayProperty] == this.model[this.displayProperty]) {
            this.model = null
            this.change(this.list[this.list.indexOf(item)])
          }
        })
      }
    }
  }
  //to detect end of animation
  public animFinish(event: any) {
    if (event.fromState !== 'expanded') {
      this.animationEnd.emit()
      if (this.isMulitSelect) {
        if (this.ul) {
          this.ul.nativeElement.style.borderBottom = 'visible';
          this.ul.nativeElement.style.borderBottom = 'none';
          this.ul.nativeElement.style.borderBottomLeftRadius = '0px';
          this.ul.nativeElement.style.borderBottomRightRadius = '0px';
        }
      } else {
        if (this.selectList) {
          this.selectList.nativeElement.style.borderBottom = 'visible';
          this.selectList.nativeElement.style.borderBottom = 'none';
          this.selectList.nativeElement.style.borderBottomLeftRadius = '0px';
          this.selectList.nativeElement.style.borderBottomRightRadius = '0px';
        }
      }
      setTimeout(() => {
        if (this.ul && this.selectList) {
          if (this.isMulitSelect) {
            this.ul.nativeElement.style.borderBottom = 'hidden';
            this.ul.nativeElement.style.borderBottom = '1px solid #149E89';
            this.ul.nativeElement.style.borderBottomLeftRadius = '10px';
            this.ul.nativeElement.style.borderBottomRightRadius = '10px';
          }
          else {
            this.selectList.nativeElement.style.borderBottom = 'hidden'
            this.selectList.nativeElement.style.borderBottom = '1px solid #149E89';
            this.selectList.nativeElement.style.borderBottom = '1px solid #149E89';
            this.selectList.nativeElement.style.borderBottomLeftRadius = '10px';
            this.selectList.nativeElement.style.borderBottomRightRadius = '10px';
          }
        }
      }, 10)

    }
  }

  public isSelected(item:any){
    return this.selectedValues?.find((i:any) => i[this.compareValue]==item[this.compareValue]);
  }

}
