import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, ElementRef, Renderer2, Inject } from '@angular/core';

@Directive({
  selector: '[appConversationsOpen]'
})
export class ConversationsOpenDirective {

  constructor(
    private renderer: Renderer2, 
    @Inject(DOCUMENT) private document: Document
  ) { }

  @HostListener("click") modalOpen() {
    //this.renderer.addClass(document.querySelector('.conversations'), 'active');
    //this.renderer.addClass(document.querySelector('.global-overlay'), 'active');
  }
}
