<app-auth></app-auth>
<app-menu></app-menu>
<!-- <appLoader [showLoader]="showLoader" [showNotyfication]="showNotyfication"
  [notyficationMessage]="notyficationMessage" [notyficationMessageStateModel]="notyficationMessageStateModel">
</appLoader> -->
<h2>Mailing group</h2>
<button (click)="add()">Add</button>
<input [(ngModel)]="filter" (input)="setMailing()">
<div>
    <div *ngFor="let mailing of mailingGroup">
        <div>{{mailing.name}}</div>
        <div>{{mailing.users.length}}</div>
        <div>
            <button (click)="edit(mailing.id)">Edit</button>
            <button>Delete</button>
        </div>
    </div>
</div>