<div class='field-types' [ngClass]="{'expanded':expanded}">
    <app-form-builder-button buttonText="Close" (click)="close()" [additive]="false"></app-form-builder-button>
    <div class='buttons'>
        <button (click)="select('single-line')"><div class='icon'></div><span>Single Text Field</span></button>
        <button (click)="select('multi-line')"><div class='icon'></div><span>Large Text Field</span></button>
        <button (click)="select('numeric')"><div class='icon'></div><span>Numeric</span></button>
    </div>
    <div class='buttons'>
        <button (click)="select('radio')"><div class='icon'></div><span>Radio Button</span></button>
        <button (click)="select('check')"><div class='icon'></div><span>Tickbox</span></button>
        <button (click)="select('slider-tick')"><div class='icon'></div><span>Tickbox with Slider</span></button>
    </div>
    <div class='buttons'>
        <button (click)="select('slider-numeric')"><div class='icon'></div><span>Numerical Slider</span></button>
        <button (click)="select('slider-percent')"><div class='icon'></div><span>Percentage Slider</span></button>
        <button (click)="select('file')"><div class='icon'></div><span>Upload</span></button>
    </div>
    <div class='buttons'>
        <button (click)="select('url')"><div class='icon'></div><span>URL</span></button>
        <button (click)="select('date')"><div class='icon'></div><span>Date Selector</span></button>
        <button (click)="select('order')" [disabled]="!canPickOrder"><div class='icon'></div><span>Item Order</span></button>
    </div>
    <div class="buttons">
        <button (click)="select('radio-group')"><div class="icon"></div><span>Radio Group</span></button>
    </div>
    <button class="showBtn" *ngIf="!expanded" (click)="expanded = true"><span>+ 7 more</span></button>
</div>