import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root',
})
export class NotificationAPI{
    constructor(
        private http:HttpClient,
        private encryptor:EpEncryptionService,
    ){}

    public fetch(type?:boolean){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}notifications${type!==undefined?'?type='+type?1:0:''}`);
    }
    public clear(id:number){
        return this.http.delete<CodedResponseModel>(`${environment.apiUrl}notifications/${id}`);
    }
}
