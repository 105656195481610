import { environment } from "src/environments/environment";
import { Attachment, attachment_response } from "./Attachment";
import { Keyed } from "./Form";

export interface support_file_response{
    id:number;
    section_id:number;
    attachment_id:number;
    order:number;

    attachment?:attachment_response;

    key?:string;
}
export class SupportFile extends Keyed{
    public id:number = 0;
    public sectionId:number = 0;
    public attachmentId:number = 0;
    public order:number = 0;

    public attachment?:Attachment;

    public getURL(){
        return `${environment.attachmentsUrl}${this.attachment?.filename}`;
    }

    public map(data:support_file_response){
        this.id = data.id;
        this.sectionId = data.section_id;
        this.attachmentId = data.attachment_id;
        this.order = data.order;

        if(data.attachment) this.attachment = Attachment.create(data.attachment);

        return this;
    }
    public responsify():support_file_response{
        return {
            id:this.id,
            section_id:this.sectionId,
            attachment_id:this.attachmentId,
            order:this.order,

            key:this.key,
        };
    }
    public static create(data:support_file_response){
        let r = new this;
        r.map(data);
        return r;
    }
}
