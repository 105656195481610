import { Component, OnInit,EventEmitter } from '@angular/core';
import { BodyScrollService } from 'src/app/services/body-scroll.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {
  public display:boolean = false;
  public exists:boolean = false;
  public static clicked = new EventEmitter<void>()
  public static show = new EventEmitter<boolean>();

  constructor(private scroll: BodyScrollService) {   
    OverlayComponent.show.subscribe(res => {
      if(res){
         this.exists = true;
        this.display = true;
        this.scroll.disableBodyScroll()
    } else {
        this.display = false;
        this.exists = false;
        this.scroll.enableBodyScroll()
    }
})}

  ngOnInit(): void {
  }
  public click(){
    this.display = false;
    this.exists = false;
    this.scroll.enableBodyScroll()
    OverlayComponent.clicked.emit()
   }
}
