import { Keyed } from "./Form";

export interface support_video_response{
    id:number;
    section_id:number;
    link:string;
    order:number;

    key?:string;
}
export class SupportVideo extends Keyed{
    public id:number = 0;
    public sectionId:number = 0;
    public link:string = '';
    public order:number = 0;

    public map(data:support_video_response){
        this.id = data.id;
        this.sectionId = data.section_id;
        this.link = data.link;
        this.order = data.order;

        return this;
    }
    public responsify():support_video_response{
        return {
            id: this.id,
            section_id: this.sectionId,
            link: this.link,
            order: this.order,

            key:this.key,
        };
    }
    public static create(data:support_video_response){
        let r = new this;
        r.map(data);
        return r;
    }
}