<div class='container'>
    <div class="btn_container">
        <app-button-secondary [isGreen]="false" [isWhite]="true" class='close' [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"></app-button-secondary>
    </div>
    <div class='content'>
        <h1>Are you sure you want to delete?</h1>
        <p>PLEASE NOTE: THIS ACTION CANNOT BE UNDONE</p>
        <app-text-field [fullWidth]="false" placeholderText="Type delete..." [(model)]="input" (keyup.enter)="close()"></app-text-field>
        <app-button-primary text="Delete" (click)="close()" [isActive]="input.toLowerCase()==='delete'"></app-button-primary>
        <app-button-secondary [isGreen]="false" [isWhite]="true" [isText]="true" buttonText="Cancel" [mat-dialog-close]="false"></app-button-secondary>
    </div>
</div>