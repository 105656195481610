import { Attachment, attachment_response } from "./Attachment";

export interface theme_response{
    id:number;
    name:string;
    link?:string;
    description?:string;
    order:number;
    active:boolean;
    icon_id?:number;
    image_id?:number;

    icon?:attachment_response;
    image?:attachment_response;
}
export class Theme{
    public id:number = 0;
    public name:string = '';
    public link:string = '';
    public description:string = '';
    public order:number = 0;
    public active:boolean = true;
    public iconId?:number;
    public imageId?:number;

    public icon?:Attachment;
    public image?:Attachment;

    public map(data:theme_response){
        this.id = data.id;
        this.name = data.name;
        this.link = data.link??'';
        this.description = data.description??'';
        this.order = data.order;
        this.active = data.active;
        this.iconId = data.icon_id;
        this.imageId = data.image_id;

        if(data.icon) this.icon = Attachment.create(data.icon);
        if(data.image) this.image = Attachment.create(data.image);

        return this;
    }
    public responsify():theme_response{
        return {
            id: this.id,
            name: this.name,
            link: this.link,
            description: this.description,
            order: this.order,
            active: this.active,
            icon_id: this.iconId,
            image_id: this.imageId,

            icon: this.icon,
            image: this.image,
        }
    }
    public static create(data:theme_response){
        let r = new this;
        r.map(data);
        return r;
    }
}