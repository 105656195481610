import { Keyed } from "./Form";

export interface support_faq_response {
    id: number;
    section_id: number;
    question: string;
    answer: string;
    order: number;

    key?:string;
}
export class SupportFAQ extends Keyed{
    public id: number = 0;
    public sectionId: number = 0;
    public question: string = '';
    public answer: string = '';
    public order: number = 0;
    public active: boolean = false

    public map(data: support_faq_response) {
        this.id = data.id;
        this.sectionId = data.section_id;
        this.question = data.question;
        this.answer = data.answer;
        this.order = data.order;

        return this;
    }
    public responsify(): support_faq_response {
        return {
            id: this.id,
            section_id: this.sectionId,
            question: this.question,
            answer: this.answer,
            order: this.order,

            key:this.key,
        };
    }
    public static create(data: support_faq_response) {
        let r = new this;
        r.map(data);
        return r;
    }
}