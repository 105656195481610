import { Component, Input, OnInit } from '@angular/core';
import { accordion } from 'src/app/pages/resources/resources.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() accordion: any[] | undefined;
  @Input() titleProperty:string = 'title';
  @Input() descProperty:string = 'desc';

  constructor() { }

  ngOnInit(): void {
  }

}
