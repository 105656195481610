import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormField } from "../../../model/responsible/FormField";
import { RadioGroupOption } from "../../../model/RadioGroupOption";

@Component({
	selector: 'app-radio-group-editor',
	templateUrl: './radio-group-editor.component.html',
	styleUrls: [ './radio-group-editor.component.scss' ]
})
export class RadioGroupEditorComponent implements OnInit {
	@Input() public fieldData!: FormField;
	@Output() public changesMade = new EventEmitter<void>();

	public options: RadioGroupOption[] = [];

	public ngOnInit() {
		if(this.fieldData.filetypes) {
			// FormField::filetypes is used as an array of options in JSON format.
			const data = JSON.parse(this.fieldData.filetypes);

			this.options = data;
		} else {
			this.options = [ { label: '', value: '0' } ];
		}
	}

	public updateData(){
		this.fieldData.filetypes = JSON.stringify(this.options);
		this.changesMade.emit();
	}

	public removeOption(index: number){
		this.options.splice(index, 1);
		this.updateData();
	}

	public insertOption(){
		this.options.push({ label: '', value: this.options.length.toString() });
	}

	public trackByFn(index: number, item: any){
		return index;
	}
}