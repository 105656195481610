<div class='container'>
    <div class='sidebar' [ngClass]="{'expanded': isMenuExpanded}">
        <div class="header_container">
            <app-button-icon class="closeMenu visible-xs" [imgUrl]="isMenuExpanded?'assets/svg/close_menu_cross.svg':'assets/svg/main-menu.svg'" (click)="isMenuExpanded = !isMenuExpanded"></app-button-icon>
            <img src='/assets/svg/rr-logo-full.svg' />
        </div>

        <div class='buttons'>
            <div class='row a_center' [class.active]="currSection==-1">
                <div class="img-container">
                    <div class="img completed" (click)="currSection=-1"></div>
                </div>
                <button (click)="currSection=-1">Overview</button>
            </div>
            <ng-container *ngFor="let s of share.application?.form?.sections; let i = index">
                <div class='row a_center' [class.active]="currSection==i" *ngIf="confirmSectionConditions(s)">
                    <div class="img-container">
                        <div class="img" (click)="currSection=i"></div>
                    </div>
                    <button (click)="currSection=i">{{s.title}}</button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="tab_container" [ngClass]="{'completing': currSection>=0}">
        <div class='tab'>
            <div class='product'>{{share.application?.productName}}</div>
            <div class='timer'>{{share.remaining}}</div>
        </div>
    </div>

    <div class='main'>
        <div class='form' *ngIf="currSection==-1">
            <h4>Let's get started</h4>
            <h1>Start your application</h1>
            <app-text-field [isAutoSize]="true" placeholderText="Product
                name" [(model)]="share.application!.productName"></app-text-field>
            <app-drag-drop textLabel="Drag and Drop banner image" (openModal)="openUploader( 'banner')" (fileEvent)="uploadBanner($event)" (fileRemoved)="deleteBanner()" [fileList]="bannerFile"></app-drag-drop>
            <app-text-field [isAutoSize]="true" [multiline]="true" placeholderText="Product description" [(model)]="share.application!.description"></app-text-field>
            <app-form-select [list]="providers" placeholderText="Select
                provider" [(model)]="share.application!.provider">
            </app-form-select>
            <app-form-select *ngIf="share.application!.collaborators" [list]="collaborators" displayProperty="fullName" placeholderText="Select collaborators" [isMulitSelect]="true" [(model)]="share.application!.collaborators" [selectedValues]="share.application!.collaborators"></app-form-select>
            <!-- <app-tick-box [(value)]="preuploadFiles" text="Pre upload
                files
                for application"></app-tick-box>
            <app-drag-drop *ngIf="preuploadFiles" (openModal)="openUploader(
                'preupload')" (fileEvent)="preuploadFile($event)" (fileRemoved)="deletePreupload($event)" [fileList]="preuploads"></app-drag-drop> -->
            <!-- <app-tick-box [(value)]="cloneApp" text="Clone another
                application"></app-tick-box>
            <app-form-select class="extra_height" *ngIf="cloneApp" placeholderText="Select product" [list]="applications" displayProperty="productName" [(model)]="appToClone">
            </app-form-select> -->
            <app-button-primary text="Go to next section" (click)="nextSection()"></app-button-primary>
        </div>
        <div class='section' *ngIf="currSection>=0">
            <div class='top'>
                <h4 *ngIf="share.application!.form!.sections[currSection].time">
                    Time to complete: {{share.application!.form!.sections[currSection].time}}
                </h4>
                <h1>{{share.application!.form!.sections[currSection].title}}</h1>
                <p>{{share.application!.form!.sections[currSection].description||''}}</p>
            </div>
            <div class='questions'>
                <app-question class='question' *ngFor="let question of share.application!.form!.sections[currSection].questions; let i= index" [question]="question" 
                    [section]="share.application!.form!.sections[currSection]" [application]="share.application!">
                </app-question>
            </div>
            <div class="btns_container">
                <app-button-primary *ngIf="!isLastSection(currSection)" text="Go to next section" (click)="nextSection()"></app-button-primary>
                <app-button-primary *ngIf="isLastSection(currSection)" text="Save answers" (click)="send.emit()"></app-button-primary>
            </div>
        </div>
    </div>
</div>