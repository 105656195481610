<div class="icon_header_con">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class="body_con">
    <span>Question {{tag}}</span>
    <h1>Conditions</h1>
    <div class="questions_wrapper">
        <div class='question' *ngFor="let question of questions">
            <h4>Question {{question.tag}} is {{question.hidden?'displayed':'hidden'}} if:</h4>
            <ng-container *ngFor="let g of question.conditions">
                <ng-container *ngFor="let c of g.conditions">
                    <p *ngIf="c.fieldId==field.id">- This field is {{field.type=='radio'?'selected':field.type=='check'?'checked':'between '+c.rangeStart+" and "+c.rangeEnd}}</p>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <h3 *ngIf="!questions.length">No conditions related to this field</h3>
</div>