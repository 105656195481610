import { Injectable } from '@angular/core';
import { EpEncryptionService } from "./ep-encryption.service";
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { CodedResponseModel } from "../model/CodedResponseModel";
import { environment } from "../../environments/environment";
import { User } from '../model/User';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private userRole = '';

  constructor(private http: HttpClient, private router: Router, private epEncryptionService: EpEncryptionService) {
    if(this.isLoggedIn()){
      this.setUserRole();
    }
  }

  private setUserRole() {
    //this.userRole = this.getUserRole();
    let self = this;
    setTimeout(() => {
      self.setUserRole();
    }, 1000);
  }

  public login(email: string, password: string, remember:boolean) {
    return this.http.post<CodedResponseModel>(
      environment.apiUrl + "login", this.epEncryptionService.sendPayload({
        email: email,
        password: password,
        remember: remember,
      })

    );
  }
  public register(name: string, email: string, password: string) {
    return this.http.post<CodedResponseModel>(
      environment.apiUrl + "register", this.epEncryptionService.sendPayload({
        name: name,
        email: email,
        password: password,
      })

    );
  }
  public logout() {
    localStorage.removeItem('access_token');
  }

  public refreshToken() {
    this.http.get<CodedResponseModel>(
      environment.apiUrl + "refreshToken"
    ).subscribe(
      response => {
        let codedResponseModel = new CodedResponseModel();
        codedResponseModel.map(response);
        let orginalResponse = codedResponseModel.decodeData();
        localStorage.setItem('access_token', orginalResponse);
      }, err => {
        localStorage.removeItem('access_token');
        this.router.navigate(['/login']);
      });
    return true;
  }

  public isTokenExpired() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token)
      return true;
    return jwtHelper.isTokenExpired(token);
  }

  public isLoggedIn() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token)
      return false;
    let isExpired = jwtHelper.isTokenExpired(token);
    return !isExpired;
  }
  public userHasRole(role: string) {
    return this.userRole == role;
  }
  public getUserRole() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token)
     return '';
    //let allRole = jwtHelper.decodeToken(token).user.role;
    //return allRole[0].name;
    let user = User.create(jwtHelper.decodeToken(token).user);
    return user.userType;
  }

  public forgotPassword(email:string){
    return this.http.post(`${environment.apiUrl}forgotPassword`, this.epEncryptionService.sendPayload({email:email}));
  }
  public resetPassword(token:string, password:string, passConfirm:string){
    return this.http.post(`${environment.apiUrl}resetPassword`, this.epEncryptionService.sendPayload({
      token: token,
      password: password,
      passConfirm: passConfirm,
    }));
  }
}
