import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-paragraph2',
  templateUrl: './paragraph2.component.html',
  styleUrls: ['./paragraph2.component.scss']
})
export class Paragraph2Component implements OnInit {
  @Input() text: string = ''
  @Input() isTextBlack: boolean = true
  constructor() { }

  ngOnInit(): void {
  }

}
