<main role="main">
    <div class="main_wrap">
        <div class="login">
            <div class="left">
                <div class="login_logos">
                    <div class="img_wrap">
                        <img [src]="logo1ImgSrc" alt="Login logo">
                    </div>
                    <div class="divider"></div>
                    <div class="img_wrap second_logo">
                        <img [src]="logo2ImgSrc" alt="Login logo">
                    </div>
                </div>
                <div class="login_register_box">
                    <app-heading4 [text]="titleSmall"></app-heading4>
                    <app-heading1 [text]="titleBig"></app-heading1>

                    <div class="box_wrap">
                        <div class="login_box">
                            <div *ngIf="!sent">
                                <app-input-field [name]="'email'" [type]="'email'" [placeholderText]="'Email'"
                                    [isError]="error" [errorText]="''" [(model)]="email"></app-input-field>
                                <app-button-primary (click)="send()" [isFullWidth]="true" [text]="sendBtnText">
                                </app-button-primary>
                            </div>
                            <div class="desc" *ngIf="sent">
                                <div>Email with the instructions to reset your password has been sent to your account's
                                    email address.</div>
                            </div>
                        </div>

                        <div class="bottom">
                            <a routerLink="/login" class="bottom_link">Go back to log in</a>
                        </div>
                    </div>
                </div>
            </div>
            <app-login-featured-content></app-login-featured-content>
        </div>
    </div>
</main>