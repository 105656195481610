<img [src]="settings.banner" alt="Login image" class="featured_image">
<div class="overlay"></div>
<div class="top">
    <div class="featured_brand" [class.active]="isFeatureBrand" *ngIf="settings.featured">
        <div class="stars">
            <img src="assets/svg/rating_star_login.svg" alt="Rating star"
                *ngFor="let star of counter(settings.featuredStars)">
        </div>
        <app-heading4 [text]="settings.featuredHead" [isTextBlack]="false"></app-heading4>
        <img [src]="settings.featuredLogo" alt="Featured brand logo">
    </div>
    <div class="featured_research" [class.active]="!isFeatureBrand" [class.only]="!settings.featured"
        *ngIf="settings.research">
        <app-heading4 [text]="settings.researchHead" [isTextBlack]="false"></app-heading4>
        <div class="desc">{{settings.researchSubhead}}</div>
        <div class="link_wrap">
            <a [href]="settings.researchLink" target="_blank">
                <span>{{settings.researchLinkTxt}}</span>
                <img src="assets/svg/external_link.svg" alt="Link image">
            </a>
        </div>
    </div>
</div>
<div class="bottom" *ngIf="settings.research || settings.featured">
    <app-heading5 [text]="'Top rated'" [isTextBlack]="false" [class.active]="isFeatureBrand"
        (click)="showFeaturedBrand()" *ngIf="settings.featured"></app-heading5>
    <div class="divider"  *ngIf="settings.research && settings.featured"></div>
    <app-heading5 [text]="'Research'" [isTextBlack]="false" [class.active]="!isFeatureBrand"
        (click)="showFeaturedResearch()" *ngIf="settings.research"></app-heading5>
</div>