<div class="text_wrap center">
    <h1>Edit Login Page</h1>
</div>
<div class="items_wrap">
    <div class="item">
        <app-text-field [(model)]="settings.login_heading" placeholderText="Heading text"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_subheading" placeholderText="Subheading text"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_riaa_logo" placeholderText="RIAA Logo"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_rr_logo" placeholderText="RR Logo"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_error_msg" placeholderText="Error message"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_rfc_link" placeholderText="Register for Certification link"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.forgot_pass_subheading" placeholderText="Forgot password subheading"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.set_pass_subheading" placeholderText="Set password subheading"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_banner" placeholderText="Login banner"></app-text-field>
    </div>
    <div class="item">
        <app-tick-box [(value)]="settings.login_featured" text="Featured brand"></app-tick-box>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_featured_heading" placeholderText="Featured Brand heading"></app-text-field>
    </div>
    <div class="item">
        <app-number-input [(model)]="settings.login_featured_stars" placeholderText="Featured Brand Star rating"></app-number-input>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_featured_logo" placeholderText="Featued Brand logo"></app-text-field>
    </div>
    <div class="item">
        <app-tick-box [(value)]="settings.login_research" text="Featured research"></app-tick-box>
    </div>
    <div class="item">
        <app-text-field [limit]="30" [(model)]="settings.login_research_heading" placeholderText="Research heading"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [limit]="80" [(model)]="settings.login_research_subheading" placeholderText="Research subheading"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.login_research_link" placeholderText="Research Link"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [limit]="30" [(model)]="settings.login_research_link_text" placeholderText="Research Link Text"></app-text-field>
    </div>
</div>
<div class="text_wrap center">
    <h1>Edit Default Images</h1>
</div>
<div class="items_wrap">
    <div class="item">
        <app-text-field [(model)]="settings.default_product_banner" placeholderText="Default Product Banner URL"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.default_provider_banner" placeholderText="Default Provider Banner URL"></app-text-field>
    </div>
    <div class="item">
        <app-text-field [(model)]="settings.default_provider_logo" placeholderText="Default Provider Logo URL"></app-text-field>
    </div>
</div>
<div class="cta_wrap">
    <app-button-primary text="Save" (click)="save()"></app-button-primary>
    <div class="grey_btn" routerLink="/admins">cancel</div>
</div>