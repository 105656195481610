import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FilterMap, IndexQuery } from 'src/app/model/IndexQuery';
import { Provider } from 'src/app/model/responsible/Provider';
import { User, UserTypes } from 'src/app/model/User';
import { UsersAPIService } from 'src/app/services/users.service';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { column } from 'src/app/model/Table';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { ProviderAPI } from 'src/app/services/responsible/provider.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/form-builder/elements/delete-confirm/delete-confirm.component';
import { PartialService } from 'src/app/services/partial.service';
import { MembersComponent } from '../members.component';
import { bulkAction } from 'src/app/core/bulk-actions/bulk-actions.component';

@Component({
    selector: 'app-index-table',
    templateUrl: './index-table.component.html',
    styleUrls: ['./index-table.component.scss'] 
})
export class IndexTableComponent implements OnInit {
    @Input() isMembers: boolean = false
    public members: User[] = [];
    public providers: Provider[] = [];
    public isLoaded: boolean = false;
    public currTab: number = 0
    @Output() itemsCount = new EventEmitter<{members:number,providers:number}>()
    ngOnChanges(changes: any) {
        this.fetchData()
    }
    public total: number = 1
    //private type: string = this.currTab==0?'member':'provider'
    public memberBulkActions: bulkAction[] = [
        { isPlus: false,  type: 'members', btnText: 'Delete all selected', imgUrl: '/assets/svg/minus-icon.svg', function: (u:User[]) => { this.massUserDelete(u); } },
    ]
    public providerBulkActions: bulkAction[] = [
        { isPlus: false, type: 'providers', btnText: 'Delete all selected', imgUrl:'/assets/svg/minus-icon.svg', function:(p:Provider[]) => { this.massProviderDelete(p); } }
    ]
    public query: IndexQuery = {
        filters: {},
        page: 1
    }   
     public filterCooldown:any;
   
    public refresher = new EventEmitter();
    public membersColumns: column[] = (() => {
        let name =      { name:'Name', key:'fullName', type:1, filterKey:'name' },
            providers = { name:'Provider(s)', key:'providerNames', type:1, filterKey:'providers' },
            email =     { name:'Email', type:1, key:'email' },
            newCount =  { name:'New Assigned', type:7, key:'newAssigned' },
            ongCount =  { name:'Ongoing Assigned', type:7, key:'ongoingAssigned' },
            join =      { name:'Join Date', key:'createdAt', type:6, filterKey:'joined' },
            type =      { name:'Type', key:'userType', type:5, filterKey:'type', filters:[{ name:'Member', type:UserTypes.Member }, { name:'Collaborator', type:UserTypes.Collaborator }] },
            actions =   { name:'Actions', type:8, key:'', actions:[
                { label:'Edit', action:(i:User) => { this.router.navigate([`/members/${i.id}`]); } },
                { label:'Delete', action:(i:User) => { this.delUser(i.id); } },
            ] };
        switch(this.jwt.decodeToken().user.type){
            case(UserTypes.Admin):
            case(UserTypes.IVP):
            case(UserTypes.RIAA): return [ name, providers, email, join, type, actions ];
            case(UserTypes.Collaborator):
            case(UserTypes.Member): return [ name, email, newCount, ongCount, type, actions ];
            default: return [];
        }
    })();
    public providersColumns: column[] = (() => {
        let provider = { name: 'Provider', key: 'name', type: 1 },
            member = { name: 'Member', key:'ownerName', type:1 },
            products = { name: 'Products', type: 7, key: 'applicationCount', filterKey:'products' },
            actions = { name: 'Actions', key: '', type: 8, actions:[
                { label:'Edit', action:(i:Provider) => { this.router.navigate([`/providers/${i.id}`]); } },
                { label:'Delete', action:(i:Provider) => { this.delProvider(i.id); } }
            ] };
        switch(this.jwt.decodeToken().user.type){
            case(UserTypes.Admin):
            case(UserTypes.IVP):
            case(UserTypes.RIAA): return [ provider, member, products, actions ];
            case(UserTypes.Collaborator):
            case(UserTypes.Member): return [ provider, products, actions ];
            default: return [];
        }
    })();
    constructor(
        private userApi: UsersAPIService,
        private providerApi: ProviderAPI,
        private jwt:JwtHelperService,
        private partials:PartialService,
        private dialog:MatDialog,
        private router:Router,
    ) { }

    ngOnInit():void{}

    private fetchData() {
        if (this.isMembers) {
            this.query.filters!.group = 'mnc';
            this.userApi.index(this.query).subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.members = [];
                for (let user of response.data) {
                    this.members.push(User.create(user))
                }
                this.isLoaded = true;
                this.refresher.emit();
                this.itemsCount.emit({ members:response.extra.members, providers:response.extra.providers });
                 this.total = response.filtered
                 this.filterCooldown = undefined
            })
        } else {
            this.providerApi.index(this.query).subscribe(res => {
                let response = CodedResponseModel.decode(res);
                this.providers = [];
                for(let p of response.data)
                    this.providers.push(Provider.create(p));
                this.isLoaded = true;
                this.refresher.emit();
                this.itemsCount.emit({ members:response.extra.members, providers:response.extra.providers });
                this.total = response.filtered
                this.filterCooldown = undefined
             })
        }
        
    }
    public getPage(page:number) {
        if(this.filterCooldown) clearTimeout(this.filterCooldown); 
        this.filterCooldown = setTimeout(() => { 
            this.query.page = page;
            this.fetchData();
         }, 200);
    
    }
    public getFilters(filters:FilterMap) {
        if(this.filterCooldown) clearTimeout(this.filterCooldown); 
        this.filterCooldown = setTimeout(() => { 
            this.query.filters = filters;
            this.query.page = 1       
            this.fetchData();
         }, 800);
        
    }
    public delUser(id:number){
        let d = this.dialog.open(DeleteConfirmComponent, { panelClass:'modal-white' });
        d.afterClosed().subscribe(r => {
            if(r){
                this.userApi.delete(id).subscribe(res => {
                    this.partials.notificator.emit({ type:'success', message:"User has been removed", timeout:5000 });
                    this.fetchData();
                }, err => {
                    this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
                });
            }
        })
    }
    public delProvider(id:number){
        let d = this.dialog.open(DeleteConfirmComponent, { panelClass:'modal-white' });
        d.afterClosed().subscribe(r => {
            if(r){
                this.providerApi.delete(id).subscribe(res => {
                    this.partials.notificator.emit({ type:'success', message:"Provider has been removed", timeout:5000 });
                    this.fetchData();
                }, err => {
                    this.partials.notificator.emit({ type:'error', message:err.error.message, timeout:5000 });
                });
            }
        })
    }
    public massUserDelete(users:User[]){
        let ids = [];
        for(let u of users) ids.push(u.id);
        this.userApi.massDelete(ids).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Users have been deleted', timeout: 5000 });
            this.fetchData();
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
    public massProviderDelete(providers:Provider[]){
        let ids = [];
        for(let p of providers) ids.push(p.id);
        this.providerApi.massDelete(ids).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Providers have been deleted', timeout: 5000 });
            this.fetchData();
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
}
