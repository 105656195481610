import { NgModule } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileEditComponent } from './pages/profile-edit/profile-edit.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UserIndexComponent } from './users/index.component';
import { UserCreateComponent } from './users/new.component';
import { UserShowComponent } from './users/show.component';
import { MailEventsComponent } from './mail-events/mail-events.component';
import { MailEventsEditComponent } from './mail-events/mail-events-edit/mail-events-edit.component';
import { MailingGroupComponent } from './mailing-group/mailing-group.component';
import { MailingGroupEditComponent } from './mailing-group/mailing-group-edit/mailing-group-edit.component';
import { FormBuilderComponent } from './form-builder/form-builder/form-builder.component';
import { TestComponent } from './core/test/test.component';
import { ApplicationEditorComponent } from './application/editor/editor.component';
import { ApplicationComponent } from './pages/application/application.component';
import { MembersComponent } from './pages/members/members.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { EditSiteComponent } from './pages/edit-site/edit-site.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { AssessApplicationComponent } from './application/assess/assess-application/assess-application.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedQuestionsComponent } from './application/shared/shared-questions/shared-questions.component';
 

const routes: Routes = [
  //HOME
  { path: '', component: DashboardComponent, data: {} },
  //* Pages
  { path: 'application', component: ApplicationComponent },
  { path: 'analytics', component: AnalyticsComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'edit_site', component: EditSiteComponent },
  //LOGIN
  { path: 'login', component: LoginComponent, data: {} },

  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  //{ path: 'resetPassword', component: ResetPasswordComponent },

  { path: 'profile', component: ProfileEditComponent },

  { path: 'users', component: UserIndexComponent },
  { path: 'user/new', component: UserCreateComponent },
  { path: 'user/:id', component: UserShowComponent },
  { path: 'user/:id/edit', component: UserShowComponent, data: { editMode: true } },

  { path: 'mail-events', component: MailEventsComponent },
  { path: 'mail-events/add', component: MailEventsEditComponent, data: { new: true } },
  { path: 'mail-events/:id', component: MailEventsEditComponent },

  { path: 'mailing-group', component: MailingGroupComponent },
  { path: 'mailing-group/add', component: MailingGroupEditComponent, data: { new: true } },
  { path: 'mailing-group/:id', component: MailingGroupEditComponent },

  { path: 'test', component: TestComponent },

  //* Members
  { path: 'members', component: MembersComponent, data:{ directive:'members' } },
  { path: 'providers', component: MembersComponent, data:{ directive:'providers' } },
  { path: 'members/new/member', component: MembersComponent, data:{ directive:'new-member' } },
  { path: 'members/new/collaborator', component: MembersComponent, data:{ directive:'new-collaborator' } },
  { path: 'providers/new', component: MembersComponent, data:{ directive:'new-provider' } },
  { path: 'members/:id', component: MembersComponent, data:{ directive:'edit-member' } },
  { path: 'providers/:id', component: MembersComponent, data:{ directive:'edit-provider' } },

  //* Admins
  { path: 'admins', component: AdminsComponent, data:{ directive:'admins' } },
  { path: 'admins/new', component: AdminsComponent, data:{ directive:'add-admin' } },
  { path: 'admins/:id', component: AdminsComponent, data:{ directive:'edit-admin' } },
  { path: 'exclusions', component: AdminsComponent, data:{ directive:'edit-exclusions' } },
  { path: 'themes', component: AdminsComponent, data:{ directive:'edit-themes' } },
  { path: 'categories', component: AdminsComponent, data:{ directive:'edit-categories' } },
  { path: 'filters', component: AdminsComponent, data:{ directive:'edit-filters' } },
  { path: 'edit-login', component: AdminsComponent, data: { directive:'edit-login' } },

  //* Form Builder
  { path: 'forms/:id', component: FormBuilderComponent },

  { path: 'applications/:id', component: ApplicationEditorComponent },
  { path: 'applications/:id/assess', component: AssessApplicationComponent },
  { path: 'applications/from/:from', component: ApplicationEditorComponent },

  //* Shares
  { path:'shared/:key', component:SharedQuestionsComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {  scrollPositionRestoration:'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { 
 
}
