import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { buildQueryParams, IndexQuery } from "src/app/model/IndexQuery";
import { Provider } from "src/app/model/responsible/Provider";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

interface ProviderSearchQuery {
    query:string,
    limit?:number
}

@Injectable({
    providedIn:'root'
})
export class ProviderAPI{
    constructor(
        private http:HttpClient,
        private epEncryptor:EpEncryptionService,
    ){}

    public index(query?:IndexQuery){
        let params = buildQueryParams(query);
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}providers${params}`);
    }
    public search(query:ProviderSearchQuery){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}providers/search?${new URLSearchParams(query as any).toString()}`);
    }
    public get(id:number){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}providers/${id}`);
    }
    public create(provider:Provider){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}providers`,
            this.epEncryptor.sendPayload(provider.responsify()));
    }
    public update(provider:Provider){
        return this.http.put<CodedResponseModel>(`${environment.apiUrl}providers/${provider.id}`,
            this.epEncryptor.sendPayload(provider.responsify()));
    }
    public delete(id:number){
        return this.http.delete<CodedResponseModel>(`${environment.apiUrl}providers/${id}`);
    }
    public massDelete(ids:number[]){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}providers/bulkDelete`,
            this.epEncryptor.sendPayload({ids:ids}));
    }
    public standardDelete(id:number, options:any){
        return this.http.post<CodedResponseModel>(`${environment.apiUrl}providers/delete`,
            this.epEncryptor.sendPayload({ target: id, options: options }));
    }
}
