import { Component, OnInit } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { ProductCategory } from 'src/app/model/responsible/CustomIntegration';
import { PartialService } from 'src/app/services/partial.service';
import { CustomIntegrationAPI } from 'src/app/services/responsible/custom-integrations.service';
import { LoaderComponent } from '../../../partials/loader/loader.component';
@Component({
    selector: 'app-edit-categories',
    templateUrl: './edit-categories.component.html',
    styleUrls: ['../admins.component.scss', './edit-categories.component.scss']
})
export class EditCategoriesComponent implements OnInit {
    public categories:ProductCategory[] = [];

    constructor(
        private integrationApi:CustomIntegrationAPI,
        private partials:PartialService,
    ){
        this.integrationApi.productCategories.index().subscribe(res => {
            let response = CodedResponseModel.decode(res);

            this.categories = [];
            for(let c of response)
                this.categories.push(ProductCategory.create(c));
                LoaderComponent.show.emit(false);
        })
    }

    ngOnInit():void{}

    public send(){
        this.integrationApi.productCategories.update(this.categories).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Categories have been updated', timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error',  message:err.error.message, timeout: 5000 });
        })
    }

    public addNew() {
        this.categories.push(new ProductCategory());
    }

    public del(e:ProductCategory) {
        this.categories.splice(this.categories.indexOf(e), 1);
    }

}
