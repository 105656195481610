<div class="bcg" *ngIf="isOpen && isProductCard" (click)="isOpen= false; scroll.enableBodyScroll()"></div>
<div class="content" [ngClass]="{'comments': isComment, 'ratingGreen': isRating
    && ratingValue> 0, 'main_assigned': isProductCard}">
    <div *ngIf="!isProductCard" class="row a_center rating_row">
        <app-filters (open)="open($event)" (clear)="clear($event)" [values]="oneLine" [isActive]="validateActive()" [placeholderText]="filterName"></app-filters>
        <svg-icon *ngIf="!isOpen && isRating && ratingValue>0" style="cursor:
            pointer;" src="assets/svg/close.svg" (click)="ratingValue=0; ratingPerv=0; this.clearField.emit()"></svg-icon>
    </div>
    <div class="assigned" *ngIf="isProductCard" (click)="disabled?false:isOpen= true" [class.clickable]="!disabled">
        <span class="hidden-xs">Assigned</span>
        <ng-container *ngFor="let item of selectedValuesObject |
            slice:0:2; let imgCount= index;">
            <app-avatar class="avatar" [model]="{name: item.fullName, imgUrl:item.avatar?item.avatarURL:''}"></app-avatar>
        </ng-container>
        <ng-container *ngIf="selectedValuesObject.length> 2"><span class="count_up">+{{selectedValuesObject.length - 2}}</span></ng-container>
    </div>
    <div #itemList class="column" (change)="setSelectPostion()" [ngClass]="{'opened': isOpen, 'right': right, 'left': left, 'rating': isRating,
        'color_list': isColorList}" [style]="positionStyle">
        <div class="list_container" *ngIf="isOpen" [@filtersOutIn] (@filtersOutIn.done)="animFinish($event)">
            <app-filters-star *ngIf="isRating" [rating]="ratingValue" (ratingChange)="getRating($event)" (click)="isOpen= false"></app-filters-star>
            <ng-container *ngIf="!isRating">
                <div *ngIf="list.length > 5 && !isColorList " class="check_all row a_center pos_rel" [ngClass]="{'active': isAllSelected}">
                    <mat-checkbox (change)="selectAll()" [checked]="isAllSelected">
                        <span class="row-label">Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row a_center pos_rel" *ngFor="let item of list" [ngClass]="{
                    'active': getValue(item), 'photo': isAvatar,'grey': isColorList && ['Admin','Not Required','In Progress','Recertification Upcoming','Disclosures Upcoming'].includes(item.name),
                    'purple': isColorList &&  ['IVP Admin','IVP Assessment'].includes(item.name),
                    'd_blue': isColorList &&  ['RIAA Admin','RIAA Assessment','More Info'].includes(item.name),
                    'orange': isColorList && ['Collaborator','Outstanding','Declined','Decertified','Disclosures Now Due','Disclosures Outstanding','Expired'].includes(item.name),
                    'l_blue':isColorList &&  ['Member','Invoiced','New Application','Recertification Updated','Updated','Material Change','Disclosures Updated'].includes(item.name),
                    'green': isColorList &&  ['Paid','Approved'].includes(item.name)}">
                    <mat-checkbox (change)="onChange(item)" [checked]="getValue(item)">
                        <app-avatar class="avatar" *ngIf="isAvatar" [model]="{name: item.name, imgUrl:item.avatar?item.avatarURL:''}"></app-avatar>
                        <span class="row-label">{{item.name}}</span>
                    </mat-checkbox>
                    <span *ngIf="isComment && getValue(item)" style="pointer-events: none;" class="row-clear">clear</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>