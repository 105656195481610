import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmComponent } from 'src/app/form-builder/elements/delete-confirm/delete-confirm.component';
import { QuizQuestion } from 'src/app/model/responsible/QuizQuestion';
import { Resources } from 'src/app/model/responsible/Resources';

@Component({
    selector: 'app-resources-question',
    templateUrl: './resources-question.component.html',
    styleUrls: ['./resources-question.component.scss']
})
export class ResourcesQuestionComponent implements OnInit {
    public selected: any
    @Input() question: QuizQuestion = new QuizQuestion();
    @Input() public cantMoveUp: boolean = false;
    @Input() public cantMoveDown: boolean = false;
    @Input() public editable:boolean = true;
    @Output() public moveUp = new EventEmitter<void>();
    @Output() public moveDown = new EventEmitter<void>();
    @Output() public deleted = new EventEmitter<void>();
    public collapsed: boolean = false;
    
    constructor(
        private dialog:MatDialog,
    ){}

    ngOnInit(): void { }

    public deleteQuestion() {
        let d = this.dialog.open(DeleteConfirmComponent, { panelClass: 'modal-green' });
        d.afterClosed().subscribe(res => {
            if (res) {
                this.deleted.emit();
            }
        })
    }
}
