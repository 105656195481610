<main role="main">
    <div class="main_wrap">
        <div class="login">
            <div class="left">
                <div class="login_logos">
                    <div class="img_wrap">
                        <img [src]="logo1ImgSrc" alt="Login logo">
                    </div>
                    <div class="divider"></div>
                    <div class="img_wrap second_logo">
                        <img [src]="logo2ImgSrc" alt="Login logo">
                    </div>
                </div>
                <div class="login_register_box">
                    <app-heading4 [text]="titleSmall"></app-heading4>
                    <app-heading1 [text]="titleBig"></app-heading1>

                    <div class="box_wrap">
                        <div class="login_box">
                            <div *ngIf="!sent">
                                <div class="password_wrap">
                                    <app-input-field [name]="'password'" [type]="'password'" [isError]="isError"
                                        [errorText]="''" [placeholderText]="'Password'" [(model)]="password"
                                        [isPasswordComparsion]="true" (passwordComparsion)="passwordComparsion()"
                                        [isPasswordStrenght]="true">
                                    </app-input-field>
                                </div>
                                <div class="password_rule">Include 8 characters and one special character</div>
                                <div class="password_confirm_wrap">
                                    <app-input-field [name]="'confirm_password'" [type]="'password'" [isError]="isError"
                                        [errorText]="''" [placeholderText]="'Confirm password'"
                                        [isPasswordComparsion]="true" (passwordComparsion)="passwordComparsion()"
                                        [(model)]="passConfirm">
                                    </app-input-field>
                                    <img src="assets/svg/confirm_pass_tick.svg" alt="Tick" [class.show]="showTick">
                                </div>
                                <div class="error_wrap" *ngIf="error">
                                    <img src="assets/svg/error.svg" alt="Error icon">
                                    <div class="error">{{ errorMsg }}</div>
                                </div>
                                <app-button-primary (click)="send()" [isFullWidth]="true" [text]="setBtnText">
                                </app-button-primary>
                            </div>
                            <div class="desc" *ngIf="sent">
                                <div>Password has been reset!</div>
                            </div>
                        </div>

                        <div class="bottom" *ngIf="sent">
                            <a routerLink="/login" class="bottom_link">Return to login page</a>
                        </div>
                    </div>
                </div>
            </div>
            <app-login-featured-content [settings]="bannerSettings"></app-login-featured-content>
        </div>
    </div>
</main>