import { Component, EventEmitter, Input, OnInit,AfterViewChecked, ViewChild,ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
 import { ShareApplicationComponent } from '../application/elements/share-application/share-application.component';
import { bulkAction } from '../core/bulk-actions/bulk-actions.component';
import { tab_interface } from '../core/top-tabs/top-tabs.component';
import { DeleteConfirmComponent } from '../form-builder/elements/delete-confirm/delete-confirm.component';
import { ConversationsComponent } from '../menu/conversations/conversations.component';
import { MenuComponent } from '../menu/menu.component';
import { CodedResponseModel } from '../model/CodedResponseModel';
import { IndexQuery } from '../model/IndexQuery';
import { Application } from '../model/responsible/Application';
import { Assessment } from '../model/responsible/Assessment';
import { ProductCategory, ProductTarget } from '../model/responsible/CustomIntegration';
import { column } from '../model/Table';
import { User, UserTypes } from '../model/User';
import { LoaderComponent } from '../partials/loader/loader.component';
import { ProductCardComponent } from '../product-card/product-card.component';
import { PartialService } from '../services/partial.service';
import { ApplicationAPI } from '../services/responsible/application.service';
import { AssessmentAPI } from '../services/responsible/assessment.service';
import { ApproveModalComponent } from './approve-modal/approve-modal.component';
import { DenyModalComponent } from './deny-modal/deny-modal.component';
import { EditCertDateModalComponent } from "./edit-cert-date-modal/edit-cert-date-modal.component";
 
@Component({ 
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    public newApplications:Application[] = [];
    public ongoingApplications:Application[] = [];
    public refresher = new EventEmitter();
    public total: number = 1
    public currTab:number = 0; 
    public currPage: number = 1
    public isLoaded: boolean = false;
    public query:IndexQuery = {
        filters:{
            type:'new',
            productName: ''
        },
        page: 1
     }
    public filterCooldown:any;
    public userType:UserTypes;
    public isAdmin:boolean;
    
    public categories:ProductCategory[] = [];
    public targets:ProductTarget[] = [];
    public admins:User[] = [];
    public expanded: boolean = false
     public tabs:tab_interface[] = [
        { label: 'New products', onClick:() => { this.goToTab(0); }, active:true, badge:0 },
        { label: 'Ongoing products', onClick:() => { this.goToTab(1); }, active:false, badge:0 },
    ];
    @ViewChild('main') main: any
    @ViewChild('table') table: any
    public columns:column[] = [
        { name:'Product Name', key:'productName', type:1 },
        { name:'Comments', type:2, key:'hasUnreads', onClick:(i:Application) => { this.partials.conversations.emit({ applicationId:i.id, formId:i.formId, name:i.productName }); ConversationsComponent.refresh.emit(); MenuComponent.chatViewOpen.emit(true); } },
        { name:'Assigned', type:3, key:'assigned', filters: this.admins },
        { name:'Provider', key:'providerName', type:1, filterKey:'provider' },
        { name:'Product target', key:'targets', type:9, filters:this.targets },
        { name:'Category', key:'categories', type:9, filters:this.categories },
        { name:'Submitted', key:'createdAt', type:6, filterKey:'submitted' },
        { name:'Last Updated', key:'updatedAt', type:6, filterKey:'updated' },
        { name:'Status', key:'status', type:5, filters:[ { name:'In Progress' }, { name: 'New Application' }, { name:'RIAA Assessment' }, { name:'IVP Assessment' },  { name:'More Info' }, { name:'Updated' } ] },
        { name:'Invoice', key:'invoice', type:5, filters:[ { name:'Not Required' }, { name:'Invoiced' } , { name:'Expired' } ] },
        { name:'Actions', key:'', type:8, actions:(() => {
            if(!this.jwt.decodeToken()) {
                this.router.navigate(['/login']);
                return;   
            }
            let assess = { label:'Assess Application', action:(i:Application) => { this.router.navigate([`/applications/${i.id}/assess`]); } },
                approve = { label:'Approve for IVP', action:(i:Application) => { let d = this.dialog.open(ApproveModalComponent, { panelClass:'modal-white', data:{ application:i } }); d.afterClosed().subscribe(r => { if(r) this.sendAssessment(i.id, r, true); }); } },
                clone = { label:'Clone Application', action:(i:Application) => { this.router.navigate([`/applications/from/${i.id}`]); } },
                deny = { label:'Deny Application', action:(i:Application) => { let d = this.dialog.open(DenyModalComponent, { panelClass:'modal-white', data:{ application:i } }); d.afterClosed().subscribe(r => { if(r) this.sendAssessment(i.id, r, false); }); } },
                del = { label:'Delete', action:(i:Application) => { let d = this.dialog.open(DeleteConfirmComponent, { panelClass:'modal-green' } ); d.afterClosed().subscribe(r => { if(r) this.deleteApplication(i.id); }); } },
                editApp = { label:'View Application', action:(i:Application) => { this.router.navigate([`/applications/${i.id}`]); } },
                share = { label:'Share Application', action:(i:Application) => { let d = this.dialog.open(ShareApplicationComponent, { panelClass:'modal-white', data:{ productName:i.productName } }); d.afterClosed().subscribe(r => { if(r) this.shareApp(i, r); }); } };
            switch(this.jwt.decodeToken().user.type){
                case(UserTypes.Admin):
                case(UserTypes.RIAA): return [ editApp, assess, approve, clone, deny, del ];
                case(UserTypes.Member):
                case(UserTypes.Collaborator): return [ editApp, clone, share, del ];
                case(UserTypes.IVP): return [ assess ];
                default: return [];
            }
        })()}
    ];
    public ongoingColumns:column[] = [
        { name:'Product Name', key:'productName', type:1 },
        { name:'Comments', type:2, key:'hasUnreads', onClick:(i:Application) => { this.partials.conversations.emit({ applicationId:i.id, formId:i.formId, name:i.productName }); ConversationsComponent.refresh.emit(); MenuComponent.chatViewOpen.emit(true); } },
        { name:'Assigned', type:3, key:'assigned', filters: this.admins },
        { name:'Provider', key:'providerName', type:1, filterKey:'provider' },
        { name:'Product target', key:'targets', type:9, filters: this.targets },
        { name:'Category', key:'categories', type:9, filters: this.categories },
        { name:'Cert start', key:'certStart', type:6 },
        { name:'Cert expiry', key:'certExpiry', type:6 },
        { name:'Last Updated', key:'updatedAt', type:6, filterKey:'updated' },
        { name:'Update Status', key:'updateStatus', type:5, filters:[ { name:'Updated' }, { name: 'Recertification Upcoming' }, { name:'Recertification Updated' }, { name:'Material Change' } ] },
        { name:'Cert Status', key:'pubStatus', type:5, filters:[ { name:'Approved' }, { name: 'Conditonal' }, { name: 'Declined' }, { name:'Decertified' }, { name:'Unpublished' } ] },
        { name:'Invoice', key:'invoice', type:5, filters:[ { name:'Not Required' }, { name:'Invoiced' } , { name:'Expired' } ] },
        { name:'Actions', key:'', type:8, actions:(() => {
            if(!this.jwt.decodeToken()) {
                this.router.navigate(['/login']);
                return;   
            }
            let assess = { label:'Assess Updates', action:(i:Application) => { this.router.navigate([`/applications/${i.id}/assess`]); } },
                approve = { label:'Approve for IVP', action:(i:Application) => { let d = this.dialog.open(ApproveModalComponent, { panelClass:'modal-white', data:{ application:i } }); d.afterClosed().subscribe(r => { if(r) this.sendAssessment(i.id, r, true); }); } },
                clone = { label:'Clone Application', action:(i:Application) => { this.router.navigate([`/applications/from/${i.id}`]); } },
                deny = { label:'Deny Updates', action:(i:Application) => { let d = this.dialog.open(DenyModalComponent, { panelClass:'modal-white', data:{ application:i } }); d.afterClosed().subscribe(r => { if(r) this.sendAssessment(i.id, r, false); }); } },
                del = { label:'Delete', action:(i:Application) => { let d = this.dialog.open(DeleteConfirmComponent, { panelClass:'modal-green' } ); d.afterClosed().subscribe(r => { if(r) this.deleteApplication(i.id); }); } },
                editApp = { label:'Edit Certification', action:(i:Application) => { this.router.navigate([`/applications/${i.id}`]); } },
                share = { label:'Share Certification', action:(i:Application) => { let d = this.dialog.open(ShareApplicationComponent, { panelClass:'modal-white', data:{ productName:i.productName } }); d.afterClosed().subscribe(r => { if(r) this.shareApp(i, r); }); } },
                viewCert = { label:'View Certification', action:(i:Application) => { this.router.navigate([`/applications/${i.id}`]) } },
                publish = { label:(i:Application) => { if(i.published) return 'Unpublish'; else return 'Publish'; }, action:(i:Application) => { this.togglePublish(i); } },
                editCertDate = { label: 'Edit Cert Start Date', action:(i:Application) => { let d = this.dialog.open(EditCertDateModalComponent, { panelClass:'modal-white', data:{ application:i } }); d.afterClosed().subscribe(r => { if(r) this.editCertStart(i.id, r.date); }); } };
            switch(this.jwt.decodeToken().user.type){
                case(UserTypes.Admin):
                case(UserTypes.RIAA): return [ viewCert, assess, editCertDate, approve, clone, deny, publish, del ];
                case(UserTypes.Member):
                case(UserTypes.Collaborator): return [ viewCert, editApp, clone, share, del ];
                case(UserTypes.IVP): return [ assess ];
                default: return [];
            }
        })()}
    ];
    public adminBulkActions: bulkAction[] = [
        { isPlus: true, btnText:'approve ALL Selected', type: 'products', imgUrl: '/assets/svg/plus-icon.svg', function: (a:Application[]) => { let d = this.dialog.open(ApproveModalComponent, { panelClass:'modal-white', data:{ application:a[0] } }); d.afterClosed().subscribe(r => { if(r) this.massAssess(a, r, true); }); } }, 
        { isPlus: false, btnText:'Deny all selected', imgUrl: '/assets/svg/minus-icon.svg', function: (a:Application[]) => { let d = this.dialog.open(DenyModalComponent, { panelClass:'modal-white', data:{ application:a[0] } }); d.afterClosed().subscribe(r => { if(r) this.massAssess(a, r, false); }); } }
    ]
    public userBulkActions: bulkAction[] = [
        { isPlus: false, btnText:'Delete all selected', type: 'products', imgUrl:'/assets/svg/minus-icon.svg', function: (a:Application[]) => { this.massDelete(a); } }
    ];
 
    constructor(
        private applicationApi:ApplicationAPI,
        private assessmentApi:AssessmentAPI,
        private route: ActivatedRoute,
        private router:Router,
        private partials:PartialService,
        private jwt:JwtHelperService,
        private dialog:MatDialog,
    ){
        this.processUrlQuery();
        this.fetchData();
        this.userType = this.jwt.decodeToken().user.type;
        this.isAdmin = [UserTypes.Admin, UserTypes.RIAA, UserTypes.IVP].includes(this.userType);
    }

    private fetchData(){         
        LoaderComponent.menuHidden.emit(false)
        LoaderComponent.menuExpanded.emit(this.expanded);
        this.applicationApi.index(this.query).subscribe(res => {
            let response = CodedResponseModel.decode(res);
            this.tabs[0].badge = response.extra.new;
            this.tabs[1].badge = response.extra.ongoing;
            let target = this.currTab?this.ongoingApplications:this.newApplications;
            target.splice(0, target.length);
            for(let a of response.data){
                target.push(Application.create(a));}

            this.categories.splice(0, this.categories.length);
            for(let c of response.extra.categories)
                this.categories.push(ProductCategory.create(c));

            this.targets.splice(0, this.targets.length);
            for(let t of response.extra.targets)
                this.targets.push(ProductTarget.create(t));

            this.admins.splice(0, this.admins.length);
            for(let a of response.extra.riaas)
                this.admins.push(User.create(a));
        
            this.total = response.filtered;
            // if(this.currTab == 0 && this.tabs[0].badge ) this.total =  this.tabs[0].badge 
            // else if (this.currTab == 1 && this.tabs[1].badge)   this.total =  this.tabs[1].badge 
            this.isLoaded = true
            this.refresher.emit();
            LoaderComponent.show.emit(false);
            this.filterCooldown = undefined
            this.showAll()
        });
    }

    private processUrlQuery(): void {
        const params = this.route.snapshot.queryParams;

        for(let p in params){
            if(p === 'type' && params[p] === 'ongoing'){
                this.currTab = 1;
                this.tabs[0].active = false;
                this.tabs[1].active = true;
                this.query.filters!.type = 'ongoing';
            } else if(['page', 'perPage'].includes(p)){
                if(isNaN(Number(params[p]))) continue;
                this.query[(p as 'page'|'perPage')] = params[p];
                if(p === 'page') this.currPage = Number(params[p]);
            } else {
                this.query.filters![p] = params[p];
            }
        }

    }

    showAll(){
        
    //     for(let elem of this.ongoingApplications){
    //         let item = elem.certStart?.toString().replace(/[ :]/g, "-").split("-")
    //        if(item !== undefined) console.log( item.toLocaleString() )
             
    //          console.log(item)
        
    // }
    }

    ngOnInit():void{
     }
     ngAfterViewInit(): void{
        LoaderComponent.show.emit(true)
     }

    public goToTab(i:number){
        if(i !== this.currTab){
            LoaderComponent.show.emit(true)
            this.currTab = i;
            for(let t of this.tabs) t.active = false;
            this.tabs[i].active = true;
            this.query.filters!.type = i?'ongoing':'new';
            this.query.page = 1;
            this.currPage = 1;
            this.isLoaded = false
            this.fetchData();
            this.updateRouteParams();
        }
    }
    // ngDoCheck(){
    //     console.log(this.tableLoading)
    // }
    getFilters(filters: any){
        if(this.filterCooldown) clearTimeout(this.filterCooldown); 
        this.filterCooldown = setTimeout(() => { 
            if(this.query.filters){
                filters.type = this.currTab?'ongoing':'new';
                this.query.filters = filters;  
                this.query.page = 1;
                this.currPage = 1;

                this.updateRouteParams();
            }
            this.fetchData()
        }, 800);
    }
    getPage(page: number){
        if(this.filterCooldown) clearTimeout(this.filterCooldown); 
        this.filterCooldown = setTimeout(() => { 
            if(this.query.filters){
                this.query.page = page;
                this.currPage = page;

                this.updateRouteParams();
            }
            this.fetchData()
        }, 200);
     
    }

    private updateRouteParams(): void {
        let flatParams: any = {
            page: this.query.page
        };
        for(let f in this.query.filters){
            flatParams[f] = this.query.filters[f]||undefined;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: flatParams,
            replaceUrl: true
        });
    }

    public sendAssessment(appId:number, assessment:Assessment, approval:boolean){
        this.assessmentApi.assess(appId, assessment).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:`Application has been ${approval?'approved':'denied'}`, timeout: 5000 });
            this.fetchData();
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        });
    }
    public massAssess(apps:Application[], assessment:Assessment, approval:boolean){
        let ids = [];
        for(let a of apps) ids.push(a.id);
        this.assessmentApi.massAssess(ids, assessment).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:`Applications have been ${approval?'approved':'denied'}`, timeout: 5000 });
            this.fetchData();
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
    public deleteApplication(appId:number){
        this.applicationApi.delete(appId).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Application has been deleted', timeout: 5000 });
            this.fetchData();
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
    public togglePublish(app:Application){
        this.applicationApi.togglePublish(app.id).subscribe(res => {
            let response = CodedResponseModel.decode(res);
            app.published = response.changedTo;

            this.partials.notificator.emit({ type:'success', message:res.message, timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error',message:err.error.message, timeout: 5000 });
        })
    }
    public getOffset(value: boolean){
          this.expanded = value
          LoaderComponent.menuExpanded.emit(value);

    }
    public massDelete(apps:Application[]){
        let ids = [];
        for(let a of apps) ids.push(a.id);
        this.applicationApi.massDelete(ids).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Application has been deleted', timeout: 5000 });
            this.fetchData();
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
    public shareApp(app:Application, email:string){
        this.applicationApi.shareApplication(app.id, email).subscribe(res => {
            this.partials.notificator.emit({ type:'success', message:'Application has been shared with provided email address', timeout: 5000 });
        }, err => {
            this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
        })
    }
    public openProductCard(i:Application){
        ProductCardComponent.exec.emit({ visibility:true, product:i });
    }

    public editCertStart(id: number, date: string): void {
        this.isLoaded = false;
        this.applicationApi.editCertStartDate(id, date).subscribe({
            next: r => {
                this.fetchData();
            },
            error: err => {
                this.partials.notificator.emit({ type:'error', message:err.error.message, timeout: 5000 });
            }
        });
    }

    public debug(){
        console.log(this);
    }
//   public scrollToTop(){
//      if(window.innerWidth < 768 && this.main){    
//          if(this.main.nativeElement.path !== undefined){ 
//             this.main.nativeElement.path[2].scrollTo(0,{behavior: "smooth"})
//            }else if(this.main.nativeElement.target !== undefined){
//                 this.main.nativeElement.target.scrollTo(0,{behavior: "smooth"})
//            }else if(this.main.nativeElement) {
//                             try{this.main.nativeElement.scrollTo(0,{behavior: "smooth"})}
//                 catch(err){
//                    // console.log(err)
//                 }
//            }
//       }
//   }
}
