<!-- <div class="container">

</div> -->
<svg-icon *ngIf="display" class="disable_mobile visible-xs" (click)="display = false; bodyScroll.enableBodyScroll()" src="../../assets/svg/close_menu_cross.svg"></svg-icon>
<div class="overlay" *ngIf="display == true" (click)="dismiss();bodyScroll.enableBodyScroll()"></div>
<div class="container" *ngIf="exists" [class.active]="display">
    <div class="top">
        <div class="row j_space">
            <h5>{{product.provider?.name}}</h5>
            <app-message (click)="display = false; bodyScroll.enableBodyScroll()" [isFixed]="false" [product]="product" 
                [isNewMessage]="product.hasUnreads"></app-message>
        </div>
        <app-heading2 [text]="product.productName" [isTextBlack]="false"></app-heading2>
        <div class="row a_center j_space">
            <div class="filters">
                <div class="all active" (click)="filterNotifs('details');getSearchPhrase()" [class.active]="activeFilter == 'details'">
                    details
                    <div class="divider divider1"></div>
                </div>
                <div class="all_products" (click)="filterNotifs('all_files');getSearchPhrase()" [class.active]="activeFilter == 'all_files'">
                    All files {{product.files.length}}
                    <!-- <div class="divider divider2"></div> -->
                </div>
            </div>
            <div class="assigned">
                <div class=".avatar">
                    <app-filters-list (change)="changeAssigned($event)" [checkById]="true" [emitSelectedItems]="true" [list]="admins" [isAvatar]="true" 
                        [isProductCard]="true" [isMultiSelect]="true" [selectedValuesObject]="assigneds()" 
                        [disabled]="![userTypes.Admin,userTypes.RIAA,userTypes.IVP].includes(userType)"></app-filters-list>
                </div>
            </div>
        </div>

    </div>
    <div class="products_content" [ngClass]="{'details': activeFilter == 'details'}">
        <div class="wrapper" *ngIf="activeFilter == 'details'">
            <section class="first">
                <ng-container *ngIf="newStatuses.includes(product.status)">
                    <app-button-primary *ngIf="[userTypes.Member,userTypes.Collaborator].includes(userType)" [text]="'Edit APPLICATION'" routerLink="/applications/{{product.id}}"></app-button-primary>
                    <app-button-primary *ngIf="![userTypes.Member,userTypes.Collaborator].includes(userType)" text="Assess Application" routerLink="/applications/{{product.id}}/assess"></app-button-primary>
                </ng-container>
                <ng-container *ngIf="!newStatuses.includes(product.status)">
                    <app-button-primary *ngIf="[userTypes.Member,userTypes.Collaborator].includes(userType)" text="Edit Certification" routerLink="/applications/{{product.id}}"></app-button-primary>
                    <app-button-primary *ngIf="![userTypes.Member,userTypes.Collaborator].includes(userType)" text="Assess Certification" routerLink="/applications/{{product.id}}/assess"></app-button-primary>
                </ng-container>
                <div class="info_row">
                    <h4>Certification</h4>
                    <div class="row j_space">
                        <div class="column">
                            <span>Submitted <span>{{product.createdAt | date:'dd/MM/yy'}}</span></span>
                            <span>Last Update <span>{{product.updatedAt | date:'dd/MM/yy'}}</span></span>
                        </div>
                        <app-color-list (click)="getRef($event)" [canOpen]="[userTypes.RIAA, userTypes.Admin].includes(userType)" (animationEnd)="scroll()" [list]="statuses" [selected]="{ name:product.status, active:true }" (change)="changeStatus($event)"></app-color-list>
                    </div>
                </div>
                <div class="info_row" *ngIf="!newStatuses.includes(product.status)">
                    <h4>updates</h4>
                    <div class="row j_space">
                        <div class="column">
                            <span>Submitted <span>{{product.createdAt | date:'dd/MM/yy'}}</span></span>
                            <span>Last Update <span>{{product.updatedAt | date:'dd/MM/yy'}}</span></span>
                        </div>
                        <app-color-list (click)="getRef($event)" [canOpen]="[userTypes.RIAA, userTypes.Admin].includes(userType)" (animationEnd)="scroll()" [list]="updates" [selected]="{ name:product.updateStatus, active:true }" (change)="changeUpdate($event)"></app-color-list>
                    </div>
                </div>
                <div class="info_row">
                    <h4>Invoices</h4>
                    <div class="row j_space">
                        <div class="column">
                            <span>Submitted <span>{{product.createdAt | date:'dd/MM/yy'}}</span></span>
                            <span>Last Update <span>{{product.updatedAt | date:'dd/MM/yy'}}</span></span>
                        </div>
                        <app-color-list (click)="getRef($event)" [canOpen]="[userTypes.RIAA, userTypes.Admin].includes(userType)" (animationEnd)="scroll()" [list]="invoices" [selected]="{ name:product.invoice, active:true }" (change)="changeInvoice($event)"></app-color-list>
                    </div>
                </div>
            </section>
            <section class="second members">
                <h4>Member(s)</h4>
                <div class="row a_center" *ngIf="product.user">
                    <app-avatar [model]="{name:product.user?.fullName, imgUrl:product.user.avatar?product.user?.avatarURL:''}"></app-avatar>
                    <span class="name">{{product.user?.fullName}}</span>
                    <span class="email">{{product.user?.email}}</span>
                </div>
            </section>
            <section class="second colabolators">
                <h4>Collaborator (s)</h4>
                <div class="row a_center" *ngFor="let c of product.collaborators">
                    <app-avatar [model]="{name:c.fullName, imgUrl:c.avatar?c.avatarURL:''}"></app-avatar>
                    <span class="name">{{c.fullName}}</span>
                </div>
            </section>
            <section class="second target">
                <div class="hr"></div>
                <h4>target</h4>
                <div class="row a_center">
                    <span>{{product.targets}}</span>
                </div>
            </section>
            <section class="second category">
                <h4>category</h4>
                <div class="row a_center">
                    <span>{{product.categories}}</span>
                </div>
            </section>
            <!-- <section class="second classes">
                <h4>asset classes</h4>
                <div class="row a_center">
                    <span></span>
                </div>
            </section> -->
        </div>
        <div class="list" *ngIf="activeFilter == 'all_files'">
            <input class="search_input" type="text" (keyup)="getSearchPhrase($event)" placeholder="Search...">
            <div class="column">
                <app-download-button *ngFor="let file of searchResults" [name]="file.label||file.filename" [downloadable]="true" fileSize="{{file.filesize}}" 
                    [fileType]="file.getExtension()" [fileUrl]="file.getUrl()"></app-download-button>
            </div>
        </div>
    </div>
</div>