import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:'root',
})
export class AttachmentAPI{
    constructor(
        private http:HttpClient
    ){}

    public upload(file:File){
        let p = new FormData();
        p.append('file', file);
        return this.http.post(`${environment.apiUrl}upload`, p, {  reportProgress: true, observe:'events' });
    }
}
