import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { environment } from "src/environments/environment";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root'
})
export class AnalyticsAPI{
    constructor(
        private http:HttpClient,
        private encryptor:EpEncryptionService,
    ){}

    public fetch(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}analytics`);
    }

    public fetchExport(){
        return this.http.get<CodedResponseModel>(`${environment.apiUrl}export-data`);
    }

    // public applicationExport(sections?:number[]|string, questions?:number[]|string){
    //     return this.http.post<CodedResponseModel>(`${apiUrls.apiUrl}export-data/applications`,
    //         this.encryptor.sendPayload({ sections:sections, questions:questions }));
    // }

    public productExport(members?:number[]|string, products?:number[]|string, email?:string, type:'json'|'csv' = 'csv'){
        return this.http.post(`${environment.apiUrl}export-data?type=${type}`,
            this.encryptor.sendPayload({ members, products, email }));
    }

    public providerExport(providers?:number[]|string, email?:string, type:'json'|'csv' = 'csv',){
        return this.http.post(`${environment.apiUrl}export-providers?type=${type}`,
            this.encryptor.sendPayload({ providers, email }));
    }
}
