import { Component } from "@angular/core";
import { CodedResponseModel } from "../model/CodedResponseModel";
import { NotyficationMessageStateModel } from "../model/NotyficationMessageStateModel";
import { User } from "../model/User";
import { EpEncryptionService } from "../services/ep-encryption.service";
import { UsersAPIService } from "../services/users.service";

@Component({
	selector: 'app-users',
	templateUrl: './index.component.html'
})
export class UserIndexComponent {
	//loader
	public showLoader: boolean = false;
	public showNotyfication: boolean = false;
	public notyficationMessage: string = '';
	public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
	//loader END

	originalData:User[] = [];
	displayedData:User[] = [];

	public showDeleteModal:boolean = false;
	public deleteTarget:User = new User();

	filter:string = '';
	constructor(
		private userApi: UsersAPIService,
	){
		this.showLoader = true;
		this.userApi.index().subscribe(res => {
			let response = new CodedResponseModel();
			response.map(res);
			this.originalData = [];
			for (let user of response.decodeData()) {
				let u = new User();
				u.map(user);
				this.originalData.push(u);
			}
			this.filterData();
			this.showLoader = false;
		}, err => {
			this.showLoader = false;
			this.showNotyfication = true;
			this.notyficationMessage = err.error.message;
			this.notyficationMessageStateModel.errors_save = true;
		});
	}

	public filterData() {
		if (this.filter == '') this.displayedData = this.originalData;
		else {
			let tmp = [],
				lcf = this.filter.toLowerCase();
			for (let i of this.originalData) {
				if (String(i.id).includes(lcf) || i.name.toLowerCase().includes(lcf) || i.lname.toLowerCase().includes(lcf) || i.email.toLowerCase().includes(lcf)/* || i.user_role.toLowerCase().includes(lcf)*/)
					tmp.push(i);
			}
			this.displayedData = tmp;
		}
	}
	public del(user:User){
		this.deleteTarget = user;
		this.showDeleteModal = true;
	  }
	  public confirmDeletion(){
		this.userApi.delete(this.deleteTarget.id).subscribe(res => {
		  this.showNotyfication = true;
		  this.notyficationMessage = res.message;
		  this.notyficationMessageStateModel.save_info = true;
		  this.originalData.splice(this.originalData.indexOf(this.deleteTarget), 1);
		  this.showDeleteModal = false;
		}, err => {
		  this.showNotyfication = true;
		  this.notyficationMessage = err.error.message;
		  this.notyficationMessageStateModel.errors_save = true;
		  this.showDeleteModal = false;
		});
	  }
}