import { Component, OnInit, Input } from '@angular/core';
import { ConversationsComponent } from 'src/app/menu/conversations/conversations.component';
import { MenuComponent } from 'src/app/menu/menu.component';
import { Application } from 'src/app/model/responsible/Application';
import { PartialService } from 'src/app/services/partial.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() public isNewMessage: boolean = false;
  @Input() public link: string = ''
  @Input() public isFixed: boolean = true
  @Input() public isLeft: boolean = false
  @Input() public product?:Application;
  @Input() public qId?:number;
  constructor(
    private partials:PartialService,
  ) { }

  ngOnInit(): void {
  }

  public openChat(e: any) {
      e.preventDefault();
      if(this.product) 
        this.partials.conversations.emit({ name:this.product.productName, applicationId:this.product.id, formId:this.product.formId, questionId: this.qId });
      MenuComponent.chatViewOpen.emit(true);
      ConversationsComponent.refresh.emit();
  }
}
