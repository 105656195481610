<app-auth></app-auth>
<app-menu (expanded)="getOffset($event)" [ngClass]="{'menu_expanded': !expanded}"></app-menu>
<main role="main">
    <div class="main_wrap">
        <div class='offset' [class.export]="currTab==1" [class.lg_bcg]="currTab==1" [ngClass]="{'menu_minimised': expanded}">
            <div class='page-top'>
                <app-top-tabs [tabs]="tabs"></app-top-tabs>
                <div class="text_wrap" *ngIf="currTab==0">
                    <div class="subheading">Analytics</div>
                    <div class="title_wrap">
                        <h2 (click)="debug()">Data Viewer</h2>
                        <app-circled-add-edit-link (click)="goToPB()" [label]="'Select more question data'"></app-circled-add-edit-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="offset main_content lg_bcg" [class.export]="currTab==1" [ngClass]="{'menu_minimised': expanded}">
            <div class="tabs_content">
                <div class="tab_0" *ngIf="currTab==0&&loaded">
                    <div class="data_viewer" *ngIf="questions.length">
                        <div class="single_data" *ngFor="let q of questions; index as i;">
                            <div class="chart_wrap">
                                <app-pie-chart [pieChartData]="chartData[q.id]"></app-pie-chart>
                            </div>
                            <div class="label">Question {{q.tag}}</div>
                            <div class="title">{{q.question}}</div>
                            <div class="percentage_list">
                                <div class="percentage_wrap" *ngFor="let singlePercentage of chartData[q.id].percentageView; index as s">
                                    <div class="percentage_wrap_inner" [class.hidden]="s > 2">
                                        <div class="percentage">{{singlePercentage.percentage}}%</div>
                                        <div class="percentage_desc">{{singlePercentage.desc}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="more" (click)="openOverlayView(q)" *ngIf="chartData[q.id].percentageView.length - 3 > 0">+ {{chartData[q.id].percentageView.length - 3}} more</div>
                        </div>
                    </div>
                </div>
                <div class="tab_1" *ngIf="currTab==1">
                    <!-- <div class="application_data">
                        <h2>Application Data</h2>
                        <div class="desc">Download spreadsheet of data for sections and their questions.</div>
                        <app-form-select [isMulitSelect]="true" [isNoneValue]="true" [list]="sectionsList" [placeholderText]="'Select section(s)'" 
                            [(model)]="selectedSections" (modelChange)="updateQuestionList()" noneLabel="All" noneValue="all"></app-form-select>
                        <app-form-select [isMulitSelect]="true" [isNoneValue]="true" [list]="questionsList" [placeholderText]="'Select question(s)'" 
                            [(model)]="selectedQuestions" noneLabel="All" noneValue="all"></app-form-select>
                        <app-button-primary [text]="'Download .json data'" (click)="requestApplicationExport()"></app-button-primary>
                    </div> -->
                    <div class="product_data">
                        <h2>Product Data</h2>
                        <div class="desc">Download spreadsheet of data for members and their products.</div>
                        <app-form-select [isMulitSelect]="true" [list]="membersList" [placeholderText]="'Select member(s)'" [(model)]="selectedMembers" 
                            (modelChange)="updateProductList()" [customSearch]="true" [loading]="membersLoading" (searched)="searchMembers($event)"
                            displayProperty="fullName" noneLabel="All" noneValue="all" [isNoneValue]="true"></app-form-select>
                        <app-form-select [isMulitSelect]="true" [list]="productsList" [placeholderText]="'Select product(s)'" [(model)]="selectedProducts"
                            [customSearch]="true" (searched)="searchApps($event)" [loading]="appsLoading" displayProperty="productName" noneLabel="All" 
                            noneValue="all" [isNoneValue]="true"></app-form-select>
                        <app-input-field placeholderText="Email address" [(model)]="exportEmail"></app-input-field>
                        <span class='info'>The requested data will be sent to the provided email address once the export process is finished. Depending on the amount of data exported, this process could take up to several minutes.</span>
                        <app-button-primary [text]="'Download .csv data'" (clicked)="requestProductExport('csv')"></app-button-primary>
                        <app-button-primary [text]="'Download .json data'" (clicked)="requestProductExport('json')"></app-button-primary>
                    </div>
                    <div class="member_data">
                        <h2>Member Data</h2>
                        <div class="desc">Download spreadsheet of data for providers and their members.</div>
                        <app-form-select [isMulitSelect]="true" [list]="providersList" placeholderText="Select provider(s)" [(model)]="selectedProviders"
                            [customSearch]="true" [loading]="providersLoading" (searched)="searchProviders($event)"
                            displayProperty="name" noneLabel="All" noneValue="all" [isNoneValue]="true"></app-form-select>
                        <app-input-field placeholderText="Email address" [(model)]="exportEmail"></app-input-field>
                        <span class='info'>The requested data will be sent to the provided email address once the export process is finished. Depending on the amount of data exported, this process could take up to several minutes.</span>
                        <app-button-primary [text]="'Download .csv data'" (clicked)="requestProviderExport('csv')"></app-button-primary>
                        <app-button-primary [text]="'Download .json data'" (clicked)="requestProviderExport('json')"></app-button-primary>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>