import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { FormField } from 'src/app/model/responsible/FormField';
import { Theme } from 'src/app/model/responsible/Theme';
import { ThemeAPI } from 'src/app/services/responsible/theme.service';

@Component({
    selector: 'app-themes',
    templateUrl: './themes.component.html',
    styleUrls: ['./themes.component.scss']
})
export class FieldThemesComponent implements OnInit {
    public list:Theme[] = [];
    public selected?:Theme;
    public field:FormField = new FormField();
    public loading:boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any,
        private themeApi:ThemeAPI,
    ){
        this.themeApi.index().subscribe(res => {
            let response = CodedResponseModel.decode(res);
            for(let t of response){
                let o = Theme.create(t);
                this.list.push(o);
                if(o.id==this.data.field.customs)
                    this.selected = o;
            }
            
            this.loading = false;
        })
    }

    ngOnInit():void{
        this.field = FormField.create(this.data.field);
    }
}
