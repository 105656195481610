import { Component, Input, OnInit, Output, EventEmitter,ViewChild} from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment'
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { trigger, style, animate, transition,state, animateChild } from '@angular/animations';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { OverlayComponent } from '../overlay/overlay.component';
@Component({
  selector: 'app-filters-calendar',
  templateUrl: './filters-calendar.component.html',
  styleUrls: ['./filters-calendar.component.scss'],
   animations: [
    // trigger('openClose', [
    //   state('open', style({
    //    transform: 'scaley(1)',
    //     opacity: 1,
    //    })),
    //   state('closed', style({
    //     transform: 'scaley(0)',
    //     opacity: 0
    //   })),
    //   transition('open => closed', [
    //     animate('0.3s'),
    //     animateChild()
    //   ]),
    //   transition('closed => open', [
    //     animate('0.3s'),
    //     animateChild()
    //   ]),
    //   ]),
  ],

})
export class FiltersCalendarComponent implements OnInit {
    @Input() public initialValue: any;
    @Input() public chosenLabel = '';
  @Input() public selected:any
  @Input() public disabled: boolean = false;
  @Input() public fullwidth: boolean = false;
  @Input() public filterName: string = "date"
  @Output() public date = new EventEmitter<{ startDate: Date, endDate: Date, isReset?: boolean }>()
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective?: DaterangepickerDirective;
  @Output() public clearField = new EventEmitter()
  public enabled: boolean = false
  public headerEnabled: boolean = false
  public noSelection: boolean = true
  public firstrun: boolean = true
  public helpVal : { startDate: Moment, endDate: Moment } = { startDate: moment(), endDate: moment() }
  public firstRun: boolean = true
    constructor(public scroll:BodyScrollService
      ) {
  }
  public days: any = {
    daysOfWeek: ["S ", "M ", "t ", "w", "t", "f", "s"],
    format: 'DD-MM-YYYY',
    startKey: "enabled",
    monthNames: moment.months()
  }
   ngOnInit(): void {
      if(this.initialValue) {
          this.selected = {...this.initialValue};
          this.enabled = true;
      }
    }

  selectedDate(newValue: any) {
    OverlayComponent.show.emit(false)
      //  if(newValue.startDate == this.selected.startDate && newValue.endDate == this.selected.endDate) return;
       if(newValue !== undefined ){ 
        this.selected = newValue
        this.noSelection = false
      }
      else this.noSelection = true
      this.valSel()
      if (this.enabled && this.noSelection == false) {
      const startDate: any = `${newValue.startDate.unix()}`;
      const endDate: any = `${newValue.endDate.unix()}`;
      if(newValue) this.date.emit({ startDate: startDate, endDate: endDate, isReset: false })
   //   this.scroll.enableBodyScroll()
     } else {
       this.reset()
    }
  }
public now(e:any){
  console.log(e)
}
  reset() {
    this.enabled = false
    this.selected = undefined
    this.clearField.emit()
  }

public openCalendar(){ 
    setTimeout(()=>{
       if(this.pickerDirective){ 
        this.pickerDirective.open()
        this.scroll.disableBodyScroll()
        OverlayComponent.show.emit(true)
      
      }
  },100)
 }
 public valSel(){
    if(!this.selected && this.firstRun) {
      this.headerEnabled = true;
      this.firstrun = false
      return true
    }
   else  {  
     this.headerEnabled = false
      this.enabled = true
      this.firstrun = true
      return false
  }
 }
}
