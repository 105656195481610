<div class="icon_header_char">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='container'>
    <h3>An unpublished draft of the form has been found.</h3>
    <p>Would you like to continue working on this draft, or discard it and edit the public version of the form?</p>
    <div class='buttons'>
        <app-button-primary text="Discard draft" [matDialogClose]="false"></app-button-primary>
        <app-button-primary text="Continue working on the draft" [matDialogClose]="true"></app-button-primary>
    </div>
</div>