import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormField } from "../../../model/responsible/FormField";
import { RadioGroupOption } from "../../../model/RadioGroupOption";
import { MatRadioChange } from "@angular/material/radio";

@Component({
	selector: 'app-radio-group-field',
	templateUrl: './radio-group-field.component.html',
	styleUrls: [ './radio-group-field.component.scss' ]
})
export class RadioGroupFieldComponent implements OnInit {
	@Input() public fieldData!: FormField;
	@Input() public model?: string;
	@Input() public readonly = false;
	@Output() public modelChange = new EventEmitter<string>();

	public options: RadioGroupOption[] = [];

	public ngOnInit() {
		const data = JSON.parse(this.fieldData.filetypes!);

		this.options = data;
	}

	public valueChanged(event: MatRadioChange){
		this.model = event.value;
		this.modelChange.emit(event.value);
	}
}