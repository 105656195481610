import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoginService } from "../services/login.service";
import { Router, NavigationEnd, RouterLinkActive } from "@angular/router";
import { Subscription } from 'rxjs';
import { PartialService } from "src/app/services/partial.service";
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { User, UserTypes } from '../model/User';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OverlayComponent } from '../core/overlay/overlay.component';
import { LoaderComponent } from '../partials/loader/loader.component';
@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'] 
})
export class MenuComponent implements OnInit, OnDestroy {
    @Input() display: boolean = true;
    @Input() exists: boolean = true;

    @Input() minimizable: boolean = true;
    @Input() public minimise: boolean = false;
    public showOverlay: boolean = false;
    public isNewConversationViewOpen: boolean = false;
    public isChatViewOpen: boolean = false;
    public static show = new EventEmitter<boolean>();
    public static formNavMobile = new EventEmitter<boolean>();
    public static chatViewOpen = new EventEmitter<boolean>();
    public static hideOverlay = new EventEmitter<boolean>();
    public static unreadNotifs = new EventEmitter<boolean>();
    public userId: number = 1;
    public formNavMobileToggle: boolean = false;
    public notifOpen: boolean = false;
    public newNotifs:boolean = false;
    private subs: Subscription[] = [];
    public currUser: User = new User();

    private showTimeout: any;
    @Input() public isAssessment: boolean = false
    
    //to detect wrap
    @Output() expanded = new EventEmitter()
    ngOnChange(){
        console.log(this.notifOpen)
    }
    public menuItems: Array<Menu> = [
        {
            link: "/",
            text: "Dashboard",
            //permissions: ['', 'Admin', 'RIAA Admin', 'IVP Admin', 'Member', 'Collaborator'],
            permissions: [ UserTypes.Admin, UserTypes.RIAA, UserTypes.IVP, UserTypes.Member, UserTypes.Collaborator],
            icon: 'assets/svg/i_dashboard.svg',
            iconActive: 'assets/svg/i_dashboard_active.svg',
        },
        {
            link: "/forms/new",
            text: "Application",
            //permissions: ['', 'Admin', 'RIAA Admin'],
            permissions: [ UserTypes.Admin, UserTypes.RIAA],
            icon: 'assets/svg/i_application.svg',
            iconActive: 'assets/svg/i_application_active.svg',
        },
        {
            link: "/members",
            text: "Members",
          //  permissions: ['', 'Admin', 'RIAA Admin', 'Member', 'Collaborator'],
          permissions: [ UserTypes.Admin, UserTypes.RIAA, UserTypes.Member, UserTypes.Collaborator],
            icon: 'assets/svg/i_members.svg',
            iconActive: 'assets/svg/i_members_active.svg',
            sub: ['/members/','/providers', '/members/new/member', '/members/new/collaborator', '/providers/new']
        },
        {
            link: "/analytics",
            text: "Analytics",
           // permissions: ['', 'Admin', 'RIAA Admin'],
           permissions: [ UserTypes.Admin, UserTypes.RIAA],
            icon: 'assets/svg/i_analytics.svg',
            iconActive: 'assets/svg/i_analytics_active.svg',
        },
        {
            link: "/resources",
            text: "Resources",
          //  permissions: ['', 'Admin', 'RIAA Admin', 'Member', 'Collaborator'],
          permissions: [ UserTypes.Admin, UserTypes.RIAA, UserTypes.Member, UserTypes.Collaborator],
            icon: 'assets/svg/i_resources.svg',
            iconActive: 'assets/svg/i_resources_active.svg',
        },
        {
            link: "/edit_site",
            text: "Edit site",
         //   permissions: ['', 'Admin', 'RIAA Admin'],
         permissions: [ UserTypes.Admin, UserTypes.RIAA],
            icon: 'assets/svg/i_edit_site.svg',
            iconActive: 'assets/svg/i_edit_site_active.svg',
        },
    ];

    public bottomMenuItems: Array<Menu> = [
        {
            link: `/profile`,
            text: "Edit profile",
        //    permissions: ['', 'Admin', 'RIAA Admin', 'IVP Admin', 'Member', 'Collaborator'],
        permissions: [ UserTypes.Admin, UserTypes.RIAA, UserTypes.IVP, UserTypes.Member, UserTypes.Collaborator],
            icon: '',
            iconActive: '',
        },
        {
            link: "/admins",
            text: "Admins",
          //  permissions: ['', 'Admin', 'RIAA Admin', 'IVP Admin'],
          permissions: [ UserTypes.Admin, UserTypes.RIAA, UserTypes.IVP],
            icon: '',
            iconActive: '',
            sub: ['/admins/new', '/exclusions', '/themes', '/categories', '/filters', '/edit-login']
        },
    ]

    constructor(
        private loginService: LoginService,
        public router: Router,
        private comm: PartialService,
        private bodyScroll: BodyScrollService,
        private jwt: JwtHelperService,
    ) { }

    ngOnInit(): void {
        this.currUser.map(this.jwt.decodeToken().user);
        this.subs.push(MenuComponent.show.subscribe(res => {
            if (res === undefined) {
                clearTimeout(this.showTimeout);
                if (this.exists) {
                    this.display = false;
                    this.showTimeout = setTimeout(() => { this.exists = false }, 300);
                    if (window.innerWidth < 768) {
                        this.minimise = false;
                        // LoaderComponent.menuExpanded.emit(false)
                    }
                } else {
                    this.exists = true;
                    
                    this.showTimeout = setTimeout(() => { this.display = true }, 40);
                    if (window.innerWidth < 768) {
                        this.showTimeout = setTimeout(() => { this.minimise = true; }, 40)
                    }
                    // LoaderComponent.menuExpanded.emit(false)
                }
            } else if (res && !this.exists) {
                clearTimeout(this.showTimeout);
                this.exists = true;
                this.showTimeout = setTimeout(() => { this.display = true }, 40);
                if (window.innerWidth < 768) {
                    this.showTimeout = setTimeout(() => { this.minimise = true; }, 40)
                    // LoaderComponent.menuExpanded.emit(false)
                }
            } else if (!res && this.exists) {
                clearTimeout(this.showTimeout);
                this.display = false;
                this.showTimeout = setTimeout(() => { this.exists = false }, 300);
                if (window.innerWidth < 768) {
                    this.minimise = false;
                }
                // LoaderComponent.menuExpanded.emit(true)
            }
        }),MenuComponent.unreadNotifs.subscribe(res => {
            this.newNotifs = res;
        }));

        if (this.minimizable && (window.innerWidth > 767 && window.innerWidth < 1025)) {
            this.minimise = true;
        }

        this.subs.push(MenuComponent.formNavMobile.subscribe(res => {
            this.formNavMobileToggle = res;
        }));

        this.subs.push(MenuComponent.chatViewOpen.subscribe(rest => {
            this.openCoversations();
        }));

        this.subs.push(MenuComponent.hideOverlay.subscribe(rest => {
            this.showOverlay = false;
        }));
        this.expanded.emit(this.minimise)
        LoaderComponent.menuExpanded.emit(this.minimise)

    }
    ngOnDestroy() {
        for (let s of this.subs) s.unsubscribe();
        this.bodyScroll.enableBodyScroll();
    }

    public toggleMenu() {
         if (window.innerWidth < 768) {
            this.comm.notifications.emit({ isActive: false });
            this.notifOpen = false;
            this.showOverlay = false;
        }
        if (this.minimizable) {
            this.minimise = !this.minimise;
            this.expanded.emit(this.minimise)
            LoaderComponent.menuExpanded.emit(this.minimise)

             if (window.innerWidth < 768) {
                this.bodyScroll.disableBodyScroll();
            }
            if (this.minimise) {
                if (window.innerWidth < 768) {
                    this.bodyScroll.disableBodyScroll();
                }
            } else {
                if (window.innerWidth < 768) {
                    this.bodyScroll.enableBodyScroll();
                }
            }
         } else {
            LoaderComponent.menuExpanded.emit(this.minimise)
            this.expanded.emit(this.minimise);
            this.closeMainMenu();
            if (window.innerWidth < 768) {
                this.bodyScroll.enableBodyScroll();
            }
        }

    }


    public open_submenu(event: any) {
        let $this = event.currentTarget;
        let parent = $this.parentNode;
        parent.classList.toggle('active');
    }

    public menuChangePage(link: string) {
      //  OverlayComponent.show.emit(false)
        this.router.navigate([link]);
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        return false;
    }
    public canShow(menuItem: Menu) {
        if (menuItem.permissions.length > 0) {
            let canSee: boolean = false;
            // menuItem.permissions.forEach(item => {
            //     if ([UserTypes.Admin, UserTypes.RIAA].includes(this.jwt.decodeToken().user.type)) {
            //         canSee = true;
            //     }
            // });

           if(menuItem.permissions.includes(this.jwt.decodeToken().user.type)) canSee = true;
            return canSee;
        } else {
            return true;
        }
        return false;
    }

    public showButton(string: string) {
        switch (string) {
            case '':
                return false;
                break;

            default:
                return false;
                break;
        }
    }
    public getCounter(string: string) {
        switch (string) {
            case '':
                return 0;
                break;

            default:
                return 0;
                break;
        }
    }

    public openNotif() {
        this.comm.notifications.emit({ isActive: true });
        if (window.innerWidth < 768) {
            this.notifOpen = true;
            this.minimise = false;
        }
    }

    public closeNotifs() {
        if (window.innerWidth < 768) {
            this.comm.notifications.emit({ isActive: false });
            this.notifOpen = false;
            this.showOverlay = false;
            this.minimise = false;
        }
    }

    public openNotifOverlay() {
        this.showOverlay = true;
    }

    public openCoversations() {
        this.showOverlay = true;
        this.comm.conversations.emit({ isActive: true });
        this.bodyScroll.disableBodyScroll();
    }

    public videModalOpen() {
        this.showOverlay = true;
    }

    public newConversationViewIsOpen() {
        this.isNewConversationViewOpen = true;
        this.bodyScroll.disableBodyScroll();
    }

    public openChat() {
        this.isChatViewOpen = true;
        this.bodyScroll.disableBodyScroll();
    }

    public dismiss() {
        this.bodyScroll.enableBodyScroll();
        this.isNewConversationViewOpen = false;
        this.isChatViewOpen = false;
        this.showOverlay = false;
        this.comm.notifications.emit({ isActive: false });
        this.comm.conversations.emit({ isActive: false });
        this.comm.videoModal.emit({ isActive: false });
    }

    public closeOverlay() {
        this.showOverlay = false;
    }

    public closeMainMenu() {
        MenuComponent.show.emit(false);
        this.toggleMenu
    }

    public onSwipeDown(event: any) {
        MenuComponent.show.emit(false);
    }

    public logOut() {
        console.log('click');
        this.loginService.logout();
        this.router.navigate(['/login']);
    }
    public detectSub(item: any){
        if(item.sub) 
        {
            if(item.sub.find((e: string) => this.router.url.includes(e))) return true
            else return false
        }
        else return false
    }
}

export class Menu {
    link: string = "";
    text: string = "";
    permissions: Array<UserTypes> = [];
    icon: string = '';
    iconActive: string = '';
    sub?: Array<string> = []
}