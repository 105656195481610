import { Component, OnInit,Input,EventEmitter, Output,ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-color-list',
  templateUrl: './color-list.component.html',
  styleUrls: ['./color-list.component.scss'],  animations: [
    trigger(
      'filtersOutIn',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.3s ease-out',
              style({ height:'100%', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '100%', opacity: 1 }),
            animate('0.3s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class ColorListComponent implements OnInit {
  @Input() public list: Array<any> = [{ name: 'Admin', active: false }, { name: 'IVP Admin', active: false }, { name: 'RIAA Admin', active: false }, { name: 'Collaborator', active: false }, { name: 'Member', active: false }, { name: 'Invoced', active: false }, { name: 'Paid', active: false }];
  public isOpen: boolean = false
  @Input() public selected: any = { name: 'Admin', active: false }
  @Input() public canOpen:boolean = true;
  @Output() public change = new EventEmitter<any>()
  @Output() public animationEnd = new EventEmitter()
  constructor() { }
  // ngOnChanges(changes: any){
  //   console.log(changes)
  // }
  ngOnInit(): void {
    if(this.selected && this.list){ 
      this.list.find((item)=>{
        if(item.name == this.selected.name) this.list[this.list.indexOf(item)].active = true
      })
    }
 }
public onChange(item: any){  
  if(this.selected.name !== item.name){
    this.selected = item
    this.list.filter(item => item.active = false)
   this.list[this.list.indexOf(item)].active = true
   this.change.emit(this.selected)
   console.log(item)
   console.log(this.list)
   console.log(this.selected)
  }
}
}
