import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, DoCheck } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {

  constructor() { }
  @Input() public model: string | number = '0';
  @Input() public isError: boolean = false;
  @Input() public errorText: string = 'This is example error message';
  @Input() public placeholderText: string = 'Number Input';
  @Input() public min?: number;
  @Input() public max?: number;
  @Input() public isDisabled: boolean = false;
  @Input() public isReadOnly: boolean = false;
  @Input() public small: boolean = false;
  @Input() public isSuffix: boolean = false
  @Input() public sufix: string = '$'
  @Input() public isDecimal: boolean = false
  @Input() public step: number = 0.001
  @Input() public limit: number = 100
  @Input() public isNegative: boolean = true
  @Output() public modelChange = new EventEmitter<number>();
  @Output() public enterClicked = new EventEmitter()
  val = new FormControl();

  private previousValue: string | number = '0'
  public pattern: string = '[0-9]*[,.]?[0-9]*$'

  public isFocused: boolean = false
  public isAccepted: boolean = false
  public isEmpty: boolean = true
  public length: number = 0;

  private rounding: number = 2
  @ViewChild('inputNumber')
  input!: ElementRef;
  ngOnChanges(changes: any){
    //TODO initialization
    console.log(changes)
     if(changes.model == undefined && changes.model.firstChange == true){
       this.model = '';
      this.val.setValue('');
   }else if(changes.model.currentValue !== undefined ) 
  { 
    this.model = changes.model.currentValue
    this.val.setValue(changes.model.currentValue);
  }
  }
 

  ngDoCheck(): void {
    // if (this.input && this.model == undefined) {
    //   this.input.nativeElement.value = ''
    //   this.model = ''
    // }
    // if (this.model !== undefined && isNaN(Number(this.model.toString().replace(',', '.')))) {
    //   this.model = this.previousValue
    // }
  }
  private numberValidate(value: number | string) {
     this.model = value
    if (this.val && this.val.value.toString().charAt(0) == "0" && this.input.nativeElement.value.length > 1 && (this.input.nativeElement.value.charAt(1) !== '.' && this.input.nativeElement.value.charAt(1) !== ',')) {
      this.model = this.input.nativeElement.value = this.rmZero(this.val.value)
    }
    if (this.val.errors?.pattern) {
        this.val.setValue(this.previousValue)
       this.model = this.previousValue
    }
    else {
      if (this.isDecimal) {
        let second = ''
        if (this.val.value.toString().includes(',') && this.val.value) {
          second = this.input.nativeElement.value.split(',')
        }
        if (this.val.value.toString().includes('.') && this.val.value) {
          second = this.input.nativeElement.value.split('.')
        }
        if (second[1] !== undefined) {
           if (this.decimalVal(second[1].length)) {
             this.modelChange.emit(Number(this.model))
              this.previousValue = this.model
          }
          else if (!this.val.errors?.pattern) {
             this.model =  this.previousValue
             this.val.setValue(this.previousValue)
          }
        } else {
           this.previousValue = this.model
          this.modelChange.emit(Number(this.model))
        }
      }
      else {
         this.previousValue = this.model
        this.modelChange.emit(Number(this.model))
      }
    }
  }

  rmZero(val: string) {
    return Number(val.toString().slice(0))
  }

  ngOnInit(): void {
    this.val = new FormControl( {value: this.model, disabled: this.isDisabled})
    this.listener()
    if (!this.isDecimal) {
      this.previousValue = this.model
       this.step = 1
      if(this.isNegative) this.pattern = '^\[-]?[0-9]*$'
      else this.pattern = '^[0-9]*$'
    } else {
      this.previousValue = this.model
      let tmp = this.step.toString().split(".")
      this.rounding = tmp[1].length
     if(this.isNegative)  this.pattern = '^\[-]?\[0-9]*[,.]?[0-9]*$'
    }
  }

  decimalVal(val: number) {
     if (val > this.rounding) return false
    else return true
  }

  onFocus(event: any) {
    if (event) {
      this.isFocused = true;
      this.isAccepted = false
    }
    if (Number(this.model) == 0) {
      this.model == null
    }
  }

  onBlur() {
    if (this.isEmpty) {
      this.isFocused = false;
    }
    if(this.isDecimal && (this.model.toString().charAt(0) == ',' || this.model.toString().charAt(0) == '.')) this.isError = true
    else this.isError = false
   }

  isInputEmpty(text: number) {
    if (!text && text != 0) {
      this.isEmpty = true
    } else {
      this.isEmpty = false
      this.isFocused = true
    }
  }

  public arrowStep(key: any) {
    let tmp: number
    if (key.keyCode == 38) {
      tmp = Number(this.val.value) + this.step
      if (this.isDecimal) this.val.setValue(tmp.toFixed(this.step.toString().length - 2)) 
      else this.val.setValue(tmp) 
    }
    if (key.keyCode == 40) {
      tmp = Number(this.val.value) - this.step
      if (this.isDecimal)  this.val.setValue( tmp.toFixed(this.step.toString().length - 2))
      else this.val.setValue(tmp) 
    }
  }
  public validatePlaceholder() {
    if (this.model || this.model !== undefined) {
      if (this.isFocused) {
        if (this.model == null) return true
        else if (this.model.toString().length > 0) return true
        else return true
      }
      else {
        if (this.model == null) return false
        else if (this.model.toString().length > 0) return true
        else return false
      }
    } else return false
  }

  private listener(){
     this.val.valueChanges.subscribe((data)=>{
      this.numberValidate(data)
    })
  }
}