<div class="column">
    <div class="drag_and_drop_wrap">
        <div class="drag_and_drop" appFileDrop (filesChangeEmiter)="fileEvent.emit($event)">
            <div class="drag_and_drop_inner">
                <div class="row">
                    <div class="icon_wrap">
                        <svg-icon src="../../../assets/svg/plus-icon.svg"></svg-icon>
                    </div>
                    <div class="drag_and_drop_desc">
                        <div class="single_desc">{{textLabel}}</div>
                        <div class="single_desc"><span class="upload" (click)="modal?openModal.emit():uploader.click()">Or select file</span>
                        </div>
                    </div>
                    <input type="file" #uploader hidden (change)="fileUploaded($event)">
                </div>
            </div>
        </div>
    </div>
    <div class="info">
        <span><!--PDF, Word and Pages documents allowed.-->{{types}} allowed. <ng-container *ngIf="isFileLimit">Max file size 5MB.</ng-container></span>
    </div>
    <div *ngIf="fileList?.length" class="file_list_wrap" [ngClass]="{'error': isError}">
        <p class="desc">Uploaded files</p>
        <div class="file_list">
            <div class='file' *ngFor="let file of fileList">
                <span>{{file.file?.getExtension()||file.extension}}</span>
                <!-- <span>{{file.file?.filename||file.filename}}</span> -->
                <a [class.clickable]="file.progress == 100 && !file.error" [href]="fileUrl(file)" target="_blank">
                    {{getName(file.file?.label||file.file?.filename||file.filename)}}
                </a>
                <div class="progress">
                    <span *ngIf="file.progress<100 && !file.error">{{file.progress}}%</span>
                    <mat-spinner *ngIf="file.progress<100 && !file.error" mode="determinate" [value]="file.progress"></mat-spinner>
                    <app-button-secondary [isText]="true" buttonText="Delete" *ngIf="file.progress == 100 && !file.error" [imgUrl]="'assets/svg/close.svg'" (click)="fileRemoved.emit(file)"></app-button-secondary>
                    <div *ngIf="isError" class="error_info">
                        <span>{{errorMsg}}</span>
                        <svg-icon src="assets/svg/close.svg"></svg-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>