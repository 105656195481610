import { Component, ElementRef, Inject, OnInit, ViewChild,ViewChildren,QueryList, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Condition } from 'src/app/model/responsible/Condition';
import { ConditionGroup, condition_group_response } from 'src/app/model/responsible/ConditionGroup';
import { Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { FormField } from 'src/app/model/responsible/FormField';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
 export interface ConditionBuilderInterface {
    type: 'question' | 'field',
    conditions: condition_group_response[],
    conditionable: any[],
    qIndex: string,
    hidden: boolean,
    fIndex?: string,
}

@Component({
    selector: 'app-condition-builder',
    templateUrl: './condition-builder.component.html',
    styleUrls: ['./condition-builder.component.scss']
})
export class ConditionBuilderComponent implements OnInit {
    public hidden = { name: '', value: true };
    public conditions: ConditionGroup[] = [];
    public conditionable: any[] = [];

    public hiddenSelect: any[] = [{ name: 'Show', value: true }, { name: 'Hide', value: false }];
    public radioSelect: any[] = [{ name: 'Selected', value: true }, { name: 'Unselected', value: false }];
    @ViewChild('container') container?: ElementRef
    @ViewChildren('select') selectsList? :QueryList<any>;
   // @ViewChildren('select') selects?:QueryList<any>;
    public select: Array<any> = []
     public clicked: any = ''
      constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConditionBuilderInterface
    ) { }

    ngOnInit(): void { 
         this.conditions = (() => {
            let arr = [];
            for (let i of this.data.conditions)
                arr.push(ConditionGroup.create(i));
            return arr;
        })();
        this.hidden = this.data.hidden ? this.hiddenSelect[0] : this.hiddenSelect[1];
        this.conditionable = this.data.conditionable;
        for (let g of this.conditions) {
            for (let i of this.conditionable)
                if (i.object.id == g.targetId) {
                    g.target = i.object;
                    break;
                }
            g.answerList = [];
            switch (g.target?.custom) {
                case 3:
                    for (let c of ProductCategory.latest)
                        g.answerList.push({ name: c.name, object: c });
                    break;
                case 4:
                    for (let m of Market.latest)
                        g.answerList.push({ name: m.name, object: m });
                    break;
                case 5:
                    for (let t of ProductTarget.latest)
                        g.answerList.push({ name: t.name, object: t });
                    break;
                case 6:
                    for (let s of RIStrategy.latest)
                        g.answerList.push({ name: s.name, object: s });
                    break;
                default:
                    for (let [k, v] of g.target!.fields.entries())
                        if (['radio', 'check', 'slider-numeric', 'slider-percent'].includes(v.type))
                            g.answerList.push({ name: v.label ?? `${v.typeLabel()} ${k}`, object: v });
            }
            for (let c of g.conditions)
                c.field = (() => { for (let f of g.target?.fields ?? []) if (f.id == c.fieldId) return f; return undefined; })();
        } 
                 

    }

    public addGroup() {
        let g = new ConditionGroup();
        g.conditions.push(new Condition());
        this.conditions.push(g);
    }
    public removeGroup(index: number) {
        this.conditions.splice(index, 1);
    }
    public addField(group: ConditionGroup) {
        group.conditions.push(new Condition());
    }
    public removeField(group: ConditionGroup, index: number) {
        group.conditions.splice(index, 1);
    }
    public setQuestion(group: ConditionGroup, question: FormQuestion) {
        group.target = question;
        group.targetId = question.id;
        group.targetKey = question.key;
        group.answerList = [];
        switch (question.custom) {
            case 3:
                for (let c of ProductCategory.latest)
                    group.answerList.push({ name: c.name, object: c });
                break;
            case 4:
                for (let m of Market.latest)
                    group.answerList.push({ name: m.name, object: m });
                break;
            case 5:
                for (let t of ProductTarget.latest)
                    group.answerList.push({ name: t.name, object: t });
                break;
            case 6:
                for (let s of RIStrategy.latest)
                    group.answerList.push({ name: s.name, object: s });
                break;
            default:
                for (let [k, v] of question.fields.entries())
                    if (['radio', 'check', 'slider-numeric', 'slider-percent'].includes(v.type))
                        group.answerList.push({ name: v.label || `${v.typeLabel()} ${k}`, object: v });
        }
    }

    public setCondition(condition: Condition, value: any, type: string) {
        console.log(type);
        if (['radio', 'check', 'custom'].includes(type)) {
            condition.condition = value ? 'true' : 'false';
            console.log(condition.condition);
        } else {
            condition.condition = `${value[0]}:${value[1]}`;
        }
    }
    public setAnswer(condition: Condition, answer: FormField) {
        if (condition.field?.type != answer.type)
            condition.condition = 'false';
        condition.field = answer;
        condition.fieldId = answer.id;
        condition.fieldKey = answer.key;
    }
    public setCustom(condition: Condition, id: number) {
        condition.fieldId = 0;
        condition.fieldKey = '';
        condition.customId = id;
    }

    public getInitialConditionable(group: ConditionGroup) {
        for (let c of this.conditionable) {
            if (c.object.id == group.targetId)
                return c;
        }
        return { name: 'None' };
    }
    public getInitialAnswer(group: ConditionGroup, condition: Condition) {
        for (let a of group.answerList)
            if ((group.target?.custom ?? 0) > 2) {
                if (a.object.id == condition.customId) return a;
            } else
                if (a.object.id == condition.fieldId) return a;
    } 
    public reset() {
        this.conditions = [];
    }

    public debug() {
        console.log(this);
    }
    
    public scroll() { 
          if(this.clicked.path !== undefined){ 
             this.clicked.path[2].scrollIntoView({behavior: "smooth", block: "center"})
            }else if(this.clicked.target !== undefined){
                 this.clicked.target.scrollIntoView({behavior: "smooth" ,block: "center"})
            }else if(this.clicked) {
                 try{this.clicked.scrollIntoView({behavior: "smooth", block: "center"})}
                 catch(err){
                    // console.log(err)
                 }
            }
      }

    public setClick(e :any){
          if(e) this.clicked = e
     }
     setNewSelect(){
    if(this.selectsList !== undefined && this.selectsList?.toArray()[this.selectsList?.toArray().length - 1])  this.clicked = this.selectsList?.toArray()[this.selectsList?.toArray().length - 1].eRef.nativeElement
     }
}
