import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirm',
    templateUrl: './delete-confirm.component.html',
    styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent implements OnInit {
    public input:string = '';

    constructor(
        private ref:MatDialogRef<DeleteConfirmComponent>
    ){}

    ngOnInit():void{}

    public close(){
        if(this.input.toLowerCase()==='delete') this.ref.close(true);
    }
}
