<div class="single_question" [ngClass]="{'expanded': isExpanded}">
    <h5>Question {{question.tag}}</h5>
    <div class="row a_center j_space" style="cursor: pointer;" (click)="isExpanded = !isExpanded">
        <div class="question">{{question.question}}</div>
        <svg-icon src="../../../../assets/svg/arrow_top.svg"></svg-icon>
    </div>
    <div class="answers">
        <ng-container *ngIf="question.custom<3">
            <div class='fields' *ngIf="question.type!=='order'">
                <mat-radio-group (change)="setRadio($event.value)">
                    <ng-container *ngFor="let o of question.fields">
                        <div class="field_container" [ngClass]="{'single-line':
                            o.type=='single-line','multi-line':
                            o.type=='multi-line','numeric': o.type=='numeric',
                            'radio': o.type=='radio', 'check': 
                            o.type=='check','slider-tick':
                            o.type=='slider-tick','slider-numeric':
                            o.type=='slider-numeric','slider-percent':
                            o.type=='slider-percent','url':o.type=='url','date':
                            o.type==='date','file': o.type=='file','order':
                            o.type=='order'}">
                            <ng-container *ngIf="o.type==='single-line'">
                                <app-text-field [isAutoSize]="true" [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-text-field>
                            </ng-container>
                            <ng-container *ngIf="o.type==='multi-line'">
                                <!-- <app-text-field class="multi-line" [multiline]="true" [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-text-field> -->
                                <app-large-text-field [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-large-text-field>
                            </ng-container>
                            <ng-container *ngIf="o.type==='numeric'">
                                <app-number-input [placeholderText]="o.label" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-number-input>
                            </ng-container>
                            <ng-container *ngIf="o.type==='radio'">
                                <app-radio-button-group class="radio" [singular]="true" [label]="o.label" [value]="o.id" [checked]="o.id==question.default?.value?.radio"></app-radio-button-group>
                            </ng-container>
                            <ng-container *ngIf="o.type==='check'">
                                <app-tick-box [text]="o.label" [value]="getAnswer(o.id)" (valueChange)="setAnswer(o.id, $event)"></app-tick-box>
                            </ng-container>
                            <ng-container *ngIf="o.type==='slider-tick'">
                                <ng-container>
                                    <app-tick-box #tick [text]="o.label" [value]="getAnswer(o.id)?true:false" (valueChange)="setAnswer(o.id,
                                        ($event?o.rangeStart:$event))"></app-tick-box>
                                    <app-slider class="slider-tick-slider" *ngIf="tick.value" [min]="o.rangeStart" [max]="o.rangeEnd" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-slider>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="o.type==='slider-numeric'">
                                <app-slider [min]="o.rangeStart" [max]="o.rangeEnd" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-slider>
                            </ng-container>
                            <ng-container *ngIf="o.type==='slider-percent'">
                                <app-slider [min]="o.rangeStart" [max]="o.rangeEnd" [percentage]="true" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-slider>
                            </ng-container>
                            <ng-container *ngIf="o.type==='url'">
                                <app-url-field placeholderText="URL" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-url-field>
                                <!-- <app-text-field placeholderText="URL" [model]="getAnswer(o.id)" (modelChange)="setAnswer(o.id, $event)"></app-text-field> -->
                            </ng-container>
                            <ng-container *ngIf="o.type==='date'">
                                <app-form-builder-calendar (date)="setDateAnswer(o.id, $event)" [input]="getDateAnswer(o.id)"></app-form-builder-calendar>
                            </ng-container>
                            <ng-container *ngIf="o.type==='file'">
                                <app-drag-drop [fileList]="files[o.id]" [types]="o.filetypes||''" (fileEvent)="uploadFile($event, o.id)" (openModal)="openUploader(o.id)"></app-drag-drop>
                            </ng-container>
                        </div>
                    </ng-container>
                </mat-radio-group>
            </div>
            <div class='fields' *ngIf="question.type==='order'">
                <div class='field_container item' *ngFor="let id of
                    question.answer!.value.order; let i= index">
                    <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="i==0" style='transform:rotate(180deg)' (click)="moveOrderUp(i)"></app-button-secondary>
                    <app-button-secondary [isGreen]="false" [isHoverGreen]="true" [imgUrl]="'assets/svg/arrow.svg'" [disabled]="i==question.answer!.value.order!.length-1" (click)="moveOrderDown(i)"></app-button-secondary>
                    <span>{{question.getFieldById(id)?.label}}</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="question.custom>2">
            <div class='fields'>
                <div class="field_container check" *ngFor="let o of question.options">
                    <app-tick-box [text]="o.name" [value]="getAnswer(o.id)" (valueChange)="setAnswer(o.id, $event)"></app-tick-box>
                </div>
            </div>
        </ng-container>
    </div>
</div>