import { EventEmitter, Injectable } from "@angular/core";
import { NotificationsCall } from "../menu/notifications/notifications.component";
import { CoversationsCall } from "../menu/conversations/conversations.component";
import { VideoModalCall } from "../partials/video-modal/video-modal.component";
import { NotificatorCall } from "../partials/notificator/notificator.component";
import { PopUpsCall } from "../pop_ups/pop-ups.component";
/**
 * *    Partials service
 * ?    The service has multiple event emitters, to which the partial components are subscribed.
 * ?    In order to use a partial you have to use emit method with appropriate parameters on the
 * ?    event emitter of the partial you want to use.
 */

@Injectable({
    providedIn:'root'
})
export class PartialService{
    /**
     * Display a modal
     * @param {string} header Header title
     * @param {string} message Body message displayed by the modal
     * @param {string} type Modal type
     * @param {boolean} destructive Is modal confirming deletion of a resource? Only really adds "red" class to the confirmation button
     * @param {CallableFunction} confirmCallback Function to be called if the user clicks "yes" or "ok" on the modal
     * @param {CallableFunction} cancelCallback Function to be called if the user clicks "no" on the modal
     */

    /**
     * Display a notification
     * @param {string} message Message to be displayed
     * @param {number} timeout Time in ms before the notification disappears. If null or 0, notification will not disappear automatically.
     * @param {enum} type Serverity of notification message
     * @param {string} details Text to be shown when clicking "More" button next to the message
     * @param {boolean} dismissable Whether the notification can be manually dismissed by clicking the close button, by default it's false unless timeout is also unset or set to null or 0
     */

    /**
     * Display a notifications/coversations/viewModal/popUps
     * @param {boolean} isActive Show/Hide content + overlay
     */

    /**
     * VideoModal
     * @param {string} videoUrl Pass video url to the video modal component
     */

    public notificator:EventEmitter<NotificatorCall>        = new EventEmitter<NotificatorCall>();
    public notifications:EventEmitter<NotificationsCall>    = new EventEmitter<NotificationsCall>();
    public conversations:EventEmitter<CoversationsCall>     = new EventEmitter<CoversationsCall>();
    public videoModal:EventEmitter<VideoModalCall>          = new EventEmitter<VideoModalCall>();
    public popUps:EventEmitter<PopUpsCall>                  = new EventEmitter<PopUpsCall>();
    public loader:EventEmitter<boolean>                     = new EventEmitter<boolean>();
}