export interface quiz_question_response{
    id:number;
    question:string;
    yes_text:string;
    no_text:string;
    order:number;
    answer:boolean;
}
export class QuizQuestion{
    public id:number = 0;
    public question:string = '';
    public yesText:string = '';
    public noText:string = '';
    public order:number = 0;
    public answer:boolean = true;

    public userAnswer?:boolean;

    public map(data:quiz_question_response){
        this.id = data.id;
        this.question = data.question;
        this.yesText = data.yes_text;
        this.noText = data.no_text;
        this.order = data.order;
        this.answer = data.answer;

        return this;
    }
    public responsify():quiz_question_response{
        return {
            id: this.id,
            question: this.question,
            yes_text: this.yesText,
            no_text: this.noText,
            order: this.order,
            answer: this.answer,
        }
    }
    public static create(data:quiz_question_response){
        let r = new this;
        r.map(data);
        return r;
    }
}