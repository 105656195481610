import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent implements OnInit {
  @Input() isSmall: boolean = false;
  @Input() fileUrl: string | any = '';
  @Input() name: string | any = 'download';
  @Input() isActive: boolean = true;
  @Input() fileType: string | any = 'PDF';
  @Input() fileSize: string = '2MB';
  @Input() isFullWidth: boolean = false;
  @Input() downloadable: boolean = true;

  //display name limit 
  public sufix: string = "...";
  public displayName: string = ''
  public type: string = 'pdf'
  public isMobile: boolean = false;
  // public isTablet: boolean = false
  // public isDesktopDevice: boolean = false
  //get name limited by characters

  //TODO dot recognize
  getName = () => {
    if (this.fileUrl.length > 0&&!this.name) {
      this.name = this.fileUrl.split('/');
      this.name = this.name[this.name.length - 1];
    }
    if (this.isMobile) {
      if (this.name.length < 15) {
        this.sufix = '';
      }
      if (this.name.split("").splice(0, 14).join("").includes(".")) this.rmDot(this.name.split("").splice(0, 14).join(""))
      else this.name = this.name.split("").splice(0, 14).join("") + this.sufix;
    } else {
      if (this.name.length < 35) {
        this.sufix = '';
      }
      if (this.name.split("").splice(0, 34).join("").includes(".")) this.rmDot(this.name.split("").splice(0, 34).join(""))
      else this.name = this.name.split("").splice(0, 34).join("") + this.sufix;
    }
  }
  constructor(private deviceService: DeviceDetectorService) { this.checkDevice() }
  rmDot = (val: string) => {
    this.name = val.split(".")[0] + this.sufix;
  }

  ngOnInit(): void {
    this.getName()
  }


  checkDevice = () => {
    this.isMobile = this.deviceService.isMobile();

  }
}
