import { Keyed } from "./Form";
import { FormField, form_field_response } from "./FormField";
import { SectionConditionGroup, section_condition_group_response } from "./SectionConditionGroup";

export interface section_condition_response{
    id:number;
    condition:string;
    field_id?:number;
    group_id:number;
    custom_id?:number;
    field?:form_field_response;
    group?:section_condition_group_response;

    field_key?:string;
    key?:string;
}
export class SectionCondition extends Keyed{
    public id:number = 0;
    public condition:string = '';

    public fieldId?:number;
    public groupId:number = 0;
    public customId?:number;

    public field?:FormField;
    public group?:SectionConditionGroup;

    public fieldKey:string = '';

    public map(data:section_condition_response){
        this.id = data.id;
        this.condition = data.condition;

        this.fieldId = data.field_id;
        this.groupId = data.group_id;
        this.customId = data.custom_id;

        if(data.field) this.field = FormField.create(data.field);
        if(data.group) this.group = SectionConditionGroup.create(data.group);

        return this;
    }
    public responsify():section_condition_response{
        return {
            id: this.id,
            condition: this.condition,
            field_id: this.fieldId,
            group_id: this.groupId,
            custom_id: this.customId,
            field: this.field?.responsify(),

            field_key:this.fieldKey,
            key:this.key,
        }
    }
    public static create(data:any){
        let r = new this;
        r.map(data);
        return r;
    }

    public get rangeStart(){
        if(this.condition=='') this.condition = '0:10';
        let a = this.condition.split(':');
        return Number(a[0]);
    }
    public set rangeStart(n:number){
        if(this.condition=='') this.condition = '0:10';
        let a = this.condition.split(':');
        a[0] = String(n);
        if(isNaN(Number(a[1]))||a[1]==null) a[1] = "10";
        this.condition = `${a[0]}:${a[1]}`;
    }
    public get rangeEnd(){
        if(this.condition=='') this.condition = '0:10';
        let a = this.condition.split(':');
        return Number(a[1]);
    }
    public set rangeEnd(n:number){
        if(this.condition=='') this.condition = '0:10';
        let a = this.condition.split(':');
        a[1] = String(n);
        if(isNaN(Number(a[0]))) a[0] = "0";
        this.condition = `${a[0]}:${a[1]}`;
    }
}