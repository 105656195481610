import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss']
})
export class NotificationOverlayComponent implements OnInit {
  @Input() username: string = 'Username';
  @Input() avatarUrl: string = '';
  @Input() head: string = "notification"
  @Input() name: string = "Altius Sustainable Bond Fund"
  @Input() time: Date = new Date();//"2 days ago";
  @Input() newMessage: boolean = true
  @Input() isToogle: boolean = false
  public isRadioTrue: boolean = false;
  @Input() clearable: boolean = false;
  public clearLeftAnim: boolean = false;
  public clearRightAnim: boolean = false;
  @Output() onClear = new EventEmitter<void>();
  @ViewChild('notifElement') private notifElement: ElementRef | undefined;

  constructor(private deviceService: DeviceDetectorService) { this.checkDevice() }

  //display name limit
  public sufix: string = "..."



  public isMobile: boolean = false

  //get name limited by characters
  getName = () => {
    if (this.isMobile) {
      return this.name.split("").splice(0, 19).join("") + this.sufix
    } else {
      return this.name.split("").splice(0, 29).join("") + this.sufix
    }
  }

  public getUsernameFirtsLetter() {
    return this.name.slice(0, 1);
  }

  ngOnInit(): void {
  }

  checkDevice = () => {
    this.isMobile = this.deviceService.isMobile();
    // this.isTablet = this.deviceService.isTablet();
    // this.isDesktopDevice = this.deviceService.isDesktop();
  }

  public clearSwipe(event: any) {
    if(event.direction == 4 || event.direction == 2){
    if (window.innerWidth < 1025) {
      const x = Math.abs(event.deltaX) > 40 ? (event.deltaX > 0 ? "Right" : "Left") : "";
      if (x == "Left") {
        this.clearLeftAnim = true;
        this.removeElem();
      }
      if (x == "Right") {
        this.clearRightAnim = true;
        this.removeElem();
      }
    }
  } 
  }

  public clearClick(e: any) {
    this.clearRightAnim = true;
    this.onClear.emit();
    //this.removeElem();
  }

  public removeElem() {
    setTimeout(() => {
      this.notifElement?.nativeElement.remove();
    }, 301)
  }

  public elapsed(){
    if(!this.time) return '';
    let now = new Date().getTime(),
        then = this.time.getTime();
    let diff = (now - then)/1000/60/60; //? Difference in hours
    if(diff<1) return `<1 Hour ago`;
    else if(diff==1) return `1 Hour ago`;
    else if(diff>=2&&diff<24) return `${Math.floor(diff)} Hours ago`;
    else if(diff>=24&&diff<168) return `${Math.floor(diff/24)} Days ago`;
    else if(diff>=168&&diff<720) return `${Math.floor(diff/24/7)} Weeks ago`;
    else return `${Math.floor(diff/24/30)} Months ago`;
  }

}
