import { ApplicationAnswer, application_answer_response } from "./ApplicationAnswer";
import { AssessmentAnswer, assessment_answer_response } from "./AssessmentAnswer";
import { ConditionGroup, condition_group_response } from "./ConditionGroup";
import { ConversationMessage, conversation_message_response } from "./ConversationMessage";
import { CustomIntegration, Market, ProductCategory, ProductTarget, RIStrategy } from "./CustomIntegration";
import { DefaultAnswer } from "./DefaultAnswer";
import { Keyed } from "./Form";
import { FormField, form_field_response } from "./FormField";
import { FormQuestionFront, form_question_front_response } from "./FormQuestionFront";
import { FormSection, form_section_response } from "./FormSection";
import { SectionBundle } from "./FormSectionBundle";

export interface form_question_response{
    id:number;
    question:string;
    subheading?:string;
    hid:boolean;
    order:number;
    type?:string;
    update_timeframe:number;
    viewable_data:boolean;
    ivp:boolean;
    starred:boolean;
    defaultable:boolean;
    category:string;
    custom:number;
    hint?:string;

    tag?:string;

    parent_id?:number;
    section_id?:number;

    section?:form_section_response;
    parent?:form_question_response;
    answer?:application_answer_response|assessment_answer_response;

    subquestions?:form_question_response[];
    fields?:form_field_response[];
    conditions?:condition_group_response[];
    fronts?:form_question_front_response[];
    options?:CustomIntegration[];

    last3_messages?:conversation_message_response[];

    key?:string;
}
export class FormQuestion extends Keyed{
    public id:number = 0;
    public question:string = '';
    public subheading:string = '';
    public hidden:boolean = false;
    public order:number = 0;
    public type?:string;
    public updateTimeframe:number = 0;
    public viewableData:boolean = false;
    public ivp:boolean = false;
    public starred:boolean = false;
    public defaultable:boolean = false;
    public category:string = '';
    public custom:number = 0;
    public hint:string = '';

    public parentId?:number;
    public sectionId?:number;

    public section?:FormSection;
    public parent?:FormQuestion;
    public answer?:ApplicationAnswer|AssessmentAnswer;
    public default?:DefaultAnswer;

    public subquestions:FormQuestion[] = [];
    public fields:FormField[] = [];
    public conditions:ConditionGroup[] = [];
    public fronts:FormQuestionFront[] = [];
    public options:CustomIntegration[] = [];

    public lastMessages:ConversationMessage[] = [];

    public answerCount:{ [key:string]:number, total:number } = { total: 0 };

    public tag:string = '';

    public appearing:boolean = true;

    public map(data:form_question_response){
        this.id = data.id;
        this.question = data.question;
        this.subheading = data.subheading??'';
        this.hidden = data.hid;
        this.order = data.order;
        this.type = data.type;
        this.updateTimeframe = data.update_timeframe;
        this.viewableData = data.viewable_data;
        this.ivp = data.ivp;
        this.starred = data.starred;
        this.defaultable = data.defaultable;
        this.category = data.category;
        this.custom = data.custom;
        this.hint = data.hint??'';

        if(data.tag) this.tag = data.tag;

        this.parentId = data.parent_id;
        this.sectionId = data.section_id;

        if(data.parent) this.parent = FormQuestion.create(data.parent);
        if(data.section) this.section = FormSection.create(data.section);
        if(data.answer) this.answer = ApplicationAnswer.create(data.answer as application_answer_response);

        this.subquestions = [];
        for(let q of data.subquestions??[])
            this.subquestions.push(FormQuestion.create(q));

        this.fields = [];
        for(let f of data.fields??[])
            this.fields.push(FormField.create(f));

        this.conditions = [];
        for(let c of data.conditions??[])
            this.conditions.push(ConditionGroup.create(c));

        this.fronts = [];
        for(let f of data.fronts??[])
            this.fronts.push(FormQuestionFront.create(f));

        this.lastMessages = [];
        for(let m of data.last3_messages??[])
            this.lastMessages.push(ConversationMessage.create(m));

        this.options = [];
        for(let c of data.options??[])
            this.options.push(c);

        this.fields.sort((a,b) => { return a.order>b.order?1:-1 });
        this.subquestions.sort((a,b) => { return a.order>b.order?1:-1 });

        return this;
    }
    public responsify():form_question_response{
        return {
            id: this.id,
            question: this.question,
            subheading: this.subheading,
            hid: this.hidden,
            order: this.order,
            type: this.type,
            update_timeframe: this.updateTimeframe,
            viewable_data: this.viewableData,
            ivp: this.ivp,
            starred: this.starred,
            defaultable: this.defaultable,
            category: this.category,
            parent_id: this.parentId,
            section_id: this.sectionId,
            custom: this.custom,
            hint: this.hint,

            answer: this.answer?.responsify(),

            subquestions: (() => {
                let arr:form_question_response[] = [];
                for(let q of this.subquestions) arr.push(q.responsify());
                return arr;
            })(),
            fields:(() => {
                let arr:form_field_response[] = [];
                for(let f of this.fields) arr.push(f.responsify());
                return arr;
            })(),
            conditions:(() => {
                let arr:condition_group_response[] = [];
                for(let c of this.conditions) arr.push(c.responsify());
                return arr;
            })(),
            fronts:(() => {
                let arr:form_question_front_response[] = [];
                for(let f of this.fronts) arr.push(f.responsify());
                return arr;
            })(),

            key:this.key,
        }
    }
    public static create(data:form_question_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public fixSubquestionOrdinals(){
        for(let [i, q] of this.subquestions.entries())
            q.order = i;
    }
    public fixFieldOrdinals(){
        for(let [i, f] of this.fields.entries())
            f.order = i;
    }
    public getFieldById(id:number){
        for(let i of this.fields)
            if(i.id==id) return i;
        return null;
    }
    public getFieldByKey(key:string){
        for(let i of this.fields)
            if(i.key==key) return i;
        return null;
    }

    public get conditionable():boolean{
        //if(!this.id) return false;
        if(this.custom>2) return true;
        for(let f of this.fields)
            if(['check','radio','slider-numeric','slider-percent'].includes(f.type)) return true;
        return false;
    }
    public filled():boolean{
        if(this.answer){
            if(this.answer.value.radio) return true;
            if(this.answer.value.custom?.length) return true;
            if(this.answer.value.order?.length) return true;
            for(let [i, a] of Object.entries(this.answer.value))
                if(a) return true;
        }
        return false;
    }

    public confirmConditions(form:SectionBundle){
        if(!this.conditions.length){ 
            this.appearing = true;
            return;
        }
        let hasValidCondi = false, 
            confirmed = false;

        for(let c of this.conditions){
            if(!c.conditions.length) continue;

            // Get target question
            if(c.targetId)
                c.target = form.getQuestionById(c.targetId)??undefined;
            else continue;

            if(c.target){
                let final = true,
                    radioFinal = undefined,
                    answer = c.target.answer;
                for(let r of c.conditions){
                    if(!final) break;

                    if(c.target.custom<3){
                        // Get target field
                        if(r.fieldId)
                            r.field = c.target.getFieldById(r.fieldId)??undefined;
                        else continue;

                        if(r.field){
                            switch(r.field.type){
                                case 'radio':
                                    hasValidCondi = true;
                                    if(radioFinal) continue;        // Radio conditions work on OR. If one is confirmed, no need to verify the rest.
                                    if(radioFinal===undefined) radioFinal = false;
                                    if(!answer||!c.target.appearing) radioFinal = r.condition==='false';
                                    else if(answer.value.radio===r.fieldId) radioFinal = r.condition==='true';
                                    break;
                                case 'check':
                                    hasValidCondi = true;
                                    if(!answer||!c.target.appearing) final = r.condition==='false';
                                    else{
                                        if(answer.value[r.fieldId]) final = r.condition==='true';
                                        else final = r.condition==='false';
                                    }
                                    break;
                                case 'slider-numeric':
                                case 'slider-percent':
                                    hasValidCondi = true;
                                    if(!answer||!c.target.appearing) final = r.rangeStart<=r.field.rangeStart;
                                    else {
                                        if(answer.value[r.fieldId]) final = (answer.value[r.fieldId]>=r.rangeStart&&answer.value[r.fieldId]<=r.rangeEnd);
                                        else final = r.rangeStart<=r.field.rangeStart;
                                    }
                                    break;
                                default:
                                    continue;
                            }
                        } else continue;
                    } else {
                        if(r.customId){
                            hasValidCondi = true;
                            if(!answer||!c.target.appearing) final = r.condition==='false';
                            else {
                                if(answer.value[r.customId]) final = r.condition==='true';
                                else final = r.condition==='false';
                            }
                        } else continue;
                    }
                }
                if(final&&(radioFinal===undefined||radioFinal===true)) confirmed = true;
            } else continue;
        }

        if(hasValidCondi){ 
            if(confirmed) this.appearing = this.hidden;
            else this.appearing = !this.hidden;
        } else this.appearing = true;
    }
}