<div class="content">
    <div class="member_tab add_member" *ngIf="isLoaded">
        <h2 [class.small]="isEdit">{{isEdit?'Edit':'Add new'}} {{isMember?'member':'collaborator'}}</h2>
        <h2 *ngIf="isEdit">{{user.fullName}}</h2>
        <app-text-field [isAutoSize]="true" [placeholderText]="'First name'" [(model)]="user.name"></app-text-field>
        <app-text-field [isAutoSize]="true" [placeholderText]="'Last name'" [(model)]="user.lname"></app-text-field>
        <app-text-field [isAutoSize]="true" [placeholderText]="'Email'" [(model)]="user.email"></app-text-field>
        <app-text-field [placeholderText]="'Phone number'" [(model)]="user.phone"></app-text-field>
        <app-text-field [isAutoSize]="true" [placeholderText]="'Job title'" *ngIf="isMember" [model]="user.jobTitle||''" (modelChange)="user.jobTitle=$event"></app-text-field>
        <app-form-select [placeholderText]="'Job function'" *ngIf="isMember" [list]="jobFunctions" displayProperty="name" [isMulitSelect]="false" [model]="user.jobFunction?{ name: user.jobFunction }:undefined" (modelChange)="user.jobFunction = $event.name"></app-form-select>
        <app-form-select [placeholderText]="'Select member'" *ngIf="!isMember&&isAdmin" [list]="members" displayProperty="fullName" [model]="user.parent" (modelChange)="user.parent=$event; user.parentId=$event.id" [isMulitSelect]="false"></app-form-select>
        <!-- <app-tick-box [text]="'New provider'"></app-tick-box>
        <app-text-field [placeholderText]="'Provider name'"></app-text-field> -->
        <app-button-primary [text]="isEdit?'Save':('Add '+(isMember?'member':'collaborator'))" (click)="send()"></app-button-primary>
        <div *ngIf="isEdit" class="editing member">
            <h3>Delete your {{isMember?'member':'collaborator'}}</h3>
            <app-input-field type="password" [placeholderText]="'Enter password'" [(model)]="deleteInputs.password"></app-input-field>
            <ng-container *ngIf="isMember">
                <div class="column">
                    <app-tick-box [text]="'Move providers to another member'" [(value)]="deleteInputs.moveProviders"></app-tick-box>
                    <app-tick-box [text]="'Move products to another member'" [(value)]="deleteInputs.moveProducts"></app-tick-box>
                    <app-tick-box [text]="'Move collaborators to another member'" [(value)]="deleteInputs.moveCollaborators"></app-tick-box>
                </div>
                <app-form-select [placeholderText]="'Select member'" [list]="members" [isMulitSelect]="false" (modelChange)="deleteInputs.moveTo=$event.id" displayProperty="fullName"></app-form-select>
            </ng-container>
            <app-button-primary [text]="'Delete '+(isMember?'member':'collaborator')" [isActive]="deleteInputs.password.length>0" (click)="del()"></app-button-primary>
        </div>
    </div>
</div>