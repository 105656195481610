import { Component, Input, OnInit } from '@angular/core';
import { FormBuilderComponent } from 'src/app/form-builder/form-builder/form-builder.component';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { ResourceFAQ } from 'src/app/model/responsible/ResourceFAQ';
import { SupportFAQ } from 'src/app/model/responsible/SupportFAQ';


@Component({
    selector: 'app-faq-questions',
    templateUrl: './faq-questions.component.html',
    styleUrls: ['../../form-builder/support-content/support-content.component.scss', './faq-questions.component.scss']
})
export class FaqQuestionsComponent implements OnInit {
    @Input() public section: FormSection = new FormSection();
    @Input() title: string = 'FAQ';
    @Input() public desc: string = '';
    @Input() public type: 'resources' | 'support' = 'support';
    @Input() public faq: ResourceFAQ[] = [];

    constructor(){}

    ngOnInit():void{
 
    }

    // FAQ
    public addNewFAQ() {
        if(this.type==='support')
            this.section.supportFAQs.push(new SupportFAQ());
        else   
            this.faq.push(new ResourceFAQ());
    }
    public deleteFAQ(index: number) {
        if(this.type==='support'){
            this.section.supportFAQs.splice(index, 1);
            FormBuilderComponent.changed = true;
        } else
            this.faq.splice(index, 1);
    }
    public moveFAQUp(index: number) {
        if(this.type==='support'){
            let item = this.section.supportFAQs.splice(index, 1);
            this.section.supportFAQs.splice(index - 1, 0, item[0]);
            FormBuilderComponent.changed = true;
        } else {
            let item = this.faq.splice(index, 1);
            this.faq.splice(index - 1, 0, item[0]);
        }
    }
    public moveFAQDown(index: number) {
        if(this.type==='support'){
            let item = this.section.supportFAQs.splice(index, 1);
            this.section.supportFAQs.splice(index + 1, 0, item[0]);
            FormBuilderComponent.changed = true;
        } else {
            let item = this.faq.splice(index, 1);
            this.faq.splice(index + 1, 0, item[0]);
        }
    }
    public changeMade() {
        FormBuilderComponent.changed = true;
    }
}
