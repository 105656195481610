import { Component, OnInit, Input, Output, EventEmitter, DoCheck, ViewChild, ElementRef, HostListener, AfterContentInit } from '@angular/core';
import { FormControl } from '@angular/forms'
@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss']
})
export class NumberFieldComponent implements OnInit {
  //TODO delete ngModel, use only form Control
  constructor() { }
  @Input() public model: number = 0;
  @Input() public isError: boolean = false;
  @Input() public errorText: string = '';
  @Input() public placeholderText: string = '';
  @Input() public min?: number;
  @Input() public max?: number;
  // @Input() public isDisabled: boolean = false;
  @Input() public isReadOnly: boolean = false;
  @Input() public small: boolean = false;
  @Input() public sufix: string = ''
  @Input() pattern: string = '^\[-]?[0-9]*$'
  @Output() public modelChange = new EventEmitter<number>();
  @ViewChild('input') input!: ElementRef;
  private previousValue = this.model

  val = new FormControl({value: this.model});
  
  public isFocused: boolean = false
  public isAccepted: boolean = false
  public isEmpty: boolean = true

  public displayVal: string | number= ''

  public listener(){
      this.val.valueChanges.subscribe(data =>{
       if(data.toString().charAt(0) == '[')
       this.onValChange(this.previousValue)
       else this.onValChange(data)
      })
  }
  onBlur() {
    this.minMaxVal()
    if (this.isEmpty) {
      this.isFocused = false;
    } else {
      this.isAccepted = true
    }
  }
  ngAfterContentInit(): void {
    if (this.model.toString() == '') this.modelChange.emit(0)
    else this.modelChange.emit(Number(this.model.toString()))
  }
  ngOnInit(): void {
     this.listener()
    this.displayVal = this.model.toString()
    if(isNaN(this.model))this.val.setValue(0) 
    else if(this.val.value.toString().charAt(0) == '[')this.val.setValue(this.model) 
  }
  minMaxVal() {
    if (this.max !== undefined && Number(this.val.value.toString()) > this.max) {
      this.val.setValue(this.max)
      this.previousValue = this.max
      this.displayVal =  Number(this.max.toString())
    }
    if (this.min !== undefined && Number(this.val.value.toString()) < this.min) {
      this.previousValue = this.min
      this.val.setValue(this.min)
      this.displayVal =  Number(this.min.toString())
    }
  }
  // ngDoCheck(): void {
  //  // if ( isNaN(Number(this.val.value.toString()))) this.val.setValue(this.previousValue) 
  // }
  onFocus(event: any) {
    if (event) {
      this.isFocused = true;
      this.isAccepted = false
     }
  }
  isInputEmpty(text: number) {
    if (!text && text != 0) {
      this.isEmpty = true
    } else {
      this.isEmpty = false
      this.isFocused = true
    }
  }

  getValue() {
    return this.displayVal
  }

  rmZero(val: string) {
    return Number(val.toString().slice(0))
  }

  public arrowStep(key: any) {
    if (key.keyCode == 38) {
      this.val.setValue(Number(this.val.value) + 1)
    }
    if (key.keyCode == 40) {
      this.val.setValue(Number(this.val.value) - 1)
    }
  }
  
  private onValChange(newVal: number | string){
     if(newVal.toString() == '') {
       this.displayVal = ''
        this.modelChange.emit(Number(undefined))
     }
     else {
      if (this.val.errors?.pattern) {
      this.displayVal = this.input.nativeElement.value = this.previousValue.toString()
      this.model = this.previousValue
    }
      else {
        this.displayVal = newVal
        this.previousValue = Number(newVal)
          if (this.input && newVal.toString().charAt(0) == "0" && newVal.toString().length >= 2) {
             this.displayVal = this.rmZero(newVal.toString())
              this.input.nativeElement.value = this.rmZero(this.input.nativeElement.value).toString()
             }
        this.modelChange.emit(Number(this.displayVal))
    }
    }   
   }
}
