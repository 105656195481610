import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EpEncryptionService {
  private readonly decryptPayload = environment.decryptPayload;

  private key: any;
  private iv: any;


  constructor() {
    this.key = crypto.enc.Hex.parse("0123456789abcdef0123456789abcdef");
    this.iv = crypto.enc.Hex.parse("abcdef9876543210abcdef9876543210");
  }

  public encrypt(data: any) {
    let paylod: string = JSON.stringify(data);
    let encrypted = crypto.AES.encrypt(paylod, this.key, { iv: this.iv });
    return encrypted;
  }

  public decrypt(encryptedText: string) {
    let bytes = crypto.AES.decrypt(encryptedText, this.key, { iv: this.iv });
    let plainText = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return plainText
  }

  public sendPayload(data: any) {
    if (this.decryptPayload) {
      let epEncryptionServiceToSendClass = new EpEncryptionServiceToSendClass();
      epEncryptionServiceToSendClass.payload = this.encrypt(data).toString();
      return epEncryptionServiceToSendClass;
    } else {
      let epEncryptionServiceToSendClass = new EpEncryptionServiceToSendClass();
      epEncryptionServiceToSendClass.payload = data;
      return epEncryptionServiceToSendClass;
    }

  }
}
export class EpEncryptionServiceToSendClass {
  public payload: any;
}
