<app-auth></app-auth>
<app-menu></app-menu>
<!-- <appLoader [showLoader]="showLoader" [showNotyfication]="showNotyfication"
  [notyficationMessage]="notyficationMessage" [notyficationMessageStateModel]="notyficationMessageStateModel">
</appLoader> -->
<button routerLink="/users">< Return</button>
<div>
    <label>Username:</label>
    <input [(ngModel)]="user.name">
</div>
<div>
    <label>Email:</label>
    <input [(ngModel)]="user.email">
</div>
<div>
    <label>Password:</label>
    <input [(ngModel)]="user.password" type="password">
</div>
<div>
    <label>Confirm password:</label>
    <input [(ngModel)]="user.passConfirm" type="password">
</div>
<button (click)="send()">Create</button>
<div>
    {{err}}
</div>