<div class="cell_placeholder row a_center">
    <span *ngIf="this.model.length == 0" (click)="activate()">{{cellName}}</span>
    <span class="search_phrase" (click)="activate()">{{this.model}}</span>
    <svg-icon *ngIf="this.model.length>0" style="cursor: pointer;" src="../../../assets/svg/close.svg" (click)="clear()"></svg-icon>
</div>
<div #searchContainer class="search_container" [style]="positionStyle">
    <div class="serach_wrap " *ngIf="enabled" [@filtersOutIn] (@filtersOutIn.done)="animFinish($event)">
        <input maxlength="33 " type="search " [ngClass]="{ 'active': model.length>0}" [ngModel]="getModel()" (ngModelChange)="change($event)" (keyup.enter)="onEnter.emit()" [placeholder]="placeholder">
        <button *ngIf="model.length>0" (click)="clear()">clear</button>
    </div>
</div>