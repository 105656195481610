import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  @Input() placeholderText: string = 'Choose'
  @Input() isActive: boolean = false;
  @Input() public values: string = ''

  @Output() clear = new EventEmitter()
  @Output() open = new EventEmitter()
  private limit: number = 5

  constructor() {
    this.limit = this.placeholderText.length
  }

  ngOnInit(): void {
  }
  public openList() {
    this.open.emit(true)
  }
  public clearList() {
    this.values = ''
    this.clear.emit(true)
    this.isActive = false
  }
}
