<div class="icon_header_ex">
    <app-button-icon [imgUrl]="'assets/svg/plus-icon.svg'" [mat-dialog-close]="false"> </app-button-icon>
</div>
<div class='container'>
    <h4>{{field.typeLabel()}}</h4>
    <h1>Responsible Returns Front-End</h1>
    <div class='list' *ngIf="!loading">
        <mat-radio-group [(ngModel)]="selected" class="column mat-rg">
            <mat-radio-button *ngFor="let exclusion of list" [value]="exclusion">
                <div class="square"></div><span>{{exclusion.name}}</span></mat-radio-button>
        </mat-radio-group>
        <!-- <div class='position' *ngFor="let exclusion of list ">
            <app-tick-box [value]="selected.includes(exclusion.id) " (valueChange)="toggleExclusion(exclusion) " text=" "></app-tick-box>
            <div class='icon'>
                []
            </div>
            <span>{{exclusion.name}}</span>
        </div> -->
    </div>
    <app-button-primary text="Save " [matDialogClose]="selected?.id"></app-button-primary>
    <app-button-secondary [isText]="true " buttonText="Close" [isGreen]="false" [matDialogClose]="false "></app-button-secondary>
</div>