import { User, user_response } from "../User";
import { Application, application_response } from "./Application";
import { Attachment, attachment_response } from "./Attachment";
import { Conversation, conversation_response } from "./Conversation";

export const NotificationType = {
    New: false,
    Ongoing: true,
}
export interface notification_response{
    id:number;
    user_id:number;
    product_id:number;
    commenter_id?:number;
    conversation_id?:number;
    type:boolean;
    title:string;
    desc:string;
    created_at?:string;

    user?:user_response;
    product?:application_response;
    commenter?:user_response;
    conversation?:conversation_response;
}
export class Notification{
    public id:number = 0;
    public userId:number = 0;
    public productId:number = 0;
    public commenterId?:number;
    public conversationId?:number;
    public type:boolean = NotificationType.New;
    public title:string = '';
    public desc:string = '';
    public createdAt:Date = new Date();

    public user?:User;
    public product?:Application;
    public commenter?:User;
    public conversation?:Conversation;

    public map(data:notification_response){
        this.id = data.id;
        this.userId = data.user_id;
        this.productId = data.product_id;
        this.conversationId = data.conversation_id;
        this.type = data.type;
        this.title = data.title;
        this.desc = data.desc;
        this.createdAt = data.created_at?new Date(data.created_at):new Date();

        this.user = data.user?User.create(data.user):undefined;
        this.product = data.product?Application.create(data.product):undefined;
        this.commenter = data.commenter?User.create(data.commenter):undefined;
        this.conversation = data.conversation?Conversation.create(data.conversation):undefined;

        return this;
    }
    public responsify():notification_response{
        return {
            id: this.id,
            user_id: this.userId,
            product_id: this.productId,
            commenter_id: this.commenterId,
            conversation_id: this.conversationId,
            type: this.type,
            title: this.title,
            desc: this.desc,
        }
    }
    public static create(data:notification_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public get imgUrl(){
        if(this.title==='New Message')
            return this.commenter?.avatarURL;
        else
            return this.product?.provider?.logo?.getUrl();
    }
}