import { AnswerHistory, answer_history_response } from "./AnswerHistory";
import { Application, application_response } from "./Application";
import { Attachment, attachment_response } from "./Attachment";
import { FormQuestion, form_question_response } from "./FormQuestion";

interface ValueMap{
    [key:number]:any,
    radio?:number,
    order?:number[],
    custom?:number[],
}

export interface application_answer_response{
    id:number;
    application_id:number;
    question_id:number;
    value:string;
    updated_at?:string;

    application?:application_response;
    question?:form_question_response;
    files?:attachment_response[];

    history?:answer_history_response[];
}
export class ApplicationAnswer{
    public id:number = 0;
    public applicationId:number = 0;
    public questionId:number = 0;
    public value:ValueMap = {};
    public updatedAt?:Date;
    
    public application?:Application;
    public question?:FormQuestion;
    public files:Attachment[] = [];

    public history:AnswerHistory[] = [];

    public map(data:application_answer_response){
        this.id = data.id;
        this.applicationId = data.application_id;
        this.questionId = data.question_id;
        this.value = JSON.parse(data.value);
        this.updatedAt = data.updated_at?new Date(data.updated_at):undefined;

        if(data.application) this.application = Application.create(data.application);
        if(data.question) this.question = FormQuestion.create(data.question);

        this.files = [];
        for(let f of data.files??[])
            this.files.push(Attachment.create(f));

        this.history = data.history?.map(h => AnswerHistory.create(h))??[];
        this.history = this.history.slice(0, 4);

        return this;
    }
    public responsify():application_answer_response{
        return {
            id:this.id,
            application_id:this.applicationId,
            question_id:this.questionId,
            value:JSON.stringify(this.value),

            files:(()=>{
                let arr:attachment_response[] = [];
                for(let f of this.files) arr.push(f.responsify());
                return arr;
            })()
        };
    }
    public static create(data:application_answer_response){
        let r = new this;
        r.map(data);
        return r;
    }

    public getFileById(id:number){
        for(let f of this.files){
            if(f.id == id) return f;
        }
        return null;
    }
}