import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { ConditionGroup } from 'src/app/model/responsible/ConditionGroup';
import { AssetClass, Market, ProductCategory, ProductTarget, RIStrategy } from 'src/app/model/responsible/CustomIntegration';
import { FormField } from 'src/app/model/responsible/FormField';
import { FormQuestion } from 'src/app/model/responsible/FormQuestion';
import { form_question_front_response } from 'src/app/model/responsible/FormQuestionFront';
import { FormSection } from 'src/app/model/responsible/FormSection';
import { CustomIntegrationAPI } from 'src/app/services/responsible/custom-integrations.service';
import { ConditionBuilderComponent } from '../../condition-builder/condition-builder.component';
import { FormBuilderComponent } from '../../form-builder/form-builder.component';
import { AddToFrontendComponent } from '../add-to-frontend/add-to-frontend.component';
import { DeleteConfirmComponent } from '../delete-confirm/delete-confirm.component';
import { MoveCategoryComponent } from '../move-category/move-category.component';
import { UpdateTimeframeComponent } from '../update-timeframe/update-timeframe.component';
import { BodyScrollService } from 'src/app/services/body-scroll.service';
import { SectionBundle } from 'src/app/model/responsible/FormSectionBundle';
@Component({
    selector: 'app-fb-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class FBQuestionComponent implements OnInit {
    @Input() public question: FormQuestion = new FormQuestion();
    @Input() public cantMoveUp: boolean = false;
    @Input() public cantMoveDown: boolean = false;
    @Input() public sub: number = 0;
    @Input() public section: FormSection = new FormSection();
    @Input() public form:SectionBundle = new SectionBundle();
    @Output() public moveUp = new EventEmitter<void>();
    @Output() public moveDown = new EventEmitter<void>();
    @Output() public deleted = new EventEmitter<void>();

    public insertingField: boolean = false;
    public collapsed: boolean = false;
    public adding: boolean = false;

    @Input() public options: Market[] | ProductCategory[] | ProductTarget[] | RIStrategy[] | AssetClass[] = [];

    public edited: boolean = false;

    constructor(
        private dialog: MatDialog,
        private integrationApi: CustomIntegrationAPI,
        public scroll:BodyScrollService
    ) { }

    ngOnInit(): void {
        this.question.tag = `${this.sub ? this.sub : this.question.order + 1}${this.sub ? this.ntl(this.question.order) : ''}`;
        //this.getIntegrationData();
    }

    private getIntegrationData() {
        switch (this.question.custom) {
            case 3: //? Product Categories
                this.integrationApi.productCategories.index().subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.options = [];
                    for (let c of response)
                        this.options.push(ProductCategory.create(c));
                });
                break;
            case 4: //? Markets
                this.integrationApi.markets.index().subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.options = [];
                    for (let m of response)
                        this.options.push(Market.create(m));
                });
                break;
            case 5: //? Product Targets
                this.integrationApi.productTargets.index().subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.options = [];
                    for (let t of response)
                        this.options.push(ProductTarget.create(t));
                });
                break;
            case 6: //? RI Strategies
                this.integrationApi.riStrategies.index().subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.options = [];
                    for (let s of response)
                        this.options.push(RIStrategy.create(s));
                });
                break;
            case 7:
                this.integrationApi.assetClasses.index().subscribe(res => {
                    let response = CodedResponseModel.decode(res);
                    this.options = response.map((c:AssetClass) => AssetClass.create(c));
                })
        }
    }

    public insertSubquestion(type: string) {
        if (type) {
            let q = new FormQuestion();
            q.order = this.question.subquestions.length;
            q.type = type;
            q.category = this.question.category;
            let f = new FormField();
            f.type = type;
            q.fields.push(f);
            this.question.subquestions.push(q);
        }
        this.adding = false;
    }
    public insertField(type?: string) {
        if (type) {
            let f = new FormField();
            f.type = type;
            this.question.fields.push(f);
            this.question.type = type;
            this.insertingField = false;
            this.changesMade();
        } else this.insertingField = false;
    }

    public addAnotherField(field: FormField) {
        let f = new FormField();
        f.type = field.type;
        this.question.fields.push(f);
        this.changesMade();
    }
    public deleteField(index: number) {
        this.question.fields.splice(index, 1);

        if (!this.question.fields.length) this.question.type = undefined;
        this.changesMade();
    }
    public fieldMoveUp(index: number) {
        let item = this.question.fields.splice(index, 1);
        this.question.fields.splice(index - 1, 0, item[0]);
        this.changesMade();
    }
    public fieldMoveDown(index: number) {
        let item = this.question.fields.splice(index, 1);
        this.question.fields.splice(index + 1, 0, item[0]);
        this.changesMade();
    }

    public subquestionMoveUp(index: number) {
        let item = this.question.subquestions.splice(index, 1);
        this.question.subquestions.splice(index - 1, 0, item[0]);
        this.question.fixSubquestionOrdinals();
        this.changesMade();
    }
    public subquestionMoveDown(index: number) {
        let item = this.question.subquestions.splice(index, 1);
        this.question.subquestions.splice(index + 1, 0, item[0]);
        this.question.fixSubquestionOrdinals();
        this.changesMade();
    }

    public optionMoveUp(index: number) {
        let item = this.options.splice(index, 1);
        this.options.splice(index - 1, 0, item[0]);
        this.changesMade();
    }
    public optionMoveDown(index: number) {
        let item = this.options.splice(index, 1);
        this.options.splice(index + 1, 0, item[0]);
        this.changesMade();
    }

    public openConditions() {
        let d = this.dialog.open(ConditionBuilderComponent, {
            data: {
                type: 'question',
                qIndex: (this.sub ? this.sub + this.ntl(this.question.order) : this.question.order + 1),
                conditions: (() => {
                    let arr = [];
                    for (let c of this.question.conditions)
                        arr.push(c.responsify());
                    return arr;
                })(),//this.question.conditions,
                conditionable: (() => {
                    let arr: any[] = [];
                    base: for (let i of this.section.questions) {
                        if (i.id==this.question.id) break;
                        if (i.conditionable) arr.push({ name: `Question ${i.order + 1}`, object: i });
                        for (let [j, k] of i.subquestions.entries()){
                            if (k.id==this.question.id) break base;
                            if (k.conditionable) arr.push({ name: `Question ${i.order + 1}${this.ntl(j)}`, object: k });
                        }
                    }
                    return arr;
                })(),
                hidden: this.question.hidden
            }, panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) {
                this.question.conditions = [];
                this.question.hidden = res.hidden;
                for (let i of res.condis as ConditionGroup[]){
                    i.target = undefined;
                    for(let c of i.conditions) c.field = undefined;
                    this.question.conditions.push(i);
                }
                this.changesMade();
            }
        })
    }
    public openTimeframe() {
        let d = this.dialog.open(UpdateTimeframeComponent, {
            data: {
                tag: (this.sub ? this.sub + this.ntl(this.question.order) : this.question.order + 1),
                curr: this.question.updateTimeframe
            }, panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res !== false) {
                this.question.updateTimeframe = res;
                this.changesMade();
            }
        })
    }
    public deleteQuestion() {
        let d = this.dialog.open(DeleteConfirmComponent, { panelClass: 'modal-green' });
        d.afterClosed().subscribe(res => {
            if (res) {
                this.deleted.emit();
            }
        })
    }
    public openFront() {
        let d = this.dialog.open(AddToFrontendComponent, {
            data: {
                tag: (this.sub ? this.sub + this.ntl(this.question.order) : this.question.order + 1),
                pages: (() => {
                    let arr: form_question_front_response[] = [];
                    for (let i of this.question.fronts) arr.push(i.responsify());
                    return arr;
                })(),
                types: (() => {
                    let arr:string[] = [];
                    for(let i of this.question.fields) if(!arr.includes(i.type)) arr.push(i.type);
                    return arr;
                })()
            }, panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res) {
                this.question.fronts = res;
                this.changesMade();
            }
        })
    }
    public moveCategory() {
        let d = this.dialog.open(MoveCategoryComponent, {
            data: {
                current: this.question.sectionId,
                sections: this.form.sections,
                qIndex: (this.sub ? this.sub + this.ntl(this.question.order) : this.question.order + 1),
            }, panelClass: 'modal-white'
        });
        d.afterClosed().subscribe(res => {
            if (res && res != this.question.sectionId) {
                this.question.sectionId = res;
                this.question.id = 0;
                this.question.conditions = [];
                let category:'questions'|'riaaQuestions'|'ivpQuestions' = (() => {
                    switch(this.question.category){
                        case 'application': return 'questions';
                        case 'riaa': return 'riaaQuestions';
                        case 'ivp': return 'ivpQuestions';
                        default: return 'questions';
                    }
                })();
                this.section[category].splice(this.section[category].indexOf(this.question), 1);
                for(let s of this.form.sections){
                    if(s.id==res){ 
                        s[category].push(this.question);
                        switch(category){
                            case 'questions':
                                this.section.fixQuestionOrdinals();
                                s.fixQuestionOrdinals();
                                break;
                            case 'riaaQuestions':
                                this.section.fixRIAAOrdinals();
                                s.fixRIAAOrdinals();
                                break;
                            case 'ivpQuestions':
                                this.section.fixIVPOrdinals();
                                s.fixIVPOrdinals();
                                break;
                        }
                        break;
                    }
                }
                this.changesMade();
            }
        })
    }

    public addOption() {
        this.edited = true;
        switch (this.question.custom) {
            case 3:
                this.options.push(new ProductCategory());
                break;
            case 4:
                this.options.push(new Market());
                break;
            case 5:
                this.options.push(new ProductTarget());
                break;
            case 6:
                this.options.push(new RIStrategy());
                break;
            case 7:
                this.options.push(new AssetClass());
                break;
            default:
                this.edited = false;
        }
    }
    public deleteOption(index: number) {
        this.options.splice(index, 1);
        this.edited = true;
    }
    public changesMade(){
        FormBuilderComponent.changed = true;
    }

    public ntl(n: number) { return String.fromCharCode(65 + n); }

    public deboog() { console.log(this); }

    public menuOpen(e: any){
        this.scroll.disableBodyScroll()
     }
    public menuClose(e: any){
        this.scroll.enableBodyScroll()
     }
}
