import { Application } from "./Application";
import { User } from '../User';
import { AssessmentAnswer, assessment_answer_response } from "./AssessmentAnswer";
import { Attachment, attachment_response } from "./Attachment";

export enum AssessmentType{
    RIAA,
    IVP
}

export interface assessment_response{
    id:number;
    type:boolean;
    application_id:number;
    condition?:number;
    score?:number;
    comments?:string;
    ivp_id?:number;
    stars?:number;
    procedures?:string;
    findings?:string;
    errors?:string;
    finished:boolean;
    cert_start?:string;
    no_notif?:boolean;

    answers?:assessment_answer_response[];
    attachments?:attachment_response[];
}
export class Assessment{
    public id:number = 0;
    public type:AssessmentType = AssessmentType.RIAA;
    public applicationId:number = 0;
    public condition?:number;
    public score?:number;
    public comments?:string;
    public ivpId?:number;
    public stars?:number;
    public procedures?:string;
    public findings?:string;
    public errors?:string;
    public finished:boolean = false;
    public certificationStart?: string;
    public noNotif: boolean = false;

    public application?:Application;
    public ivp?:User;

    public answers:AssessmentAnswer[] = [];
    public attachments:Attachment[] = [];

    public map(data:assessment_response){
        this.id = data.id;
        this.type = Number(data.type);
        this.applicationId = data.application_id;
        this.condition = data.condition;
        this.score = data.score;
        this.comments = data.comments;
        this.ivpId = data.ivp_id;
        this.stars = data.stars;
        this.procedures = data.procedures;
        this.findings = data.findings;
        this.errors = data.errors;
        this.finished = data.finished;

        this.answers = [];
        for(let a of data.answers??[])
            this.answers.push(AssessmentAnswer.create(a));

        this.attachments = [];
        for(let a of data.attachments??[])
            this.attachments.push(Attachment.create(a));

        return this;
    }
    public responsify():assessment_response{
        return {
            id: this.id,
            type: this.type==1,
            application_id: this.applicationId,
            condition: this.condition,
            score: this.score,
            comments: this.comments,
            ivp_id: this.ivp?.id??this.ivpId,
            stars: this.stars,
            procedures: this.procedures,
            findings: this.findings,
            errors: this.errors,
            finished: this.finished,
            cert_start: this.certificationStart,
            no_notif: this.noNotif,

            answers:(() => {
                let arr:assessment_answer_response[] = [];
                for(let a of this.answers) arr.push(a.responsify());
                return arr;
            })(),
            attachments:(() => {
                let arr:attachment_response[] = [];
                for(let a of this.attachments) arr.push(a.responsify());
                return arr;
            })()
        };
    }
    public static create(data:assessment_response){
        let r = new this;
        r.map(data);
        return r;
    }
}