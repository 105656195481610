import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BodyScrollService } from 'src/app/services/body-scroll.service';

import {OverlayComponent } from '../overlay/overlay.component'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filters-number',
  templateUrl: './filters-number.component.html',
  styleUrls: ['./filters-number.component.scss'],
  animations: [
    trigger(
      'filtersOutIn',
      [
        transition(
          ':enter',
          [
            style({ transform: 'scaley(0) ', opacity: 0 }),
            animate('0.3s ease-out',
              style({ transform: 'scaley(1)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'scaley(1)', opacity: 1 }),
            animate('0.3s ease-in',
              style({ transform: 'scaley(0)', opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class FiltersNumberComponent implements OnInit {
  @Input() number: number = 0
  @Input() filterName: string = 'number'
  @Input() isActive: boolean = false
  public positionStyle: string = ''
  @ViewChild('input') input: any
  @Input() widnowWidth?: number = 82

  public left: boolean = false
  public right: boolean = false
  @Output() change = new EventEmitter()
  @Output() clearNumber = new EventEmitter()
  constructor(public scroll:BodyScrollService) { }

  ngOnInit(): void {
    this.getOverlayState
  }
  ngOnDestroy(){
    this.getOverlayState.unsubscribe()
   }
  public getOverlayState: Subscription = OverlayComponent.clicked.subscribe((res)=>{
     this.isActive = false; 
    this.scroll.enableBodyScroll()  
   })

  private close(){
    OverlayComponent.show.emit(false)
    this.isActive = false;
    this.positionStyle = '';
    this.scroll.enableBodyScroll() 
  }
  plusClick() {
    this.number++
    this.change.emit(this.number)

  }
  minusClick() {
    if (this.number > 0) {
      this.number--
      this.change.emit(this.number)
    }
  }
  clear() {
    OverlayComponent.show.emit(false)
    this.scroll.enableBodyScroll()
    this.positionStyle = ''
    this.clearNumber.emit()
    this.number = 0
  }
  public setSelectPostion() {
    setTimeout(()=>{ 
      if (this.input && this.widnowWidth && window.innerWidth > 767 && this.isActive) {
        const selectRect = this.input.nativeElement.getBoundingClientRect();
        const selectR = selectRect.right;
        let windowWidth = window.innerWidth;
        let menuWidth = 82
        if (this.widnowWidth !== undefined) {
          windowWidth = this.widnowWidth;
          menuWidth = window.innerWidth - this.widnowWidth
        }
        const spaceToLeft = windowWidth - (windowWidth - selectR + 165) - menuWidth
        const spaceToRight = windowWidth - selectR + menuWidth
        // console.log('lewo',spaceToLeft)    
        // console.log('prawo',spaceToRight)
        if (spaceToLeft < 10) {
          if (this.left == false) {
            this.left = true
            this.right = false
          }
          this.positionStyle = 'transform: translateX(' + (Math.abs(spaceToLeft) + 20) + 'px)'
        }
        else if (spaceToRight < 10) {
          if (this.right == false) {
            this.right = true
            this.left = false
          }
          this.positionStyle = 'transform: translateX( -' + (Math.abs(spaceToRight) + 20) + 'px)'
        }
        else if ((spaceToLeft >= 10 && !this.left) && (spaceToRight >= 10 && !this.right)) {
          this.right = false
          this.left = false
          this.positionStyle = ''
        }
      }
    },0)
  }
  public open(){
    OverlayComponent.show.emit(true)
    this.isActive=true;
    this.setSelectPostion(); 
    this.scroll.disableBodyScroll()
  }
}
